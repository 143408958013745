<template>
  <v-container fluid fill-height style="background-color: #1e1e1e">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="mx-auto px-12 py-6" width="400px" max-width="100%" light shape>
          <div :style="{'background-image': `url(${images.logo})`}" class="card-logo">Crysp</div>

          <div v-if="isComplete">
            <p class="body-2 text-center">
              You have been successfully logged out!
            </p>

            <p class="body-2 text-center">
              You will be automatically redirected to the login page in 5 seconds.<br />
            </p>

            <v-btn
              class="mt-4"
              color="primary"
              depressed
              width="100%"
              :to="{ name: 'login' }"
            >
              Return to Login
            </v-btn>
          </div>

          <v-row align="center" justify="center" v-if="isLoading">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Logout',
  data() {
    return {
      isLoading: false,
      isComplete: false,
      images: {
        logo: require("@/images/logo-crysp-dark.png")
      }
    }
  },
  computed: {
  },
  mounted() {
    this.handleLogout()
  },
  methods: {
    handleLogout() {
      this.isLoading = true

      this.$store.commit('AUTH_STORE/LOGOUT_START')

      this.$http.post('logout')
        .then(() => {
          this.isLoading = false

          this.$auth.removeData()
          this.$store.dispatch('AUTH_STORE/LOGOUT')
        },
        ({ error }) => {
          this.isComplete = true
          this.redirectToLogin(0)
        })
        .finally(() => {
          this.isComplete = true
          this.redirectToLogin(5000)
        })
    },
    redirectToLogin(timeout) {
      let t = setTimeout(() => {
        this.$router.push({ name: 'login' }, () => {})
      }, timeout)
    }
  }
}
</script>
