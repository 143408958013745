<template>
  <div v-bind:class="{ 'app-logo-wrapper': true, 'has-background': useBackground, 'is-centered': centered }">
    <div class="app-logo" :style="{'background-image': `url(${logo})`}"></div>
  </div>
</template>

<script>
import StorageManager from '@/_util/storage'
// import OrganisationUtils from '@/_util/organisation'
import axios from '../../_store/axios'
import { isValidJSON } from '@/_util/json'

export default {
  name: 'OrganisationLogo',
  mixins: [
    StorageManager,
    // OrganisationUtils
  ],
  props: {
    organisation: {
      type: Object,
      default: null
    },
    useBackground: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      profile: false,
      defaultLogo: require('@/images/logo-crysp-light.png'),
      logo: undefined
    }
  },
  computed: {
  },
  mounted() {
    this.setProfile(this.organisation)
  },
  watch: {
    organisation: function(n,o) {
      this.setProfile(n)
    },
    profile: function(n, o) {
      //put whole function inline here
      let token = localStorage.getItem('token');

      //check the json of the profile
      if (this.profile && isValidJSON(this.profile.logo)) {
        
        try {
          let logo = JSON.parse(this.profile.logo);
          let path = this.getStorageFile(logo.url);
          
          axios.get(path, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            responseType: 'blob' // Important for handling file downloads
          }).then((response) => {
            const imageUrl = URL.createObjectURL(response.data);
            this.logo = imageUrl;
          });
        } catch (error) {
          console.error("Error getting logo - ", error);
        }
      } else {
        this.logo = this.defaultLogo;
      }
    }
  },
  methods: {
    setProfile(d) {
      if (d && d.profile) {
        this.profile = d.profile
        this.profile.name = d.name
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .app-logo-wrapper {
    &.has-background {
      padding: 8px 12px;
      background-color: #1e1e1e;
      border-radius: 6px;
    }

    &.is-centered {
      .app-logo {
        background-position: center center;
      }
    }
  }

  .app-logo {
    min-width: 160px;
    max-width: 200px;

    height: 0px;
    max-height: 24px;

    padding-bottom: 24%;

    display: block;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;

    white-space: nowrap;

    text-indent: -9999px;
    
    cursor: pointer;
  }
</style>