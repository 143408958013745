<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Organisations"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card route>
            <v-row class="px-4 py-3" justify="end">

              <v-col cols="12" md="4" lg="6" class="py-1 d-flex align-center">
                <Can I="create" a="organisation">
                  <v-btn
                    :block="!$vuetify.breakpoint.mdAndUp"
                    outlined
                    color="primary"
                    :to="{ name: 'organisation/create' }"
                  >
                    <v-icon dark left>mdi-domain-plus</v-icon>Create New
                  </v-btn>


                    <v-spacer></v-spacer>
                  </Can>
                </v-col>

                <v-col cols="12" md="8" lg="6" class="py-1 d-flex justify-end">
                  <v-text-field
                    outlined
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    clearable
                    :disabled="isLoading"
                  ></v-text-field>
                </v-col>
              </v-row>

            <v-divider></v-divider>

            <v-data-table
              class="v-data-table--selectable"
              :headers="headers"
              :items="organisations"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              :search="search"
              :loading="isLoading"
              loading-text="Loading organisations... please wait."
            >

              <template v-slot:item.profile.avatar="{ item }">
                <v-row justify="center">
                  <v-col cols="12" class="text-center">
                    <OrganisationAvatar
                      v-if="$vuetify.breakpoint.smAndUp"
                      :organisation="item"
                      :size="32"
                      color="primary"
                    ></OrganisationAvatar>
                    <OrganisationAvatar
                      v-else
                      :organisation="item"
                      :size="60"
                      color="primary"
                    ></OrganisationAvatar>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>

              <template v-slot:item.status.name="{ item }">
                <v-chip v-if="item.status" small :color="item.status.name === 'Active' ? 'primary' : 'warning'"></v-chip>
              </template>

               <template v-slot:item.profile.industry_type.name="{ item }">
                {{ item.profile.industry_type.name }}
              </template>

              <template v-slot:item.edit="{ item }">
                <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        :to="{ name: 'organisation', params: { org_id: item.id }}"
                        :disabled="isLoading"
                      >
                        <v-icon medium>mdi-domain</v-icon>
                      </v-btn>
                    </template>
                    <span>View Organisation</span>
                  </v-tooltip>

                  <Can I="update" a="organisation">

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :to="{ name: 'organisation/edit', params: { org_id: item.id }}"
                          :disabled="isLoading"
                        >
                          <v-icon medium>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Organisation</span>
                    </v-tooltip>

                  </Can>
                </div>

                <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      outlined
                      :to="{ name: 'organisation', params: { org_id: item.id }}"
                      :disabled="isLoading"
                    ><v-icon dark left>mdi-domain</v-icon>
                    View Organisation</v-btn>
                  </v-col>

                  <Can I="update" a="organisation">
                    <v-col cols="12">
                      <v-btn
                        block
                        color="primary"
                        outlined
                        :to="{ name: 'organisation/edit', params: { org_id: item.id }}"
                        :disabled="isLoading"
                      >
                        <v-icon dark left>mdi-file-document-edit-outline</v-icon>
                        Edit Organisation
                      </v-btn>
                    </v-col>
                  </Can>

                </v-row>

              </template>

            </v-data-table>
          </v-card>

        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import moment from 'moment'

import BreadcrumbsManager from '@/_util/breadcrumbManager'
import OrganisationAvatar from '@/components/organisation/Avatar'

export default {
  name: 'OrganisationView',
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    OrganisationAvatar
  },
  data() {
    return {
      search: '',
      options: {},
      timer: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      organisations: state => state.ORG_STORE.organisations,
      serverItemsLength: state => {
        return state.ORG_STORE.organisations[0]?.total || 0
      },
      isLoading: state => state.ORG_STORE.loadingOrganisations
    }),
    headers() {
      let headers = []

      headers.push({ text: "", value: "profile.avatar", sortable: false })
      headers.push({ text: "Organisation Name", value: "name" })
      headers.push({ text: "Status", value: "status.name" })
      headers.push({ text: "Industry Type", value: "profile.industry_type.name" })
      headers.push({ text: "Organisation Type", value: "profile.organisation_type.name" })

      if (this.$can('update','organisation')) {
        headers.push({ text: "", value: "edit", sortable: false })
      }

      return headers
    }
  },
  beforeMount() {
    // this.getData()
  },
  mounted() {},
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true,
    },
    search() {
      this.getDataDebounced();
    }
  },
  methods: {
    getDataDebounced() {
      // cancel pending call
      clearTimeout(this.timer)

      // delay new call 500ms
      this.timer = setTimeout(() => {
        // ensure we request the first page (reset pagination)
        this.options.page = 1;
        this.getData()
      }, 500)
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const search = this.search;
      this.$store.dispatch('ORG_STORE/GET_ORGS', {sortBy, sortDesc, page, itemsPerPage, search})
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' }
    ])
  }
}
</script>

<style lang="scss">
  .v-data-table-header tr th {
    white-space: nowrap;
  }
</style>