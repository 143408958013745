<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>

    <v-card-title>
      Content
    </v-card-title>

    <v-card-subtitle>
      View <Can I="create" a="document">and create</Can> documents and more.
    </v-card-subtitle>
    
    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <v-btn :to="{ name: 'content/all'}">
        View All
      </v-btn>

      <v-spacer></v-spacer>

      <Can I="create" a="document">
        <v-btn color="primary" :to="{ name: 'content/create' }">
          Create New
        </v-btn>
      </Can>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Documents.png")
      }
    }
  }
}
</script>
