const parseUserRole = (user) => {
  return user.role
}

const parseUserPermissions = (user) => {
  if (user.permissions) {
    let r = []

    user.permissions.forEach(e => {
      let i = e.lastIndexOf("-")

      if (i != -1) {
        let p = e.substring(0, i)
        let a = e.substring(i+1, e.length)

        r.push([p,a])
      }
    })

    return r
  }
}

export {
  parseUserRole,
  parseUserPermissions
}
