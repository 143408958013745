import Vue from 'vue'
import Vuex from 'vuex'

import BREADCRUMBS from './modules/breadcrumbs'
import AUTH_STORE from './modules/auth'
import UTIL_STORE from './modules/util'
import ORG_STORE from './modules/organisation'
import USER_STORE from './modules/user'
import GROUP_STORE from './modules/group'
import FORM_STORE from './modules/form'
import CONTENT_STORE from './modules/content'
import POLICIES_STORE from './modules/policies'

Vue.config.productionTip = false

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    BREADCRUMBS,
    AUTH_STORE,
    UTIL_STORE,
    ORG_STORE,
    USER_STORE,
    GROUP_STORE,
    FORM_STORE,
    CONTENT_STORE,
    POLICIES_STORE
  },
  strict: debug
})
