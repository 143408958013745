<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route>
      <v-card-title class="primary primary--text--contrast">Manage Groups</v-card-title>

      <v-row class="px-4 py-3" justify="end">

        <v-col cols="12" md="4" lg="6" class="py-1 d-flex align-center">
          <Can I="create" a="group">
            <v-btn
              :block="!$vuetify.breakpoint.mdAndUp"
              outlined
              color="primary"
              :to="{ name: 'organisation/groups/create' }"
            >
              <v-icon dark left>mdi-account-multiple-plus-outline</v-icon>Create New
            </v-btn>

            <v-spacer></v-spacer>
          </Can>
        </v-col>

        <v-col cols="12" md="8" lg="6" class="py-1 d-flex justify-end">
          <v-text-field
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            clearable
            :disabled="isLoading"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-divider></v-divider>

      <v-data-table
        class="v-data-table--selectable"
        :headers="headers"
        :items="groups"
        :items-per-page="10"
        :search="search"
        :loading="isLoading"
        loading-text="Loading groups... please wait."
      >
        <template v-slot:no-data>
          No groups available.
        </template>

        <template v-slot:item="{ item }">
          <tr @click.stop="$router.push({ name: 'organisation/groups/edit', params: { group_id: item.id }})" class="clickable-row" >
            <td>{{ item.name }}</td>
            <td>
              <p class="my-0" v-if="item.users.length">{{ item.users.length }} member{{ item.users.length > 1 ? 's' : '' }} assigned to this group.</p>
              <p class="my-0" v-if="!item.users.length">No members assigned to this group.</p>
            </td>
            <td>
              <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      route
                      icon
                      v-on="on"
                      :to="{ name: 'organisation/groups/edit', params: { group_id: item.id }}"
                      :disabled="isLoading"
                    >
                      <v-icon medium>mdi-account-group</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Group</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.stop="confirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                      icon
                      :disabled="isLoading"
                      v-on="on"
                    >
                      <v-icon medium>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Group</span>
                </v-tooltip>
              </div>

              <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">
                <v-col cols="12">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    :to="{ name: 'organisation/groups/edit', params: { group_id: item.id }}"
                    :disabled="isLoading"
                  >
                    <v-icon dark left>mdi-account-group</v-icon>
                    Edit Group
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    @click.stop="confirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                    :disabled="isLoading"
                  >
                    <v-icon dark left>mdi-delete</v-icon>
                    Delete Group
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>

<!-- 

        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:item.members="{ item }">
            <p class="my-0" v-if="item.users.length">{{ item.users.length }} member{{ item.users.length > 1 ? 's' : '' }} assigned to this group.</p>
            <p class="my-0" v-if="!item.users.length">No members assigned to this group.</p>
        </template> -->

        <!-- <template v-slot:item.actions="{ item }">
        </template> -->

        <v-alert slot="no-results" :value="true" type="error" border="top" color="red lighten-2" class="mt-3">
          Your search for "{{ search }}" found no results.
        </v-alert>

      </v-data-table>
    </v-card>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="confirmation && !isLoading">
        <v-card class="mx-auto" max-width="480" light v-if="!isDeleting">

          <v-card-title class="primary primary--text--contrast mb-4">
            Confirm Delete Group
          </v-card-title>

          <v-card-text>
            Are you sure you want to delete group <strong>{{ toDelete.name }}</strong>? This action cannot be undone!
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text color="primary" @click.stop="confirmation = !confirmation, toDelete = {}">Cancel</v-btn>

            <v-spacer></v-spacer>

            <v-btn @click.stop="handleDeleteGroup" color="error">
              Delete
            </v-btn>

          </v-card-actions>
        </v-card>

        <v-row align="center" justify="center" v-if="isDeleting">
          <v-loading-spinner></v-loading-spinner>
        </v-row>
      </v-overlay>
    </v-fade-transition>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import * as Sentry from '@sentry/browser';

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ManageGroups',
  props: [
    'organisation',
    'users',
    'groups'
  ],
  mixins: [BreadcrumbsManager],
  data() {
    return {
      search: '',
      headers: [
        { text: "Group Name", value: "name" },
        { text: "Members", value: "members", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],
      toDelete: {},
      confirmation: false,
      isDeleting: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.GROUP_STORE.loadingGroups
    })
  },
  methods: {
    getData() {
      this.$store.dispatch('GROUP_STORE/GET_GROUPS', this.$route.params.org_id)
    },
    handleDeleteGroup() {
      this.isDeleting = true

        this.$http.delete('group', {
          params: {
            group_id: parseInt(this.toDelete.id),
            confirm_delete: 1
          }
        })
        .then(data => {
          this.confirmation = false
          this.isDeleting = false

          this.$store.commit('GROUP_STORE/DELETE_GROUP', this.toDelete.id)

          this.$toast.success(`Group deleted successfully.`)

          this.toDelete = {}
        }, error => {
          this.$toast.error('Something has gone wrong deleting group, please try again.')

          //alert sentry
          Sentry.captureException(error);
        })
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted() {},
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Groups' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: { text: this.organisation.name, path: `/organisation/${this.$route.params.org_id}` }
    })
  }
}
</script>
