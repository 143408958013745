import { mapState } from 'vuex'
import auth from '@/_util/auth'
import axios from '@/_store/axios'

export default {
  data() {
    return {
      sessionRefreshColdBoot: true,
      sessionRefreshInterval: 300000,
      sessionIsRefreshing: false
    }
  },
  computed: {
    ...mapState({
      sessionIsAuthenticated: (state) => {
        return state.AUTH_STORE.authUser ? true : false
      },
      sessionIsRefreshBlocked: (state) => {
        return state.AUTH_STORE.loggingIn || state.AUTH_STORE.loggingOut
      }
    })
  },
  methods: {
    initRefreshTimer() {
      if (this.sessionRefreshColdBoot) {
        this.sessionRefreshColdBoot = false
        this.refreshToken();
      }

      let timer = setInterval(() => {
        this.refreshToken();
      }, this.sessionRefreshInterval)
    },
    async refreshToken() {
      if (!this.sessionIsAuthenticated ||
        this.sessionIsRefreshing ||
        this.sessionIsRefreshBlocked
      ) {
        return
      }

      this.sessionIsRefreshing = true

      const response = await axios.post('login/refresh')
        .then(response => {
          return response
        })

      const data = response.data;

      if (data.data) {
        if (data.data.token !== "OK") {
          if (this.sessionIsAuthenticated) {
            this.$store.commit('AUTH_STORE/LOGIN_UPDATE', { token: data.data.token })
          }

          auth.refresh(data.data.token, data.data.expires_in)
        }
      }

      this.sessionIsRefreshing = false
    }
  },
  created() {
    this.initRefreshTimer()
  }
}
