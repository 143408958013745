<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="6">
          <v-page-header value="Compliance Calendar"></v-page-header>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-row align="center" justify="center" v-if="!isLoading">
          <v-col cols="12" md="8" xl="6">
            <p>Compliance items here upcoming and need reviewing.</p>
            <v-row
                justify="end">
                <v-col>
                  <div style="width: 100px;">
                    <v-btn
                        depressed
                        block
                        color="primary"
                        @click="downloadCalendarFile"
                        >
                        <span>Download Calendar</span>
                    </v-btn>

                  </div>
                </v-col>
                <v-col md="4">
                    <v-menu
                        v-model="menu"
                        ref="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Select date/range"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            range
                            :show-adjacent-months="true"
                            @input="parseData(calendarItems,date)"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                md="2"
                >
                    <v-btn
                        depressed
                        block
                        height="42"
                        color="primary"
                        @click="date = [],parseData(calendarItems,null)"
                        >
                        <span>Clear</span>
                    </v-btn>
                </v-col>
            </v-row>
            <div
                v-for="(items,key) in parsedItems"
                :key="key">
                <!-- <p>{{ $moment(items[0].actioned_on).add(items[0].frequency, 'd').format('DD/MM/YYYY') }}</p> -->
                <div
                    v-for="item in items"
                    :key="item.id">
                    <p>{{ $moment(item.actioned_on).add(item.frequency, 'd').format('DD/MM/YYYY') }}</p>
                    <v-row>
                      <v-col>
                          <v-alert
                              outlined
                              text
                              dense
                              :icon="complianceStatuses.find((status) => status.id === item.complianceState.id).icon"
                              :type="complianceStatuses.find((status) => status.id === item.complianceState.id).type"
                          >
                              <b>{{ complianceStatuses.find((status) => status.id === item.complianceState.id).label }}</b>
                              {{ item.name }}
                          </v-alert>
                      </v-col>
                      <v-col
                      col="12"
                      md="2"
                      sm="3"
                      >
                          <v-btn
                          v-if="item.complianceState.id !== 2"
                          depressed
                          block
                          height="42"
                          color="primary"
                          @click="manageComplianceItem(item,'action')"
                          >
                          <span>Action</span>
                          </v-btn>
                      </v-col>
                    </v-row>
                </div>
            </div>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-row align="center" justify="center" v-if="!isLoading && Object.keys(parsedItems).length === 0">
          <v-col cols="12" md="8" xl="6">
            <v-card class="pa-10 mb-8">
              <div class="text-center mb-6">You are not managing any compliance items in the selected date range.</div>
              <div class="text-center">
                <v-btn
                  color="primary"
                  :to="{ name: 'dashboard' }"
                >
                  Return to Dashboard
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="managingComplianceItem == 'action' && !isLoading">
          <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!isActioning">
            <v-card-title class="primary primary--text--contrast mb-4">
              Manage Compliance Item
            </v-card-title>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-state-machine</v-icon>
                Name
              </span><br />
              <v-divider class="my-2"></v-divider>
              {{ toManage.name }}
            </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon :color="getColor(toManage.complianceState.id)" small>mdi-checkbox-blank-circle</v-icon>
                Status
              </span><br />
              <v-divider class="my-2"></v-divider>
              <div v-html="toManage.complianceState.name"></div>
            </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-text-box-outline</v-icon>
                Description
              </span><br />
              <v-divider class="my-2"></v-divider>
              <div v-html="toManage.description"></div>
            </v-card-text>

            <v-divider></v-divider>

            <div class="px-2 pt-4">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDate"
                        label="Actioned on"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="toManage.actioned_on"
                        :show-adjacent-months="true"
                        @input="menu2 = false"
                        :max="$moment().format('YYYY-MM-DD')"
                    >
                    </v-date-picker>
                </v-menu>
            </div>

            <div class="px-2 pt-2 pb-4">
              <v-select
                :items="complianceActions"
                v-model="toManage.action"
                item-text="name"
                item-value="id"
                label="Select Action"
                outlined
                hide-details
              ></v-select>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click="manageComplianceItem(false,null)">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn color="primary" @click="handleActionComplianceItem">
                Update
              </v-btn>

            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isActioning">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-row align="center" justify="center" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ViewCalendar',
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  components: {
  },
  data() {
    return {
      date: [],
      menu: false,
      menu2: false,
      managingComplianceItem: null,
      toManage: {},
      isActioning: false,
      complianceActions: [
        { id: 1, name: "Mark as Compliant" },
        { id: 2, name: "Mark as Warning" },
        { id: 0, name: "Mark as Outstanding" }
      ],
      complianceStatuses: [
        { id: 2, type: 'success', label: "Complete", icon: 'mdi-check-circle' },
        { id: 3, type: 'warning', label: "Due", icon: 'mdi-alert-circle' },
        { id: 4, type: 'error', label: "Overdue", icon: 'mdi-close-circle' },
        { type: 'warning', label: "Due" },
      ],
      parsedItems: {},
    }
  },
  computed: {
    ...mapState({
      calendarItems: state => state.CONTENT_STORE.userComplianceCalendarItems,
      isLoading: state => state.CONTENT_STORE.loadingUserComplianceCalendarItems
    }),
    formattedDate() {
      return this.$moment(this.toManage.actioned_on).format('DD/MM/YYYY');
    }
  },
  beforeMount() {
    this.getData()
    this.parseData(this.calendarItems,null)
  },
  methods: {
    getData() {
      this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_CALENDAR_ITEMS', this.date[0], this.date[1])
    },
    downloadCalendarFile() {
      this.$http.get('content/compliance/icalendar', { responseType: 'blob' })
        .then(response => {
          console.log(response.data)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'compliance_calendar.ics')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$toast.error('Something went wrong downloading the calendar file, please try again.')
        })
    },
    parseData(d,r) {
      let items = {};

      const sortedDates = r ? r.sort((a, b) => this.$moment(a) - this.$moment(b)) : null;

      if (d && sortedDates) {
        // console.log(sortedDates[0], sortedDates[sortedDates.length - 1]);
        for (const key in d) {
          if (this.$moment(key).isBetween(sortedDates[0], sortedDates[sortedDates.length - 1], null, '[]')) {
            items[key] = d[key];
          }
        }
        return this.parsedItems = items;
      }

      return this.parsedItems = d;

    },
    handleActionComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isActioning = true

      let variables = {
        content_id:         parseInt(this.toManage.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        pass_or_failed:     parseInt(this.toManage.action),
        actioned_on:        `${this.$moment(this.toManage.actioned_on).format('YYYY-MM-DD')} ${this.$moment().format('HH:mm:ss')}`
      }

      this.$http.post('content/compliance/action', variables)
        .then(response => {
          this.isActioning = false

          this.$toast.success('Successfully actioned compliance item.')

          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true)
          this.manageComplianceItem(false,null)
          this.getData();
        })
        .catch(error => {
          this.isActioning = false

          this.$toast.error('Something went wrong actioning compliance item, please try again.')
        })
    },
    manageComplianceItem(item,method) {
      this.managingComplianceItem = method
      this.toManage.action = false
      this.toManage = item ? {...item} : {}

      let currentDate = new Date();
  
      if(new Date(this.toManage.actioned_on) != currentDate) {
        this.toManage.actioned_on =  this.$moment(currentDate).format('YYYY-MM-DD hh:mm:ss');
      } 
    },
    getColor(state) {
      if (state == 2) {
        return '#4caf50'
      }

      if (state == 3) {
        return '#fb8c00'
      }

      if (state == 4) {
        return '#ff5252'
      }
    },
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Compliance Calendar' }
    ])
  },
  watch: {
    calendarItems(payload) {
      this.parseData(payload)
    },
    date(payload) {
      if (payload.length > 1) {
        this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_CALENDAR_ITEMS', payload)
      }
    }
  }
}
</script>

<style scoped>
  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  .scrollable-content {
    max-height: 640px; /* Adjust as needed */
    overflow-y: auto;
  }

</style>