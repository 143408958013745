<template>
  <v-card>
    <v-card-title class="primary primary--text--contrast">My Details</v-card-title>

    <ValidationObserver v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="" v-if="!isLoading">

        <v-row class="mt-4">

          <v-col cols="12" class="px-8">

            <v-row>

              <v-col cols="12" sm="5" md="4">

                <v-row align="center" justify="center" class="mb-4">
                  <UserAvatar
                    :profile="userProfile"
                    :size="128"
                    color="primary"
                  ></UserAvatar>
                </v-row>

                <v-row align="center" justify="center" class="text-center">
                  <UploadModal
                    uploadType="user_avatar"
                    buttonLabel="Upload Image"
                    modalLabel="Upload Profile Image"
                    @onUploaded="handleUploadedProfilePicture"
                  ></UploadModal>
                </v-row>

                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <div class="d-flex align-center justify-center">
                      <v-card flat outlined>
                        <div class="px-6 py-1"><small class="d-block">File Type: PNG, JPG</small></div>
                        <v-divider></v-divider>
                        <div class="px-6 py-1"><small class="d-block">Ideal Size: 512x512</small></div>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="7" md="8">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="Username"
                  placeholder="Username"
                  v-bind:value="user.username"
                ></v-text-field>

                <v-text-field
                  outlined
                  disabled
                  dense
                  hide-details="auto"
                  label="Email Address"
                  placeholder="Email Address"
                  v-bind:value="user.email"
                ></v-text-field>

                <v-divider class="my-8"></v-divider>

                <VTextFieldWithValidation
                  rules="required"
                  v-model="userProfile.first_name"
                  label="First Name"
                />

                <VTextFieldWithValidation
                  rules="required"
                  v-model="userProfile.last_name"
                  label="Last Name"
                />

                <VTextFieldWithValidation
                  rules="required"
                  v-model="userProfile.job_title"
                  label="Job Title"
                  placeholder="Crysp User"
                />
              </v-col>

            </v-row>

          </v-col>

          <v-col cols="12" class="py-0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="px-8">

            <v-card-actions class="px-0">
              <v-spacer></v-spacer>

              <v-btn
                outlined
                color="primary"
                type="submit"
                @click="passes(handleSubmit)"
              >
                Update Profile
              </v-btn>
            </v-card-actions>

          </v-col>

        </v-row>

      </v-form>
    </ValidationObserver>

    <v-row align="center" justify="center" v-if="isLoading">
      <v-loading-spinner></v-loading-spinner>
    </v-row>

  </v-card>
</template>

<script>
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import UserAvatar from '@/components/user/Avatar'
import UploadModal from '@/components/upload/UploadModal'

import { mapState } from 'vuex'

export default {
  name: 'UpdateProfile',
  components: {
    VTextFieldWithValidation,
    UserAvatar,
    UploadModal
  },
  props: ['user'],
  computed: {
    ...mapState({
      isLoading: state => state.AUTH_STORE.updatingUser
    }),
    userProfile: function() {
      const obj = {}

      Object.keys(this.user.profile).map((key, index) => {
        obj[key] = this.user.profile[key]
      })

      return obj
    }
  },
  mounted() {
  },
  methods: {
    async handleSubmit(data = {}) {

      this.$store.commit('AUTH_STORE/UPDATING_USER')

      const profileData = this.userProfile

      Object.keys(data).map((key, index) => {
        profileData[key] = data[key]
      })

      const user = await this.$http.put('user', profileData)
        .then(response => {
          return response.data.data[0]
        })
        .catch(error => {
          this.$store.commit('AUTH_STORE/UPDATING_USER_STOP')
          this.$toast.error('Something went wrong updating your profile, please try again.')
        })


      if (user) {
          this.$store.commit('AUTH_STORE/UPDATE_USER', user)
          this.$store.commit('AUTH_STORE/UPDATING_USER_STOP')
          this.$toast.success('Profile successfully updated.')
      }

    },
    async handleUploadedProfilePicture(data) {
      if (data) {
        const file = data[0]

        if (file) {
          this.handleSubmit({
            avatar: `${file.id}:${file.meta.path}`
          })
        }
      }
    }
  }
}
</script>
