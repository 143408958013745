<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>

    <v-card-title>
      Users & Groups
    </v-card-title>

    <v-card-subtitle>
      Manage users and groups.
    </v-card-subtitle>

    <v-spacer></v-spacer>

    <div class="px-4 mb-2">
      <v-autocomplete outlined dense flat return-object
          height="35" label="Select" hide-details="auto" item-text="name" item-value="id"
          v-model="organisation"
          :loading="isLoading"
          :items="organisations"
      >
      </v-autocomplete>
    </div>

    <v-card-actions class="mb-2 px-4">

      <v-btn disabled v-if="!organisation">
        Groups
      </v-btn>

      <v-btn disabled v-if="!organisation">
        Users
      </v-btn>

      <v-btn :to="{ name: 'organisation/groups', params: { org_id: organisation.id }}" v-if="organisation">
        Groups
      </v-btn>

      <v-btn :to="{ name: 'organisation/users', params: { org_id: organisation.id }}" v-if="organisation">
        Users
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Groups.png")
      },
      organisation: null
    }
  },
  computed: {
    ...mapState({
      organisations: state => state.ORG_STORE.organisations,
      isLoading: state => state.ORG_STORE.loadingOrganisations
    })
  },
  beforeMount() {
    this.getData()
  },
  mounted() {},
  methods: {
    getData() {
      this.$store.dispatch('ORG_STORE/GET_ORGS')
    }
  }
}
</script>
