<template>
    <v-row no-gutters>
        <v-col class="tertiary white--text pa-12">
            <OrganisationLogo />
            <h1 class="mb-5">Time’s up! <span class="font-weight-light">- 14 day free trial</span></h1>
            <p>We hope you’ve enjoyed using Crysp, so much so that your ready to signup and take advantage of being fully compliant long-term.</p>
            <p>We have 3 varying plans that you can pay for on an annual or monthly basis.</p>

            
            <v-layout align-center>
                <b>Pay Monthly</b>
                
                <v-switch
                v-model="frequency"
                true-value="year"
                false-value="month"
                inset
                class="ml-5 mr-2"
                ></v-switch>

                <b>Pay Yearly</b>
            </v-layout>

            <p>Remember:</p>

            <p>All Crysp accounts are safe, secure, password-protected and backed-up to our secure cloud infrastructure</p>
            <p>Cancel Anytime</p>
        </v-col>
        <v-col class="pa-12 d-flex">
            <v-layout column justify-space-between>
                <stripe-checkout
                    ref="checkoutRef"
                    mode="subscription"
                    :pk="publishableKey"
                    :line-items="lineItems"
                    :success-url="successURL"
                    :cancel-url="cancelURL"
                    @loading="v => loading = v"
                />
                <v-expansion-panels accordion multiple>
                    <v-expansion-panel
                        v-for="(product,i) in products"
                        :key="i"
                        class="mb-4">
                        <v-expansion-panel-header>
                            <div>
                            <p><b>{{ product.name }}</b> <v-chip label color="tertiary" dark small class="ml-2" v-if="product.name == 'Enterprise Package'"><b>Best value</b></v-chip></p>
                            {{ product.desc }}
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-divider class="mb-5"></v-divider>
                            <p class="body-2 tertiary--text">{{ product.more }}</p>
                            <v-layout align-end>
                                <v-flex xs3 v-if="product.name != 'Enterprise Package'">
                                <v-select
                                    :items="propertyCount"
                                    label="Properties"
                                    v-model="product.quantity"
                                    outlined
                                    class="mr-3"
                                    hide-details
                                    dense
                                ></v-select>
                                </v-flex>
                                <b v-if="product.name != 'Enterprise Package'">&pound;{{ product.price[frequency].value }} /{{frequency}}</b>

                                <v-flex grow>
                                <v-layout justify-end>
                                    <v-btn
                                    color="primary"
                                    :loading="loading"
                                    @click="generateLineItems(product.id)"
                                    v-text="product.name == 'Enterprise Package' ? 'Contact Us' : 'Select Plan'"></v-btn>
                                </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn text plain href="https://www.crysp.co.uk/pricing" target="_blank" class="mt-auto">See a full price comparison</v-btn>
                <v-btn text plain @click="handleLogout">Logout</v-btn>
            </v-layout>
        </v-col>
    </v-row>
</template>

<script>

import OrganisationLogo from '@/components/organisation/Logo'

import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    name: 'Plan',
    components: {
        OrganisationLogo,
        StripeCheckout
    },
    data() {
        this.publishableKey = 'pk_live_51LFaoTLU9wmp7LXi4MqvmeVaAG5fwrwQcfslwIKAzj7oaDiT45mhOXn91n1Xr52nrEAOmV0jfK6oVFRreX396Tk700h6i7KToR';
        return {
            loading: false,
            propertyCount: [1,2,3,4,5,6,7,8,9],
            properties: 1,
            frequency: 'month',
            lineItems: [],
            products: [
                {
                    name: 'Enterprise Package',
                    id: 'prod_Lxwyaf9Kq019s3',
                    desc: 'Good for organisations that need a platform, support, and annual property audits',
                    quantity: 1,
                    price: {
                        month: {
                            value: '150',
                            id: 'price_1LG14nLU9wmp7LXirW7Ku6yB'
                        },
                        year: {
                            value: ' 1,800',
                            id: 'price_1LG14nLU9wmp7LXiAVYvA42j'
                        }
                    }
                },
                {
                    name: 'Standard Package',
                    id: 'prod_LxwybMWAHO3m4F',
                    desc: 'Good for organisations that need a platform and virtual support',
                    quantity: 1,
                    price: {
                        month: {
                            value: '30.75',
                            id: 'price_1LG14KLU9wmp7LXiRPcigYGj'
                        },
                        year: {
                            value: '369',
                            id: 'price_1LG14KLU9wmp7LXi70Rlgo7n'
                        }
                    }
                },
                {
                    name: 'Starter Package',
                    id: 'prod_LxwxH2titoABva',
                    desc: 'Good for organisations that just need a platform',
                    quantity: 1,
                    price: {
                        month: {
                            value: '13.25',
                            id: 'price_1LGMc2LU9wmp7LXiQYrJl1g0'
                        },
                        year: {
                            value: '159',
                            id: 'price_1LG13ZLU9wmp7LXicQN4s2FQ'
                        }
                    }
                }
            ],

            successURL: 'https://portal.crysp.co.uk/subscribe?success=true',
            cancelURL: 'https://portal.crysp.co.uk/subscribe?cancel=true',
        }
    },
    methods: {
        handleLogout: function() {
            this.$store.commit('AUTH_STORE/LOGOUT_START')

            this.$http.post('logout')
            .then(() => {
                this.isLoading = false

                this.$auth.removeData()
                this.$store.dispatch('AUTH_STORE/LOGOUT')
            },({ error }) => {
                this.isComplete = true
                this.redirectToLogin(0)
            })
            .finally(() => {
                this.isComplete = true
                this.redirectToLogin(0)
            })
        },  
        redirectToLogin(timeout) {
            let t = setTimeout(() => {
                this.$router.push({ name: 'login' }, () => {})
            }, timeout)
        },
        generateLineItems: function(id) {
            if (id == 'prod_Lxwyaf9Kq019s3') {
                window.location = 'https://www.crysp.co.uk/contact';
            } else {
                this.lineItems = [];
                const product = this.products.find(product => product.id === id);
                this.lineItems = [
                    {
                        price: product.price[this.frequency].id,
                        quantity: product.quantity
                    }
                ]
                this.successURL = this.successURL+`&product=${product.name.toLowerCase().replace(/[\s_-]+/g, '-')}`;
                this.cancelURL = this.cancelURL+`&product=${product.name.toLowerCase().replace(/[\s_-]+/g, '-')}`;
                this.submit();
            }
        },
        submit() {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        }
    }
}
</script>
