<template>
  <v-main>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Content"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card route>

            <v-row class="px-4 py-3" dense justify="end">

              <v-col cols="12" md="4" lg="3" class="py-1 d-flex align-center">
                <Can I="create" a="document">
                  <v-btn
                    :block="!$vuetify.breakpoint.mdAndUp"
                    outlined
                    color="primary"
                    :to="{ name: 'content/create' }"
                  >
                    <v-icon dark left>mdi-text-box-plus-outline</v-icon>Create New
                  </v-btn>

                  <v-spacer></v-spacer>
                </Can>
              </v-col>

              <v-col>
                <v-select
                  :items="headers.slice(0,-1)"
                  outlined
                  dense
                  hide-details
                  v-model="sort"
                  prepend-inner-icon="mdi-filter-outline"
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <v-btn outlined block class="px-5 v-btn--icon" @click.stop="descending = !descending" min-height="40">
                  <v-icon dark v-text="descending ? 'mdi-sort-descending' : 'mdi-sort-ascending'"></v-icon>
                </v-btn>
              </v-col>

              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      block
                      v-on="on"
                      class="px-5 v-btn--icon"
                      @click.stop="parent_content = !parent_content"
                      min-height="40">
                      <v-icon dark v-text="parent_content ? 'mdi-file-tree-outline' : 'mdi-file-tree'"></v-icon>
                    </v-btn>
                  </template>
                  <span>{{ parent_content ? 'Show Content Children' : 'Hide Content Children' }}</span>
                </v-tooltip>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                  :disabled="isLoading"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-divider></v-divider>

            <v-data-table
              class="v-data-table--selectable"
              :headers="headers"
              :items="contentFiltered"
              :options.sync="options"
              :server-items-length="contentFilteredLength"
              :search="search"
              :loading="isLoading"
              loading-text="Loading content... please wait."
            >

              <template v-slot:no-data>
                No content available.
              </template>

              <template v-slot:item="{ item }">
                <tr @click.stop="$router.push({ name: 'content', params: { content_id: item.id }})" class="clickable-row">
                  <td>
                    <span v-if="item.meta.name">
                      {{ item.meta.name }}
                    </span>
                    <!-- <template v-slot:item.meta.name="{ item }">
                      {{ item.meta.name }}
                    </template> -->
                  </td>
                  <!-- <td>
                    <span v-if="item.ownership.organisation_id">
                      <Can I="manage" a="organisations">
                        {{ item.ownership.organisation_id|getOrganisationName }}
                      </Can>
                    </span>
                  </td>
                  <td>
                    <span v-if="item.status">
                      <v-chip small :color="item.status === 2 ? 'primary' : 'warning'"></v-chip>
                    </span>
                  </td> -->
                  <td>
                    <!-- <template v-slot:item.unix="{ item }"> -->
                      <span v-if="item.unix">
                        {{ formatDate(item.unix, item.viewed_at) }}
                      </span>
                    <!-- </template> -->
                  </td>
                  <td>
                    <!-- <template v-slot:item.viewed_at="{ item }"> -->
                      <span v-if="item.viewed_at">
                        <div class="d-flex align-center">
                          <span v-if="item.viewed_at">
                            {{ item.viewed_at | moment("DD/MM/YYYY") }} : {{ item.viewed_at | moment("h:mma") }}
                            <small v-if="item.viewed_by" class="d-block">By {{ item.viewed_by }}</small>
                          </span>
                          <span v-else>Never</span>
                        </div>
                      </span>
                    <!-- </template> -->
                  </td>
                  <td>
                    <span key="item.actions">
                      <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">
  
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                              :to="{ name: 'content', params: { content_id: item.id }}"
                              :disabled="isLoading"
                            >
                              <v-icon medium>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View Content</span>
                        </v-tooltip>
  
                        <Can I="update" a="document">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                :to="{ name: 'content/edit', params: { content_id: item.id }}"
                                :disabled="isLoading"
                              >
                                <v-icon medium>{{ $vuetify.icons.values.editContent }}</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Content</span>
                          </v-tooltip>
                        </Can>
  
                        <Can I="delete" a="document">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn @click.stop="
                                contentDeleteConfirmation = true,
                                toDelete['id'] = item.id,
                                toDelete['name'] = item.meta.name"
                                icon
                                v-on="on"
                                :disabled="isLoading"
                              >
                                <v-icon medium>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Content</span>
                          </v-tooltip>
                        </Can>
  
                      </div>
                      <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">
  
                        <v-col cols="12">
                          <v-btn
                            color="primary"
                            block
                            outlined
                            :to="{ name: 'content', params: { content_id: item.id }}"
                            :disabled="isLoading"
                          >
                            <v-icon dark left>mdi-eye</v-icon>
                            View Content
                          </v-btn>
                        </v-col>
  
                        <Can I="update" a="document">
                          <v-col cols="12">
                            <v-btn
                              color="primary"
                              block
                              outlined
                              :to="{ name: 'content/edit', params: { content_id: item.id }}"
                              :disabled="isLoading"
                            >
                              <v-icon dark left>{{ $vuetify.icons.values.editContent }}</v-icon>
                              Edit Content
                            </v-btn>
                          </v-col>
                        </Can>
  
                        <Can I="delete" a="document">
                          <v-col cols="12">
                            <v-btn @click.stop="
                              contentDeleteConfirmation = true,
                              toDelete['id'] = item.id,
                              toDelete['name'] = item.meta.name"
                              color="primary"
                              block
                              outlined
                              :disabled="isLoading"
                            >
                              <v-icon dark left>mdi-delete</v-icon>
                              Delete Content
                            </v-btn>
                          </v-col>
                        </Can>
                      </v-row>
                    </span>
                    <!-- <template v-slot:item.actions="{ item }">
                    </template> -->
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="contentDeleteConfirmation && !isLoading">
          <v-card class="mx-auto" max-width="480" light v-if="!isDeleting">
            <v-card-title class="primary primary--text--contrast mb-4">
              Confirm Delete Content
            </v-card-title>

            <v-card-text>
              Are you sure you want to delete content <strong>{{ toDelete.name }}</strong>? This action cannot be undone!
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click.stop="contentDeleteConfirmation = !contentDeleteConfirmation, toDelete = {}">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn @click.stop="deleteContent" color="error">
                Delete
              </v-btn>

            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isDeleting">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ViewContent',
  mixins: [
    BreadcrumbsManager
  ],
  components: {},
  data() {
    return {
      search: '',
      toDelete: {},
      contentDeleteConfirmation: false,
      isDeleting: false,
      filterOption: 1,
      filterOptions: [
        { name: 'All Content', value: 1 },
        { name: 'Root Content', value: 2 }
      ],
      options: {},
      timer: null,
      filter: null,
      sort: 'meta.name',
      descending: true,
      updateOptionsBasedOnManualSortControls: true,
      parent_content: false
    }
  },
  computed: {
    ...mapState({
      content: state => state.CONTENT_STORE.content,
      serverItemsLength: state => {
        return state.CONTENT_STORE.content[0]?.total || 0
      },
      isLoading: state => state.CONTENT_STORE.loadingContent,
    }),
    contentFiltered() {
      if (!this.content.length) {
        return []
      }

      if (this.isLoading) {
        return this.content
      }

      let content = []

      this.filterOption == 1
        ? content = this.content
        : content = this.content.filter(function(item) { return item.parent_id == null });

      return content.length ? content : [content]
    },
    contentFilteredLength() {
      return this.contentFiltered.length
    },
    headers() {
      let headers = []

      headers.push({ text: "Name", value: "meta.name" })

      if (this.$can('manage','organisations')) {
        headers.push({ text: "Organisation", value: "ownership.organisation_id", sortable: false })
      }

      // headers.push({ text: "Status", value: "status" })
      headers.push({ text: "Last Updated", value: "unix" })
      headers.push({ text: "Last Accessed", value: "viewed_at" })
      headers.push({ text: "", value: "actions", sortable: false })

      return headers
    }
  },
  beforeMount() {
    // this.getData()
  },
  mounted() {},
  watch: {
    options: {
      handler () {
        this.updateOptionsBasedOnManualSortControls = false;
        this.sort = this.options.sortBy[0];
        this.descending = this.options.sortDesc[0];
        this.updateOptionsBasedOnManualSortControls = true;
        this.getData()
      },
      deep: true,
    },
    search() {
      this.getDataDebounced();
    },
    sort() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortBy = [this.sort];
      }
    },
    descending() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortDesc = [this.descending];
      }
    },
    parent_content() {
      if (this.parent_content) {
        return this.filterOption = 2;
      }
      return this.filterOption = 1;
    }
  },
  methods: {
    getDataDebounced() {
      // cancel pending call
      clearTimeout(this.timer)

      // delay new call 500ms
      this.timer = setTimeout(() => {
        // ensure we request the first page (reset pagination)
        this.options.page = 1;
        this.getData()
      }, 500)
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search;
      this.$store.dispatch('CONTENT_STORE/GET_CONTENT', {sortBy, sortDesc, page, itemsPerPage, search})
    },
    deleteContent() {
      this.isDeleting = true

      this.$http.delete('content', {
        params: {
          id: parseInt(this.toDelete.id)
        }
      }).then(data => {
          this.contentDeleteConfirmation = false
          this.isDeleting = false

          // Remove from store
          this.$store.commit('CONTENT_STORE/DELETE_CONTENT', this.toDelete.id)

          // Show message
          this.$toast.success(`${this.toDelete.name} archived successfully.`)

          // Clear deletion
          this.toDelete = {}
        }, error => {
          console.error('Something has gone wrong deleting content')
        })
    },
    formatDate(unixDate, viewedDate) {
      try {
        return this.$moment(unixDate * 1000).format('DD/MM/YYYY')
      } catch (e) {
        return 'N/A'
      }
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Content' }
    ])
  }
}
</script>