<template>
  <div>
    <v-btn
      :outlined="buttonOutlined"
      :color="buttonOutlined ? 'primary' : ''"
      @click.stop="openModal()"
      :disabled="disabled"
      :class="this.classList"
      :icon="buttonIconOnly"
    >
      <v-icon :left="buttonLabel != ''" v-if="buttonIcon">{{ buttonIcon }}</v-icon>
      {{ this.buttonLabel }}
    </v-btn>

    <v-dialog
      v-model="uploadModal"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary primary--text--contrast text-center py-3">
          {{ this.modalLabel }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.stop="closeModal()"
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-4 pb-0">
          <span class="overline">
            Policy Details
          </span><br />
          <v-divider class="mt-2"></v-divider>
        </v-card-text>

        <v-layout v-if="isUploading" style="overflow: hidden;">
          <v-row align="center" justify="center">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-layout>

        <v-row class="px-4 pt-2">
          <v-col cols="12">
            <v-text-field
            v-model="toUpdate.name"
            label="Policy Name"
            hide-details
            outlined
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" v-if="!isLoading">
            <v-autocomplete
              v-model="toUpdate.viewer_groups"
              :items="groups"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Assign to Group"
              multiple
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="px-4">
          <v-col :cols="toUpdate.next_review_date !== null ? 4 : 6">
            <v-menu
              v-model="showDp"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                :value="toUpdate.last_review_date | moment('DD/MM/YYYY')"
                label="Last review date"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toUpdate.last_review_date"
                @input="showDp = false;updateNextReviewDate()"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col :cols="toUpdate.next_review_date !== null ? 4 : 6">
            <v-select
              v-model="toUpdate.reviewFrequency"
              :items="reviewFrequencies"
              item-text="name"
              item-value="value"
              :menu-props="{ maxHeight: '400' }"
              label="Review Frequency"
              hide-details
              outlined
              @change="updateNextReviewDate()"
            ></v-select>
          </v-col>

          <v-col v-if="toUpdate.next_review_date !== null" cols="4">
            <v-text-field
              :value="toUpdate.next_review_date | moment('DD/MM/YYYY')"
              label="Next review date"
              readonly
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="px-4">
          <v-col cols="9">
            <v-autocomplete
              v-model="toUpdate.owner_id"
              :items="users"
              item-text="profile.full_name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Policy owner"
              outlined
              hide-details></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
            v-model="toUpdate.version"
            label="Version No."
            hide-details
            outlined
            readonly
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-card-text class="pa-4">
          <span class="overline">
            Description
          </span><br />
          <v-divider class="my-2"></v-divider>
          <!-- <v-textarea
            v-model="toUpdate.description"
            label="Policy Description"
            outlined
            hide-details></v-textarea> -->
          <TextEditor v-model="toUpdate.description" light :label="'Policy Description'"></TextEditor>
        </v-card-text>

        <v-card-text class="pa-4">
          <span class="overline">
            Approval
          </span><br />
          <v-divider class="my-2"></v-divider>
          <div>A member needs to accept that they have read, understood and will implement policy.</div>
        </v-card-text>

        <v-row class="px-4">
          <v-col cols="8">
            <v-switch
              v-model="toUpdate.requires_acknowledgement"
              inset
              label="Requires Acknowledgement"
            ></v-switch>
          </v-col>

          <v-col cols="4" v-if="toUpdate.requires_acknowledgement">
            <!-- <v-dialog
              ref="dialog"
              v-model="modal3"
              :return-value.sync="toUpdate.time_threshold"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }"> -->
                <v-text-field
                  v-model="toUpdate.time_threshold"
                  label="Time Threshold"
                  placeholder="HH:mm"
                  hide-details
                  outlined
                  append-icon="mdi-clock-outline"
                ></v-text-field>
               <!-- </template>
             <v-time-picker
                v-if="modal3"
                :allowed-hours="allowedHours"
                format="24hr"
                scrollable
                v-model="toUpdate.time_threshold"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal3 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(toUpdate.time_threshold)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog> -->
          </v-col>
        </v-row>

        <div class="pa-4" v-if="!isUploading">
          <div
            :class="['file-input-container', isDragging ? 'is-dragging' : '']"
          >
            <div
              :class="['file-input-overlay', isDragging ? 'is-dragging' : '']"
            >
              Drop Files
            </div>
            <div class="file-input-wrapper" @drop="handleFileDrop">
              <input v-if="uploadModal" type="file" ref="uploadFileInput" name="uploadFileInput" @change="handleFileInput"/>
            </div>
            <div class="file-input-label">Drag &amp; Drop Files or Click to Browse</div>
          </div>

          <v-list
            dense
            v-if="uploadFiles.length && !autoUpload"
          >
            <template v-for="(uploadFile, i) in uploadFiles">
              <v-list-item
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="uploadFile.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn
                    icon
                    small
                    @click="handleFileRemove(i)"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider
                v-if="i + 1 < uploadFiles.length"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </div>

        <v-divider v-if="!autoUpload"></v-divider>

        <v-card-actions v-if="!autoUpload" class="pa-4">
          <v-btn text color="primary" @click.stop="closeModal()">Cancel</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            outlined
            color="primary"
            :disabled="!uploadFiles.length && !toUpdate.file_path || isUploading || toUpdate.owner_id === null"
            @click="handleFileUpload()"
          >
            {{ this.modalLabel }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import TextEditor from '@/components/inputs/TextEditor'

export default {
  name: 'UploadPolicyModal',
  components: {
    TextEditor
  },
  props: {
    policy: {
      type: Object,
      default() {
        return {
          name: '',
          version: 0,
          description: '',
          requires_acknowledgement: false,
          groups: [],
          owner_id: null
        }
      }
    },
    reseller_id: {
      default: false
    },
    organisation_id: {
      default: false
    },
    uploadType: {
      type: String,
      default: 'user_generic'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    closeOnUpload: {
      type: Boolean,
      default: true
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    buttonIconOnly: {
      type: Boolean,
      default: false
    },
    buttonIcon: {
      type: String,
      default: 'mdi-upload'
    },
    buttonOutlined: {
      type: Boolean,
      default: true
    },
    modalLabel: {
      type: String,
      default: 'Upload'
    },
    modalDescription: {
      type: String,
      default: ''
    },
    flush: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classList: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      toUpdate: {},
      showDp: false,
      isUploading: false,
      isDragging: false,
      lastTarget: null,
      uploadModal: false,
      uploadFiles: [],
      allowMultiple: false,
      modal: false,
      modal2: false,
      modal3: false,
      owners: [],
      reviewFrequencies: [
        { value: 0, name: 'None'},
        { value: 0.25, name: '3 Months'},
        { value: 0.5, name: '6 Months'},
        { value: 1, name: '1 Year'},
        { value: 1.5, name: '1 Year 6 Months'},
        { value: 2, name: '2 Years'},
        { value: 3, name: '3 Years'},
        { value: 4, name: '4 Years'}
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => {
        return state.GROUP_STORE.loadingGroups ? true : false
      },
      user: state => state.AUTH_STORE.authUser,
      groups: state => state.GROUP_STORE.groups,
      users: state => state.USER_STORE.users,
    })
  },
  beforeDestroy() {
    this.handleReset()
  },
  watch: {
    uploadModal(v) {
      !v && this.handleReset()
    }
  },
  methods: {
    allowedHours: v => v < 12,
    updateNextReviewDate() {
      const value = this.toUpdate.reviewFrequency;
      const date = this.toUpdate.last_review_date;
      if (value !== 0) {
        this.toUpdate.next_review_date = this.$moment.utc(date).add(value,'Y');
      } else {
        this.toUpdate.next_review_date = null;
      }
    },
    closeModal () {
      this.handleReset()
      this.uploadModal = false
    },
    openModal () {
      this.createListeners()

      //get the previous review dates
      let old_last_review_date = this.policy.last_review_date;
      let old_next_review_date = this.policy.next_review_date;
      
      let calcReviewFrequency = 1;

      // get the number of years between this.toUpdate.next_review_date and this.toUpdate.last_review_date
      if (this.toUpdate.next_review_date !== null) {
        calcReviewFrequency = this.$moment.utc(old_next_review_date).diff(this.$moment.utc(old_last_review_date), 'years', true);
      }
      // this.toUpdate.reviewFrequency = reviewFrequency;

      this.toUpdate = {
        ...this.policy, 
        reviewFrequency: calcReviewFrequency, 
        last_review_date: this.$moment.utc().format('YYYY-MM-DD').toString(), 
        next_review_date: this.$moment.utc().add(calcReviewFrequency,'Y')
      };


      if (this.toUpdate.time_threshold) {
        this.toUpdate.time_threshold = this.$moment.utc(this.toUpdate.time_threshold).format('HH:mm');
      }
      if (this.toUpdate.groups.length) {
        this.toUpdate.viewer_groups = this.toUpdate.groups.map(group => { return {name: group.name , id: group.id } });
      }

      this.toUpdate.version = parseInt(this.toUpdate.version);
      this.toUpdate.version++;
      this.uploadModal = true
    },
    createListeners() {
      window.addEventListener('dragenter', this.onDragEnter)
      window.addEventListener('dragleave', this.onDragLeave)
      window.addEventListener('dragover', this.onDragOver)
      window.addEventListener('drop', this.onDrop)
    },
    destroyListeners() {
      window.removeEventListener('dragenter', this.onDragEnter)
      window.removeEventListener('dragleave', this.onDragLeave)
      window.removeEventListener('dragover', this.onDragOver)
      window.removeEventListener('drop', this.onDrop)
    },
    onDragEnter(e) {
      this.lastTarget = e.target
      this.isDragging = true
    },
    onDragLeave(e) {
      if (e.target === this.lastTarget) {
        this.isDragging = false
      }
    },

    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();
      this.isDragging = false;
      this.handleFileDrop(e)
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files;

      if(!droppedFiles) {
        return
      }

      if (!this.allowMultiple) {
        this.uploadFiles = []
      }

      ([...droppedFiles]).forEach(f => {
        this.uploadFiles.push(f)
      })

      if (this.autoUpload) {
        this.handleFileUpload()
      }
    },
    handleFileInput(e) {
      let files = e.target.files

      if(!files) {
        return
      }

      if (!this.allowMultiple) {
        this.uploadFiles = []
      }

      ([...files]).forEach(f => {
        this.uploadFiles.push(f)
      })

      if (this.autoUpload) {
        this.handleFileUpload()
      }
    },
    handleFileRemove(i){
      this.uploadFiles.splice(i, 1)
    },
    getFileToUpload() {
      return this.allowMultiple ? this.uploadFiles : this.uploadFiles[0]
    },
    handleFileUpload() {
      const file = this.getFileToUpload()
      const owner_id = this.user.id
      const reseller_id = (this.reseller_id !== false) ? this.reseller_id : this.user.reseller_id
      const organisation_id = (this.organisation_id !== false) ? this.organisation_id : this.user.organisation_id

      this.toUpdate.reseller_id = parseInt(reseller_id)
      this.toUpdate.organisation_id = parseInt(organisation_id)
      this.toUpdate.owner_id = parseInt(this.toUpdate.owner_id)
      this.toUpdate.time_threshold = this.$moment.duration(`${this.toUpdate.time_threshold}:00`).as('milliseconds')
      if (file) {
        this.isUploading = true

        const data = new FormData();

        data.append('file', file);

        let media_type, entity_type;

        [entity_type, media_type] = this.uploadType.split('_')

        data.append('media_type_id', media_type)
        data.append('entity_type_id', entity_type)
        data.append('reseller_id', parseInt(reseller_id))
        data.append('organisation_id', parseInt(organisation_id))
        
        this.$http.post('file', data)
        .then(response => {
          this.isUploading = false

          if (response.data.data) {
            if (this.toUpdate.name == '') {
              this.toUpdate.file_name = response.data.data[0].meta.original_name;
            }
            this.handlePassback({...response.data.data, ...this.toUpdate})
          } else {
            this.$toast.error('There was a problem completing the request, please try again.')
            this.handleReset()
          }
        })
      } else {
        if (this.toUpdate.file_path) {
          this.toUpdate.reseller_id = parseInt(reseller_id)
          this.toUpdate.organisation_id = parseInt(organisation_id)
          this.toUpdate.owner_id = parseInt(this.toUpdate.owner_id)
          this.handlePassback(this.toUpdate)
        }
      }
    },
    handlePassback(d) {
      this.$emit('onUploaded', d)
      this.handleReset()

      if (this.closeOnUpload) {
        this.closeModal()
      }
    },
    handleReset() {
      this.isUploading = false
      this.isDragging = false
      this.lastTarget = null
      this.uploadFiles = []
      this.toUpdate = {}
    }
  }
}
</script>

<style lang="scss">
.v-time-picker-clock__item.v-time-picker-clock__item--disabled {
  display: none;
}
</style>

<style lang="scss" scoped>
.v-time-picker-clock__item.v-time-picker-clock__item--disabled {
  display: none;
}
.file-input-container {
  position: relative;

  border: 2px dashed #ddd;
  border-radius: 2px;

  width: 100%;
  height: 100%;

  transition: {
    property: opacity;
    duration: 0.2s;
  }

  &.is-dragging {
    border-color: #31cbb9;
  }
}
.file-input-overlay {
  position: fixed;
  display: flex;

  align-items: center;
  justify-content: center;

  visibility: hidden;

  z-index: 10000;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;

  transition: {
    property: visibility, opacity;
    duration: 0s, 0.2s;
    delay: 0.2s, 0s;
  };

  font: {
    size: 3vw;
    weight: bold;
  }

  color: #fff;

  pointer-events: none;

  &::before,
  &::after {
    position: fixed;
    display: block;
    content: '';
  }

  &::before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;

    background-color: rgba(0,0,0,0.66);
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 12px;

    border: 6px dashed #31cbb9;
  }

  &.is-dragging {
    visibility: visible;

    opacity: 1;

    pointer-events: all;

    transition: {
      delay: 0s, 0s;
    }
  }
}
.file-input-wrapper {
    position: relative;
    overflow: hidden;

    height: 100%;
    min-height: 128px;

    margin: 6px;

    text-align: center;

    overflow: hidden;

    background-color: #CCC;
    border-radius: 2px;

    input {
      position: absolute;

      top: 0;
      right: 0;

      width: 100%;
      height: 100%;

      cursor: pointer;

      opacity: 0;

      font-size: 300px;
    }
}
.file-input-label {
  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  color: #282828;
  font-size: 90%;
}
</style>
