// -------------------------------------------
// main.js file
// -------------------------------------------

// Import VueJS
import Vue from 'vue'

// Import Vuetify
import vuetify from '@/_plugins/vuetify'

// Import moment.js for Vue
import VueMoment from 'vue-moment'
import moment from 'moment-timezone/builds/moment-timezone-with-data'

// Import VueGtag
import VueGtag from "vue-gtag";

// Import IdleVue
import IdleVue from 'idle-vue'

// Import CASL
import { abilitiesPlugin } from '@casl/vue';
import { Can } from '@casl/vue';
import { ability } from '@/_services/ability';

// Import plugins/utils/misc
import App from '@/App'
import router from '@/_router/'
import store from '@/_store/'
import AuthPlugin from '@/_plugins/authentication'
import session from '@/_util/session'
import '@/_util/validation'

// Import Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// Import VeeValidate
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Import Trumbowyg editor
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

// Import custom global loader
import SpinnerBlocks from '@/components/spinners/SpinnerBlocks'

// Import custom global page header
import PageHeader from '@/components/page/Header'

// Import Sentry for reporting errors
import * as Sentry from "@sentry/vue";

// const express = require('express');
// const app = express();
// app.get("/", (request, response) => {
//   response.set("Document-Policy", "js-profiling");
//   response.sendFile("index.html");
// });


// Initialize Sentry
Sentry.init({
  Vue: Vue,
  dsn: "https://a3fb0ab214aef267729dbfccedcb9566@o4506868927037440.ingest.us.sentry.io/4506868932476928",
  ignoreErrors: [
    // Ignore the play() request was interrupted error
    'AbortError: The play() request was interrupted',
    // Ignore Vuex mutation error
    '[vuex] do not mutate vuex store state outside mutation handlers.',
    // Other errors to ignore can be added here
  ],
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      beforeAddRecordingEvent: (event) => {
        if (event.data.tag === 'login_event' || event.data.tag === 'login') {
          return null;
        }
        return event;
      }
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      buttonLabel: "Feedback",
      submitButtonLabel: "Send Feedback",
      useSentryUser: {
        email: "email",
        name: "fullName",
      },
    }),
    // add browser profiling
    new Sentry.BrowserProfilingIntegration(),
  ],
  trackComponents: true,
  tracesSampleRate: 0.2, // Adjust this value in production
  
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api-test\.crysp\.co\.uk/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.2,

  //Set the environment for Sentry based on the environment variables
  environment: "production", //testing, staging

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.5,
});

// -------------------------------------------
// Component config and registration
// -------------------------------------------

// Configuration for IdleVue
const eventsHub = new Vue()
const idleTime = 900000

// Register IdleVue component
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: idleTime,
  startAtIdle: false
})

// Register VeeValidate components
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Register global loader component
Vue.component('v-loading-spinner', SpinnerBlocks)

// Register global page header component
Vue.component('v-page-header', PageHeader)

// Configuration for moment.js
moment.tz.setDefault(moment.tz.guess())

// Register moment.js for Vue
Vue.use(VueMoment, {
  moment
})

// Register Auth plugin
Vue.use(AuthPlugin)

// Register CASL components
Vue.use(abilitiesPlugin, ability)
Vue.component('Can', Can)

// Register Trumbowyg editor
Vue.use(VueTrumbowyg);

// Register Axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

//Register VueGtag
Vue.use(VueGtag, {
  config: {
    id: "G-0P4P8Y4PD8",
  },
}, router);

Vue.directive('click-outside-custom', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

// -------------------------------------------
// Filter registration
// -------------------------------------------

Vue.filter('titleCase', function(value) {
  return value.replace('_', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})

Vue.filter('getInputType', function(value) {
  if (!value) {
    return 'Not Set';
  }

  if (typeof value === 'object') {
    value = value.id;
  }

  const type = store.getters['UTIL_STORE/getInputTypes'].find(type => type.id === value);

  return type.name;
})

Vue.filter('returnFileFromString', function(value) {
    return `${process.env.VUE_APP_URL}/storage/${value.url}`
})

Vue.filter('getOrganisationName', function(value) {
  const organisation = store.getters['ORG_STORE/getOrganisation'].find(organisation => organisation.id == value)

  if (organisation) {
    return organisation.name
  }

  return
})

Vue.filter('filterStatus', function(value) {
  return value.filter(function(obj) {
    return obj.id  != 3
  });
})


// -------------------------------------------
// Misc configuration
// -------------------------------------------

// Disable Vue mode warnings
Vue.config.productionTip = false


// Create a JavaScript Proxy
let target = {};
let handler = {
  get: function(target, prop, receiver) {
    // console.log(`GET ${prop}`);
    return Reflect.get(...arguments);
  }
};

let proxy = new Proxy(target, handler);

// Use the proxy...
// proxy.someProperty = 'someValue';
// console.log(proxy.someProperty);


// -------------------------------------------
// Application
// -------------------------------------------

// Pre auth ensures valid auth and user data are present
// before initializing the main app.
function preAuthentication() {
  return new Promise(async (resolve) => {
    const auth = await store.dispatch('AUTH_STORE/Authentication')
    return resolve(auth)
  });
}

// Run pre-auth then mount app.
preAuthentication().then((res) => {
  const authenticated = res

  const Crysp = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    mixins: [
      session
    ],
    beforeMount() {
      const route = router.currentRoute

      if (!authenticated && route.meta.requiresAuth !== false) {
        localStorage.setItem("redirectTo", route.fullPath);
        router.push({ name: 'login' }, () => {})
      }

      if (authenticated) {
        if (route.name == 'login' || route.name == 'register') {
          router.push({ name: 'dashboard' }, () => { })
        }
      }
    },
     mounted() {
      store.dispatch('UTIL_STORE/GET_UTILS')
      if (authenticated) {
        store.dispatch('CONTENT_STORE/GET_USEFUL_CONTENT')
        store.dispatch('ORG_STORE/GET_ORGS');
      }
    }
  })

  Crysp.$mount("#app")
})
