import * as jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

class auth {
  isAuthenticated() {
    if (this.isExpired()) {
      return false
    }

    return this.getToken()
  }

  getToken() {
    const token = localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY)
    return (token && typeof token === 'string' && token.length > 0) ? token : null
  }

  getDecodedToken() {
    return jwt_decode(localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY))
  }

  isExpired() {
    if(!localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY)) {
      return false
    }

    const currentTime = new Date().getTime() / 1000
    const decoded = jwt_decode(localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY))

    return currentTime > decoded.exp ? true : false
  }

  getAuthenticatedUser() {
    if (localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY)) {
      const { jwtToken: { user }} = jwt_decode(localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY))
      return user
    } else {
      return null
    }
  }

  removeData() {
    localStorage.removeItem(process.env.VUE_APP_LOCAL_AUTH_KEY)
    localStorage.removeItem('microsoftToken')

    Cookies.remove('token')
  }

  debugExpiry() {
    if(!localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY)) {
      return false
    }

    const currentTime = new Date().getTime() / 1000
    const decoded = jwt_decode(localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY))

    return {
      current: currentTime,
      expiry: decoded.exp
    }
  }

  async handleLogin(data, req) {
    if (
      data.status !== undefined &&
      data.status == "OK" &&
      data.data.token &&
      data.data.token != ""
    ) {
      const token = data.data.token
      const user = data.data.user
      
      localStorage.setItem(process.env.VUE_APP_LOCAL_AUTH_KEY, token)

      if(data.data.microsoft_user && data.data.microsoft_user.token){
        const microsoftToken = data.data.microsoft_user.token
        localStorage.setItem('microsoftToken', microsoftToken)
      }
      // const decoded = jwt_decode(token)
      // const user = decoded.jwtToken.user


      const expires = {
        expires: new Date(new Date().getTime() + (data.data.expires_in * 1000))
      }

      // Cookies.set('user', `${user}`, expires)
      Cookies.set('token', `${data.data.encrypted_token}`, expires) // This is only used for files from the /storage/ route

      return {
        token,
        user
      };
    } else {
      let error = data.message;
      throw new Error(JSON.stringify(error));
    }
  }

  refresh(token, expiresIn) {
    const expires = {
      expires: new Date(new Date().getTime() + (expiresIn * 1000))
    }

    // Cookies.set('user', `${user}`, expires)
    Cookies.set('token', `${token}`, expires)
  }
}

export default new auth()
