<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header :value="isLoadingComplianceItems ? 'View Compliance Item' : compliance.name"></v-page-header>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-row align="center" justify="center" v-if="isLoadingComplianceItems">
            <v-loading-spinner></v-loading-spinner>
          </v-row>

          <v-fade-transition mode="out-in">
            <v-card outlined v-if="!isLoadingComplianceItems" class="mb-7">

              <v-card-title class="grey lighten-5">
                <span>{{ compliance.name }}</span>

                <v-spacer></v-spacer>

                <v-chip outlined small v-if="$vuetify.breakpoint.mdAndUp">
                  <v-avatar left class="mr-1">
                    <v-icon small>mdi-update</v-icon>
                  </v-avatar>
                  <span class="font-weight-regular mr-1">Last Updated:</span> {{ parseComplianceUpdatedDate(compliance.updated_at) }}
                </v-chip>

                <Can I="update" a="document">
                  <v-divider vertical class="ml-4"></v-divider>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        :to="{ name: 'content/edit', params: { content_id: cItems.content_id }}"
                        :disabled="isLoadingComplianceItems"
                        class="ml-2"
                      >
                        <v-icon medium>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Content</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="manageComplianceItem(compliance,'action')"
                      >
                        <v-icon medium>{{ $vuetify.icons.values.update}}</v-icon>
                      </v-btn>
                    </template>
                    <span>Action</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="manageComplianceItem(compliance,'update')"
                      >
                        <v-icon medium>{{ $vuetify.icons.values.edit }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                </Can>
              </v-card-title>

              <v-divider v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>

              <v-card-title class="grey lighten-5" v-if="!$vuetify.breakpoint.mdAndUp">
                <v-chip outlined small>
                  <v-avatar left class="mr-1">
                    <v-icon small>mdi-update</v-icon>
                  </v-avatar>
                  <span class="font-weight-regular mr-1">Last Updated:</span> {{ parseComplianceUpdatedDate(compliance.updated_at) }}
                </v-chip>
              </v-card-title>

              <v-divider></v-divider>

              <v-row justify="space-between" class="no-gutters">
                <v-col cols="12" md="7">
                  <v-card-text class="pt-1">
                    <span class="overline">
                      <v-icon small>mdi-state-machine</v-icon>
                      Name
                    </span><br />
                    <v-divider class="my-2"></v-divider>
                    <div v-html="compliance.name"></div>
                  </v-card-text>

                  <v-card-text class="pt-1">
                    <span class="overline">
                      <v-icon small>mdi-checkbox-blank-circle</v-icon>
                      Status
                    </span><br />
                    <v-divider class="my-2"></v-divider>
                    <div v-html="compliance.complianceState.name"></div>
                  </v-card-text>

                  <v-card-text class="pt-1">
                    <span class="overline">
                      <v-icon small>mdi-text-box-outline</v-icon>
                      Description
                    </span><br />
                    <v-divider class="my-2"></v-divider>
                    <v-row>
                      <v-col cols="12">
                        <div v-html="compliance.description"></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>

                <v-col cols="12" md="5" class="grey lighten-5">
                  <v-card-text class="pa-7">
                    <v-row
                      class="mx-auto px-0 mb-2 v-chip v-chip--outlined v-chip--label"
                    >
                      <v-col>
                        Status
                        <v-chip small class="ml-2" :color="getComplianceState(compliance.complianceState.id)"></v-chip>
                      </v-col>
                      <v-col class="text-right" v-if="compliance.latest_action">Next Due <b :class="`${getComplianceState(compliance.complianceState.id)}--text`">{{ parseNextDueDate(compliance.latest_action.actioned_date, compliance.frequency) }}</b></v-col>
                      <v-col class="text-right" v-else>Next Due <b :class="`${getComplianceState(compliance.complianceState.id)}--text`">{{ parseNextDueDate(compliance.created_at, compliance.frequency) }}</b></v-col>
                    </v-row>
                    <div class="mx-auto row justify-space-between" v-if="calculateDaysRemaining(compliance.created_at, compliance.frequency) > 0">
                      <v-col>Days Remaining</v-col>
                      <v-col class="text-right" v-if="compliance.latest_action"><b :class="`${getComplianceState(compliance.complianceState.id)}--text`">{{ calculateDaysRemaining(compliance.latest_action.actioned_date, compliance.frequency) }} day(s)</b></v-col>
                      <v-col class="text-right" v-else><b :class="`${getComplianceState(compliance.complianceState.id)}--text`">{{ calculateDaysRemaining(compliance.created_at, compliance.frequency) }} day(s)</b></v-col>
                    </div>
                    <div class="mx-auto row justify-space-between">
                      <v-col>Frequency</v-col>
                      <v-col class="text-right">{{ compliance.frequency }} day(s)</v-col>
                    </div>
                    <div class="mx-auto row justify-space-between">
                      <v-col>Warning Threshold</v-col>
                      <v-col class="text-right">{{ compliance.warning }} day(s)</v-col>
                    </div>

                    <v-divider></v-divider>

                    <div class="mx-auto row justify-space-between">
                      <v-col>Date Created On</v-col>
                      <v-col class="text-right">{{ parseComplianceDate(compliance.created_at) }}</v-col>
                    </div>
                    <div class="mx-auto row justify-space-between" v-if="compliance.latest_action">
                      <v-col>Last Actioned</v-col>
                      <v-col class="text-right" v-if="compliance.latest_action.actioned_date">{{ parseComplianceDate(compliance.latest_action.actioned_date) }}</v-col>
                      <v-col class="text-right" v-else>Never</v-col>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-card outlined v-if="!isLoadingComplianceItems">

              <v-card-title class="grey lighten-5">
                <v-icon left>mdi-paperclip</v-icon>
                Attachments
              </v-card-title>

              <v-row justify="space-between">
                <v-col cols="12" class="py-0">

                  <v-row class="px-4 py-3" justify="end">

                    <v-col cols="auto" class="py-1">
                      <Can I="update" a="document">
                        <UploadModal
                          :autoUpload="false"
                          uploadType="organisation_generic"
                          buttonLabel="Upload Attachment"
                          modalLabel="Upload Attachment"
                          @onUploaded="handleUploadedAttachment"
                          flush
                        ></UploadModal>
                      </Can>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-data-table
                    class="v-data-table--selectable"
                    :headers="attachmentsHeaders"
                    :items="compliance.attachments"
                    :items-per-page="10"
                    :loading="isUploadingAttachment"
                    loading-text="Loading attachments... please wait."
                  >
                    <template v-slot:no-data>
                      No attachments available.
                    </template>

                    <template v-slot:item.name="{ item }">
                      {{ item.name ? item.name : 'New Attachment' }}
                    </template>

                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | moment("ddd, MMMM Do YYYY") }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <!-- :href="getStorageFile(item.file)" -->
                            <v-btn
                              icon
                              v-on="on"
                              :disabled="isLoadingComplianceItems"
                              @click="downloadFile(getStorageFile(item.file), item.name.replace(/ /g,'').toLowerCase())"
                              download
                            >
                              <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Download</span>
                        </v-tooltip>

                        <Can I="update" a="document">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                :disabled="isLoadingComplianceItems"
                                @click="manageAttachmentItem(item)"
                              >
                                <v-icon medium>{{ $vuetify.icons.values.edit }}</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                :disabled="isLoadingComplianceItems"
                                @click="attachmentDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                              >
                                <v-icon medium>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </Can>

                      </div>
                      <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">

                        <v-col cols="12">
                          <!-- :href="getStorageFile(item.file)" -->
                          <v-btn
                            color="primary"
                            block
                            outlined
                            :disabled="isLoadingComplianceItems"
                            @click="downloadFile(getStorageFile(item.file), item.name.replace(/ /g,'').toLowerCase())"
                            download
                          >
                            <v-icon dark left>mdi-arrow-down-bold-box-outline</v-icon>
                            Download
                          </v-btn>
                        </v-col>

                        <Can I="update" a="document">
                          <v-col cols="12">
                            <v-btn
                              color="primary"
                              block
                              outlined
                              :disabled="isLoadingComplianceItems"
                              @click="manageAttachmentItem(item)"
                            >
                              <v-icon dark left>mdi-square-edit-outline</v-icon>
                              Edit
                            </v-btn>
                          </v-col>

                          <v-col cols="12">
                            <v-btn
                              color="primary"
                              block
                              outlined
                              :disabled="isLoadingComplianceItems"
                              @click="attachmentDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                            >
                              <v-icon dark left>mdi-delete</v-icon>
                              Delete
                            </v-btn>
                          </v-col>
                        </Can>
                      </v-row>
                    </template>
                  </v-data-table>

                </v-col>
              </v-row>
            </v-card>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingAttachmentItem">
              <v-card class="mx-auto" max-width="480" min-width="420" light v-if="!isUpdatingAttachment">
                <v-card-title class="primary primary--text--contrast mb-1">
                  Manage Attachment
                </v-card-title>

                <v-row class="px-2 py-1">
                  <v-col cols="12">
                    <v-text-field
                    v-model="toUpdate.name"
                    label="Attachment Name"
                    hide-details
                    outlined
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                    v-model="toUpdate.description"
                    label="Attachment Description"
                    hide-details
                    outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click="manageAttachmentItem(false)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click="handleEditAttachmentItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isUpdatingAttachment">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="attachmentDeleteConfirmation && !isLoadingComplianceItems">
              <v-card class="mx-auto" max-width="480" light v-if="!isDeletingAttachment">
                <v-card-title class="primary primary--text--contrast mb-4">
                  Confirm Delete Attachment
                </v-card-title>

                <v-card-text>
                  Are you sure you want to delete attachment <strong>{{ toDelete.name ? toDelete.name : 'New Attachment' }}</strong>? This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click="attachmentDeleteConfirmation = !attachmentDeleteConfirmation, toDelete = {}">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn @click="handleDeleteAttachment(toDelete)" color="error">
                    Delete
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isDeletingAttachment">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingComplianceItem == 'action' && !isLoadingComplianceItems">
              <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!Updating" >
                <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
                  Manage Compliance Item
                  <v-btn icon @click.stop="manageComplianceItem(false,null)">
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-state-machine</v-icon>
                    Name
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  {{ toManage.name }}
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    Status
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.complianceState.name"></div>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-text-box-outline</v-icon>
                    Description
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.description"></div>
                </v-card-text>

                <v-divider></v-divider>
                <div class="px-2 pt-4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Actioned on"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toManage.actioned_on"
                    :show-adjacent-months="true"
                    @input="menu2 = false"
                    :max="$moment().format('YYYY-MM-DD')"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="px-2 py-4">
                  <v-select
                    :items="complianceActions"
                    v-model="toManage.action"
                    item-text="name"
                    item-value="id"
                    label="Select Action"
                    outlined
                    hide-details
                  ></v-select>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click="manageComplianceItem(false,null)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click="handleActionComplianceItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="Updating">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingComplianceItem == 'update' && !isLoadingComplianceItems">
              <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!Updating">
                <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
                  Manage Compliance Item
                  <v-btn icon @click.stop="manageComplianceItem(false,null)">
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-state-machine</v-icon>
                    Name
                  </span><br />
                  <v-divider class="my-2"></v-divider>

                  <v-row dense class="pt-2">
                    <!-- Compliance item name -->
                    <v-col cols="12">
                      <VTextFieldWithValidation rules="required" v-model="toManage.name" label="Name" hideDetails />
                    </v-col>
                    
                <!-- Compliance item frequency -->
                <v-col  cols="12" >
                  <div class="d-flex align-center">
                    <v-col cols="4">
                      <span class="mr-2">Repeat every</span>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-if="toManage.frequencyUnit !== 'None'"
                        v-model="toManage.frequencyValue"
                        label="Frequency"
                        @input="valideFrequency"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="toManage.frequencyUnit"
                        :items="reviewFrequencies"
                        item-text="name"
                        item-value="value"
                        label="Review Frequency"
                        hide-details
                        outlined
                        @change="updateNextReviewDate()"
                      ></v-select>
                    </v-col>
                  </div>
                </v-col>

                <!-- Compliance item warning -->
                <v-col cols="12" sm="6" offset-sm="6" v-if="toManage.next_review_date !== null">
                  <VNumberFieldWithValidation
                    rules="numeric"
                    v-model="toManage.warning"
                    label="Warning Threshold"
                    hideDetails
                    suffix="day(s)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col  cols="12" sm="6">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      :value="toManage.last_review_date | moment('DD/MM/YYYY')"
                      label="Last review date"
                      readonly
                      hide-details
                      outlined
                      ></v-text-field>
                    </template>
                    <!-- <v-date-picker
                      v-model="toManage.last_review_date"
                      @input="updateNextReviewDate()"
                    ></v-date-picker> -->
                  </v-menu>
                </v-col>
                <v-col v-if="toManage.next_review_date !== null" cols="12" sm="6">
                  <v-text-field
                    :value="formattedNextReviewDate"
                    label="Next review date"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="pt-0">
                      <small>For one-time events that do not repeat, leave these values blank or set to 0.</small>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    Status
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.complianceState.name"></div>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-text-box-outline</v-icon>
                    Description
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <TextEditor v-model="toManage.description" light :label="`Description`" />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click="manageComplianceItem(false,null)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click="handleUpdateComplianceItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="Updating">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VNumberFieldWithValidation from '@/components/inputs/VNumberFieldWithValidation'
import TextEditor from '@/components/inputs/TextEditor'

import UploadModal from '@/components/upload/UploadModal'

export default {
  name: 'ViewComplianceItem',
  components: {
    VTextFieldWithValidation,
    VNumberFieldWithValidation,
    TextEditor,
    UploadModal
  },
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  data() {
    return {
      menu2: false,
      toManage: {},
      Updating: false,
      isUpdating: false,
      managingComplianceItem: null,
      managingAttachmentItem: false,
      attachmentDeleteConfirmation: false,
      toUpdate: {},
      toDelete: {},
      selectedAttachment: {},
      isActioning: false,
      isUploadingAttachment: false,
      isUpdatingAttachment: false,
      isDeletingAttachment: false,
      complianceActions: [
        { id: 1, name: "Mark as Compliant" },
        { id: 2, name: "Mark as Warning" },
        { id: 0, name: "Mark as Outstanding" }
      ],
      reviewFrequencies: [
        'None',
        'Days',
        'Weeks',
        'Months',
        'Years'
      ],
      formattedNextReviewDate: null,
    }
  },
  computed: {
    ...mapState({
      content: state => state.CONTENT_STORE.content,
      complianceItems: state => state.CONTENT_STORE.userComplianceItems,
      isLoadingComplianceItems: state => state.CONTENT_STORE.loadingUserComplianceItems
    }),
    item: function() {
      return this.content.find(item => item.id == this.$route.params.content_id)
    },
    cItems: function() {
      return this.complianceItems.find(item => item.content_id == this.$route.params.content_id) || []
    },
    compliance: function() {
      return this.cItems?.items?.find(item => item.id == this.$route.params.compliance_id) || []
    },
    attachmentsHeaders() {
      let headers = []

      headers.push({ text: "Name", value: "name" })
      headers.push({ text: "Uploaded", value: "created_at" })
      headers.push({ text: "", value: "actions", sortable: false })

      return headers
    },
    formattedDate() {
      return this.$moment(this.toManage.actioned_on).format('DD/MM/YYYY');
    },
  },
  async beforeMount() {
    await this.getData()
  },
  methods: {
    parseDateToTimestamp(date) {
      if (!date) return null

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    getData() {
      this.$store.dispatch('CONTENT_STORE/GET_CONTENT')
      this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS')
    },
    handleUploadedAttachment(data) {
      if (!data) {
        return
      }

      this.isUploadingAttachment = true

      let variables = {
        compliance_item_id: parseInt(this.$route.params.compliance_id),
        file: data[0].meta.path,
        name: data.name,
        description: data.description
      }

      this.$http.post('content/compliance/attachment', variables)
        .then(response => {
          if (response.data) {
            let attachment = response.data.data
            this.compliance.attachments.push(attachment)

            this.isUploadingAttachment = false

            this.$toast.success('Successfully uploaded attachment.')
          } else {
            this.$toast.error('Something went wrong uploading attachment, please try again.')
          }
        })
        .catch(error => {
          this.isUploadingAttachment = false

          this.$toast.error('Something went wrong uploading attachment, please try again.')
        })
    },
    handleDeleteAttachment(data) {
      if (!data) {
        return
      }

      this.isDeletingAttachment = true

      let variables = {
        id: parseInt(data.id),
      }

      this.$http.delete('content/compliance/attachment', {
          params: variables
        })
        .then(response => {
          if (response.data) {
            let attachments = this.compliance.attachments.filter((item) => {
              return item.id != parseInt(data.id)
            })

            this.compliance.attachments = attachments

            this.attachmentDeleteConfirmation = false
            this.isDeletingAttachment = false

            this.toDelete = {}

            this.$toast.success('Successfully deleted attachment.')
          } else {
            this.attachmentDeleteConfirmation = false
            this.isDeletingAttachment = false

            this.toDelete = {}

            this.$toast.error('Something went wrong deleting attachment, please try again.')
          }
        })
        .catch(error => {
          this.isDeletingAttachment = false

          this.$toast.error('Something went wrong deleting attachment, please try again.')

          this.toDelete = {}
        })
    },
    handleActionComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.Updating = true

      let variables = {
        content_id:         parseInt(this.$route.params.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        pass_or_failed:     parseInt(this.toManage.action),
        actioned_on:        `${this.$moment(this.toManage.actioned_on).format('YYYY-MM-DD')} ${this.$moment().format('HH:mm:ss')}`,
      }

      this.$http.post('content/compliance/action', variables)
        .then(response => {
          this.isActioning = false

          this.$toast.success('Successfully actioned compliance item.')

          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true)
          this.manageComplianceItem(false,null);
          this.Updating = false;
        })
        .catch(error => {
          this.isActioning = false
          this.$toast.error('Something went wrong actioning compliance item, please try again.')
        })
    },
    handleUpdateComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isUpdating = true

      let variables = {
        content_id:         parseInt(this.$route.params.content_id),
        compliance_item_id: parseInt(this.$route.params.compliance_id),
        name:               this.toManage.name,
        description:        this.toManage.description,
        warning:            parseInt(this.toManage.warning),
        frequency:          parseInt(this.toManage.frequency)
      }

      this.$http.post('content/compliance/update', variables)
        .then(response => {
          this.isUpdating = false

          this.$toast.success('Successfully updated compliance item.')

          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true)
          this.manageComplianceItem(false,null);
          this.getData();
        })
        .catch(error => {
          this.isUpdating = false

          this.$toast.error('Something went wrong updating compliance item, please try again.')
        })
    },
    handleEditAttachmentItem() {
      if (!this.toUpdate) {
        return
      }

      this.isUpdatingAttachment = true

      let variables = {
        id:          parseInt(this.toUpdate.id),
        name:        this.toUpdate.name,
        description: this.toUpdate.description
      }

      this.$http.put('content/compliance/attachment', variables)
        .then(response => {
          this.isUpdatingAttachment = false

          if (response.data) {
            let attachment = response.data.data

            this.compliance.attachments.find( function (item) {
              if (item.id == attachment.id) {
                Object.assign(item, attachment)
                return true
              }

              return false;
            })

            this.$toast.success('Successfully updated attachment.')
          } else {
            this.$toast.error('Something went wrong updating attachment, please try again.')
          }

          this.manageAttachmentItem(false)
        })
        .catch(error => {
          this.isUpdatingAttachment = false

          this.$toast.error('Something went wrong updating attachment, please try again.')
        })
    },
    parseComplianceUpdatedDate(date) {
      if (date) {
        return `${this.$moment(date).format("Do MMMM, YYYY")} at ${this.$moment(date).format("h:mma")}`
      }
    },
    parseComplianceDate(date) {
      if (date) {
        return `${this.$moment(date).format("MMM DD, YYYY : H:mm")}`
      }
    },
    parseNextDueDate(date,frequency) {
      if(frequency == 0) {
        return 'Not Set'
      }
      if (date) {
        return `${this.$moment(date).add(frequency, 'd').format("MMM DD, YYYY")}`
      }
    },
    calculateDaysRemaining(date,frequency) {
      if (date) {
        return `${this.$moment(date).add(frequency, 'd').diff(this.$moment(), 'days')}`
      }
    },
    getComplianceState(state) {
      let states = {
        '2': 'success',
        '3': 'warning',
        '4': 'error'
      }

      return states[state]
    },
    manageComplianceItem(item,method) {
      this.managingComplianceItem = method
      this.toManage.action = false
      this.toManage = item ? {...item} : {}
      // this.toManage.last_review_date = this.toManage.last_review_date == null ? this.$moment.utc().format('YYYY-MM-DD').toString() : this.toManage.last_review_date; 
      // this.toManage.review_frequency = this.toManage.review_frequency == null ? 1 : this.toManage.review_frequency; 
      // this.toManage.next_review_date = this.toManage.next_review_date == null ? this.$moment.utc().add(1,'Y') : this.toManage.last_review_date; 

      this.toManage.last_review_date = this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null ? this.toManage.created_at : this.toManage.latest_action.actioned_on; 
      this.toManage.frequency = this.toManage.frequency == null ? 0 : this.toManage.frequency; 
      this.toManage.next_review_date = this.$moment(this.toManage.last_review_date).add(this.toManage.frequency,'d'); 
      
      if (this.toManage.frequency == 0) {
        this.toManage.frequencyValue = null;
        this.toManage.frequencyUnit = 'None';
      } else {
        //if the frequency is a multiple of 365, then it is a year
        if (this.toManage.frequency % 365 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 365;
          this.toManage.frequencyUnit = 'Years';
        } else if (this.toManage.frequency % 30 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 30;
          this.toManage.frequencyUnit = 'Months';
        } else if (this.toManage.frequency % 7 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 7;
          this.toManage.frequencyUnit = 'Weeks';
        } else {
          this.toManage.frequencyValue = this.toManage.frequency;
          this.toManage.frequencyUnit = 'Days';
        }
      }
    },
    manageAttachmentItem(item) {
      this.managingAttachmentItem = item ? true : false
      this.toUpdate.update = false
      this.toUpdate = item ? _.cloneDeep(item) : {}
      this.selectedAttachment = item ? _.cloneDeep(item) : {}
    },
    updateNextReviewDate() {
      let value = this.toManage.frequencyValue;
      const unit = this.toManage.frequencyUnit;
      const date = (this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null) ? this.toManage.created_at : this.toManage.latest_action.actioned_on;

      if(unit == 'None'){
        this.toManage.next_review_date = null;
        this.formattedNextReviewDate = null;
        this.toManage.frequencyValue = 0;
        this.toManage.frequency = 0;
        this.toManage.warning = 0;
        return;
      }

      if (value !== 0) {
        switch (unit) {
          case 'Days':
            this.$set(this.toManage, 'next_review_date', this.$moment.utc(date).add(value,'d'));
            break;
          case 'Weeks':
            value = value * 7;
            this.$set(this.toManage, 'next_review_date', this.$moment.utc(date).add(value,'w'));
            break;
          case 'Months':
            value = value * 30;
            this.$set(this.toManage, 'next_review_date', this.$moment.utc(date).add(value,'M'));
            break;
          case 'Years':
            value = value * 365;
            this.$set(this.toManage, 'next_review_date', this.$moment.utc(date).add(value,'y'));
            break;
        }

        this.$set(this.toManage, 'frequency', value);
      } else {
        //set the value to the default value of 1
        this.toManage.frequencyValue = 1;

        this.updateNextReviewDate();
      }

      this.formattedNextReviewDate = this.$moment(this.toManage.next_review_date).format('DD/MM/YYYY');

      //update the warning threshold
      this.toManage.warning = Math.min(this.toManage.warning, this.toManage.frequency);
    },
    valideFrequency(){
      const value = this.toManage.frequencyValue;
      if(!isNaN(parseFloat(value)) && isFinite(value)){
        this.toManage.frequencyValue = Math.floor(this.toManage.frequencyValue);

        this.updateNextReviewDate();
      }
    }
  },
  watch: {
    item: function(payload) {
      this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Manage Compliance' , path: '/compliance/all/' },
        { text: ':item' }
      ])

      this.replaceBreadcrumb({
        find: ':item',
        replace: {
          text: this.compliance.name
        }
      })

      // this.parseAttachmentData(payload)
    },
  }
}
</script>

<style lang="scss">
  .v-content-body {
    h1,h2,h3,h4,h5,h6 {
      margin: 10px 0 20px;
    }
  }

  .scrollable-content {
    max-height: 640px; /* Adjust as needed */
    overflow-y: auto;
  }
</style>
