<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>
    
    <v-card-title>
      Organisations
    </v-card-title>

    <v-card-subtitle>
      Manage organisations
    </v-card-subtitle>

    <v-spacer></v-spacer>

    <v-card-text v-for="content in card.content" class="text--primary py-0" :key="content.text">
      <v-btn text :to="content.url" style="text-transform: capitalize; text-decoration: underline">{{ content.text }}</v-btn>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <v-btn :to="{ name: 'organisation/all' }">
        View All
      </v-btn>

      <v-spacer></v-spacer>

      <Can I="create" a="organisation">
        <v-btn color="primary" :to="{ name: 'organisation/create' }">
          Create New
        </v-btn>
      </Can>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Groups.png")
      }
    }
  }
}
</script>