<template>
  <div>
    <v-chip
      v-for="(item, index) in chipsItems"
      :key="index"
      :color="color"
      :small="true"
      class="my-1 mr-1 chip-item"
    >
      {{ isArrayString ? item : item[itemName] }}
    </v-chip>
    <template v-if="higherThanDisplayChipsLimit">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            :color="color"
            :small="true"
            v-on="on"
            class="extra-chips-items-chip my-1"
          >
            +{{ items?.length - displayChipsLimit }}
          </v-chip>
        </template>
        <v-list rounded dense class="pt-0" :max-width="150" :max-height="200">
          <v-list-item
            dense
            v-for="(item, index) in items.slice(displayChipsLimit)"
            :key="index"
          >
            <v-list-item-title>{{
              isArrayString ? item : item[itemName]
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  name: "VChips",
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemName: {
      type: String,
      default: "name",
    },
    displayChipsLimit: {
      type: Number,
      default: 3,
    },
    color: {
      type: String,
      default: "primary",
    },
  },

  data() {
    return {};
  },
  beforeMount() {
    
  },
  computed: {
    isArrayString() {
      if (!this.items ||this.items.length === 0) return true;
      return (
        typeof this.items[0] === "string" || this.items[0] instanceof String
      );
    },
    higherThanDisplayChipsLimit() {
      return this.items?.length > this.displayChipsLimit;
    },
    chipsItems() {
      if (!this.items) return [];
      return this.higherThanDisplayChipsLimit
        ? this.items.slice(0, this.displayChipsLimit)
        : this.items;
    },
  },
};
</script>

<style scoped>
.chip-item {
    pointer-events: none;
}
.extra-chips-items-chip {
  cursor: pointer;
}
</style>
