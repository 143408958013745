<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>

    <v-card-title>
      Organisation
    </v-card-title>

    <v-card-subtitle>
      Manage your organisation.
    </v-card-subtitle>
    
    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <v-btn :to="{ name: 'organisation', params: { org_id: user.organisation_id }}">
        Manage
      </v-btn>

      <v-spacer></v-spacer>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      card: {
          image: require("@/images/cards/Crysp_Icons_Organisations.png")
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    })
  }
}
</script>
