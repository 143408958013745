<template>
  <div style="display: flex; min-height: 100vh;">
      <v-row align="center" justify="center">
        <v-loading-spinner></v-loading-spinner>
      </v-row>
  </div>
</template>

<script>
import { parseUserRole, parseUserPermissions } from '@/_util/casl'
import {AbilityBuilder} from "@casl/ability";

export default {
  name: 'MicrosoftLogin',
  methods: {
    async handleLogin() {
      this.$store.commit('AUTH_STORE/LOGIN_START')

      const auth = await this.$http.get('microsoftCallback', {
          params: {
            code: this.$route.query.code,
            session_state: this.$route.query.session_state
          }
        })
        .then(async ({ data }) => {
          return await this.$auth.handleLogin(data)
        }).catch((error) => {

          this.$store.commit('AUTH_STORE/LOGIN_STOP', error)
          this.$toast.error(error.response.data.message ?? error.message)

          this.redirectToLogin(5000)
        })


      if (auth) {
        this.handleUpdateAbilities(auth.user)

        this.$store.commit('AUTH_STORE/LOGIN_STOP', null)

        this.$store.commit('AUTH_STORE/LOGIN_UPDATE', {
          token: auth.token,
          authUser: auth.user
        })

        this.$store.dispatch('UTIL_STORE/GET_UTILS', null, { root: true })
        this.$store.dispatch('ORG_STORE/GET_MY_ORG', null, { root: true }).then(() => {
            this.$store.dispatch('ORG_STORE/GET_ORG_COMPLIANCE_TAGS', null, { root: true })
          })

        try {
          // Fire a logon event to Sentry
          Sentry.withScope(scope => {
            scope.setTag('organisation', auth.user.organisation_id);
            scope.setTag('user_id', auth.user.id);
            scope.setTag('login_event', 'login');
            scope.setUser({
              email: auth.user.email,
              fullName: auth.user.profile.first_name + ' ' + auth.user.profile.last_name
            });
            scope.clearBreadcrumbs();
            Sentry.captureMessage('User logged in');
          });
        } catch (error) {
          
        }

        let redirect = localStorage.getItem("redirectTo");
        console.log(redirect);
        localStorage.removeItem("redirectTo"); // Clear the localStorage item
        if (redirect) {
          this.$router.push(redirect);
        } else {
          this.$router.push({ name: 'dashboard' }, () => {})
        }  
      }
    },
    handleUpdateAbilities(user) {
      let role = parseUserRole(user)
      let permissions = parseUserPermissions(user)

      if (permissions) {
        const { can, rules } = new AbilityBuilder();

        if (role == "system_admin") {
          can("manage", "all")
        } else {
          permissions.forEach(p => {
            can(p[1], p[0])
          });
        }

        this.$ability.update(rules);
      }
    },
    redirectToLogin(timeout) {
      let t = setTimeout(() => {
        this.$router.push({ name: 'login' }, () => {})
      }, timeout)
    }
  },
  mounted() {
    this.handleLogin()
  }
}
</script>
