import auth from '@/_util/auth'

import { AbilityBuilder } from '@casl/ability'
import { parseUserRole, parseUserPermissions } from '@/_util/casl'

import axios from '../axios'

const initialState = () => {
  return {
    authUser: null,
    token: null,
    updatingUser: false,
    loggingIn: false,
    loggingOut: false,
    loginError: null
  }
}

const state = initialState()

const getters = {
  isAuthenticated: state => !!state.token
}

const mutations = {
  LOGIN_START: state => state.loggingIn = true,

  LOGIN_STOP: (state, errorMessage) => {
    state.loggingIn = false
    state.loggingOut = false
    state.loginError = errorMessage
  },

  LOGIN_UPDATE: (state, { token, authUser }) => {
    if (token) {
      state.token = token

      axios.defaults.headers.common = {
        'Authorization': `Bearer ${token}`
      }
    }

    if (authUser) {
      state.authUser = authUser
    }
  },

  LOGOUT_START: state => state.loggingOut = true,

  UPDATING_USER: state => state.updatingUser = true,
  UPDATING_USER_STOP: state => state.updatingUser = false,

  UPDATE_USER: (state, updatedUser) => {
    Object.assign(state.authUser, updatedUser)
    state.updatingUser = false
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  LOGOUT({ commit }) {
    commit('LOGIN_STOP', null)
    commit('RESET_STORE')

    this.dispatch('CONTENT_STORE/RESET_STORE', null, { root: true })
    this.dispatch('FORM_STORE/RESET_STORE', null, { root: true })
    this.dispatch('GROUP_STORE/RESET_STORE', null, { root: true })
    this.dispatch('ORG_STORE/RESET_STORE', null, { root: true })
    this.dispatch('USER_STORE/RESET_STORE', null, { root: true })
    this.dispatch('UTIL_STORE/RESET_STORE', null, { root: true })
    this.dispatch('POLICIES_STORE/RESET_STORE', null, { root: true })
  },

  /**
   * When we first load the application, we want to update vuex from information we may already have in localstorage
   * this is fired in App.vue on created, we check to see if we have a token and if that token is expired:
   * - If the token is expired we want to send the authUser to the login page and remove the tokens they have in localstorage,
   * we also want to reset our vuex store
   *
   * - If the token is not expired we want to:
   *  update the token in store from localstorage
   *  update the authUser via a new request to get authUser based on localstorage authUser id
   */
  async Authentication({ commit }) {
    if (!auth.isAuthenticated()) {
      commit('LOGIN_STOP', null)
      commit('RESET_STORE')

      auth.removeData()

      return false
    } else {
      const token = auth.getToken()

      let headers = {
        "Content-Type": "application/json"
      }

      const response = await axios.get('user')
        .then(response => {
          return response
        })

      const user = response.data.data

      if (user) {
        let role = parseUserRole(user)
        let permissions = parseUserPermissions(user)

        if (permissions) {
          const { can, rules } = new AbilityBuilder();

          if (role == "system_admin") {
            can("manage", "all")
          } else {
            permissions.forEach(p => {
              can(p[1], p[0])
            });
          }

          this._vm.$ability.update(rules);
        }

        if (auth.isAuthenticated() && token && user) {
          commit('LOGIN_STOP', null)

          commit('LOGIN_UPDATE', {
            token: token,
            authUser: user
          })

          this.dispatch('UTIL_STORE/GET_UTILS', null, { root: true })
          this.dispatch('ORG_STORE/GET_MY_ORG', null, { root: true }).then(() => {
            this.dispatch('ORG_STORE/GET_ORG_COMPLIANCE_TAGS', null, { root: true })
          })

        } else {
          commit('LOGIN_STOP', null)
          commit('RESET_STORE')
        }

        return true
      } else {
        commit('LOGIN_STOP', null)
        commit('RESET_STORE')

        return false
      }
    }

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
