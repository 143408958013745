<template>
  <nav>
    <v-app-bar app dark clipped-left color="secondary">

      <v-toolbar-title class="mr-12 align-center">

        <router-link tag="div" :to="{ name: 'dashboard' }">
          <OrganisationLogo 
            :organisation="userOrganisation"
          ></OrganisationLogo>
        </router-link>

      </v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">

        <!-- Putting Microsoft mapping link here -->
        <!-- <Can I="update" a="organisations"> -->
          <!-- <v-btn v-if="user.role === 'organisation_admin' && isMicrosoftAccount"
             class="ml-2" text :to="{ name: 'microsoft/mapping' }">
            <v-icon left>mdi-microsoft</v-icon>
            Microsoft Mapping
          </v-btn> -->
        <!-- </Can> -->

        <Can I="read" a="document">
          <v-btn class="ml-2" text :to="{ name: 'content/all' }">
            <v-icon left>mdi-text-box-outline</v-icon>
            Content
          </v-btn>
        </Can>
        
        <Can I="read" a="form">
          <v-btn class="ml-2" text :to="{ name: 'form/all' }">
            <v-icon left>mdi-clipboard-text-outline</v-icon>
            Forms
          </v-btn>
        </Can>

        <Can I="update" a="organisations">
          <v-btn class="ml-2" text :to="{ name: 'organisation/all' }">
            <v-icon left>mdi-domain</v-icon>
            Organisations
          </v-btn>
        </Can>

        <v-btn class="ml-2" text :to="{ name: 'compliance/all' }">
          <v-icon left>mdi-state-machine</v-icon>
          Compliance
        </v-btn>
        
        <v-divider inset vertical class="mx-2"></v-divider>

        <v-menu offset-y open-on-click>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <!-- conditional span used to stop extreme shrink of logo, may not be necessary with properly sized image  -->
              <div :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp, 'mr-0': $vuetify.breakpoint.smAndDown }">
                <UserAvatar
                  :profile="user.profile"
                  :size="32"
                  color="primary"
                ></UserAvatar>
              </div>
              <span v-if="$vuetify.breakpoint.mdAndUp">{{ user.profile.first_name }} </span>
            </v-btn>
          </template>

          <v-card min-width="280px">
            <UserNav
            :user="user"
            showLogout
            ></UserNav>
          </v-card>

        </v-menu>
      </v-toolbar-items>

      <v-app-bar-nav-icon 
        v-if="!$vuetify.breakpoint.mdAndUp"
        @click="mobileMenu = !mobileMenu"
      ></v-app-bar-nav-icon>
      
    </v-app-bar>
    
    <v-navigation-drawer 
      v-model="mobileMenu"
      fixed
      dark
      top
      temporary
      class="secondary"
      transition="slide-y-transition"
    >
      <v-card flat tile color="secondary" dark>
        <UserNav
          :user="user"
        ></UserNav>
      </v-card>

      <v-divider class="mb-2"></v-divider>

      <v-list rounded class="pt-0">
        <Can I="read" a="document">
          <v-list-item
              :to="{ name: 'content/all' }"
            >
            <v-list-item-action>
              <v-icon>mdi-text-box-outline</v-icon>
            </v-list-item-action>
            <v-list-item-title>Content</v-list-item-title>
          </v-list-item>
        </Can>

        <Can I="read" a="form">
          <v-list-item
            :to="{ name: 'form/all' }"
          >
            <v-list-item-action>
              <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-list-item-action>
            <v-list-item-title>Forms</v-list-item-title>
          </v-list-item>
        </Can>

        <Can I="manage" a="organisations">
          <v-list-item
            :to="{ name: 'organisation/all' }"
          >
            <v-list-item-action>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-action>
            <v-list-item-title>Organisations</v-list-item-title>
          </v-list-item>
        </Can>

        <v-list-item
          :to="{ name: 'compliance/all' }"
        >
          <v-list-item-action>
            <v-icon>mdi-state-machine</v-icon>
          </v-list-item-action>
          <v-list-item-title>Compliance</v-list-item-title>
        </v-list-item>

      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>

        <v-list rounded>
          <v-list-item  @click="handleLogout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

import UserAvatar from '@/components/user/Avatar'
import UserNav from '@/components/navs/UserNav'
import OrganisationLogo from '@/components/organisation/Logo'

export default {
  components: {
    UserAvatar,
    UserNav,
    OrganisationLogo
  },
  props: {
    user: Object
  },
  data() {
    return {
      mobileMenu: false,
      images: {
        logo: require('@/images/logo-crysp-light.png')
      }
    }
  },
  computed: {
    ...mapState({
      userOrganisation: state => state.ORG_STORE.myorganisation
    }),
  },
  methods: {
    handleLogout: function() {
      this.$router.push({ name: 'logout' }, () => {})
    }
  },
}
</script>
