<template>
  <v-content>
    <v-container fluid v-if="user" class="pt-0 mt-0">

      <v-row align="center" justify="center" class="pt-12 grey lighten-5">
        <UserAvatar
          :profile="user.profile"
          :size="96"
          color="primary"
        ></UserAvatar>
      </v-row>

      <!-- Welcome banner -->
      <v-row align="center" justify="center" class="grey lighten-5">
        <v-col cols="12" md="8" xl="8">
          <p class="text-center headline pt-2 pb-4">{{ welcomeMessage }}, {{ user.profile.first_name}} {{ user.profile.last_name }}</p>
          <!-- <v-btn
          icon
          absolute
          right
          top
          outlined
          v-on:click="updateOnboarding"
          class="ma-12 tertiary--text"
          >
              <v-icon small>mdi-help</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>

      <!-- <Onboarding :show="showOnboarding" ref="onboarding"/> -->

      <v-row justify="space-around" class="mt-6">
        <v-col cols="12" sm="12" md="12" lg="11" xl="8">
          <v-row align="center" justify="center">

            <v-col cols="12" sm="6" lg="3">
              <Content />
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <Forms />
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <Compliance />
            </v-col>

            <v-col cols="12" sm="6" lg="3">
              <Policies />
            </v-col>

          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="11" xl="8">
          <v-row align="center" justify="center">

            <v-col cols="12" sm="6" lg="3">
              <MyProfile />
            </v-col>

            <Can not I="manage" a="organisations"><Can I="update" a="organisation">
              <v-col cols="12" sm="6" lg="3">
                <Organisation />
              </v-col>
            </Can></Can>

            <Can not I="manage" a="organisations"><Can I="create" a="user"><Can I="create" a="group">
              <v-col cols="12" sm="6" lg="3">
                <OrganisationUsersGroups />
              </v-col>
            </Can></Can></Can>

            <Can not I="manage" a="organisations">
              <v-col cols="12" sm="6" lg="3">
                <QuickLinks />
              </v-col>
            </Can>

            <Can I="manage" a="organisations">
              <v-col cols="12" sm="6" lg="3">
                <OrganisationsSuperAdmin />
              </v-col>
            </Can>

            <Can I="manage" a="organisations">
              <v-col cols="12" sm="6" lg="4">
                <OrganisationUsersGroupsSuperAdmin />
              </v-col>
            </Can>

          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

import UserAvatar from '@/components/user/Avatar'
import Compliance from '@/components/cards/Compliance'
import Policies from '@/components/cards/Policies'
import Content from '@/components/cards/Content'
import Forms from '@/components/cards/Forms'
import Organisation from '@/components/cards/Organisation'
import OrganisationUsersGroups from '@/components/cards/OrganisationUsersGroups'
import MyProfile from '@/components/cards/MyProfile'
import QuickLinks from '@/components/cards/QuickLinks'
import OrganisationUsersGroupsSuperAdmin from '@/components/cards/OrganisationUsersGroupsSuperAdmin'
import OrganisationsSuperAdmin from '@/components/cards/OrganisationsSuperAdmin'
// import Onboarding from '@/components/Onboarding'
import * as Sentry from '@sentry/browser';
import TestFunctions from '../components/testComponents/testFunctions.vue'

export default {
  name: 'Dashboard',
  components: {
    UserAvatar,
    Compliance,
    Policies,
    Content,
    Forms,
    Organisation,
    OrganisationUsersGroups,
    MyProfile,
    QuickLinks,
    OrganisationUsersGroupsSuperAdmin,
    OrganisationsSuperAdmin,
    TestFunctions
    // Onboarding,
    // FormsSuperAdmin
  },
  mounted() {
    if(this.trialHasExpired) {
      this.$router.push({ name: 'subscribe' });
    }

    // set the Sentry user context
    Sentry.setUser({
      email: this.user.email,
      fullName: this.user.profile.first_name + ' ' + this.user.profile.last_name
    });
  },
  data() {
    return {
      showOnboarding: [0]
    }
  },
  methods: {
    updateOnboarding() {
      this.$refs.onboarding.setShow([0]);
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    }),
    welcomeMessage: function() {
      let currentTime = moment()

      if (!currentTime || !currentTime.isValid()) { return 'Hello' }

      const splitAfternoon = 12 // 24hr time to split the afternoon
      const splitEvening = 17 // 24hr time to split the evening
      const currentHour = parseFloat(currentTime.format('HH'))

      if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        return 'Good afternoon'
      } else if (currentHour >= splitEvening) {
        return 'Good evening'
      }

      return 'Good morning'
    },
  },
}
</script>
