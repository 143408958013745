import { render, staticRenderFns } from "./ViewCompliancyItems.vue?vue&type=template&id=160da412&scoped=true"
import script from "./ViewCompliancyItems.vue?vue&type=script&lang=js"
export * from "./ViewCompliancyItems.vue?vue&type=script&lang=js"
import style0 from "./ViewCompliancyItems.vue?vue&type=style&index=0&id=160da412&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160da412",
  null
  
)

export default component.exports