<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Manage Profile"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card route>

            <v-row dense class="px-4 py-3" justify="end">

              <v-col cols="12" md="4" lg="4" class="d-flex align-center">

                  <v-spacer></v-spacer>
              </v-col>

              <v-col>
                <v-select
                  :items="headers.slice(0,-1)"
                  outlined
                  dense
                  hide-details
                  v-model="sort"
                  prepend-inner-icon="mdi-filter-outline"
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <v-btn outlined block class="px-5 v-btn--icon" @click="descending = !descending" min-height="40">
                  <v-icon dark v-text="descending ? 'mdi-sort-descending' : 'mdi-sort-ascending'"></v-icon>
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                  :disabled="isLoading"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-tabs>
              <v-tab text large plain @click="filter = null"><v-icon dark left>mdi-clipboard-text-multiple-outline</v-icon>Unread</v-tab>
              <v-tab text large plain @click="filter =2"><v-icon dark left>mdi-clipboard-check-outline</v-icon>Read &amp; Signed</v-tab>
            </v-tabs>

            <v-divider></v-divider>

            <v-data-table
              class="v-data-table--selectable pt-md-12"
              :headers="filter ? headers : unreadHeaders"
              :items="getFilteredObservations"
              :options.sync="options"
              :search="search"
              :loading="isLoading"
              loading-text="Loading policies... please wait."
            >
              <template v-slot:no-data>
                No policies available.
              </template>

              <template v-slot:item.policy.name="{ item }">
                {{ item.policy.name }}
              </template>

              <template v-slot:item.status="{ item }">
                <span :class="status[item.status].class">{{ status[item.status].text }}</span>
              </template>

              <template v-slot:item.time_clicked_to_download="{ item }">
                {{ item.time_clicked_to_download || '-' | moment('DD/MM/YYYY : HH:mm') }}
              </template>

              <template v-slot:item.time_clicked_to_confirm="{ item }"  v-if="filter">
                {{ item.time_clicked_to_confirm || '-' | moment('DD/MM/YYYY : HH:mm') }}
              </template>

              <template v-slot:item.time="{ item }"  v-if="filter">
                {{ getTimeSpent(item.time_clicked_to_download,item.time_clicked_to_confirm) }}
              </template>

              <template v-slot:item.actions="{ item }">

                <div class="d-flex justify-end align-center" v-if="$vuetify.breakpoint.smAndUp">

                  <v-btn
                    v-if="filter == null && item.time_clicked_to_download"
                    @click="policyReadConfirmation = true, toSign = item"
                    :disabled="isLoading || item.status.id === 3"
                    color="primary" class="mr-2" small rounded>
                    Mark as read
                  </v-btn>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.prevent="policyDownloadConfirmation = true, toSign = item"
                        :disabled="isLoading"
                        :download="item.policy.name"
                        v-if="!item.time_clicked_to_download"
                      >
                        <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                      </v-btn>
                      <!-- :href="getStorageFile(item.policy.file_path)" -->
                      <!-- :download="item.policy.name" -->
                      <v-btn
                        icon
                        :disabled="isLoading"
                        @click="handleDownloadPolicy2(item.policy)"
                        v-else
                      >
                        <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>

                </div>

              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="policyDownloadConfirmation && !isLoading">
          <v-card class="mx-auto" max-width="600" width="100vw" light v-if="!isDownloading">
            <v-btn
              icon
              tile
              class="ml-auto d-block"
              @click="policyDownloadConfirmation = !policyDownloadConfirmation, toSign = {}"
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
            <v-card-text class="text-center pa-12">
              <v-icon color="primary" x-large class="mb-6">mdi-alert-circle-outline</v-icon>
              <h1 class="mb-12">Acknowlegement Required</h1>

              <p>This document requires acknowlegement that you've read, understood and will implement the policy.</p>

              <p>As we store the time taken to read this policy, are you sure you would like to continue with the download right now. We estimate you need approximately <b>{{ $moment.duration(toSign.policy.time_threshold).humanize() }}</b>.</p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
              <v-btn text color="primary" @click="policyDownloadConfirmation = !policyDownloadConfirmation, toSign = {}">I'll do this later</v-btn>

              <v-spacer></v-spacer>

              <v-btn @click="handleDownloadPolicy" color="primary">
                Continue Downloading
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isDownloading">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="policyReadConfirmation && !isLoading">
          <v-card class="mx-auto" max-width="480" light v-if="!isSigning">
            <v-card-title class="primary primary--text--contrast mb-4">
              Mark policy as read
            </v-card-title>

            <v-card-text>
              <v-alert
                colored-border
                type="warning"
                elevation="3"
                outlined
                icon="mdi-alert"
                class="text--disabled"
                v-if="belowThreshold(toSign.time_clicked_to_download,toSign.policy.time_threshold)"
              >
                <span class="text--secondary">There is a read time threshold of <strong>{{ $moment.utc(toSign.policy.time_threshold).format('HH:mm') }} minutes</strong> on this policy, are you sure you read this thoroughly?</span>
              </v-alert>

              Thanks for reading the <strong>{{ toSign.policy.name }} policy</strong>, to complete this action can you confirm the following.

              <v-switch
                v-model="toSign.has_read_policy"
                inset
                label="I have read the policy"
                class="mt-5"
              ></v-switch>

              <v-switch
                v-model="toSign.has_understood_policy"
                inset
                class="mt-0"
                label="I have understood the contents of the policy"
              ></v-switch>

              <v-switch
                v-model="toSign.agrees_to_implement_policy"
                inset
                class="mt-0"
                label="I agree that I will implement the policy"
              ></v-switch>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click="policyReadConfirmation = !policyReadConfirmation, toSign = {}">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn
                @click="handleSignPolicy"
                color="primary"
                :disabled="!(toSign.agrees_to_implement_policy && toSign.has_understood_policy && toSign.has_read_policy)">
                Mark as read
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isSigning">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>
    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ViewPolicies',
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  components: {
  },
  data() {
    return {
      search: '',
      filter: null,
      sort: 'name',
      descending: true,
      updateOptionsBasedOnManualSortControls: true,
      options: {},
      policyReadConfirmation: false,
      policyDownloadConfirmation: false,
      isSigning: false,
      isDownloading: false,
      toSign: {},
      status: [
        {text: 'Never', class:'error--text'},
        {text: 'Accessed', class:'warning--text'},
        {text: 'Signed', class:'success--text'}
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      // policies: state => state.POLICIES_STORE.policies,
      observations: state => state.POLICIES_STORE.observations,
      serverItemsLength: state => {
        return state.POLICIES_STORE.policies[0]?.total || 0
      },
      isLoading: (state) => {
        return state.POLICIES_STORE.loadingPolicies ? true : false
      },
      getFilteredObservations() {
        let filteredObservations = []

        filteredObservations = this.observations.filter(item => {
          if (!this.filter) {
            if (item.status <= 1) {
              return item
            }
          } else {
            if (item.status == this.filter) {
              return item
            }
          }
        })

        return filteredObservations
      }
    }),
    headers() {
      let headers = []

      headers.push({ text: "Policy Name", value: "policy.name" })
      headers.push({ text: "Status", value: "status" })
      headers.push({ text: "Accessed", value: "time_clicked_to_download" })
      headers.push({ text: "Signed", value: "time_clicked_to_confirm" })
      headers.push({ text: "Time", value: "time" })

      headers.push({ text: "", value: "actions", sortable: false, align: "end" })

      return headers
    },
    unreadHeaders() {
      let headers = []

      headers.push({ text: "Policy Name", value: "policy.name" })
      headers.push({ text: "Status", value: "status" })
      headers.push({ text: "Accessed", value: "time_clicked_to_download" })

      headers.push({ text: "", value: "actions", sortable: false, align: "end" })

      return headers
    }
  },
  watch: {
    options: {
      handler () {
        this.updateOptionsBasedOnManualSortControls = false;
        this.sort = this.options.sortBy[0];
        this.descending = this.options.sortDesc[0];
        this.updateOptionsBasedOnManualSortControls = true;
        this.getData()
      },
      deep: true,
    },
    search() {
      this.getDataDebounced();
    },
    sort() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortBy = [this.sort];
      }
    },
    descending() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortDesc = [this.descending];
      }
    }
  },
  methods: {
    getDataDebounced() {
      // cancel pending call
      clearTimeout(this.timer)

      // delay new call 500ms
      this.timer = setTimeout(() => {
        // ensure we request the first page (reset pagination)
        this.options.page = 1;
        this.getData()
      }, 500)
    },
    async handleDownloadPolicy() {
      this.isDownloading = true

      this.toSign.time_clicked_to_download = this.$moment();
      this.toSign.status = 1;

      await this.$http.put('policy/observation', this.toSign)
        .then(data => {
          this.policyDownloadConfirmation = false
          this.isDownloading = false

          //updated to use storage.js function rather than below funciton
          //call download file function in storage.js
          this.downloadFile(this.getStorageFile(this.toSign.policy.file_path), (this.toSign.policy.name));

          this.toSign = {}
        }, error => {
          console.error('Something has gone wrong downloading policy')
        })

    },
    /**
     * 2nd method to download policy
     * @param {*} policy 
     */
    async handleDownloadPolicy2(policy){
      // console.log(policy)
      // console.log('downloading policy')
      // console.log(policy.name)
      // console.log(policy.file_path)
      try{
        this.downloadFile(this.getStorageFile(policy.file_path), policy.name);
      }catch(e){
        console.log(e)
      }
    },
    // commented out to use storage.js funciton instead
    // downloadFile(filePath,fileName){
    //     let link = document.createElement('a');
    //     link.href = filePath;
    //     link.download = fileName;
    //     link.click();
    // },
    async handleSignPolicy() {
      this.isSigning = true

      this.toSign.time_clicked_to_confirm = this.$moment();
      this.toSign.status = 2;

      await this.$http.put('policy/observation', this.toSign)
        .then(data => {
          this.policyReadConfirmation = false
          this.isSigning = false

          this.$store.commit('POLICIES_STORE/UPDATE_OBSERVATION', this.toSign.id)
          this.$toast.success(`${this.toSign.policy.name} signed successfully.`)

          this.toSign = {}
        }, error => {
          console.error('Something has gone wrong signing policy')
        })

    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search;
      this.$store.dispatch('POLICIES_STORE/GET_OBSERVATIONS', {sortBy, sortDesc, page, itemsPerPage, search})
    },
    getTimeSpent(from, to) {
      if (from && to) {
        let diff = this.$moment.duration(this.$moment(to).diff(this.$moment(from)));
        let duration = this.$moment.duration({
          days: diff.days(),
          hours: diff.hours(),
          minutes: diff.minutes(),
          seconds: diff.seconds()
        });
        return this.$moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
      } else {
        return '-';
      }
    },
    belowThreshold(from,threshold) {
      return this.$moment.duration(this.$moment().diff(this.$moment(from))).asMilliseconds() < threshold;
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Manage Profile', path: '/profile/' },
      { text: 'Policies', path: '/policies' }
    ])
  },
  beforeMount() {
    this.getData()
    this.$moment.relativeTimeThreshold('m', 60);
  },
}
</script>
