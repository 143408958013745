<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card>
      <v-card-title class="primary primary--text--contrast" v-if="!isLoading">

        {{ response.user.profile ? `${response.user.profile.first_name} ${response.user.profile.last_name}` : 'User' }}'s Form Response

        <v-spacer></v-spacer>
      </v-card-title>

      <v-simple-table v-if="!isLoading">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Field name</th>
              <th class="text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in JSON.parse(response.response_body)" :key="i">

              <td>{{ item.config.label }}</td>

              <td v-if="item.type.id == 9 && item.field_value">
                <a :href="item.field_value | returnFileFromString" download>Download</a>
              </td>
              <td v-else-if="!item.field_value">
                [No Response]
              </td>
              <td v-else>{{ Array.isArray(item.field_value) ? item.field_value.join(', ') : item.field_value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row align="center" justify="center" class="pa-8" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BreadcrumbsManager from '@/_util/breadcrumbManager'
import { mapState } from 'vuex'

export default {
  name: "FormResponse",
  mixins: [
    BreadcrumbsManager
  ],
  props: [
    'form'
  ],
  computed: {
    ...mapState({
      responses: state => state.FORM_STORE.responses,
      isLoading: state => state.FORM_STORE.loadingResponses
    }),
    response() {
      return !this.responses
        ? false
        : this.responses.filter((item) => {
          return item.id == this.$route.params.response_id
        })[0]
    }
  },
  beforeMount() {
    if (!this.response) {
      this.getData()
    }
  },
  mounted() {},
  methods: {
    async getData() {
      await this.$store.dispatch('FORM_STORE/GET_RESPONSES', this.$route.params.form_id)
    }
  },
  watch: {
    response: function() {
      this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Forms' , path: '/form/all/' },
        { text: ':form' },
        { text: ':response' }
      ])

      this.replaceBreadcrumb({
        find: ':form',
        replace: {
          text: this.form.meta.name,
          path: `/form/${this.form.id}`
        }
      })

      this.replaceBreadcrumb({
        find: ':response',
        replace: {
          text: `${this.response.user.username}'s response`,
          path: `/form/${this.response.id}`
        }
      })
    }
  }
}
</script>
