<template>

  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>
    
    <v-card-title>
      Useful Links
    </v-card-title>

    <v-card-subtitle>
      Useful information and links.
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card flat tile height="100%" class="overflow-y-auto">
      <v-card-text 
        v-if="!isLoading"
        v-html="usefulContent.meta ? usefulContent.meta.content : 'No useful links available.'"
      />
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Links.png")
      }
    };
  },
  beforeMount() {
    this.getData()
  },
  computed: {
    ...mapState({
      usefulContent: state => state.CONTENT_STORE.usefulContentItem,
      isLoading: state => state.CONTENT_STORE.loadingContent,
      user: state => state.AUTH_STORE.authUser
    })
  },
  methods: {
    getData() {
      this.$store.dispatch('CONTENT_STORE/GET_USEFUL_CONTENT',parseInt(this.user.organisation_id))
    }
  }
};
</script>
