<template>
  <v-container
    fluid
    fill-height
    class="pa-0">

    <VideoBackground
        ref="videobackground"
        :src="assets.videos.background"
        style="max-height: 100vh; height: 100vh;">

      <v-container fluid>

        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card class="mx-auto overflow-hidden" :width="!offerSuccess ? '1200px' : '600px'" max-width="100%" light shape>
              <v-fade-transition mode="out-in" v-if="!isLoading">
                <v-row no-gutters v-if="!offerSuccess">
                  <v-col sm="12" lg="6" class="tertiary white--text pa-12">
                    <OrganisationLogo />
                    <h1>Starter <span class="font-weight-light">- 14 day free trial</span></h1>
                    <p>Good for organisations that just need a platform</p>

                    <p
                      class="mb-2"
                      v-for="(item, i) in items"
                      :key="i">
                      <v-btn
                        class="mr-2"
                        text
                        icon
                        color="white"
                        small
                      >
                        <v-icon small>mdi-check</v-icon>
                      </v-btn>
                      <span v-text="item"></span>
                    </p>
                  </v-col>
                  <v-col sm="12" lg="6" class="pa-12">
                    <h1 class="mb-5">Join the leading business compliance platform today</h1>

                    <ValidationObserver v-slot="{ invalid, validated, passes, errors  }" ref="provider">
                      <v-form @submit.prevent="passes(handleRegistration)">
                        <label for="first_name">Your name *</label>
                        <v-row>
                          <v-col cols="6">
                            <ValidationProvider name="first_name" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                hint="First Name"
                                persistent-hint
                                id="first_name"
                                v-model="first_name"
                                outlined
                                dense
                                :error-messages="errors"
                                :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="6">
                            <ValidationProvider name="last_name" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                hint="Last Name"
                                persistent-hint
                                id="last_name"
                                v-model="last_name"
                                outlined
                                dense
                                :error-messages="errors"
                                :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <label for="email">Your email *</label>
                            <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">
                              <v-text-field
                              id="email"
                              v-model="email"
                              outlined
                              dense
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <label for="password">Your password *</label>
                            <ValidationProvider name="password" rules="required|min:8"  v-slot="{ errors, valid }">
                              <v-text-field
                              id="password"
                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPass ? 'text' : 'password'"
                              @click:append="showPass = !showPass"
                              v-model="password"
                              outlined
                              dense
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <label for="password_confirmation">Confirm your password *</label>
                            <ValidationProvider name="confirmation" rules="required|password:@password" v-slot="{ errors, valid }">
                              <v-text-field
                              id="password_confirmation"
                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPass ? 'text' : 'password'"
                              @click:append="showPass = !showPass"
                              v-model="password_confirmation"
                              outlined
                              dense
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <label for="org_name">Organisation Name *</label>
                            <ValidationProvider name="organisation_name" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                              id="org_name"
                              placeholder="Enter your organisation name"
                              outlined
                              dense
                              hide-details="auto"
                              v-model="org_name"
                              :error-messages="errors"
                              :success="valid"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <ValidationProvider name="industry_type" rules="required"  v-slot="{ errors, valid }">
                                <v-autocomplete
                                  outlined
                                  dense
                                  label="Industry Type" item-text="name" item-value="type_id" hide-details="auto"
                                  :loading="isLoading"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="industry_type"
                                  :items="industryTypes">
                                </v-autocomplete>
                              </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                              <ValidationProvider name="organisation_type" rules="required" v-slot="{ errors, valid }">
                                <v-autocomplete
                                  outlined
                                  dense
                                  label="Organisation Type" item-text="name" item-value="type_id" hide-details="auto"
                                  :loading="isLoading"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-model="organisation_type"
                                  :items="organisationTypes">
                                </v-autocomplete>
                              </ValidationProvider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col sm="12" lg="6">
                            <p>
                              <v-btn
                                class="grey lighten-4 mr-2"
                                text
                                icon
                                color="primary"
                                small
                              >
                                <v-icon small>mdi-check</v-icon>
                              </v-btn>
                              <span>No credit card required</span>
                            </p>
                            <p>
                              <v-btn
                                class="grey lighten-4 mr-2"
                                text
                                icon
                                color="primary"
                                small
                              >
                                <v-icon small>mdi-check</v-icon>
                              </v-btn>
                              <span>Cancel at anytime</span>
                            </p>
                          </v-col>
                          <v-col sm="12" lg="6">
                            <v-btn color="primary" @click="passes(handleRegistration)" :disabled="invalid || !Object.values(errors).every(x => !x.length)">Let’s do this!</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </ValidationObserver>
                  </v-col>
                </v-row>
              </v-fade-transition>
              <v-fade-transition mode="out-in">
                <v-layout column v-if="offerSuccess" class="pa-12 text-center">
                  <v-icon color="primary" x-large>mdi-checkbox-marked-circle-outline</v-icon>
                  <h1 class="mb-12">Success</h1>
                  <p>Thank you for registering for our <b>Starter Plan Trial</b>!</p>
                  <p class="mb-12">We’ve sent you an email to <u>{{ email }}</u>, could you please confirm this is you to start exploring Crysp today!</p>
                  <v-btn color="primary" :to="{ name: 'login' }" class="align-self-center">Back to log in</v-btn>
                </v-layout>
              </v-fade-transition>

              <v-row align="center" justify="center" v-if="isLoading">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </VideoBackground>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as Sentry from '@sentry/browser';

import VideoBackground from 'vue-responsive-video-background-player'

import OrganisationLogo from '@/components/organisation/Logo'

import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  name: 'Register',
  components: {
    VideoBackground,
    OrganisationLogo,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      assets: {
        images: {
          background: require("@/images/background.jpg"),
          logo: require("@/images/logo-crysp-dark.png")
        },
        videos: {
          background: require("@/videos/background.mp4"),
        }
      },
      showOffer: true,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      showPass: false,
      organisation_type: '',
      industry_type: '',
      isCreating: false,
      org_type: '',
      org_name: '',
      package: 'trial',
      offerSuccess: false,
      items: [
        'Securely centralise all of your key documents and policies',
        'Create a real time compliance dashboard',
        'Take all of your forms to the cloud',
        'Build workflows that can streamline the running of your property',
        'Manage your full legal and compliance lifecycle in one place',
        'Invite your team members to ensure a transparent and accountable workplace'
      ],
      newUser: {
        send_activation: 0,
        send_welcome: 1,
        role: 'organisation_admin',
        reseller_id: 0
      }
    }
  },
  computed: {
    ...mapState({
        isLoading: state => state.UTIL_STORE.loadingTypes,
        industryTypes: state => state.UTIL_STORE.industryTypes,
        organisationTypes: state => state.UTIL_STORE.organisationTypes,

    //   isLoading: state => state.AUTH_STORE.loggingIn
    })
  },
  mounted() {
    this.$refs.videobackground.player.play()
    this.$store.dispatch('UTIL_STORE/GET_ORG_AND_INDUSTRY_TYPES')
  },
  methods: {
    organisationAvatarName() {
      if (this.name) {
        return this.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase().substring(0, 2);
      }

      return false
    },
    handleRegistration(){

      this.isCreating = true

      const organisation = this.$http.post('organisation', {
          name: this.org_name,
          reseller_id: 0,
          organisation_type: this.organisation_type,
          industry_type: this.industry_type,
          package_name: this.package
        })
        .then(response => {
          const org = response.data.data[0]
          this.$store.commit('ORG_STORE/CREATE_ORG', org)

          this.newUser['organisation_id'] = parseInt(org.id)
          this.newUser['username'] = this.email
          this.newUser['email'] = this.email
          this.newUser['password'] = this.password
          this.newUser['password_confirmation'] = this.password_confirmation
          this.newUser['first_name'] = this.first_name
          this.newUser['last_name'] = this.last_name

          const userResponse = this.$http.post('user', this.newUser)
            .then(response => {
              console.log('success');
              this.isCreating = false

              this.offerSuccess = true
              this.isCreating = false
            }).catch((error) => {
              console.log('Something went wrong');
              this.isCreating = false
              this.$toast.error(error.response.data.message ?? error.message)

              //set the local scope of the Sentry error to have the newUser object
              Sentry.setUser({
                email: this.email,
                fullName: this.first_name + ' ' + this.last_name
              });
              Sentry.setTag("registerExternal", this.email);

              // raise a Sentry error
              Sentry.captureException(error)
            })
        })
        .catch(error => {
          this.isCreating = false

          //set the local scope of the Sentry error to have the newUser object
          Sentry.setUser({
                email: this.email,
                fullName: this.first_name + ' ' + this.last_name
              });
          Sentry.setTag("registerExternal", this.email);

          // raise a Sentry error
          Sentry.captureException(error)

          if (error.status == 419) // This is probably insufficient
          {
            this.$toast.error('The Organisation name is already in use, Please check and try again if you feel this in error please contact support.')
          }else{
            this.$toast.error('Something went wrong creating a new organisation, please try again.')
          }
        })
    }
  },
}
</script>

<style lang="scss">
  .videobg-content {
    overflow: auto;
  }
</style>
