<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img
      class="white--text align-end"
      style="max-height: 160px"
      :src="card.image"
    />

    <v-divider></v-divider>

    <v-card-title class="pb-2"> Manage Compliance </v-card-title>

    <v-card outlined class="mx-4 mb-2">
      <v-row
        align="center"
        justify="center"
        style="height: 100%"
        v-if="!isLoading && statusData.total_compliance_items == 0"
      >
        <v-col align="center" justify="center">
          <div class="text-center caption px-4">
            You are not managing any compliance items.
          </div>
        </v-col>
      </v-row>

      <template v-if="isLoading || statusData?.total_compliance_items > 0">
        <v-row align="center" justify="center" class="summary-status pa-1 " >
          <v-col cols="4" md="3" align="center" justify="center">
            <v-progress-circular
              :rotate="-90"
              :size="50"
              :width="6"
              :value="getPercentValue('2')"
              :indeterminate="isLoading"
              color="success"
            >
           {{ Math.round(getPercentValue("2"))}}%
            </v-progress-circular>

          </v-col>

          <v-col cols="4" md="3" align="center" justify="center">
            <v-progress-circular
              :rotate="-90"
              :size="50"
              :width="6"
              :value="getPercentValue('3')"
              :indeterminate="isLoading"
              color="warning"
            >
            {{ Math.round(getPercentValue("3")) }}%
            </v-progress-circular>
        
          </v-col>

          <v-col cols="4" md="3" align="center" justify="center">
            <v-progress-circular
              :rotate="-90"
              :size="50"
              :width="6"
              :value="getPercentValue('4')"
              :indeterminate="isLoading"
              color="error"
            >
            {{ Math.round(getPercentValue("4")) }}%
            </v-progress-circular>
          
          </v-col>
        </v-row>
      </template>
    </v-card>

    <v-card-actions class="mb-2 px-4">
      <v-btn :to="{ name: 'compliance/all' }"> Manage Compliance </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import ViewCompliancesTotalStatus from "../../views/compliance/ViewCompliancesTotalStatus.vue";

export default {
  components: {
    ViewCompliancesTotalStatus,
  },
  data() {
    return {
      totals: {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0,
      },
      card: {
        image: require("@/images/cards/Crysp_Icons_Compliance.png"),
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.CONTENT_STORE.loadingComplianceStats,
      statusData: (state) => state.CONTENT_STORE.compliancesStats,
      complianceItems: (state) => state.CONTENT_STORE.userComplianceItems,
    }),
    percentageStatusData() {
      return this.statusData?.percent_by_status;
    },

    statusDataCount() {
      return this.statusData?.items_per_status;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch("CONTENT_STORE/GET_COMPLIANCES_STATS");
    },
    getPercentValue(statusId) {
      if (!this.percentageStatusData) return null;

      return this.percentageStatusData[statusId]
    },

    getCountValue(statusId) {
      if (!this.statusDataCount) return null;
      return this.statusDataCount[statusId];
    },
    calculateItemPercentage(v) {
      return Math.round((v / total) * 100);
    },
  },
};
</script>

<style>

</style>
