<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Manage Profile"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <UpdateProfile :user="user" v-if="user"/>
          <UpdatePassword v-if="user"/>
        
          <v-row align="center" justify="center" v-if="!user">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

import UpdatePassword from './UpdatePassword'
import UpdateProfile from './UpdateProfile'

export default {
  name: 'ManageProfile',
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    UpdatePassword,
    UpdateProfile
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    })
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Manage Profile', path: '/profile/' }
    ])

    //update the state of state.ORG_STORE.loadingMyOrganisation to false
    this.$store.dispatch('ORG_STORE/setLoadingMyOrganisation', false)
  }
}
</script>
