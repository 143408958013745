<template>
  <v-avatar 
    v-if="profile"
    :color="profile.avatar ? '' : color"
    :size="size"
    class="v-organisation-avatar text-center"
    left
  >
      <img
        v-if="profile.avatar"
        :src="avatar"
      />
      <span 
        v-if="!profile.avatar && profile.name"
        :class="['primary--text--contrast', getFontSizeClass()]"
      >{{ profile.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase().substring(0, 2) }}</span>
  </v-avatar>
</template>

<script>
import StorageManager from '@/_util/storage'
// import OrganisationUtils from '@/_util/organisation'
import { isValidJSON } from '@/_util/json'
import axios from '../../_store/axios'

export default {
  name: 'OrganisationAvatar',
  mixins: [
    StorageManager,
    // OrganisationUtils
  ],
  props: {
    organisation: {
      type: Object,
      default: null
    },
    size: {
      type: Number,
      default: 48
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      profile: false,
      avatar: undefined
    }
  },
  mounted() {
    this.setProfile(this.organisation)

    if(this.profile.avatar){
      this.setAvatar();
    }
  },
  watch: {
    organisation: function(n,o) {
      this.setProfile(n)
    },
    profile: function(n, o) {
      if (n.avatar) {
        this.setAvatar();
      }
    }
  },
  methods: {
    setProfile(d) {
      if (d) {
        this.profile = d.profile
        this.profile.name = d.name
      }
    },
    setAvatar(){
      //put whole function inline here
      let token = localStorage.getItem('token');

      if(isValidJSON(this.profile.avatar)) {
        let avatar = JSON.parse(this.profile.avatar);
        let path = this.getStorageFile(avatar.url);

        try {
          axios.get(path, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            responseType: 'blob'
          }).then(response => {
            const imageUrl = URL.createObjectURL(response.data);
            this.avatar = imageUrl;
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    getFontSizeClass() {
      if (this.size >= 128) {
        return 'display-2'
      }
      if (this.size >= 96) {
        return 'display-1'
      }
      if (this.size > 48) {
        return 'headline'
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .v-organisation-avatar img,
  .v-organisation-avatar svg {
    object-fit: cover;
  }
</style>