<template>
  <v-select
    v-model="selected"
    outlined
    color="hsla(256, 0%, 0%, 0.5)"
    :dense="dense"
    :items="options"
    :item-text="itemNameKey"
    :item-value="itemValueKey"
    :disabled="disabled || options.length === 0"
    clearable
    :menu-props="{ bottom: true, offsetY: true}"
    :label="label"
    multiple
    hide-details
    :return-object="useObjectValues"
  >
    <template #selection="{ item }">
      <v-chip
        close
        :close-icon="chipCloseIcon"
        @click:close="handleItemDeselected(item)"
        small
        label
        :color="color || null" 
      >
        {{ isArrayOfObjects ? item[itemNameKey] : item }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "VMultipleSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "Select Items",
    },
    initialSelected: {
      type: Array,
      default: () => [],
    },
    itemNameKey: {
      type: String,
      default: "name",
    },
    itemValueKey: {
      type: String,
      default: "id",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chipCloseIcon: {
      type: String,
      default: "mdi-close",
    },
    useObjectValues: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  created() {
    this.selected = this.initialSelected;
  },
  computed: {
    isArrayOfObjects() {
      return (
        this.options.length > 0 &&
        typeof this.options[0] === "object" &&
        !Array.isArray(this.options[0])
      );
    },
  },
  watch: {
    selected(newValue) {
      this.$emit("updateSelected", newValue);
    },
  },
  methods: {
    clearSelected() {
      this.selected = [];
    },
    handleItemDeselected(item) {
      if (this.isArrayOfObjects) {
        this.selected = this.selected.filter(
          (i) => i !== item[this.itemValueKey]
        );
      } else {
        this.selected = this.selected.filter((value) => value !== item);
      }
    },
  },
};
</script>

<style></style>
