var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{staticClass:"compliance-items-grouped"},_vm._l((_vm.items),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v("View compliance items")])])]},proxy:true},{key:"default",fn:function({ open }){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6"}},[_c('strong',[_vm._v(_vm._s(item.content_name))])]),_c('v-col',{staticClass:"d-flex align-center justify-end text--secondary px-2",attrs:{"cols":"6","align":"right"}},[_c('v-fade-transition',[(open)?_c('span',{key:"0"}):_c('span',{key:"1"},[_c('v-progress-circular',{staticClass:"mx-1",attrs:{"rotate":-90,"size":32,"width":5,"value":_vm.calculateItemPercentage(
                      _vm.itemTotals[i]?.pass,
                      _vm.itemTotals[i].total
                    ),"color":"success"}},[_c('strong',{staticClass:"caption"},[_vm._v(_vm._s(_vm.itemTotals[i]?.pass))])]),_c('v-progress-circular',{staticClass:"mx-1",attrs:{"rotate":-90,"size":32,"width":5,"value":_vm.calculateItemPercentage(
                      _vm.itemTotals[i]?.warn,
                      _vm.itemTotals[i].total
                    ),"color":"warning"}},[_c('strong',{staticClass:"caption"},[_vm._v(_vm._s(_vm.itemTotals[i]?.warn))])]),_c('v-progress-circular',{staticClass:"mx-1",attrs:{"rotate":-90,"size":32,"width":5,"value":_vm.calculateItemPercentage(
                      _vm.itemTotals[i]?.fail,
                      _vm.itemTotals[i].total
                    ),"color":"error"}},[_c('strong',{staticClass:"caption"},[_vm._v(_vm._s(_vm.itemTotals[i]?.fail))])])],1)]),_c('v-fade-transition',[(!open)?_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}):_vm._e()],1),_c('v-menu',{attrs:{"right":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("$vuetify.icons.values.download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.exportAsPDF(i)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#FF7981"}},[_vm._v("mdi-file-pdf-box")])],1),_c('v-list-item-title',[_vm._v("Export as PDF")])],1),_c('JsonExcel',{staticClass:"btn btn-default d-flex",attrs:{"data":item.items,"fields":_vm.json_fields_item,"worksheet":"Crysp Compliance Report","name":`crysp-compliance-report-${item.content_name}.xls`}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"#83CC70"}},[_vm._v("mdi-file-excel-box")])],1),_c('v-list-item-title',[_vm._v(" Export for Excel ")])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{
                    name: 'content',
                    params: { content_id: item.content_id },
                  }}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("View Content")])]),_c('Can',{attrs:{"I":"manage","a":"documents"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{
                      name: 'content/edit',
                      params: { content_id: item.content_id },
                    }}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-file-document-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Content")])])],1)],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',[_c('v-divider'),_c('ViewContentItemTable',{attrs:{"complianceHeaders":_vm.headers,"complianceItems":item.items,"filtersMap":_vm.filtersMap,"contentId":item.content_id,"isChildContent":true,"isLoading":_vm.isLoading,"enableDelete":false,"showFilters":true,"filterItems":true,"hideFooter":false},on:{"manageComplianceItem":function($event){return _vm.$emit('manageComplianceItem', $event)}}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }