import axios from '../axios'

const initialState = () => {
  return {
    organisations: [],
    organisation: {},
    myorganisation: {},
    loadingOrganisations: false,
    loadingOrganisation: false,
    loadingMyOrganisation: false,
    cache: {
      organisations: {
        lifetime: 30000,
        microtime: 0
      }
    },
    orgComplianceTags: [],
  }
}

const state = initialState()

const getters = {
  getOrganisation: state => state.organisations
}

const mutations = {
  CREATE_ORG: (state, organisation) => {
    state.organisations.push(organisation)
  },

  LOADING_ORGS: state => state.loadingOrganisations = true,
  LOADING_ORG: state => state.loadingOrganisation = true,
  LOADING_MY_ORG: state => state.loadingMyOrganisation = true,

  DELETE_ORG: (state, id) => {
    const filtered = state.organisations.filter(organisation => organisation.id !== id)
    state.organisations = filtered
  },

  SET_ORGS: (state, organisations) => {
    state.loadingOrganisations = false
    state.organisations = organisations

    state.cache.organisations.microtime = Date.now()
  },

  SET_ORG: (state, organisation) => {
    state.loadingOrganisation = false
    state.organisation = organisation
  },

  SET_MY_ORG: (state, organisation) => {
    state.loadingMyOrganisation = false
    state.myorganisation = organisation
  },
  SET_ORG_COMPLIANCE_TAGS: (state, tags) => {
    state.orgComplianceTags = tags
  },  

  SET_TRIAL_EXPIRED: (state, value) => {
    state.trialExpired = value;
  },

  UPDATE_ORG_IN_ORGS: (state, payload) => {
    state.organisations[payload.index] = payload.org
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  /**
   * This method returns an array of all organisations
   *
   */
  GET_ORGS({ commit, dispatch, rootState }, options) {
    commit('LOADING_ORGS')

    let cacheStatus = (Date.now() - state.cache.organisations.microtime) < state.cache.organisations.lifetime && state.cache.organisations.microtime > 0
      ? true
      : false

    let variables = {};

    if (typeof options !== "undefined") {

      let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

      if (sortBy == 'profile.organisation_type.name') {
        sortBy = 'type.name';
      }

      if (sortBy == 'profile.industry_type.name') {
        sortBy = 'industry.name';
      }

      variables = {
        offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
        limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
        sortBy: sortBy,
        sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
        search: options.search
      }

    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
    }

    axios.get('organisations', {
      params: variables
    }).then(({ data }) => {
      commit('SET_ORGS', data.data)
    }, error => {
      console.error('Something has gone wrong getting organisations.', error)
    })
  },

  GET_ORG({ commit, dispatch, rootState }, organisation_id) {
    commit('LOADING_ORG')
  },

  GET_MY_ORG({ commit, rootState }) {
    commit('LOADING_MY_ORG')

    let variables = {}

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
    }

    axios.get('organisations', {
      params: variables
    }).then(({ data }) => {
      commit('SET_MY_ORG', data.data[0])
      }, error => {
        console.error('Something has gone wrong getting user organisation.', error)
      })
  },

  GET_ORG_COMPLIANCE_TAGS({ commit, rootState }) {

    let variables = {}
    let organisation_id;

    if (rootState.AUTH_STORE.authUser) {
      organisation_id = rootState.AUTH_STORE.authUser.organisation_id
    }

    axios.get(`organisation/${organisation_id}/tags`, {
      params: variables
    }).then(({ data }) => {
      commit('SET_ORG_COMPLIANCE_TAGS', data.data)
      }, error => {
        console.error('Something has gone wrong getting organisation compliance tags.', error)
      })
  },

  /**
   * Update an organisation, update store organisations
   */
  UPDATE_ORG({ commit }, organistion) {
    const organisations = state.organisations

    for (var i = 0; i < organisations.length; i++) {
      if (organistion.id === organisations[i].id) {
        commit('UPDATE_ORG_IN_ORGS', {
          index: i,
          org: organistion
        })
      }
    }
  },

  /**
   * Update an organisation plan after successful purchase
   */
  UPDATE_ORG_PLAN({ commit }, organisation_id) {

    axios.put('organisation/upgrade', {
      id: organisation_id
    }).then(({ data}) => {
      commit('SET_MY_ORG', data.data[0])
    }, error => {
      console.log('Something has gone wrong saving the organisation plan upgrade')
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
