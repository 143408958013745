<template>
  <div class="text-center">
    <v-pagination v-model="page" class="my-4" :length="length"></v-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    length: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    page() {
      this.$emit("updatePage", this.page);
    },
  },
};
</script>

<style></style>
