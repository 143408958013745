import axios from '../axios'

const initialState = () => {
  return {
    loadingTypes: false,
    loadingUsefulContent: false,
    loadingContent: false,
    industryTypes: [],
    organisationTypes: [],
    inputTypes: [],
    formPermissions: [],
    formStatuses: []
  }
}

const state = initialState()

const getters = {
  getIndustryTypes: state => state.industryTypes,
  getOrganisationTypes: state => state.organisationTypes,
  getInputTypes: state => state.inputTypes
}

const mutations = {
  LOADING_UTILS: state => state.loadingTypes = true,

  LOADED_UTILS: state => state.loadingTypes = false,

  SET_IND_TYPES: (state, types) => {
    state.industryTypes = types
  },

  SET_ORG_TYPES: (state, types) =>  {
    state.organisationTypes = types
  },

  SET_INPUT_TYPES: (state, types) => {
    state.inputTypes = types
  },

  SET_FORM_STATUSES: (state, statuses) => {
    state.formStatuses = statuses
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  GET_ORG_AND_INDUSTRY_TYPES({ commit, dispatch, rootState }){
    commit ('LOADING_UTILS')

    const getIndustryTypes = async () => {
      const response = await axios.get('industryTypes')

      return response.data || response
    }

    const getOrganisationTypes = async () => {
      const response = await axios.get('organisationTypes')

      return response.data || response
    }

    Promise.all([
      getIndustryTypes(),
      getOrganisationTypes(),
    ]).then(([industry, org, inputs, statuses, usefulContent]) => {
      commit('SET_IND_TYPES', industry.data)
      commit('SET_ORG_TYPES', org.data)
      commit('LOADED_UTILS')
    })
  },

  /**
   * Get and combine all types
   */
  GET_UTILS({  commit, dispatch, rootState  }) {
    commit('LOADING_UTILS')

    const getIndustryTypes = async () => {
      const response = await axios.get('industry-types');

      return response.data || response
    }

    const getOrganisationTypes = async () => {
      const response = await axios.get('organisation-types')

      return response.data || response
    }

    const getInputTypes = async () => {
      const response = await axios.get('form/inputTypes')

      return response.data || response
    }

    const getFormStatuses = async () => {
      const response = await axios.get('form/statuses')

      return response.data || response
    }

    Promise.all([
      getIndustryTypes(),
      getOrganisationTypes(),
      getInputTypes(),
      getFormStatuses(),
    ]).then(([industry, org, inputs, statuses]) => {
      commit('SET_IND_TYPES', industry.data)
      commit('SET_ORG_TYPES', org.data)
      commit('SET_INPUT_TYPES', inputs.data)
      commit('SET_FORM_STATUSES', statuses.data)
      commit('LOADED_UTILS')
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
