<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card>
      <v-card-title class="primary primary--text--contrast" style="position: relative;">
        Form Editor

        <span style="position: absolute; left: 0; bottom: 0; width: 100%; height: 1px; background-color: #000; opacity: 0.05;"></span>
      </v-card-title>

      <v-row justify="space-between" v-if="!isUpdating && !isLoading">
        <v-col class="py-0">

          <ValidationObserver v-slot="{ invalid, validated, passes }">
            <v-form @submit.prevent="">

              <v-tabs dark background-color="primary" v-model="activeTab">
                <v-tab>
                  <v-icon left>mdi-file-document</v-icon>
                  Stage 1
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-form-textbox</v-icon>
                  Stage 2
                  </v-tab>
                <v-tab>
                  <v-icon left>mdi-cog</v-icon>
                  Stage 3
                </v-tab>

                <!-- Details -->
                <v-tab-item>
                  <v-row class="px-5 py-6">
                    <!-- Form Name -->
                    <v-col cols="12">
                      <VTextFieldWithValidation rules="required" v-model="formData.name" label="Title of the form" />
                    </v-col>

                    <!-- Form Introduction -->
                    <v-col cols="12">
                      <TextEditor v-model="formData.introduction" :value="formData.introduction" light :label="'Introduction'" :prefix="'Please provide a summary of what your form is about, i.e., Form for reporting accidents within our organisation'" />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Fields -->
                <v-tab-item>
                  <!-- EXPANDED: Field dynamic expansion panel -->
                  <v-row justify="center" class="px-8 py-6">
                    <v-expansion-panels focusable v-if="formData.fields.length" v-model="panels">
                      <v-expansion-panel v-for="(item, i) in formData.fields" :key="i">
                        <v-expansion-panel-header>
                          <template v-slot:default="{ open }">
                            <v-row no-gutters>
                                <v-col cols="12" class="text--secondary">
                                  <v-fade-transition leave-absolute>
                                      <span v-if="open">{{ item.type | getInputType }} Field</span>
                                      <v-row v-else no-gutters style="width: 100%" class="align-center">
                                        <v-col cols="5">Label: {{ item.config.label || 'Not Set' }}</v-col>
                                        <v-col cols="5" v-if="$vuetify.breakpoint.lgAndUp">Type: {{ item.type | getInputType }}</v-col>
                                      </v-row>
                                  </v-fade-transition>
                                </v-col>
                            </v-row>
                          </template>

                          <template v-slot:actions>
                            <v-icon>mdi-menu-down</v-icon>

                            <v-divider vertical class="mx-4"></v-divider>

                            <v-btn @click="remove(formData.fields, i)" fab small text class="keep-icon-upright">
                              <v-icon small>mdi-close</v-icon>
                            </v-btn>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-row justify="space-between" class="pt-2">
                            <!-- Field name -->
                            <v-col cols="7">
                              <VTextFieldWithValidation rules="required" v-model="formData.fields[i].config.label"  label="Question" hideDetails />
                            </v-col>

                            <!-- Field type -->
                            <v-col cols="5">
                              <div class="faux__select">
                                <v-select
                                  v-model="formData.fields[i].type"
                                  :rules="[(v) => !!v || 'Input type is required.']"
                                  :items="inputTypes"
                                  item-text="name"
                                  item-value="id"
                                  menu-props="auto"
                                  label="Field Type"
                                  hide-details
                                  outlined
                                ></v-select>
                                <v-btn @click.stop="fauxSelect = !fauxSelect">Field Type</v-btn>
                              </div>
                            </v-col>

                            <v-col cols="12" class="py-0" v-if="fauxSelect" v-click-outside-custom="closeFauxSelect">
                              <div class="faux__select-menu">
                                <v-card outlined>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12" sm="6" lg="4" v-for="(type,g) in inputTypes" :key="g" class="d-flex"
                                      @click="formData.fields[i].type = type.id" type="button">
                                        <!-- <v-icon left x-large class="align-self-start">mdi-form-textbox</v-icon> -->
                                        <v-img max-width="75" :contain="true" position="top center" :src="`${require('@/images/crysp-icons/'+type.input_type+'.svg')}`"/>
                                        <div>
                                          <b>{{type.name}}</b>
                                          <p>{{type.description}}</p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </div>
                            </v-col>
                          </v-row>

                          <!-- EXPANDED: Field configuration (validation rules) -->
                          <!-- TODO: Remove check for config from file upload fields - until Emman and Tom have resolved  (id: 5) -->
                          <v-row class="mt-2">
                            <v-col cols="12" class="pb-0">
                              Field Configuration
                            </v-col>
                            <!-- Required? -->
                            <v-col cols="12">
                              <v-switch class="ml-1" inset v-model="formData.fields[i].config.validation.required" hide-details label="Required"></v-switch>
                            </v-col>

                            <v-col cols="12" class="pt-0" v-if="formData.fields[i].type === 6 || formData.fields[i].type === 7 || formData.fields[i].type === 8">
                              <v-switch class="ml-1" inset v-model="formData.fields[i].config.advancedMode" hide-details label="Advanced Configuration"></v-switch>
                            </v-col>

                            <!-- Options for radio buttons and select input -->
                            <v-col cols="12" class="pt-0 pb-0" v-if="formData.fields[i].type === 6 || formData.fields[i].type === 7 || formData.fields[i].type === 8">
                              <!-- EXPANDED: Selct & Radio Options -->
                              <v-list>
                                <template v-for="(item, j) in formData.fields[i].config.options">
                                  <div :key="j">
                                    <!-- <v-divider v-if="j !== 0"></v-divider> -->
                                    <v-row>
                                      <v-col cols="6">
                                        <v-text-field v-model="item.name" hide-details dense outlined label="Option Label"></v-text-field>
                                      </v-col>
                                      <v-col cols="5">
                                        <v-text-field v-if="formData.fields[i].config.advancedMode" v-model="item.value" hide-details dense outlined label="Option Value"></v-text-field>
                                      </v-col>
                                      <v-col cols="1" class="d-flex justify-center">
                                        <v-btn @click.prevent="remove(formData.fields[i].config.options, j)" small outlined fab class="grey lighten-4 keep-icon-upright">
                                          <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </template>
                                <p v-if="!formData.fields[i].config.options.length">No choices set for this field.</p>
                              </v-list>
                            </v-col>
                            <v-col cols="12" class="pt-0 d-flex" v-if="formData.fields[i].type === 6 || formData.fields[i].type === 7 || formData.fields[i].type === 8">
                              <v-btn outlined color="primary" class="mt-3" @click="addOption(formData.fields[i])">
                                <v-icon dark left>mdi-form-dropdown</v-icon>Add New Choice
                              </v-btn>
                            </v-col>

                            <!-- Text input options -->
                            <v-col cols="12" v-if="formData.fields[i].type === 2 || formData.fields[i].type === 4">
                              <v-text-field v-model="formData.fields[i].config.placeholder" hide-details outlined label="Placeholder Text"></v-text-field>
                            </v-col>
                            <v-col cols="6" v-if="formData.fields[i].type === 2 || formData.fields[i].type === 4">
                              <v-text-field v-model="formData.fields[i].config.validation.min" type="number" hide-details outlined :label="formData.fields[i].type === 2 ? 'Minimum Characters' : 'Minimum Value'"></v-text-field>
                            </v-col>
                            <v-col cols="6" v-if="formData.fields[i].type === 2 || formData.fields[i].type === 4">
                              <v-text-field v-model="formData.fields[i].config.validation.max" type="number" hide-details outlined :label="formData.fields[i].type === 2 ? 'Maximum Characters' : 'Maximum Value'"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <div v-if="!formData.fields.length">
                      <v-chip
                        outlined
                        class="px-12"
                      >
                        No fields assigned to this form.
                      </v-chip>
                    </div>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="px-8 py-6">
                    <v-btn outlined color="primary" class="ml-auto" @click="addField">
                      <v-icon dark left>mdi-form-textbox</v-icon>Add New Field
                    </v-btn>
                  </v-row>
                </v-tab-item>

                <!-- Settings -->
                <v-tab-item :eager="true">

                  <Can I="manage" a="organisations">
                    <div class="pa-6 grey lighten-5">
                      <v-row>
                        <v-col cols="6" md="6">

                          <v-text-field
                            label="Organisation"
                            :value="formData.org_id|getOrganisationName"
                            outlined
                            disabled
                            hide-details
                          ></v-text-field>

                        </v-col>
                      </v-row>
                    </div>

                    <v-divider></v-divider>
                  </Can>

                  <v-tabs background-color="grey lighten-4">
                    <v-tab>
                      <v-icon left>mdi-checkbox-blank-circle</v-icon>
                      Status
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-account-tie</v-icon>
                      Officers
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-account-multiple</v-icon>
                      Viewers
                    </v-tab>

                    <v-tab-item>
                      <v-row class="px-6 py-6">
                        <v-col cols="12" md="6" class="py-0">

                          <v-select
                            v-model="formData.status"
                            :items="formStatuses|filterStatus"
                            item-text="name"
                            item-value="id"
                            menu-props="auto"
                            label="Status"
                            outlined
                            hide-details
                          ></v-select>

                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="px-6 py-6">
                        <v-col cols="12" md="6" class="py-0">
                          <v-autocomplete
                            flat multiple return-object
                            label="Select Users" item-text="profile.full_name" item-value="id" hide-details="auto"
                            class="mb-5"
                            v-model="formData.recipients"
                            :disabled="!users.length"
                            :loading="isLoading"
                            :items="users"
                            outlined
                          >
                            <template v-slot:selection="data">
                              <v-chip v-bind="data.attrs" close @click:close="remove(formData.recipients, data.item)">
                                <UserAvatar
                                  :profile="data.item.profile"
                                  :size="32"
                                  color="primary"
                                ></UserAvatar>
                                {{ data.item.profile.full_name }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="6" class="py-0">
                        </v-col>

                        <v-col cols="12" md="6" class="py-0">
                          <v-autocomplete
                            flat multiple return-object
                            label="Select Groups" item-text="name" item-value="id" hide-details="auto"
                            v-model="formData.recipients_group_ids"
                            :disabled="!groups.length"
                            :loading="isLoading"
                            :items="groups"
                            outlined
                          >
                            <template v-slot:selection="data">
                              <v-chip v-bind="data.attrs" close @click:close="remove(formData.recipients_group_ids, data.item)">
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="6" class="py-0">
                        </v-col>
                      </v-row>

                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="px-6 py-6">
                        <v-col cols="6" class="pt-0 d-flex align-center">
                          <v-switch inset v-model="formData.visibility" hide-details class="mx-1 mt-0 pt-0">
                            <template v-slot:label>
                              <v-chip class="v-chip--visibility" outlined>
                                <v-icon class="mr-2">{{ formData.visibility ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon> Visibility: <strong class="ml-1">{{ formData.visibility ? 'Public' : 'Private' }}</strong>
                              </v-chip>
                            </template>
                          </v-switch>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                text
                                outlined
                                v-on="on"
                                class="mx-1"
                              >
                                <v-icon small>mdi-help</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              <strong>Public</strong> – Your whole organisation can view this form <br>
                              <strong>Private</strong> – Only selected users within your organisation can view this form
                              </span>
                          </v-tooltip>
                        </v-col>

                        <template v-if="!formData.visibility">
                          <v-col tag="p" cols="12">
                            Please select the users within your organisation that you wish to view this content
                          </v-col>

                          <v-col cols="12" md="6" class="py-0">
                            <v-autocomplete
                              flat multiple return-object
                              label="Select Users" item-text="profile.full_name" item-value="id" hide-details="auto"
                              class="mb-5"
                              v-model="formData.viewers"
                              :disabled="!users.length"
                              :loading="isLoading"
                              :items="users"
                              outlined
                            >
                              <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs" close @click:close="remove(formData.viewers, data.item)">
                                  <UserAvatar
                                    :profile="data.item.profile"
                                    :size="16"
                                    color="primary"
                                  ></UserAvatar>

                                  {{ data.item.profile.full_name }}
                                </v-chip>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </template>

                        <v-col cols="6" class="py-0">
                        </v-col>

                        <v-col cols="12" md="6" class="py-0" v-if="!formData.visibility">
                          <v-autocomplete
                            flat multiple return-object
                            label="Select Groups" item-text="name" item-value="id" hide-details="auto"
                            v-model="formData.viewer_group_ids"
                            :disabled="!groups.length"
                            :loading="isLoading"
                            :items="groups"
                            outlined
                          >
                            <template v-slot:selection="data">
                              <v-chip v-bind="data.attrs" close @click:close="remove(formData.viewer_group_ids, data.item)">
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="6" class="py-0">
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                </v-tab-item>
              </v-tabs>

              <v-divider></v-divider>

              <v-card-actions class="pa-5">
                <v-btn outlined color="primary" @click="activeTab--" :disabled="activeTab == 0">
                  Previous
                </v-btn>

                <v-btn outlined color="primary" @click="activeTab++" :disabled="activeTab == 2">
                  Next
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn text route :to="{ name: 'form', params: { form_id: form.id }}" class="mr-2">Back</v-btn>

                <v-btn color="primary" @click="passes(handleUpdateForm); handleFailedValidation(invalid)" class="ml-auto">
                  Save Changes
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="pa-8" v-if="isLoading || isUpdating">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import UserAvatar from '@/components/user/Avatar'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import TextEditor from '@/components/inputs/TextEditor'


export default {
  name: "EditForm",
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    UserAvatar,
    VTextFieldWithValidation,
    TextEditor
  },
  props: [
    'form'
  ],
  data() {
    return {
      activeTab: 0,
      isUpdating: false,
      users: [],
      groups: [],
      isLoading: false,
      panels: [],
      formData: {
        id: null,
        status: null,
        name: null,
        introduction: null,
        fields: [],
        org_id: null,
        recipients: [],
        recipients_group_ids: [],
        viewers: [],
        viewer_group_ids: [],
        visibility: null
      },
      fauxSelect: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      inputTypes: state => state.UTIL_STORE.inputTypes,
      formStatuses: state => state.UTIL_STORE.formStatuses,
      organisations: state => state.ORG_STORE.organisations,
      isLoadingOrganisations: state => state.ORG_STORE.loadingOrganisations,
    })
  },
  beforeMount() {
    this.getData()
  },
  mounted() {},
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Forms' , path: '/form/all/' },
      { text: ':form' },
      { text: 'Edit' }
    ])

    this.replaceBreadcrumb({
      find: ':form',
      replace: {
        text: this.form.meta.name,
        path: `/form/${this.form.id}`
      }
    })
  },
  methods: {
    getData() {
      this.isLoading = true

      const getUsers = async () => {
        const { data } = await this.$http.get('users', {
          params: {
            organisation_id: this.form.ownership.organisation_id,
            reseller_id: this.user.reseller_id
          }
        })

        return data
      }

      const getGroups = async () => {
        const { data } = await this.$http.get('group', {
          params: {
            organisation_id: this.form.ownership.organisation_id,
            reseller_id: this.user.reseller_id
          }
        })

        return data
      }

      Promise.all([
        getUsers(),
        getGroups()
      ]).then(([users, groups]) => {

        this.users = users.data
        this.groups = groups.data

        this.prepareForm()
        this.mapFields()
      })
    },
    handleFailedValidation(v) {
      if (v) {
        this.$toast.error('Please fill out all required fields across all tabs.')
      }
    },
    async handleUpdateForm() {
      this.isUpdating = true

      if (this.formData.fields.length === 0) {
        this.$toast.error('Please add at least one form field.')
        this.isUpdating = false;
        return
      }

      let clone = JSON.parse(JSON.stringify(this.formData))

      clone.fields.forEach((field) => {
        field.field_value = [];
        if(field.config.options.length > 0 ) {
            field.config.options.forEach((option) => {
              if(option.value == '') {
                option.value = option.name
              }
            })
        }

        const configString = JSON.stringify(field.config);
        field.config = configString

        delete field.field_value
        delete field.__typename
      })

      let variables = this.formatData(clone)

      this.$http.put('form', variables)
        .then(response => {
          this.isUpdating = false

          const form = response.data.data;
          // Use map to create a new array with updated field_value
          const updatedFields = form.fields.map((field) => {
            if (field.type.input_type === "checkbox") {
              return { ...field, field_value: [] };
            }
            return field;
          });
          form.fields = updatedFields;

          this.$store.commit('FORM_STORE/SET_FORM', form)
          this.$store.commit('FORM_STORE/UPDATE_FORM', form)

          this.$toast.success('Successfully updated form.')

          this.$router.push({ name: 'form', params: { form_id: this.form.id }})
        })
        .catch(error => {
          this.isUpdating = false

          this.$toast.error('Something went wrong updating form, please try again.')
        })
    },
    prepareForm() {
      const form = JSON.parse(JSON.stringify(this.form))

      this.formData = {
        id: form.id,
        status: form.status.id,
        name: form.meta.name,
        introduction: form.meta.introduction,
        fields: form.fields,
        org_id: form.ownership.organisation_id,
        recipients: [],
        recipients_group_ids: [],
        viewers: [],
        viewer_group_ids: [],
        visibility: form.visibility
      }

      this.mapFields()
      this.populatePermissions(this.users, this.groups)

      this.isLoading = false
    },
    formatData(data) {
      const id = data.id
      data.id = parseInt(id)
      // We need to return an array of objects as a comma seperated string of those object id's
      const propsToStringify = ['recipients', 'recipients_group_ids', 'viewers', 'viewer_group_ids']

      propsToStringify.forEach(function(prop) {
        if(data[prop].length) {
          const ids = data[prop].map(function(item) {
            return item.id
          })

          data[prop] = ids
        }

        data[prop] = data[prop].toString()
      })

      // We need to format boolean output of v-switch to int (casting it to itself to return numbers value)
      data.visibility = +data.visibility

      return data
    },
    /**
     * This method removes items from a collection, works in both removing fields and options within fields
     */
    remove(arr, item) {
      let index = item

      if (typeof item === 'object') {
        index = arr.map(function(e) { return e.id }).indexOf(item.id)
      }

      if (index >= 0) arr.splice(parseInt(index), 1)
    },
    /**
     * Add a blank field to the formdata
     */
    addField() {
      this.formData.fields.push({...{
        type: '',
        config: {
          validation: {
            required: '',
            min: '',
            max: ''
          },
          options: [],
          name: '',
          label: '',
          placeholder: '',
          advancedMode: '',
          order: this.formData.fields.length + 1
        }
      }})
    },
    /**
     *  Add a blank option to a field
     */
    addOption(field) {
      field.config.options.push({
        name: '',
        value: ''
      })
    },
    /**
     *  Map the form data, return what we need for the render
     */
    mapFields() {
      this.formData.fields.forEach((field) => {
        const config = field.config

        if (typeof config === "string") {
          field.config = JSON.parse(config)
        }

        const type = field.type

        if (typeof type === "object")
          field.type = type.id
      })
    },
    /**
     *  Filter permissions in the form to populate the recipients, users and groups and also the viewers
     */
    populatePermissions(users, groups) {
      const recipientsAll = this.form.permissions.filter(item => item.permission.id === 1)
      const viewersAll = this.form.permissions.filter(item => item.permission.id === 2)

      const recipientUserIds  = recipientsAll.filter(item => item.user_id !== null).map(({ user_id }) => (user_id))
      const recipientGroupIds = recipientsAll.filter(item => item.group_id !== null).map(({ group_id }) => (group_id))
      const viewerUserIds     = viewersAll.filter(item => item.user_id !== null).map(({ user_id }) => (user_id))
      const viewerGroupIds    = viewersAll.filter(item => item.group_id !== null).map(({ group_id }) => (group_id))

      this.formData.recipients           = users.filter(({ id }) => recipientUserIds.includes(parseInt(id)))
      this.formData.recipients_group_ids = groups.filter(({ id }) => recipientGroupIds.includes(parseInt(id)))
      this.formData.viewers              = users.filter(({ id }) => viewerUserIds.includes(parseInt(id)))
      this.formData.viewer_group_ids     = groups.filter(({ id }) => viewerGroupIds.includes(parseInt(id)))
    },
    closeFauxSelect() {
      if (this.fauxSelect) {
        this.fauxSelect = false;
      }
    }
  },
  watch: {
    formData: {
      handler: function(oldVal, newVal) {
        this.fauxSelect = false;
        newVal.fields.forEach((field) => {
          field.config.name = field.config.label.replace(/\W+/g, " ")
                                                .split(/ |\B(?=[A-Z])/)
                                                .map(word => word.toLowerCase())
                                                .join('_')
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .keep-icon-upright i {
    transform: unset!important;
  }

  .v-expansion-panel-content {
    width: 100%;
  }

  .faux__select {
    position: relative;
    .v-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100% !important;
      opacity: 0;
    }
  }
  .faux__select-menu {
    position: relative;
    .v-card {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }
</style>

<style scoped>
  .v-chip--visibility {
    height: 28px;
  }
</style>
