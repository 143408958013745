<template>
  <div>
    <div v-if="!isLoading && !isComplete">

      <ValidationObserver v-slot="{ invalid, validated, passes }">
        <p class="caption text-center">
          Please enter the email address associated with your account and we will send you a link you can use to reset your password.
        </p>

        <v-form @submit.prevent="">
          <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">
            <v-text-field
            v-model="email"
            :error-messages="errors"
            :success="valid"
            label="Email Address"
            outlined dense required
            ></v-text-field>
          </ValidationProvider>

          <!-- TODO forgot username... -->
          <!-- <v-row align="center" justify="center" class="mb-5">
            <v-btn x-small text class="grey--text mt-4" style="text-decoration: underline" @click="$emit('FORGOT_USERNAME')">
              Forgotten your username?
            </v-btn>
          </v-row> -->

          <v-card-actions class="mx-0 px-0">
            <v-btn color="primary"
              small outlined
              @click="redirectToLogin()"
            >
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="primary"
              depressed type="submit"
              @click="passes(handlePasswordResetRequest)"
              :disabled="invalid || !validated"
            >
              Request Reset
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>

    </div>

    <div v-if="isComplete">
      <p class="body-2 text-center">
        Success! Please check your inbox for further instructions on how to reset your password.<br />
      </p>

      <v-btn
        class="mt-4"
        color="primary"
        depressed
        width="100%"
        @click="redirectToLogin()"
      >
        Return to Login
      </v-btn>
    </div>

    <v-row align="center" justify="center" v-if="isLoading">
      <v-loading-spinner></v-loading-spinner>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordForm',
  data() {
    return {
      email: '',
      isLoading: false,
      isComplete: false
    }
  },
  methods: {
    handlePasswordResetRequest() {
      this.isLoading = true

      this.$http.post('user/password/reset/request', {
          email: this.email
        })
        .then(({ data }) => {
          this.isLoading = false
          this.isComplete = true
        },
        ({ error }) => {
          this.isLoading = false
          this.isComplete = true
        })
    },
    redirectToLogin() {
      this.isLoading = false
      this.isComplete = false

      this.$emit('RESET')
    }
  }
}
</script>
