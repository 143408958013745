<template>
  <div>
    <h1 class="text-center mb-3">Welcome Back</h1>

    <p class="text-center mb-8">Get a real-time picture of your commercial property or school compliance life cycle </p>

    <v-btn
      depressed
      outlined
      block
      plain
      large
      class="justify-space-between text-none text--disabled mb-5 body-2"
      style="border-color:#00000029"
      @click="loginWithMicrosoft()"
      >
        <v-img height="21"
          max-width="21"
          contain
          :src="microsoftIcon"
          class="mr-10"
        ></v-img>
      Sign in with Microsoft <span style="width:21px;height:21px;display:block;">&nbsp;</span>
    </v-btn>

    <div class="d-flex align-center col-8 mx-auto mb-5"><v-divider></v-divider> <small class="mx-4 text--disabled">or</small> <v-divider></v-divider></div>

    <ValidationObserver v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="">
        <ValidationProvider name="username" rules="required"  v-slot="{ errors, valid }">
          <v-text-field
            v-model="username"
            :error-messages="errors"
            :success="valid"
            placeholder=" "
            label="Username"
            outlined dense required>
          </v-text-field>
        </ValidationProvider>

        <ValidationProvider name="password" rules="required"  v-slot="{ errors, valid }">
          <v-text-field
            v-model="password"
            :error-messages="errors"
            :success="valid"
            placeholder=" "
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            outlined dense required hide-details class="flex-wrap justify-end mb-3">
              <template v-slot:append-outer>
                <v-btn color="primary"
                  small text
                  class="pr-0 text-none body-2 font-weight-medium"
                  @click="$emit('FORGOT_PASSWORD')"
                >
                  <small>Forgot Password?</small>
                </v-btn>
              </template>
            </v-text-field>
        </ValidationProvider>

        <v-card-actions class="mx-0 px-0">

          <v-btn
            class="secondary mb-4 text-none body-2"
            depressed
            block
            large
            type="submit"
            @click="passes(handleLogin)"
            :disabled="invalid || !validated"
          >
            Sign in
          </v-btn>
        </v-card-actions>

        <p class="text-center"><small>Not yet registered? <a href="https://www.crysp.co.uk/pricing" class="font-weight-medium" style="text-decoration: none;">Get started now</a></small></p>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { AbilityBuilder } from '@casl/ability';
import { parseUserRole, parseUserPermissions } from '@/_util/casl'
import * as Sentry from '@sentry/browser';
import { mapState } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      loadingUsingLoadingKeyFeature: 0,
      microsoftIcon: require("@/images/microsoft.svg")
    }
  },
  methods: {
    async handleLogin(e) {
      this.$store.commit('AUTH_STORE/LOGIN_START')

      const auth = await this.$http.post('login', {
          username: this.username,
          password: this.password
        })
        .then(async (response) => {
          return await this.$auth.handleLogin(response.data)
        }).catch((error) => {
          this.$store.commit('AUTH_STORE/LOGIN_STOP', error)
          this.$toast.error(error.response.data.message ?? error.message)
        })
      if (auth) {
        this.handleUpdateAbilities(auth.user)

        this.$store.commit('AUTH_STORE/LOGIN_STOP', null)

        this.$store.commit('AUTH_STORE/LOGIN_UPDATE', {
          token: auth.token,
          authUser: auth.user
        })

        this.$store.dispatch('UTIL_STORE/GET_UTILS', null, { root: true })
        this.$store.dispatch('ORG_STORE/GET_MY_ORG', null, { root: true }).then(() => {
            this.$store.dispatch('ORG_STORE/GET_ORG_COMPLIANCE_TAGS', null, { root: true })
          })

        try {
          // Fire a logon event to Sentry
          Sentry.withScope(scope => {
            scope.setTag('organisation', auth.user.organisation_id);
            scope.setTag('user_id', auth.user.id);
            scope.setTag('login_event', 'login');
            scope.setUser({
              email: auth.user.email,
              fullName: auth.user.profile.first_name + ' ' + auth.user.profile.last_name
            });
            scope.clearBreadcrumbs();
            Sentry.captureMessage('User logged in');
          });
        } catch (error) {
          
        }
        
        // After successful login
        let redirect = localStorage.getItem("redirectTo");
        console.log(redirect);
        localStorage.removeItem("redirectTo"); // Clear the localStorage item
        if (redirect) {
          this.$router.push(redirect);
        } else {
          this.$router.push({ name: 'dashboard' }, () => {})
        }        
      }
    },
    handleUpdateAbilities(user) {
      let role = parseUserRole(user)
      let permissions = parseUserPermissions(user)

      if (permissions) {
        const { can, rules } = new AbilityBuilder();

        if (role == "system_admin") {
          can("manage", "all")
        } else {
          permissions.forEach(p => {
            can(p[1], p[0])
          });
        }

        this.$ability.update(rules);
      }
    },
    loginWithMicrosoft() {
      this.$http.get('loginWithMicrosoft')
        .then((response) => {
          window.location = response.data.data.redirect_url
        })
    }
  }
}
</script>
