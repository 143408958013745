import { render, staticRenderFns } from "./SpinnerBlocks.vue?vue&type=template&id=dbfff9bc&scoped=true"
import script from "./SpinnerBlocks.vue?vue&type=script&lang=js"
export * from "./SpinnerBlocks.vue?vue&type=script&lang=js"
import style0 from "./SpinnerBlocks.vue?vue&type=style&index=0&id=dbfff9bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbfff9bc",
  null
  
)

export default component.exports