<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card>
      <v-card-title class="primary primary--text--contrast">Edit Theme</v-card-title>

      <ValidationObserver v-slot="{ invalid, validated, passes }">
        <v-form @submit.prevent="" v-if="!isUpdating">

          <v-row class="mt-4">

            <v-col cols="12" class="px-8">
              <v-banner elevation="2">
                <v-avatar
                  slot="icon"
                  color="grey lighten-4"
                  size="42"
                >
                  <v-icon
                    icon="mdi-alert"
                    color="warning"
                  >
                    mdi-alert
                  </v-icon>
                </v-avatar>

                Users may need to logout and login again to see changes made here.

              </v-banner>
            </v-col>

            <v-col cols="12" v-if="updateOrganisation" class="px-8">

              <v-row>

                <v-col cols="12" sm="5" md="4">

                  <v-row align="center" justify="center" class="mb-4">
                    <OrganisationLogo
                      :organisation="organisation"
                      useBackground
                      centered
                    ></OrganisationLogo>
                  </v-row>

                  <v-row align="center" justify="center" class="text-center">
                    <UploadModal
                      :reseller_id="organisation.reseller_id"
                      :organisation_id="organisation.id"
                      uploadType="organisation_logo"
                      buttonLabel="Upload Logo"
                      modalLabel="Upload Organisation Logo"
                      :disabled="updateOrganisation.id == 0"
                      @onUploaded="handleUploadedOrganisationLogo"
                    ></UploadModal>
                  </v-row>

                  <v-row align="center" justify="center" class="text-center">
                    <v-col>
                      <div class="d-flex align-center justify-center">
                        <v-card flat outlined>
                          <div class="px-6 py-1"><small class="d-block">File Type: PNG, JPG</small></div>
                          <v-divider></v-divider>
                          <div class="px-6 py-1"><small class="d-block">Ideal Size: 400x48</small></div>
                          <v-divider></v-divider>
                          <div class="px-6 py-1"><small class="d-block">Max Size: 400x400</small></div>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>

                <v-col cols="12" sm="7" md="8">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="organisationTheme.colour"
                    label="Primary Colour"
                    :disabled="updateOrganisation.id == 0"
                    readonly
                    append-icon="mdi-palette"
                    @click:append="isPickingColour = !isPickingColour"
                  />
                </v-col>

              </v-row>

            </v-col>

            <v-col cols="12" class="py-0">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" class="px-8">

              <v-card-actions class="px-0">
                <v-spacer></v-spacer>

                <v-btn
                  outlined
                  color="primary"
                  @click="passes(handleEditOrganisationTheme)"
                  :disabled="updateOrganisation.id == 0"
                >
                  Save Changes
                </v-btn>
              </v-card-actions>

            </v-col>

          </v-row>

        </v-form>
      </ValidationObserver>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="isPickingColour && !isLoading">
          <v-card class="mx-auto" max-width="480" light v-if="!isUpdating">
            <v-card-title class="primary primary--text--contrast">
              Colour Picker
            </v-card-title>

            <v-color-picker flat v-model="colourPicked"></v-color-picker>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click="isPickingColour = !isPickingColour">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn @click="setColour()" color="success">
                Confirm
              </v-btn>

            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isUpdating">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-row align="center" justify="center" v-if="isUpdating">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-card>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { isValidJSON } from '@/_util/json'

import BreadcrumbsManager from '@/_util/breadcrumbManager'
import OrganisationLogo from '@/components/organisation/Logo'

import UploadModal from '@/components/upload/UploadModal'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default {
  name: "EditOrganisationTheme",
  mixins: [
    BreadcrumbsManager
  ],
  props: [
    'organisation'
  ],
  components: {
    VTextFieldWithValidation,
    OrganisationLogo,
    UploadModal
  },
  data() {
    return {
      isUpdating: false,
      isPickingColour: false,
      colourPicked: '#31CBB9',
      organisationTheme: {
        colour: '#31CBB9'
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.ORG_STORE.loadingTypes
    }),
    updateOrganisation:  function () {
      if (!this.organisation)
        return null

      return JSON.parse(JSON.stringify(this.organisation))
    },
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      this.organisationTheme.colour = this.organisation.profile.colour_scheme
    },
    setColour() {
      this.isPickingColour = !this.isPickingColour
      this.organisationTheme.colour = this.colourPicked
    },
    async handleEditOrganisationTheme(data = {}) {
      this.isUpdating = true

      const variables = {
        id: parseInt(this.organisation.id),
        colour_scheme: this.organisationTheme.colour,
      }

      Object.keys(data).map((key, index) => {
        variables[key] = data[key]
      })

      const organisation = await this.$http.put('organisation', variables)
        .then((response) => {
          return response.data.data[0]
        },
        ({ error }) => {
          this.$toast.error('Something went wrong updating organisation theme, please try again.')
        })

      this.isUpdating = false

      if (organisation) {
        this.$store.commit('ORG_STORE/SET_ORG', organisation)
        this.$store.dispatch('ORG_STORE/UPDATE_ORG', organisation)
        this.$toast.success('Successfully updated organisation theme.')
      } else {
        this.$toast.error('Something went wrong updating organisation theme, please try again.')
      }
    },
    async handleUploadedOrganisationLogo(data) {
      if (data) {
        const file = data[0]

        if (file) {
          this.handleEditOrganisationTheme({
            logo: `${file.id}:${file.meta.path}`
          })
        }
      }
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Theme' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: {
        text: this.organisation.name,
        path: `/organisation/${this.organisation.id}`
      }
    })
  }
}
</script>
