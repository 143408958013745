var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-card',{attrs:{"route":""}},[_c('v-card-title',{staticClass:"primary primary--text--contrast",staticStyle:{"position":"relative"}},[_vm._v(" Submit Form ")]),_c('div',{staticClass:"px-5 pt-5 pb-0",domProps:{"innerHTML":_vm._s(_vm.form.meta.introduction)}}),_c('v-divider',{staticClass:"my-5"}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, validated, passes }){return [(!_vm.isSubmitting)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"pa-5"},_vm._l((_vm.fields),function(field,i){return _c('div',{key:i},[(field.type.input_type === 'text')?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required|' : ''}${
                  !field.config.validation.min && field.config.validation.max
                    ? 'max:' + field.config.validation.max
                    : ''
                }${
                  field.config.validation.min && !field.config.validation.max
                    ? 'minimum:' + field.config.validation.min
                    : ''
                }${
                  field.config.validation.min && field.config.validation.max
                    ? 'minmax:' +
                      field.config.validation.min +
                      ',' +
                      field.config.validation.max
                    : ''
                }`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid,"counter":field.config.validation.max,"placeholder":field.config.placeholder,"outlined":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})]}}],null,true)}):_vm._e(),(field.type.input_type === 'checkbox' && !field.config.options.length)?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required' : ''}`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-checkbox',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})]}}],null,true)}):_vm._e(),(field.type.input_type === 'checkbox' && field.config.options.length)?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required' : ''}`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('label',{domProps:{"textContent":_vm._s(
                  `${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`
                )}}),_vm._l((field.config.options),function(option,i){return _c('v-checkbox',{key:i,attrs:{"value":option.value,"label":`${
                    field.config.validation.required
                      ? option.name + '*'
                      : option.name
                  }`,"error-messages":errors,"success":valid},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})})]}}],null,true)}):_vm._e(),(field.type.input_type === 'radio')?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required' : ''}`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('label',{domProps:{"textContent":_vm._s(
                  `${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`
                )}}),_c('v-radio-group',{attrs:{"error-messages":errors,"success":valid},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}},_vm._l((field.config.options),function(option,i){return _c('v-radio',{key:i,attrs:{"value":option.value,"label":option.name}})}),1)]}}],null,true)}):_vm._e(),(field.type.input_type === 'date')?_c('v-row',[_c('v-dialog',{ref:`dialog${i}`,refInFor:true,attrs:{"return-value":field.field_value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(field, "field_value", $event)},"update:return-value":function($event){return _vm.$set(field, "field_value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":`${
                        field.config.validation.required
                          ? field.config.label + '*'
                          : field.config.label
                      }`,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.modals[field.id]),callback:function ($$v) {_vm.$set(_vm.modals, field.id, $$v)},expression:"modals[field.id]"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modals[field.id] = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDate(i, field.field_value)}}},[_vm._v("OK")])],1)],1)],1):_vm._e(),(field.type.input_type === 'file')?_c('v-row',{class:i < _vm.fields.length - 1 ? 'mb-4' : ''},[_c('v-container',{attrs:{"py-0":"","fluid":""}},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0"},[_c('label',{attrs:{"label":`${
                          field.config.validation.required
                            ? field.config.label + '*'
                            : field.config.label
                        }`}},[_vm._v(" "+_vm._s(`${ field.config.validation.required ? field.config.label + "*" : field.config.label }`)+" ")])])],1)],1),_c('UploadModal',{attrs:{"uploadType":"user_generic","buttonLabel":"Upload File","modalLabel":"Upload File Attachment"},on:{"onUploaded":function($event){return _vm.handleFileAttachment(field, $event)}}})],1):_vm._e(),(field.type.input_type === 'select')?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required' : ''}`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid,"items":field.config.options,"item-text":"name","item-value":"value","outlined":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})]}}],null,true)}):_vm._e(),(field.type.input_type === 'textarea')?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required' : ''}`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-textarea',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid,"counter":field.config.validation.max,"placeholder":field.config.placeholder,"outlined":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})]}}],null,true)}):_vm._e(),(field.type.input_type === 'number')?_c('ValidationProvider',{attrs:{"rules":`numeric|${
                  field.config.validation.required ? 'required|' : ''
                }${
                  !field.config.validation.min && field.config.validation.max
                    ? 'maxVal:' + field.config.validation.max
                    : ''
                }${
                  field.config.validation.min && !field.config.validation.max
                    ? 'minVal:' + field.config.validation.min
                    : ''
                }${
                  field.config.validation.min && field.config.validation.max
                    ? 'minmaxVal:' +
                      field.config.validation.min +
                      ',' +
                      field.config.validation.max
                    : ''
                }`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid,"outlined":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", _vm._n($$v))},expression:"field.field_value"}})]}}],null,true)}):_vm._e(),(field.type.input_type === 'email')?_c('ValidationProvider',{attrs:{"rules":`${field.config.validation.required ? 'required|' : ''}email`,"name":field.config.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"label":`${
                    field.config.validation.required
                      ? field.config.label + '*'
                      : field.config.label
                  }`,"error-messages":errors,"success":valid,"outlined":""},model:{value:(field.field_value),callback:function ($$v) {_vm.$set(field, "field_value", $$v)},expression:"field.field_value"}})]}}],null,true)}):_vm._e()],1)}),0),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-5"},[(_vm.form.status.id === 1)?_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" This form is currently disabled, and is closed for submissions. ")]):_vm._e(),(_vm.form.status.id === 3)?_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" This form is currently archived, and is closed for submissions. ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.form.status.id !== 2},on:{"click":function($event){return passes(_vm.handleSubmit)}}},[_vm._v(" Submit ")])],1)],1):_vm._e()]}}])}),(_vm.isSubmitting)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-loading-spinner')],1):_vm._e()],1),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.continuePath != '')?_c('v-overlay',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"600","width":"100vw","light":""}},[_c('v-card-text',{staticClass:"text-center pa-12"},[_c('v-icon',{staticClass:"mb-6",attrs:{"color":"primary","x-large":""}},[_vm._v("mdi-alert-circle-outline")]),_c('h1',{staticClass:"mb-12"},[_vm._v("Just before you go…")]),_c('p',[_vm._v("This form "),_c('b',[_vm._v("has not")]),_vm._v(" been submitted."),_c('br'),_vm._v(" Are you sure you want to leave before submitting?")])],1),_c('v-card-actions',{staticClass:"flex-column pa-12"},[_c('v-btn',{staticClass:"mb-3",attrs:{"text":"","color":"primary","to":{path:_vm.continuePath, params: {force: true}}}},[_vm._v(" LEAVE UN-SUBMITTED ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.continuePath = '';_vm.isEditing = true}}},[_vm._v(" GO BACK AND SUBMIT ")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }