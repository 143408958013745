<template>
  <v-container fluid fill-height style="background-color: #1e1e1e">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="mx-auto px-12 py-6" width="400px" max-width="100%" light shape>
          <div :style="{'background-image': `url(${images.logo})`}" class="card-logo">Crysp</div>

          <div v-if="!isLoading">
            <ResetPasswordForm/>
          </div>

          <v-row align="center" justify="center" v-if="isLoading">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ResetPasswordForm from './ResetPasswordForm'

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordForm
  },
  data() {
    return {
      hash: '',
      email: '',
      isLoading: false,
      images: {
        logo: require("@/images/logo-crysp-dark.png")
      }
    }
  },
  computed: {
  },
  mounted() {
    this.hash  = this.$route.params.hash
    this.email = this.$route.query.email

    if (!this.hash && !this.email) {
      this.redirectToLogin(5000)
    }

    this.handleValidateHash()
  },
  methods: {
    handleValidateHash() {
      this.isLoading = true

      this.$http.post('user/password/reset/validate', {
          hash: this.hash,
          email: this.email
        })
        .then(({ data }) => {
          this.isLoading = false
        },
        ({ error }) => {
          this.redirectToLogin(5000)
        })
    },
    redirectToLogin(timeout) {
      let t = setTimeout(() => {
        this.$router.push({ name: 'login' }, () => {})
      }, timeout)
    }
  }
}
</script>
