import Vue from "vue";
import Router from "vue-router";

import { ability } from "@/_services/ability";

import store from "@/_store";

import {
  ExternalComplianceForm,
  HenryExternalComplianceForm,
  Register,
  Subscribe,
  Login,
  MicrosoftLogin,
  Logout,
  AccountActivate,
  ResetPassword,
  Dashboard,
  ManageProfile,
  ViewPolicies,
  ManageNotifications,
  PageNotFound,
  OrganisationHome,
  CreateOrganisation,
  EditOrganisation,
  EditOrganisationTheme,
  ViewOrganisations,
  EditOrganisationContent,
  ManageUsers,
  ManageContent,
  CreateUser,
  ManagePolicies,
  ManageGroups,
  CreateGroup,
  EditGroup,
  ViewForms,
  ViewForm,
  CreateForm,
  FormHome,
  EditForm,
  FormResponses,
  FormResponse,
  ViewContent,
  CreateContent,
  EditContent,
  ViewContentItem,
  ViewCompliancy,
  ViewCalendar,
  ViewComplianceItem,
} from "@/views";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: "complianceform",
      path: "/compliance-form/",
      component: ExternalComplianceForm,
      meta: {
        requiresAuth: false
      }
    },
    {
      name: "henryschiencomplianceform",
      path: "/henry-schien-compliance-form/",
      component: HenryExternalComplianceForm,
      meta: {
        requiresAuth: false
      }
    },
    {
      name: "register",
      path: "/register/",
      component: Register,
      meta: {
        requiresAuth: false,
        title: 'Register | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Register here for Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Register here for Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "login",
      path: "/login/",
      component: Login,
      meta: {
        title: 'Login | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Login here to Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Login here to Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "microsoftLogin",
      path: "/login/microsoft",
      component: MicrosoftLogin,
      meta: {
        requiresAuth: false,
        title: 'Login | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Login here to Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Login here to Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "logout",
      path: "/logout/",
      component: Logout,
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "password/reset",
      path: "/password/reset/:hash",
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        title: 'Password Reset | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "account/activate",
      path: "/account/activate/:hash",
      component: AccountActivate,
      meta: {
        requiresAuth: false
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      name: "dashboard",
      path: "/",
      component: Dashboard,
      meta:{
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "subscribe",
      path: "/subscribe/",
      component: Subscribe,
      meta:{
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ]
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "profile",
      path: "/profile/",
      component: ManageProfile,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          user: ["update"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "policies",
      path: "/policies/",
      component: ViewPolicies,
      props: true,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        organisationPage: true,
        permissions: {
          user: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "notifications",
      path: "/notifications/",
      component: ManageNotifications,
      props: true,
      meta: {
        organisationPage: true,
        permissions: {
          user: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "organisation/all",
      path: "/organisation/all",
      component: ViewOrganisations,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          organisation: ["read", "update"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "organisation/create",
      path: "/organisation/create/",
      component: CreateOrganisation,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          organisation: ["create"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "organisation",
      path: "/organisation/:org_id/",
      component: OrganisationHome,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        organisationHome: true,
        organisationPage: true,
        permissions: {
          organisation: ["read", "update"]
        }
      },
      children: [
        {
          name: "organisation/users",
          path: "users/",
          component: ManageUsers,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read"],
              user: ["read", "create", "update", "delete"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/users/create",
          path: "users/create",
          component: CreateUser,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read"],
              user: ["create"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/policies",
          path: "policies/",
          component: ManagePolicies,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              // organisation: ["read", "update"],
              policies: ["create", "update"],
              user: ["create"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/edit",
          path: "edit/",
          component: EditOrganisation,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read", "update"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/theme",
          path: "theme",
          component: EditOrganisationTheme,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read", "update"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/content",
          path: "content/",
          component: ManageContent,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              document: ["read"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/groups",
          path: "groups/",
          component: ManageGroups,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read"],
              group: ["read", "create", "update", "delete"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/groups/create",
          path: "groups/create/",
          component: CreateGroup,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read"],
              group: ["create"]
            }
          },
          beforeEnter: ifAuthenticated
        },
        {
          name: "organisation/groups/edit",
          path: "groups/:group_id/edit/",
          component: EditGroup,
          props: true,
          meta: {
            organisationPage: true,
            permissions: {
              organisation: ["read"],
              group: ["read", "update"]
            }
          },
          beforeEnter: ifAuthenticated
        }
      ]
    },
    {
      name: "form/all",
      path: "/form/all/",
      component: ViewForms,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          form: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "form/create",
      path: "/form/create/",
      component: CreateForm,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          form: ["create"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "form",
      path: "/form/:form_id/",
      component: FormHome,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        formHome: true,
        formPage: true,
        permissions: {
          form: ["read"]
        }
      },
      beforeEnter: ifAuthenticated,
      children: [
        {
          name: "form/view",
          path: "view/",
          component: ViewForm,
          props: true,
          meta: {
            title: 'Crysp | Crysp Compliance Portal',
            metaTags: [
              {
                name: 'description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              },
              {
                property: 'og:description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              }
            ],
            formPage: true,
            permissions: {
              form: ["read"]
            }
          }
        },
        {
          name: "form/edit",
          path: "edit/",
          component: EditForm,
          props: true,
          meta: {
            title: 'Crysp | Crysp Compliance Portal',
            metaTags: [
              {
                name: 'description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              },
              {
                property: 'og:description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              }
            ],
            formPage: true,
            permissions: {
              form: ["read", "update"]
            }
          }
        },
        {
          name: "form/responses",
          path: "responses/",
          component: FormResponses,
          props: true,
          meta: {
            title: 'Crysp | Crysp Compliance Portal',
            metaTags: [
              {
                name: 'description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              },
              {
                property: 'og:description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              }
            ],
            formPage: true,
            permissions: {
              "form-response": ["read"]
            }
          }
        },
        {
          name: "form/response",
          path: "response/:response_id",
          component: FormResponse,
          props: true,
          meta: {
            title: 'Crysp | Crysp Compliance Portal',
            metaTags: [
              {
                name: 'description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              },
              {
                property: 'og:description',
                content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
              }
            ],
            formPage: true,
            permissions: {
              "form-response": ["read"]
            }
          }
        }
      ]
    },
    {
      name: "content/all",
      path: "/content/all/",
      component: ViewContent,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          document: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "content/create",
      path: "/content/create/",
      component: CreateContent,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          document: ["create"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "content/edit",
      path: "/content/edit/:content_id",
      component: EditContent,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        contentPage: true,
        permissions: {
          document: ["update"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "content",
      path: "/content/:content_id",
      component: ViewContentItem,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        contentPage: true,
        contentItemPage: true,
        permissions: {
          document: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "compliance/all",
      path: "/compliance/all",
      component: ViewCompliancy,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          document: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "compliance/calendar",
      path: "/compliance/calendar",
      component: ViewCalendar,
      meta: {
        permissions: {
          document: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      name: "compliance",
      path: "/compliance/:content_id/:compliance_id",
      component: ViewComplianceItem,
      meta: {
        title: 'Crysp | Crysp Compliance Portal',
        metaTags: [
          {
            name: 'description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          },
          {
            property: 'og:description',
            content: "Crysp's compliance portal, allowing you to easily manage your health and safety in one place."
          }
        ],
        permissions: {
          document: ["read"]
        }
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: "*",
      component: PageNotFound,
      beforeEnter: ifNotAuthenticated
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// If user is already authenticated, move them on
// to the dashboard automatically.
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }

  next({ name: "dashboard" });
};

// If user is not authenticated, move them on
// to the login page automatically.
const ifAuthenticated = (to, from, next) => {
//   next({ path: "www.bbc.co.uk" });

//   if (store.getters.isAuthenticated) {
//     next();
//     return;
//   }

//   localStorage.setItem("redirectTo", JSON.stringify(to));
//   localStorage.setItem("redirectFrom", JSON.stringify(from));
//   localStorage.setItem("redirectNext", JSON.stringify(next));

};

// Check meta permissions on each route and
// compare this with the users' defined abilities,
// kick them to dashboard if not allowed.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  const canRoute = to.matched.some(route => {
    let valid = true;

    if (route.meta.permissions) {
      Object.keys(route.meta.permissions).forEach(e => {
        let p = route.meta.permissions[e];

        p.forEach(a => {
          if (!ability.can(a, e)) {
            valid = false;
          }
        });
      });
    }

    return valid;
  });

  if (canRoute) {
    next();
    return;
  }

  next();
  return;
});

export default router;
