<template>
  <v-expansion-panels class="compliance-items-grouped">
    <v-expansion-panel v-for="(item, i) in items" :key="i">
      <v-expansion-panel-header>
        <template v-slot:actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon medium>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <span>View compliance items</span>
          </v-tooltip>
        </template>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex align-center"
              ><strong>{{ item.content_name }}</strong></v-col
            >
            <v-col
              cols="6"
              class="d-flex align-center justify-end text--secondary px-2"
              align="right"
            >
              <v-fade-transition>
                <span v-if="open" key="0"> </span>
                <span v-else key="1">
                  <v-progress-circular
                    :rotate="-90"
                    :size="32"
                    :width="5"
                    :value="
                      calculateItemPercentage(
                        itemTotals[i]?.pass,
                        itemTotals[i].total
                      )
                    "
                    color="success"
                    class="mx-1"
                  >
                    <strong class="caption">{{ itemTotals[i]?.pass }}</strong>
                  </v-progress-circular>

                  <v-progress-circular
                    :rotate="-90"
                    :size="32"
                    :width="5"
                    :value="
                      calculateItemPercentage(
                        itemTotals[i]?.warn,
                        itemTotals[i].total
                      )
                    "
                    color="warning"
                    class="mx-1"
                  >
                    <strong class="caption">{{ itemTotals[i]?.warn }}</strong>
                  </v-progress-circular>

                  <v-progress-circular
                    :rotate="-90"
                    :size="32"
                    :width="5"
                    :value="
                      calculateItemPercentage(
                        itemTotals[i]?.fail,
                        itemTotals[i].total
                      )
                    "
                    color="error"
                    class="mx-1"
                  >
                    <strong class="caption">{{ itemTotals[i]?.fail }}</strong>
                  </v-progress-circular>
                </span>
              </v-fade-transition>

              <v-fade-transition>
                <v-divider vertical class="mx-3" v-if="!open"></v-divider>
              </v-fade-transition>

              <v-menu right bottom>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon medium>$vuetify.icons.values.download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item @click.stop="exportAsPDF(i)">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Export as PDF</v-list-item-title>
                  </v-list-item>
                  <JsonExcel
                    class="btn btn-default d-flex"
                    :data="item.items"
                    :fields="json_fields_item"
                    worksheet="Crysp Compliance Report"
                    :name="`crysp-compliance-report-${item.content_name}.xls`"
                  >
                    <v-list-item link>
                      <v-list-item-icon class="mr-3">
                        <v-icon color="#83CC70">mdi-file-excel-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Export for Excel </v-list-item-title>
                    </v-list-item>
                  </JsonExcel>
                </v-list>
              </v-menu>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    :to="{
                      name: 'content',
                      params: { content_id: item.content_id },
                    }"
                  >
                    <v-icon medium>mdi-file-document</v-icon>
                  </v-btn>
                </template>
                <span>View Content</span>
              </v-tooltip>

              <Can I="manage" a="documents">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      :to="{
                        name: 'content/edit',
                        params: { content_id: item.content_id },
                      }"
                    >
                      <v-icon medium>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Content</span>
                </v-tooltip>
              </Can>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-divider></v-divider>

        <ViewContentItemTable
          :complianceHeaders="headers"
          :complianceItems="item.items"
          :filtersMap="filtersMap"
          :contentId="item.content_id"
          :isChildContent="true"
          :isLoading="isLoading"
          :enableDelete="false"
          :showFilters="true"
          :filterItems="true"
          :hideFooter="false"
          @manageComplianceItem="$emit('manageComplianceItem', $event)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ViewContentItemTable from "../content/ViewContentItemTable.vue";
import JsonExcel from "vue-json-excel";


export default {
  name: "ViewCompliancyItems",
    components: {
        ViewContentItemTable,
        JsonExcel,
    },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    filtersMap: {
      type: Object,
      required: true,
    },
    itemTotals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
        page: 1,
      json_fields_item: {
        Name: "name",
        Status: "complianceState.name",
        Tags: {
          field: "tags",
          callback: (value) => {
            if (!value) {
              return "";
            }
            return value.map((tag) => tag.name).join(", ");
          },
        },
        "Date Created On": {
          field: "created_at",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("MMM DD, YYYY");
            }
          },
        },
        "Last Actioned": {
          field: "latest_action.actioned_on",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("MMM DD, YYYY");
            }
          },
        },
        "Next Due": {
          callback: (item) => {
            if (item.latest_action) {
              return this.$moment(item.latest_action.actioned_date)
                .add(item.frequency, "d")
                .format("MMM DD, YYYY");
            }
          },
        },
        "Days Remaining": {
          callback: (item) => {
            if (item.latest_action) {
              let date = this.$moment(item.latest_action.actioned_date).add(
                item.frequency,
                "d"
              );
              let today = this.$moment();
              return date.diff(today, "days");
            }
          },
        },
      },
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({ text: "Name", value: "name" });
      headers.push({ text: "Status", value: "complianceState.id" });
      headers.push({ text: "Tags", value: "tags" });
      headers.push({ text: "Date Created On", value: "created_at" });
      headers.push({
        text: "Last Actioned",
        value: "latest_action.actioned_on",
      });
      headers.push({ text: "Next Due", value: "latest_action.next_due" });
      headers.push({ text: "", value: "actions", sortable: false });

      return headers;
    },
  },
  methods: {
    calculateItemPercentage(value, total) {
      return (value / total) * 100;
    },
    exportAsPDF(index) {
      this.$emit("exportAsPDF", index);
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.compliance-items-grouped .v-expansion-panel::before {
  box-shadow: none;
}

.compliance-items-grouped {
  border: 1px solid #f0f0f0;
  border-top: none;
}

.compliance-items-grouped .v-expansion-panel-header--active {
  background: #f5f5f5;
}
</style>
