<template>
  <div class="text-center">
    <div class="spinner-block-wrapper">
      <div class="spinner-block-grid">
        <div class="spinner-block spinner-block-1"></div>
        <div class="spinner-block spinner-block-2"></div>
        <div class="spinner-block spinner-block-3"></div>
        <div class="spinner-block spinner-block-4"></div>
        <div class="spinner-block spinner-block-5"></div>
        <div class="spinner-block spinner-block-6"></div>
        <div class="spinner-block spinner-block-7"></div>
        <div class="spinner-block spinner-block-8"></div>
        <div class="spinner-block spinner-block-9"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerBlocks',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.spinner-block-wrapper {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 24px;
  padding: 12px;
  animation: spinnerBlockAppear 0.33s ease-in-out; 
}

.spinner-block-grid {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  animation: spinnerBlockGridRotate 4s infinite ease-in-out; 
}

.spinner-block-grid .spinner-block {
  width: 33%;
  height: 33%;
  background-color: #31cbb9;
  background-color: var(--primary-colour);
  float: left;
  animation: spinnerBlockGridScaleDelay 2s infinite ease-in-out; 
}

.spinner-block-grid .spinner-block-1 { animation-delay: 0.2s; }
.spinner-block-grid .spinner-block-2 { animation-delay: 0.3s; }
.spinner-block-grid .spinner-block-3 { animation-delay: 0.4s; }
.spinner-block-grid .spinner-block-4 { animation-delay: 0.1s; }
.spinner-block-grid .spinner-block-5 { animation-delay: 0.2s; }
.spinner-block-grid .spinner-block-6 { animation-delay: 0.3s; }
.spinner-block-grid .spinner-block-7 { animation-delay: 0s;   }
.spinner-block-grid .spinner-block-8 { animation-delay: 0.1s; }
.spinner-block-grid .spinner-block-9 { animation-delay: 0.2s; }

@keyframes spinnerBlockAppear {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes spinnerBlockGridRotate {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes spinnerBlockGridScaleDelay {
  0%, 70%, 100% {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
  35% {
    opacity: 0;
    transform: scale3D(0, 0, 1);
  } 
}
</style>
