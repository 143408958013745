import axios from '../axios'

const initialState = () => {
  return {
    user: null,
    users: null,
    loadingUsers: false,
    updatingUser: false,
    cache: {
      users: {
        lifetime: 30000,
        microtime: 0
      }
    }
  }
}

const state = initialState()

const getters = {}

const mutations = {
  CREATE_USER: (state, payload) => {
    state.users.push(payload)
  },

  RESET_STORE: (state) => {
    state.users = null
  },

  DELETE_USER: (state, payload) => {
    const filtered = state.users.filter(user => user.id !== payload)
    state.users = filtered
  },

  SET_USERS: (state, payload) => {
    state.loadingUsers = false
    state.users = payload.sort((a, b) => a.profile.first_name.localeCompare(b.profile.first_name))

    state.cache.users.microtime = Date.now()
  },

  LOADING_USERS: state => state.loadingUsers = true,

  UPDATE_USER_IN_USERS: (state, payload) => {
    let user = state.users[payload.index]
    let updatedUser = Object.assign(user, payload.user)
    state.users[payload.index] = updatedUser
  },

  UPDATING_USER: state => state.updatingUser = true,
  UPDATING_USER_STOP: state => state.updatingUser = false,

  RESET_STORE: (state) => {
    state.user = null
    state.users = null
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  /**
   * This method returns an array of all users by org id
   */
  GET_USERS({ commit, rootState }, organisation_id) {
    commit('LOADING_USERS')

    let cacheStatus = (Date.now() - state.cache.users.microtime) < state.cache.users.lifetime && state.cache.users.microtime > 0
      ? true
      : false

    let vars = {
      organisation_id: parseInt(organisation_id)
    }

    if (rootState.AUTH_STORE.authUser) {
      vars['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
    }

    axios.get('users', {
      params: vars
    }).then(({ data }) => {
      commit('SET_USERS', data.data)
    }, error => {
      console.error('Something has gone wrong getting users.', error)
    })
  },

  /**
   * Update a user, update store users
   */
  UPDATE_USER({ commit }, updated) {
    const users = state.users

    for(var i = 0; i < users.length; i++) {
      if (updated.id === users[i].id) {
        commit('UPDATE_USER_IN_USERS', {
          index: i,
          user: updated
        })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
