<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card>
      <v-card-title class="primary primary--text--contrast">
        <span>Form Responses</span>

        <v-spacer></v-spacer>

        <v-btn
          text
          dark
          @click.stop="shareResponses = true"
          :disabled="isLoading"
          class="ml-2"
          v-if="responses.length"
        >
          <v-icon medium class="mr-2">mdi-tray-arrow-up</v-icon> Share
        </v-btn>

        <v-menu right bottom v-if="responses.length">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              :disabled="isLoading"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon medium class="mr-2">mdi-clipboard-arrow-down</v-icon> EXPORT RESPONSES <v-icon medium class="ml-2">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click.stop="exportAsPDF()">
              <v-list-item-icon class="mr-3">
                <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export as PDF</v-list-item-title>
            </v-list-item>
            <JsonExcel
              class="btn btn-default"
              :data="responses"
              :fields="json_fields"
              worksheet="Crysp Form Responses"
              :name="`crysp-form-responses-${form.meta.name}.xls`"
            >
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="#83CC70">mdi-file-excel-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Export for Excel
                </v-list-item-title>
              </v-list-item>
            </JsonExcel>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="responses"
        :items-per-page="10"
        :loading="isLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="index"
        loading-text="Loading responses... please wait."
        class="v-data-table--selectable">

        <template v-slot:no-data>
            No responses available.
        </template>

        <template v-slot:item="{ item }">
          <tr @click.stop="$router.push({ name: 'form/response', params: { response_id: item.id }})">
            <td>
              {{ item.user && item.user.profile ? `${item.user.profile.first_name} ${item.user.profile.last_name}` : 'User' }}
            </td>
            <td>
              {{ item.updated_at | moment("DD/MM/YYYY, h:mma") }}
            </td>
            <td>
              <div class="d-flex flex-column mt-2">
                <p v-for="(field, index) in JSON.parse(item.response_body).slice(0, 5)" :key="index" class="mb-2">
                  <span v-if="field.type.id == 9 && field.field_value">
                    <strong>{{ field.config.label }}</strong>
                    <!-- <a class="ml-2" :href="field.field_value | returnFileFromString" download>Download</a> -->
                    <a class="ml-2" @click.stop="handleDownloadResponse(field.field_value)">Download</a>
                  </span>
                  <span v-else-if="!field.field_value">
                    <strong>{{ field.config.label }}:</strong> [No Response]
                  </span>
                  <span v-else>
                      <strong>{{ field.config.label }}:</strong> {{ Array.isArray(field.field_value) ? field.field_value.join(', ') : field.field_value }}
                  </span>
                </p>
              </div>
            </td>
            <td>
              <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      :to="{ name: 'form/response', params: { response_id: item.id }}"
                      :disabled="isLoading"
                    >
                      <v-icon medium>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Response</span>
                </v-tooltip>
              </div>

              <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">
              <v-col cols="12">
                <v-btn
                  block
                  color="primary"
                  outlined
                  :to="{ name: 'form/response', params: { response_id: item.id }}"
                  :disabled="isLoading"
                ><v-icon dark left>mdi-eye</v-icon>
                View Response</v-btn>
              </v-col>
              </v-row>
            </td>
          </tr>
        </template>

         <!-- <template v-slot:item.id="{ item }">
           
        </template>

        <template v-slot:item.updated_at="{ item }">
          
        </template>

         <template v-slot:item.preview="{ item }">
          
        </template>

        <template v-slot:item.actions="{ item }">

          
        </template> -->

      </v-data-table>

    </v-card>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="shareResponses && !isLoading">
        <v-card class="mx-auto pa-4" max-width="100%" width="640px" light>
          <v-card-title class="mb-4">
            <v-btn
              fab
              depressed
              color="primary"
              class="mr-3">
              <v-icon medium>mdi-tray-arrow-up</v-icon>
            </v-btn>
            Share this form response via email
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="close_card"
              @click.stop="shareResponses = false"
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="toShare.email"
              label="Recipient"
              outlined
              hint="Comma separate email address’ to send to multiple people"
              persistent-hint
              >
            </v-text-field>
          </v-card-text>

          <v-card-actions class="d-flex justify-end px-4">
            <v-btn @click.stop="handleShareResponses(toShare)" color="primary">
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-fade-transition>
  </v-container>
</template>

<script>
import BreadcrumbsManager from '@/_util/breadcrumbManager'
import { mapState } from 'vuex'
// import storage from '../../_util/storage'
import axios from 'axios';

import JsonExcel from "vue-json-excel";

import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export default {
  name: "FormResponses",
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    JsonExcel
  },
  props: [
    'form'
  ],
  data() {
    return {
       headers: [
        { text: "Response completed by", value: "id" },
        { text: "Date completed", value: "updated_at" },
        { text: "Response preview", value: "preview", sortable: false },
        { value: "actions", sortable: false }
      ],
      sortBy: 'updated_at',
      sortDesc: true,
      shareResponses: false,
      toShare: {},
      exportItems: [
        { title: 'Export for Excel', icon: 'mdi-file-excel-box', color: '#83CC70' },
        { title: 'Export as a PDF', icon: 'mdi-file-pdf-box', color: '#FF7981' },
        { title: 'Export for Word', icon: 'mdi-file-word-box', color: '#6ABDF8' },
      ],
      json_fields: {
        "Completed By": {
          field: "user.profile",
          callback: (value) => {
            if (value) {
              return `${value.first_name} ${value.last_name}`;
            }
          },
        },
        "Date Completed": {
          field: "created_at",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("DD/MM/YYYY")
            }
          },
        },
        "Response Preview": {
          field: "response_body",
          callback: (response) => {
            let field = JSON.parse(response)
            if (field.length) {
              let responses = '';

              for (let index = 0; index < field.length; index++) {
                let fieldValue = field[index].field_value;

                if (!fieldValue) {
                  fieldValue = '[No Response]';
                } else if (typeof fieldValue === 'object') {
                  if (field[index].field_value.type === 'file') {
                    fieldValue = '[File Download]';
                  }
                }

                responses += `<strong>${field[index].config.label}</strong>\n${fieldValue}\n`;
              }

              return responses
            }
          },
        },
      }
    }
  },
  computed: {
    ...mapState({
      responses: state => state.FORM_STORE.responses,
      isLoading: state => state.FORM_STORE.loadingResponses
    })
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        await this.$store.dispatch('FORM_STORE/GET_RESPONSES', this.$route.params.form_id)
      } catch (error) {
        if (error.response.status === 401) {
          this.$toast.error('You do not have permission to view this form\'s responses.');
          await this.$router.push({name: 'form/all'})
        }
      }
    },
    handleShareResponses() {
      this.$http.post('mail/send', {
          reseller_id: 0,
          organisation_id: 0,
          recipients: this.toShare.email,
          template: 'ShareResponses',
          template_tags: JSON.stringify({
            url: window.location.origin+'/form/'+encodeURIComponent(parseInt(this.form.id))+'/responses'
          }),
        })
        .then(
          ({ data }) => {
            this.shareResponses = false;
            this.isSubmitted = true;

            this.$toast.success('Successfully shared form responses.')
          },
          ({ error }) => {
            this.$toast.error('Something went wrong sharing form responses, please try again.')
          }
        );
    },
    exportAsPDF() {
      const doc = new jsPDF({
        orientation: "landscape"
      })

      doc.setFontSize(16)
      doc.text(`Form Responses`, 14, 22)
      doc.setFontSize(11)
      doc.setTextColor(100)
      doc.text(this.form.meta.name, 14, 30)

      const docCopy = new jsPDF({
        orientation: "landscape"
      })

      docCopy.setFontSize(16)
      docCopy.text(`Form Responses`, 14, 22)
      docCopy.setFontSize(11)
      docCopy.setTextColor(100)
      docCopy.text(this.form.meta.name, 14, 30)

      let that = this;

      let bodyItems = []

      let responses = (response) => {
        let field = JSON.parse(response)
        if (field.length) {
          let responses = []
          for (let index = 0; index < field.length; index++) {
            responses.push({label: field[index].config.label, response: field[index].field_value});
          }
          return responses
        }
      }

      this.responses.forEach(item => {
        let bodyLength = JSON.parse(item.response_body).length + 1;
        // console.log(bodyLength);

        let filtered = {
          full_name: item.user?.profile ? `${item.user.profile.first_name} ${item.user.profile.last_name}` : 'User',
          created_at: this.$moment(item.created_at).format("DD/MM/YYYY"),
          response_body: { content: responses(item.response_body), styles: { cellWidth: 200 } }
        }

        bodyItems.push(filtered);
      });

      let heightMatrix = {};

      // We create the document once and make note of how high the nested tables are.
      // We then create the document a second time and can use those values to set the cell heights.
      // There might be a simpler/less crazy way of doing this, but this does work and is better than
      // the guessing of the height that was here before.
      autoTable(docCopy, {
        columns: [
          { dataKey: 'full_name', header: 'Completed By' },
          { dataKey: 'created_at', header: 'Date Completed' },
          { dataKey: 'response_body', header: 'Response Preview' }
        ],
        body: bodyItems,
        startY: 40,
        theme: 'grid',
        headStyles: {
          fillColor: '#31cbb9',
        },
        didDrawCell: function (data) {
          if (data.column.index === 2 && data.row.section === 'body' && data.row.index >= 0) {
            autoTable(docCopy, {
              startY: 0,
              margin: { left: data.cell.x },
              tableWidth: data.cell.width,
              columns: [
                { dataKey: 'label', header: 'Question' },
                { dataKey: 'response', header: 'Answer' }
              ],
              body: data.cell.raw.content,
              theme: 'grid',
              styles: {
                fontSize: 8,
              },
              headStyles: {
                fillColor: '#e5e5e5',
                textColor: '#000000'
              },
              didDrawPage: function (page) {
                heightMatrix[data.row.index] = heightMatrix[data.row.index] ?? {};
                heightMatrix[data.row.index][data.column.index] = heightMatrix[data.row.index][data.column.index] ?? page.cursor.y;
              }
            })
          }
        },
        didDrawPage: function () {
          // We've finished the positioning copy, now create the actual document
          autoTable(doc, {
            columns: [
              { dataKey: 'full_name', header: 'Completed By' },
              { dataKey: 'created_at', header: 'Date Completed' },
              { dataKey: 'response_body', header: 'Response Preview' }
            ],
            body: bodyItems,
            startY: 40,
            theme: 'grid',
            headStyles: {
              fillColor: '#31cbb9',
            },
            didParseCell: function (data) {
              if (typeof data.cell.raw === 'object') {
                data.cell.text = []; // Clear the cell contents
                data.cell.styles.cellPadding = {bottom: heightMatrix[data.row.index][data.column.index]}; // Best way to set height so that cells cross page breaks correctly
              }
            },
            didDrawCell: function (data) {
              if (data.column.index === 2 && data.row.section === 'body' && data.row.index >= 0) {
                doc.autoTable({
                  startY: data.cell.y,
                  margin: { left: data.cell.x },
                  tableWidth: data.cell.width,
                  columns: [
                    { dataKey: 'label', header: 'Question' },
                    { dataKey: 'response', header: 'Answer' }
                  ],
                  body: bodyItems[data.row.index].response_body.content,
                  theme: 'grid',
                  styles: {
                    fontSize: 8,
                  },
                  headStyles: {
                    fillColor: '#e5e5e5',
                    textColor: '#000000'
                  },
                  didParseCell: function (data) {
                    if (!data.cell.raw) {
                      data.cell.text = ['[No Response]'];
                      return;
                    }

                    if (typeof data.cell.raw === 'object') {
                      if (data.cell.raw.type === 'file') {
                        data.cell.text = ['[File Download]'];
                      }
                    }
                  }
                })
              }
            }
          })

          doc.save(`form-responses-${that.form.meta.name}.pdf`)
        }
      })
    },
    async handleDownloadResponse(item) {
      //put code here to download the response
      // console.log(item);
      let filename = item.original_name;

      let filePath = '';
      try {
        filePath = `${process.env.VUE_APP_URL}storage/${item.url}`
      } catch (error) {
        filePath = `https://api.crysp.co.uk/storage/${item.url}`
      }
      let token = localStorage.getItem('token');

      try {
          // console.log(filePath, filename);
          await axios.get(filePath, {
            headers: {
              Authorization: `${token}`
            },
            responseType: 'blob' // Important for handling file downloads
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // filename = this.addFileExtension(filePath, filename);
            
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          });
        } catch (error) {
          // try with Bearer key word instead of just token
          try {
            await axios.get(filePath, {
              headers: {
                Authorization: `Bearer ${token}`
              },
              responseType: 'blob' // Important for handling file downloads
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;

              // filename = this.addFileExtension(filePath, filename);

              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
            });
          } catch (error) {
            console.error('Something has gone wrong downloading the file.', error)
          }
        }
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Forms' , path: '/form/all/' },
      { text: ':form' },
      { text: 'Responses' }
    ])

    this.replaceBreadcrumb({
      find: ':form',
      replace: {
        text: this.form.meta.name,
        path: `/form/${this.form.id}`
      }
    })
  }
}
</script>
