import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('BREADCRUMBS', {
      setBreadcrumbs: 'set',
      replaceBreadcrumb: 'replace',
      emptyBreadcrumbs: 'empty'
    })
  }
}