<template>
  <v-app id="app" :style="cssProps">

    <!-- TODO fix this - one of the flags is clearly not quite right when loading ManageProfile page, got rid of isLoading and allowedWhen Trial Expired -->
    <Navbar 
      :user="user" 
      v-if="navigationbar && user && !isLoggingOut && !trialHasExpired"
    /> 

    <v-fade-transition mode="out-in">
      <v-navigation-drawer 
        tag="nav" 
        v-if="drawer && user && !isLoggingOut && !trialHasExpired " 
        app 
        clipped 
        fixed
      >
        <NavDrawer />
      </v-navigation-drawer>
    </v-fade-transition>

    <div v-if="isAppIdle && timeout">
      <Timeout />
    </div>

    <v-scroll-y-transition mode="out-in">
      <Subscribe v-if="user && trialHasExpired && !allowedWhenTrialExpired"/>
      <router-view v-else-if="!isLoading"></router-view>
    </v-scroll-y-transition>

    <v-fade-transition mode="out-in">
      <v-footer v-if="footer && !isLoading && !isLoggingOut" class="mt-8">
        <div class="py-2" style="display: flex; width: 100%; justify-content: center;">
          <span class="caption font-weight-light">Powered by Crysp</span>
          <span class="caption font-weight-light mx-1">&bull;</span>
          <span class="caption font-weight-light">© {{ $moment().format('Y') }}</span>
        </div>
      </v-footer>
    </v-fade-transition>

    <v-fade-transition mode="out-in">
      <v-row align="center" justify="center" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>
    </v-fade-transition>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'

  import Timeout from '@/components/cards/Timeout'

  import Navbar from '@/components/Navbar'
  import NavDrawer from '@/components/NavDrawer'
  import Subscribe from '@/views/register/Subscribe.vue'

  export default {
    name: 'CryspDashboard',
    components: {
      Timeout,
      Navbar,
      NavDrawer,
      Subscribe
    },
    data() {
      return {
        theme: {
          primary: '#31CBB9',
          primaryContrast: '#FFFFFF'
        },
        themeDefault: {
          primary: '#31CBB9',
          primaryContrast: '#FFFFFF'
        },
        assets: {
          images: {
            background: require("@/images/background.jpg"),
            logo: require("@/images/logo-crysp-dark.png")
          }
        }
      }
    },
    computed: {
      cssProps: function() {
          return {
            '--primary-colour'          : this.theme.primary,
            '--primary-colour-contrast' : this.theme.primaryContrast
          }
      },

      allowedWhenTrialExpired: function () {

        const allowable = ['profile', 'subscribe'];

        for (var i = 0; i < allowable.length; i++) {
          if (this.$route.name.indexOf(allowable[i]) > -1) {
            return true
          }
        }

        return false;

      },

      navigationbar: function() {
        const prohibited = [
          'login', 
          'logout',
          'account/activate',
          'password/reset',
          'subscribe'
        ]

        if(!this.$route.name)
          return false

        for (var i = 0; i < prohibited.length; i++) {
          if (this.$route.name.indexOf(prohibited[i]) > -1) {
            return false
          }
        }
        
        return true
      },
      drawer: function() {
        const prohibited = [
          'dashboard', 
          'login', 
          'logout',
          'account/activate',
          'password/reset',
          'compliance',
          'subscribe',
        ]

        if(!this.$route.name)
          return false

        for (var i = 0; i < prohibited.length; i++) {
          if (this.$route.name.indexOf(prohibited[i]) > -1) {
            return false
          }
        }
        
        return true
      },
      footer: function() {
        const prohibited = [
          'login',
          'microsoftLogin',
          'logout',
          'account/activate',
          'password/reset',
          'register'
        ]

        if(!this.$route.name)
          return false

        for (var i = 0; i < prohibited.length; i++) {
          if (this.$route.name.indexOf(prohibited[i]) > -1) {
            return false
          }
        }
        
        return true
      },
      timeout: function() {
        const prohibited = [
          'login', 
          'register'
        ]

        if(!this.$route.name)
          return false

        for (var i = 0; i < prohibited.length; i++) {
          if (this.$route.name.indexOf(prohibited[i]) > -1) {
            return false
          }
        }
        
        return true
      },
      ...mapState({
        user: state => state.AUTH_STORE.authUser,
        userOrganisation: state => state.ORG_STORE.myorganisation,
        isLoggingOut: state => state.AUTH_STORE.loggingOut,
        trialHasExpired: state => state.ORG_STORE.myorganisation?.current_package?.isTrialExpired,
        isLoading: (state) => {
          return state.ORG_STORE.loadingMyOrganisation
        },
      }),
    },
    // on mounted method added to test by printing out all the parameters for the Navbar visibility
    mounted() {
    },
    methods: {
      setTheme(colour, contrast) {
        let primary = colour
        let primaryContrast = contrast ? contrast : this.getPrimaryColourContrast(colour, true)
        
        this.theme.primary = primary
        this.theme.primaryContrast = primaryContrast

        this.$vuetify.theme.themes.light.primary = primary
        this.$vuetify.theme.themes.light.primaryContrast = primaryContrast
      },
      getPrimaryColourContrast(hex, bw) {
          if (hex.indexOf('#') === 0) {
              hex = hex.slice(1)
          }

          if (hex.length === 3) {
              hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
          }

          if (hex.length !== 6) {
              throw new Error('Invalid HEX color.')
          }
          var r = parseInt(hex.slice(0, 2), 16),
              g = parseInt(hex.slice(2, 4), 16),
              b = parseInt(hex.slice(4, 6), 16)

          if (bw) {
              return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                  ? '#000000'
                  : '#FFFFFF'
          }

          r = (255 - r).toString(16)
          g = (255 - g).toString(16)
          b = (255 - b).toString(16)

          return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b)
      },
      padZero(str, len) {
          len = len || 2;
          var zeros = new Array(len).join('0');
          return (zeros + str).slice(-len);
      }
    },
    watch: {
      userOrganisation (payload) {
        let organisation = payload || null

        this.setTheme(
          organisation.profile ? organisation.profile.colour_scheme : this.themeDefault.primary,
          organisation.profile ? this.themeDefault.primaryContrast  : false,
        )
      }
    }
  }
</script>

<style lang="scss">
  .full-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  
  .card-logo {
    height: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    padding-bottom: 15%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    white-space: nowrap;
    text-indent: -9999px; 
  }
</style>

<style lang="scss">
  .v-btn:not(.v-btn--outlined).primary,
  .v-btn:not(.v-btn--outlined).secondary,
  .v-btn:not(.v-btn--outlined).accent,
  .v-btn:not(.v-btn--outlined).success,
  .v-btn:not(.v-btn--outlined).error,
  .v-btn:not(.v-btn--outlined).warning,
  .v-btn:not(.v-btn--outlined).info {
    color: #FFFFFF;
    color: var(--primary-colour-contrast);
  }

  .primary--text--contrast {
    color: #FFFFFF;
    color: var(--primary-colour-contrast);
  } 
</style>

<style lang="scss">
  .v-data-table--selectable .v-data-table__mobile-row__cell {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      
    .v-chip { pointer-events: none; }
  }
  
.clickable-row {
  cursor: pointer;
}
</style>
