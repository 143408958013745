<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>
    
    <v-card-title>
      My Profile
    </v-card-title>

    <v-card-subtitle>
      Manage your user profile.
    </v-card-subtitle>

    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <v-btn :to="{ name: 'profile'}">
        Manage Profile
      </v-btn>

      <v-spacer></v-spacer>

      <!-- <v-btn color="primary" :to="{ name: 'policies' }">
        Policies
      </v-btn> -->

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Profile.png")
      }
    };
  }
};
</script>
