import axios from '../_store/axios'

export default {
  methods: {
      getStorageFile(p) {
        return `${process.env.VUE_APP_URL}storage/${p}`
      },

      // Add the file extension to the filename
      addFileExtension(filePath, filename){
        //get the document extension from the filepath and append it to the filename
        let ext = filePath.split('.').pop();
        //if there was a paramter on the url then remove it
        if (ext.includes('?')){
          ext = ext.split('?')[0];
        }
        filename = `${filename}.${ext}`;
        return filename;
      },

      // Download a file from the server with the specified path
      async downloadFile(filePath, filename){
        let token = localStorage.getItem('token');
        try {
          // console.log(filePath, filename);
          await axios.get(filePath, {
            headers: {
              Authorization: `${token}`
            },
            responseType: 'blob' // Important for handling file downloads
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            filename = this.addFileExtension(filePath, filename);
            
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          });
        } catch (error) {
          // try with Bearer key word instead of just token
          try {
            await axios.get(filePath, {
              headers: {
                Authorization: `Bearer ${token}`
              },
              responseType: 'blob' // Important for handling file downloads
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;

              filename = this.addFileExtension(filePath, filename);

              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
            });
          } catch (error) {
            console.error('Something has gone wrong downloading the file.', error)
          }
        }
      },

      // get the url of the file to download
      // async getImage(filePath){
      //   let token = localStorage.getItem('token');
      //   try {
      //     await axios.get(filePath, {
      //       headers: {
      //         Authorization: `${token}`
      //       },
      //       responseType: 'blob' // Important for handling file downloads
      //     }).then((response) => {
      //       //create a new image object based on the response data
      //       const image = new Image();
      //       image.src = window.URL.createObjectURL(new Blob([response.data]));
      //       console.log("successfully got the image url - ", image.src)
      //       return image.src;
      //     });
      //   } catch (error) {
      //     //try with bearer key word instead of just token
      //     try {
      //       await axios.get(filePath, {
      //         headers: {
      //           Authorization: `Bearer ${token}`
      //         },
      //         responseType: 'blob' // Important for handling file downloads
      //       }).then((response) => {
      //           //create a new image object based on the response data
      //           const image = new Image();
      //           image.src = window.URL.createObjectURL(new Blob([response.data]));
      //           console.log("successfully got the image url - ", image.src)
      //           return image.src;
      //       });
      //     } catch (error) {
      //     console.error('Something has gone wrong getting the download url.', error)
      //     }
      //   }
      // }
  }
}