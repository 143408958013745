<template>
  <v-container
    fluid
    fill-height
    class="pa-0">

    <VideoBackground 
        ref="videobackground"
        :src="assets.videos.background"
        style="max-height: 100vh; height: 100vh;">

      <v-container
        fluid
        fill-height
        style="background-color: rgba(0,0,0,0.33);">

        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card class="mx-auto overflow-hidden" width="1200px" max-width="100%" light>
              <v-fade-transition mode="out-in">
                <SelectPlan v-if="!isLoading && !planSuccess && !planError"/>
              </v-fade-transition>

              <v-fade-transition mode="out-in">
                <v-layout column v-if="!isLoading && planSuccess" class="pa-12 text-center">
                  <v-icon color="primary" x-large>mdi-checkbox-marked-circle-outline</v-icon>
                  <h1 class="mb-12">Success</h1>
                  <p>Thank you for registering for our <b>{{ plan }} Plan</b>!</p>
                  <p class="mb-12">We will send through an email to confirm, please login and remain compliant.</p>
                  <v-btn color="primary" :to="{ name: 'dashboard' }" class="align-self-center">Back to dashboard</v-btn>
                </v-layout>
              </v-fade-transition>

              <v-fade-transition mode="out-in">
                <v-layout column v-if="!isLoading && planError" class="pa-12 text-center">
                  <v-icon color="primary" x-large>mdi-checkbox-marked-circle-outline</v-icon>
                  <h1 class="mb-12">Sorry</h1>
                  <p class="mb-12">There was a problem while registering for our <b>{{ plan }} Plan</b>!</p>
                  <v-btn color="primary" @click="resetSubscribing" class="align-self-center">Try Again</v-btn>
                </v-layout>
              </v-fade-transition>
              
              <v-row align="center" justify="center" v-if="isLoading">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </VideoBackground>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import VideoBackground from 'vue-responsive-video-background-player'

import SelectPlan from '@/components/subscribe/Plan'

export default {
  name: 'Subscribe',
  components: {
    VideoBackground,
    SelectPlan
  },
  data() {
    return {
      assets: {
        images: {
          background: require("@/images/background.jpg"),
          logo: require("@/images/logo-crysp-dark.png")
        },
        videos: {
          background: require("@/videos/background.mp4"),
        }
      },
      plan: null,
      planSuccess: false,
      planError: false,
      products: {
        'enterprise-package': 'Enterprise Package',
        'standard-package': 'Standard Package', 
        'starter-package': 'Starter Package'
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.ORG_STORE.loadingOrganisation
    })
  },
  async mounted () {
    this.$refs.videobackground.player.play()

    this.plan = this.products[this.$route.query.product];

    if (this.$route.query.cancel) {
      this.planError = true;
    }

    if (this.$route.query.success) {
      this.planSuccess = true;
      await this.$store.dispatch('ORG_STORE/UPDATE_ORG_PLAN', this.$store.state.ORG_STORE.myorganisation.id)
    }
  },
  methods: {
    resetSubscribing() {
      this.planError = false;
    }
  }
}
</script>
