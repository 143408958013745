<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>

    <v-card-title class="pb-2">
      Manage Policies
    </v-card-title>

    <v-card outlined class="mx-4 mb-2">
      <v-row align="center" justify="center" style="height: 100%" v-if="!isLoading && totals.total == 0">
        <v-col align="center" justify="center">
          <div class="text-center caption px-4">You are not managing any policy items.</div>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" style="height: 100%" v-if="isLoading || totals.total > 0">
        <v-col cols="3" align="center" justify="center">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-progress-circular
                v-bind="attrs" v-on="on"
                :rotate="-90"
                :size="48"
                :width="6"
                :value="calculateItemPercentage(totals.pass)"
                :indeterminate="isLoading"
                color="success"
              >
                {{ totals.pass }}
              </v-progress-circular>
  
            </template>
            <span>Compliant Items</span>
          </v-tooltip>

        </v-col>

        <v-col cols="3" align="center" justify="center">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-progress-circular
                v-bind="attrs" v-on="on"
                :rotate="-90"
                :size="48"
                :width="6"
                :value="calculateItemPercentage(totals.warn)"
                :indeterminate="isLoading"
                color="warning"
              >
                {{ totals.warn }}
              </v-progress-circular>
  
            </template>
            <span>Items with Warnings</span>
          </v-tooltip>

        </v-col>

        <v-col cols="3" align="center" justify="center">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-progress-circular
                v-bind="attrs" v-on="on"
                :rotate="-90"
                :size="48"
                :width="6"
                :value="calculateItemPercentage(totals.fail)"
                :indeterminate="isLoading"
                color="error"
              >
                {{ totals.fail }}
              </v-progress-circular>
  
            </template>
            <span>Outstanding Items</span>
          </v-tooltip>

        </v-col>
      </v-row>
    </v-card>
    
    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <!-- Need 2 buttons 1 if they can manage them 2 for their own -->
      <Can I="manage" a="organisations">
        <v-btn :to="{ name: 'organisation/policies'}">
          Manage Policies
        </v-btn>
      </Can>

      <v-spacer></v-spacer>

      <v-btn color="primary" :to="{ name: 'policies'}">
        My Policies
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      totals: {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0
      },
      card: {
        image: require("@/images/cards/Crysp_Polices_DRAFTv2.png")
      },
      options: {},
    }
  },
  computed: {
    ...mapState({
      policyObservations: state => state.POLICIES_STORE.observations,
      isLoading: state => state.POLICIES_STORE.loadingObservations
    })
  },
  beforeMount() {
    this.getData()
    this.parseData(this.policyObservations)
  },
  methods: {
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = '';
      this.$store.dispatch('POLICIES_STORE/GET_OBSERVATIONS', { sortBy, sortDesc, page, itemsPerPage, search })
    },
    parseData(d) {
      if (d) {
        this.totals.total = 0
        this.totals.pass = 0
        this.totals.warn = 0
        this.totals.fail = 0

        this.totals.total = d.length

        d.forEach((item, key) => {
          let state = item.status

          if (state == 2) { this.totals.pass++ }
          if (state == 1) { this.totals.warn++ }
          if (state == 0) { this.totals.fail++ }
        })
      }
    },
    calculateItemPercentage(v) {
      return v / this.totals.total * 100
    }
  },
  watch: {
    policyObservations (payload) {
      this.parseData(payload)
    }
  }
}
</script>
