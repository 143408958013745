<template>
  <v-container
    fluid
    fill-height
    class="pa-0">

    <VideoBackground 
        ref="videobackground"
        :src="assets.videos.background"
        style="max-height: 100vh; height: 100vh;">

      <v-container v-if="underMaintenace"
        fluid
        fill-height
        style="background-color: rgba(0,0,0,0.33);">

        <!-- Maintenance banner -->
        <v-layout
          align-center
          justify-center
          fill-height
          style="background-color: rgba(0,0,0,0.33);">
          <v-flex xs12 sm8 md6 class="pa-lg-12">
            <v-card class="mx-auto pa-12" width="600px" max-width="100%" light shape>
              <v-row align="center" justify="center">
                <v-alert type="error" icon="mdi-alert" border="left" elevation="2" colored>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-alert-title class="text-h6">Maintenance</v-alert-title>
                      <v-alert-title class="text-h6">We are currently performing maintenance on the system. Please try again later.</v-alert-title>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(0,0,0,0.33);">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 class="pa-lg-12">
            <v-card class="mx-auto pa-12" width="600px" max-width="100%" light shape>
              <v-row align="center" justify="center" v-if="isLoading">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
              <div v-else class="px-lg-12 py-lg-10">
                <div :style="{'background-image': `url(${assets.images.logo})`}" class="card-logo">Crysp</div>
                
                <div v-if="!isLoading">

                  <LoginForm 
                    v-if="!lostPassword"
                    v-on:FORGOT_PASSWORD="lostPassword = true" />

                  <ForgotPasswordForm 
                    v-if="lostPassword"
                    v-on:RESET="reset" />

                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </VideoBackground>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import VideoBackground from 'vue-responsive-video-background-player'

import LoginForm from './LoginForm'
import ForgotPasswordForm from './ForgotPasswordForm'

export default {
  name: 'Login',
  components: {
    VideoBackground,
    LoginForm,
    ForgotPasswordForm,
  },
  data() {
    return {
      lostPassword: false,
      assets: {
        images: {
          background: require("@/images/background.jpg"),
          logo: require("@/images/logo-crysp-dark.png")
        },
        videos: {
          background: require("@/videos/background.mp4"),
        }
      },
      underMaintenace: false,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.AUTH_STORE.loggingIn
    })
  },
  mounted() {
    try {
    let playPromise = this.$refs.videobackground.player.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
      }).catch(error => {
        // Auto-play was prevented
        // Show a UI element to let the user manually start playback
        // console.log("Playback was prevented", error);
      });
    }
  } catch (e) {
    // console.log("Error playing video background", e);
  }
  },
  methods: {
    reset() {
      this.lostPassword = false
      this.lostUsername = false
    }
  }
}
</script>
