<template>
  <v-dialog max-width="480" v-model="isAppIdle" v-if="!expired" light>
    <v-card light>
      <v-card-title class="primary primary--text--contrast py-4 mb-4">
        Are you still there?
      </v-card-title>

      <v-card-text class="py-0">
        Your session is about to expire due to inactivity.
      </v-card-text>

      <v-divider class="my-4"></v-divider>

      <div class="d-flex align-center justify-center pb-4">
        <v-progress-circular
          :rotate="-90"
          :size="96"
          :width="16"
          :value="time / total * 100"
          color="warning"
        >
          <span class="font-weight-medium">{{ time / 1000 }}</span>
        </v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'TimeoutModal',
  data() {
    return {
      expired: false,
      total: 30000,
      time: 30000
    }
  },
  created() {
    this.expired = false
    
    let timerID = setInterval(() => {
      this.time -=1000

      if (!this.isAppIdle) clearInterval(timerID)

      if (this.time < 2) {
        clearInterval(timerID)
        this.expired = true
        this.$router.push({ name: 'logout' }, () => {})
      }
    }, 1000)
  }
}
</script>
