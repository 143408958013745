<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header :value="isLoading || !item ? 'View Content' : item.meta.name"></v-page-header>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-row align="center" justify="center" v-if="isLoading || isLoadingComplianceItems || !item">
            <v-loading-spinner></v-loading-spinner>
          </v-row>

          <v-fade-transition mode="out-in">
            <v-card outlined v-if="!isLoading && item">

              <v-card-title class="grey lighten-5">
                <v-row>
                  <v-col cols="12" md="8">
                    <span>{{ item.meta.name }}</span>
                    
                    <v-spacer></v-spacer>

                    <v-chip outlined small v-if="$vuetify.breakpoint.mdAndUp">
                      <v-avatar left class="mr-1">
                        <v-icon small>mdi-update</v-icon>
                      </v-avatar>
                      <span class="font-weight-regular mr-1">Last Updated:</span> {{ +item.unix | moment("ddd, MMM Do, YYYY") }}
                      <span class="font-weight-regular mx-1">at</span> {{ +item.unix | moment("h:mma") }}
                    </v-chip>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="4">
                    <v-btn
                      text
                      :to="{ name: 'content/edit', params: { content_id: item.id }}"
                      :disabled="isLoading"
                      class="ml-2"
                    >
                      <v-icon medium class="mr-2">{{ $vuetify.icons.values.edit }}</v-icon> Edit
                    </v-btn>

                    <v-btn
                      text
                      @click.stop="shareContent = true"
                      :disabled="isLoading"
                      class="ml-2"
                    >
                      <v-icon medium class="mr-2">mdi-tray-arrow-up</v-icon> Share
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card-title>

              <v-divider v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>

              <v-card-title class="grey lighten-5" v-if="!$vuetify.breakpoint.mdAndUp">
                <v-chip outlined small>
                  <v-avatar left class="mr-1">
                    <v-icon small>mdi-update</v-icon>
                  </v-avatar>
                  <span class="font-weight-regular mr-1">Last Updated:</span> {{ +item.unix | moment("ddd, MMM Do, YYYY") }}
                  <span class="font-weight-regular mx-1">at</span> {{ +item.unix | moment("h:mma") }}
                </v-chip>
              </v-card-title>

              <v-divider></v-divider>

              <v-row justify="space-between">
                <v-col cols="12" class="py-0">

                  <v-tabs background-color="grey lighten-5">
                    <v-tab>
                      <v-icon left>mdi-file-document</v-icon>
                      Content
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-paperclip</v-icon>
                      Attachments
                    </v-tab>

                    <v-tab-item>
                      <v-divider></v-divider>
                      <div v-html="item.meta.content" class="v-content-body px-4 py-6"></div>
                    </v-tab-item>
                    <v-tab-item>
                      <v-divider></v-divider>

                      <v-row class="px-4 py-3" justify="end">

                        <v-col cols="12" md="4" lg="6" class="py-1 d-flex align-center">
                          <Can I="update" a="document">
                            <UploadModal
                              :autoUpload="false"
                              uploadType="organisation_generic"
                              buttonLabel="Upload Attachment"
                              modalLabel="Upload Attachment"
                              classList="upload-attachment"
                              @onUploaded="handleUploadedAttachment"
                              flush
                            ></UploadModal>

                            <v-spacer></v-spacer>
                          </Can>
                        </v-col>

                        <v-col cols="12" md="8" lg="6" class="py-1 d-flex justify-end">
                          <v-text-field
                            outlined
                            v-model="attachmentsSearch"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            dense
                            clearable
                            :disabled="isLoading"
                          ></v-text-field>
                        </v-col>

                      </v-row>

                      <v-divider></v-divider>
 
                      <v-data-table
                        class="v-data-table--selectable"
                        :headers="attachmentsHeaders"
                        :items="attachmentsParsed"
                        :items-per-page="10"
                        :search="attachmentsSearch"
                        :loading="isUploadingAttachment"
                        loading-text="Loading attachments... please wait."
                      >
                        <template v-slot:no-data>
                          No attachments available.
                        </template>

                        <template v-slot:item.name="{ item }">
                          <v-icon v-if="attachmentIcons[item.type]" large left :color="attachmentIcons[item.type].color">{{ attachmentIcons[item.type].icon }}</v-icon>
                          {{ item.name ? item.name : 'New Attachment' }}
                        </template>

                        <template v-slot:item.created_at="{ item }">
                          {{ item.created_at | moment("ddd, MMMM Do YYYY") }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  
                                  :disabled="isLoading"
                                  @click.stop="downloadFile(getStorageFile(item.file), item.name.replace(/ /g,'').toLowerCase())"
                                  download
                                >
                                  <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Download</span>
                            </v-tooltip>

                            <Can I="update" a="document">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    
                                    :disabled="isLoading"
                                    @click.stop="manageAttachmentItem(item)"
                                  >
                                    <v-icon medium>{{ $vuetify.icons.values.edit }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>

                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    
                                    :disabled="isLoading"
                                    @click.stop="attachmentDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                                  >
                                    <v-icon medium>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </Can>

                          </div>
                          <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">

                            <v-col cols="12">
                              <v-btn
                                color="primary"
                                block
                                outlined
                                :disabled="isLoading"
                                @click.stop="downloadFile(getStorageFile(item.file), item.name.replace(/ /g,'').toLowerCase())"
                                download
                              >
                                <v-icon dark left>mdi-arrow-down-bold-box-outline</v-icon>
                                Download
                              </v-btn>
                            </v-col>

                            <Can I="update" a="document">
                              <v-col cols="12">
                                <v-btn
                                  color="primary"
                                  block
                                  outlined
                                  :disabled="isLoading"
                                  @click.stop="manageAttachmentItem(item)"
                                >
                                  <v-icon dark left>{{ $vuetify.icons.values.edit }}</v-icon>
                                  Edit
                                </v-btn>
                              </v-col>

                              <v-col cols="12">
                                <v-btn
                                  color="primary"
                                  block
                                  outlined
                                  :disabled="isLoading"
                                  @click.stop="attachmentDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.name"
                                >
                                  <v-icon dark left>mdi-delete</v-icon>
                                  Delete
                                </v-btn>
                              </v-col>
                            </Can>
                          </v-row>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs>

                </v-col>
              </v-row>
            </v-card>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingAttachmentItem">
              <v-card class="mx-auto" max-width="480" min-width="420" light v-if="!isUpdatingAttachment">
                <v-card-title class="primary primary--text--contrast mb-1">
                  Manage Attachment
                </v-card-title>

                <v-row class="px-2 py-1">
                  <v-col cols="12">
                    <v-text-field
                    v-model="toUpdate.name"
                    label="Attachment Name"
                    hide-details
                    outlined
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                    v-model="toUpdate.description"
                    label="Attachment Description"
                    hide-details
                    outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click.stop="manageAttachmentItem(false)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click.stop="handleEditAttachmentItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isUpdatingAttachment">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
           <v-card outlined class="mt-8 pb-2" v-if="!isLoadingComplianceItems">

              <v-card-title class="grey lighten-5">
                <span>Compliance</span>

                <v-spacer></v-spacer>

                <div class="compliance-report-container">
                  <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        :disabled="isLoading"
                        class="compliance-report-action-button"
                        v-bind="attrs"
                        
                      >
                        <v-icon medium class="mr-2">$vuetify.icons.values.download</v-icon> Compliance Report
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click.stop="exportAsPDF()">
                        <v-list-item-icon class="mr-3">
                          <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as PDF</v-list-item-title>
                      </v-list-item>
                      <JsonExcel
                        class="btn btn-default d-flex"
                        :data="compliance.items"
                        :fields="json_fields_item"
                        worksheet="Crysp Compliance Report"
                        :name="`crysp-compliance-report-${compliance.content_name}.xls`"
                      >
                        <v-list-item link>
                          <v-list-item-icon class="mr-3">
                            <v-icon color="#83CC70">mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>

                              Export for Excel
                          </v-list-item-title>
                        </v-list-item>
                      </JsonExcel>
                    </v-list>
                  </v-menu>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        v-bind="attrs" 
                        :rotate="-90"
                        :size="32"
                        :width="5"
                        :value="calculateItemPercentage(totals.pass, totals.total)"
                        color="success"
                        class="ml-2"
                      >
                        <strong class="caption">{{ totals.pass }}</strong>
                      </v-progress-circular>
                    </template>
                    <span>Completed Items</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        v-bind="attrs" 
                        :rotate="-90"
                        :size="32"
                        :width="5"
                        :value="calculateItemPercentage(totals.warn, totals.total)"
                        color="warning"
                        class="ml-2"
                      >
                        <strong class="caption">{{ totals.warn }}</strong>
                      </v-progress-circular>
                    </template>
                    <span>Items with Warnings</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        v-bind="attrs" 
                        :rotate="-90"
                        :size="32"
                        :width="5"
                        :value="calculateItemPercentage(totals.fail, totals.total)"
                        color="error"
                        class="ml-2"
                      >
                        <strong class="caption">{{ totals.fail }}</strong>
                      </v-progress-circular>
                    </template>
                    <span>Outstanding Items</span>
                  </v-tooltip>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-row class="px-4 py-3" justify="space-between" no-gutters>

                <v-col cols="12" class="py-1 my-2 d-flex align-center">
                  <Can I="update" a="document">
                    <UploadModal
                      :autoUpload="false"
                      uploadType="organisation_generic"
                      buttonLabel="Upload Attachment"
                      modalLabel="Upload Attachment"
                      classList="upload-attachment"
                      @onUploaded="handleUploadedAttachment($event,item)"
                      flush
                    ></UploadModal>

                    <v-spacer></v-spacer>
                  </Can>
                </v-col>

                <TableFilterToolBar
                :tags="complianceTags"
                :isDisabled="isLoading"
                @updateStatuses="(newValue) => filtersMap.statuses = newValue"
                @updateTags="(newValue) => filtersMap.tags = newValue"
                @updateSearch="(newValue) => filtersMap.searchTerm = newValue"
                 />

                

              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" class="py-0">
              <ViewContentItemTable 
                  :complianceHeaders="complianceHeaders"
                  :complianceItems="filteredComplianceItems"
                  :filtersMap="filtersMap"
                  :isLoading="isLoading"
                  :contentId="item.id"
                  @manageComplianceItem="handleManageComplianceEvent"
                  @delete-item="handleItemDeleteEvent"
              />


            <template v-if="contentTreeComplianceItems.length > 0">
              <h4 class="pa-3 my-1 compliance-items-subtitle"> Nested Compliance Items</h4>
              <v-expansion-panels multiple focusable v-model="panel"  class="children-content-compliances">
                <v-expansion-panel   v-for="(contentItems, index) in parsedComplianceTreeItems" :key="index" class="my-1">
                  <v-expansion-panel-header >
                    {{  contentTreeComplianceItems[index].hierarchy }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ViewContentItemTable 
                      :complianceHeaders="complianceHeaders"
                      :complianceItems="contentItems"
                      :filterStatus="filterStatus"
                      :filtersMap="filtersMap"
                      :isLoading="isLoading"
                      :contentId="contentTreeComplianceItems[index].content_id"
                      :isChildContent="true" 
                      :filterItems="true" 
                      @manageComplianceItem="handleManageComplianceEvent"
                      @delete-item="handleItemDeleteEvent"
                  />
                    </v-expansion-panel-content>

                    </v-expansion-panel>
                    </v-expansion-panels>

            </template> 
                </v-col>
              </v-row>
            </v-card>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingComplianceItem == 'action' && !isLoadingComplianceItems">
              <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!isActioning">
                <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
                  Update Compliance Item

                  <v-btn icon @click.stop="manageComplianceItem(false,null)">
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-state-machine</v-icon>
                    Name
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  {{ toManage.name }}
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    Status
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.complianceState.name"></div>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-text-box-outline</v-icon>
                    Description
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.description"></div>
                </v-card-text>

                <v-divider></v-divider>

                <div class="px-2 pt-4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Actioned on"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    
                  ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toManage.actioned_on"
                    :show-adjacent-months="true"
                    @input="menu2 = false"
                    :max="$moment().format('YYYY-MM-DD')"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="px-2 py-4">
                  <v-select
                    :items="complianceActions"
                    v-model="toManage.action"
                    item-text="name"
                    item-value="id"
                    label="Select Action"
                    outlined
                    hide-details
                  ></v-select>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click.stop="manageComplianceItem(false,null)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click.stop="handleActionComplianceItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isActioning">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="managingComplianceItem == 'update' && !isLoadingComplianceItems">
              <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!isUpdating">
                <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
                  Manage Compliance Item
                  <v-btn icon @click.stop="manageComplianceItem(false,null)">
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-state-machine</v-icon>
                    Name
                  </span><br />
                  <v-divider class="my-2"></v-divider>

                  <v-row dense class="pt-2">
                    <!-- Compliance item name -->
                    <v-col cols="12">
                      <VTextFieldWithValidation rules="required" v-model="toManage.name" label="Name" hideDetails />
                    </v-col>
                  <!-- Compliance item frequency -->
                <v-col  cols="12" >
                  <div class="d-flex align-center">
                    <v-col cols="4">
                      <span class="mr-2">Repeat every</span>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-if="toManage.frequencyUnit !== 'None'"
                        v-model="toManage.frequencyValue"
                        label="Frequency"
                        @input="updateNextReviewDate()"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="toManage.frequencyUnit"
                        :items="reviewFrequencies"
                        item-text="name"
                        item-value="value"
                        label="Review Frequency"
                        hide-details
                        outlined
                        @change="updateNextReviewDate()"
                      ></v-select>
                    </v-col>
                  </div>
                </v-col>
                <!-- Compliance item warning -->
                <v-col cols="6" sm="6" offset-sm="6" v-if="toManage.next_review_date !== null">
                  <VNumberFieldWithValidation
                    rules="numeric"
                    v-model="toManage.warning"
                    label="Warning Threshold"
                    hideDetails
                    suffix="day(s)"
                  />
                </v-col>
              </v-row>
                  <v-row dense>
                    <v-col cols="12" class="pt-0">
                      <small>For one-time events that do not repeat, leave these values blank or set to 0.</small>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    Status
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div v-html="toManage.complianceState.name"></div>
                </v-card-text>

                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-select-group</v-icon>
                    Tags
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div   v-if="complianceTags.length > 0">
                    <VMultipleSelect 
                    :initialSelected="toManage.tags" 
                    label="Select Tags for the Item" 
                    :options="complianceTags"
                    @updateSelected="(newValue) => toManage.tags = newValue"
                    />
                  </div>
                </v-card-text>
                <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-text-box-outline</v-icon>
                    Description
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <TextEditor v-model="toManage.description"  light :label="`Description`" />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click.stop="manageComplianceItem(false,null)">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="primary" @click.stop="handleUpdateComplianceItem">
                    Update
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isUpdating">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="attachmentDeleteConfirmation && !isLoading">
              <v-card class="mx-auto" max-width="480" light v-if="!isDeletingAttachment">
                <v-card-title class="primary primary--text--contrast mb-4">
                  Confirm Delete Attachment
                </v-card-title>

                <v-card-text>
                  Are you sure you want to delete attachment <strong>{{ toDelete.name ? toDelete.name : 'New Attachment' }}</strong>? This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click.stop="attachmentDeleteConfirmation = !attachmentDeleteConfirmation, toDelete = {}">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn @click.stop="handleDeleteAttachment(toDelete)" color="error">
                    Delete
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isDeletingAttachment">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="complianceDeleteConfirmation && !isLoading">
              <v-card class="mx-auto" max-width="480" light v-if="!isDeletingCompliance">
                <v-card-title class="primary primary--text--contrast mb-4">
                  Confirm Delete Compliance Item
                </v-card-title>

                <v-card-text>
                  Are you sure you want to delete item <strong>{{ itemToDelete.name }}</strong>? This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click.stop="complianceDeleteConfirmation = !complianceDeleteConfirmation, itemToDelete = {}">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn @click.stop="handleDeleteCompliace()" color="error">
                    Delete
                  </v-btn>

                </v-card-actions>
              </v-card>

              <v-row align="center" justify="center" v-if="isDeletingCompliance">
                <v-loading-spinner></v-loading-spinner>
              </v-row>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition mode="out-in">
            <v-overlay v-if="shareContent && !isLoading">
              <v-card class="mx-auto pa-4" max-width="100%" width="640px" light>
                <v-card-title class="mb-4">
                  <v-btn
                    fab
                    depressed
                    color="primary"
                    class="mr-3">
                    <v-icon medium>mdi-tray-arrow-up</v-icon>
                  </v-btn>
                  Share this content via email
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    class="close_card"
                    @click.stop="shareContent = false"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-text-field
                    v-model="toShare.email"
                    label="Recipient"
                    outlined
                    hint="Comma separate email address' to send to multiple people"
                    persistent-hint
                    >
                  </v-text-field>
                </v-card-text>

                <v-card-actions class="d-flex justify-end px-4">
                  <v-btn @click.stop="handleShareContent(toShare)" color="primary">
                    Send
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-overlay>
          </v-fade-transition>
        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'
import * as Sentry from '@sentry/browser';

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VNumberFieldWithValidation from '@/components/inputs/VNumberFieldWithValidation'
import TextEditor from '@/components/inputs/TextEditor'

import UploadModal from '@/components/upload/UploadModal'
import ViewContentItemTable from '@/views/content/ViewContentItemTable'
import TableFilterToolBar from '@/components/compliance/TableFilterToolBar.vue'
import VMultipleSelect from '../../components/display/VMultipleSelect.vue';

import JsonExcel from "vue-json-excel";

import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

import {sortItems} from "vuetify/lib/util/helpers"


export default {
  name: 'ViewContentItem',
  components: {
    VTextFieldWithValidation,
    VNumberFieldWithValidation,
    ViewContentItemTable,
    TextEditor,
    UploadModal,
    JsonExcel,
    TableFilterToolBar,
    VMultipleSelect
  },
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  data() {
    return {
      menu2: false,
      complianceSearch: '',
      filterStatus: ["2","3","4"],
      filtersMap: {
        statuses: [],
        tags: [],
        searchTerm: ""
      },
      managingComplianceItem: false,
      managingAttachmentItem: false,
      // previewingAttachmentItem: false,
      attachmentDeleteConfirmation: false,
      complianceDeleteConfirmation: false,
      toManage: {},
      toUpdate: {},
      toDelete: {},
      // toPreview: {},
      itemToDelete: {},
      toShare: {},
      selectedAttachment: {},
      isActioning: false,
      isUpdating: false,
      isUpdatingCompliance: false,
      isUploadingAttachment: false,
      isUpdatingAttachment: false,
      isDeletingAttachment: false,
      isDeletingCompliance: false,
      shareContent: false,
      complianceActions: [
        { id: 1, name: "Mark as Compliant" },
        { id: 2, name: "Mark as Warning" },
        { id: 0, name: "Mark as Outstanding" }
      ],
      totals: {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0
      },
      panel: [],
      complianceParsed: [],
      parsedComplianceTreeItems: [],
      attachmentsParsed: [],
      attachmentsSearch: '',
      attachmentIcons: {
        'doc' : { icon:'mdi-file-word-box', color: '#6ABDF8'},
        'jpg' : { icon:'mdi-file-jpg-box', color: '#ED81FF'},
        'xls' : { icon:'mdi-file-excel-box', color: '#83CC70'},
        'pdf' : { icon:'mdi-file-pdf-box', color: '#FF7981'}
      },
      json_fields_item: {
        Name: "name",
        Status: "complianceState.name",
        Tags: "tags",
        "Date Created On": {
          field: "created_at",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("MMM DD, YYYY")
            }
          },
        },
        "Last Actioned": {
          field: "latest_action.actioned_on",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("MMM DD, YYYY")
            }
          },
        },
        "Next Due": {
          callback: (item) => {
            if (item.latest_action && item.frequency > 0) {
              return this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd').format("MMM DD, YYYY")
            }
          },
        },
        "Days Remaining": {
          callback: (item) => {
            if (item.latest_action) {
              let date = this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd')
              let today = this.$moment()
              return date.diff(today, 'days')
            }
          },
        },
      },
      reviewFrequencies: [
        'None',
        'Days',
        'Weeks',
        'Months',
        'Years'
      ],
      formattedNextReviewDate: '',
    }
  },
  computed: {
    ...mapState({
      contents: state => state.CONTENT_STORE.contents,
      content: state => state.CONTENT_STORE.content,
      contentTree: state => state.CONTENT_STORE.flatContentItemTree,
      complianceItems: state => state.CONTENT_STORE.userComplianceItems,
      complianceTags: state => state.ORG_STORE.orgComplianceTags || [],
      isLoading: state => state.CONTENT_STORE.loadingContent || state.CONTENT_STORE.loadingContentItemTree || state.CONTENT_STORE.loadingUserComplianceItems,
      isLoadingContentTree: state => state.CONTENT_STORE.loadingContentItemTree,
      isLoadingComplianceItems: state => state.CONTENT_STORE.loadingUserComplianceItems
    }),
    item: function() {
      return this.content.find(item => item.id == this.$route.params.content_id)
    },
    compliance: function() {
      return this.complianceItems.find(item => item.content_id == this.$route.params.content_id) || []
    },
    contentTreeComplianceItems() {
      let allContentItems = []

      if (this.contentTree) {
        const currentContentId = this.$route.params.content_id
        const tree = this.contentTree.find(item => item.id == currentContentId);

        if (tree?.children && tree.children.length > 0) {

          let updatedItems = this.populateChildrenContentItems(tree.children, allContentItems)
          
          allContentItems = updatedItems
        } 
        }
      return allContentItems;
    },
    complianceHeaders() {
      let headers = []

      headers.push({ text: "Name", value: "name" })
      headers.push({ text: "Status", value: "complianceState.id" })
      headers.push({ text: "Tags", value: "tags" })
      headers.push({ text: "Date Created On", value: "created_at" })
      headers.push({ text: "Last Actioned", value: "latest_action.actioned_on" })
      headers.push({ text: "Next Due", value: "latest_action.next_due" })
      headers.push({ text: "", value: "actions", sortable: false })

      return headers
    },
    filteredComplianceItems() {
      return this.filterItems(this.complianceParsed)
    },
    attachmentsHeaders() {
      let headers = []

      headers.push({ text: "Name", value: "name" })
      headers.push({ text: "Uploaded", value: "created_at" })
      headers.push({ text: "", value: "actions", sortable: false })

      return headers
    },
    formattedDate() {
      return this.$moment(this.toManage.actioned_on).format('DD/MM/YYYY');
    },
  },
  beforeMount() {
    this.getData()
    this.updateData(this.compliance)
    this.parseAttachmentData(this.item)
  },
  methods: {
    filterItems(items) {
      return items.filter((i) => {
        const matchedStatus =
          this.filtersMap.statuses.length == 0 ||
          this.filtersMap.statuses.includes(i.complianceState.id.toString());

        const matchedTags =
          this.filtersMap.tags.length == 0 ||
          this.filtersMap.tags.some((tag) =>
            i.tags.map((t) => t.id).includes(tag)
          );

        const matchedSearch = this.filtersMap.searchTerm.length == 0 || i.name.toLowerCase().includes(this.filtersMap.searchTerm.toLowerCase());

        return matchedStatus && matchedTags && matchedSearch;
      });
    },
    getData() {
      this.$store.dispatch('CONTENT_STORE/GET_CONTENT')
      this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS')
      this.$store.dispatch('CONTENT_STORE/GET_CONTENT_TREE', this.$route.params.content_id)
      this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS')
    },
    parseData(d) {
      if (d.items) {
        let items = []

        this.totals.total += d.items.length

        d.items.forEach((c, key) => {
          let item = JSON.parse(JSON.stringify(c))
          let state = item.complianceState.id

          if (state == 2) { this.totals.pass++ }
          if (state == 3) { this.totals.warn++ }
          if (state == 4) { this.totals.fail++ }

          items.push(item)
        })

        return  items

      } else {
        return []
      }
    },

    updateData(d) {
      this.resetTotals()
      this.parseComplianceData(d)
      this.parsedComplianceTreeItems = this.contentTreeComplianceItems.map((contentItems) => {
        return this.parseData(contentItems)
      })
      
    },
    parseComplianceData(d) {
      this.complianceParsed = this.parseData(d)
    },
    parseAttachmentData(d, child = false) {
      if (d) {
        if (d.attachments) {
          let items = []
          d.attachments.forEach((c, key) => {
            let item = JSON.parse(JSON.stringify(c))
            item.type = item.file.split('.').pop();

            items.push(item)
          })

          if (child) {

            this.attachmentsParsed = [...this.attachmentsParsed, ...items]
          } else {

            this.attachmentsParsed = items
          }



         const treeItem = this.contentTree.find(item => item.id == d.id)
          

         if (treeItem && treeItem?.children && treeItem?.children.length > 0) {
           treeItem.children.forEach((child) => {

            const childContent = this.contents.find(item =>{

              return item.id === child.id
            })

             if (childContent) {

              this.parseAttachmentData(childContent, true)
             }            
           })
         }
        }

        
      }
    },
    handleUploadedAttachment(data) {
      try {
        if (!data) {
          return
        }

        this.isUploadingAttachment = true

        let variables = {
          content_id: parseInt(this.$route.params.content_id),
          file: data[0].meta.path,
          name: data.name,
          description: data.description
        }

        this.$http.post('content/attachment', variables)
          .then(response => {
            if (response.data) {
              let attachment = response.data.data
              this.attachmentsParsed.push(attachment)

              this.isUploadingAttachment = false

              this.$toast.success('Successfully uploaded attachment.')
            } else {
              this.$toast.error('Something went wrong uploading attachment, please try again.')
            }
          })
          .catch(error => {
            this.isUploadingAttachment = false

            this.$toast.error('Something went wrong uploading attachment, please try again.')
          })
        } catch (error) {
          //send to sentry
          Sentry.setExtra("data", data);
          Sentry.captureException(error);
        }
    },
    handleDeleteAttachment(data) {
      if (!data) {
        return
      }

      this.isDeletingAttachment = true

      let variables = {
        id: parseInt(data.id),
      }

      this.$http.delete('content/attachment', {
        params: variables
      })
        .then(response => {
          if (response.data) {
            let attachments = this.attachmentsParsed.filter((item) => {
              return item.id != parseInt(data.id)
            })

            this.attachmentsParsed = attachments

            this.attachmentDeleteConfirmation = false
            this.isDeletingAttachment = false

            this.toDelete = {}

            this.$toast.success('Successfully deleted attachment.')
          } else {
            this.attachmentDeleteConfirmation = false
            this.isDeletingAttachment = false

            this.toDelete = {}

            this.$toast.error('Something went wrong deleting attachment, please try again.')
          }
        })
        .catch(error => {
          this.isDeletingAttachment = false

          this.$toast.error('Something went wrong deleting attachment, please try again.')

          this.toDelete = {}
        })
    },
    handleItemDeleteEvent(item) {
      this.complianceDeleteConfirmation = true;
      this.itemToDelete.id = item.id;
      this.itemToDelete.name = item.name;
    },

    handleDeleteCompliace() {
      this.isDeletingCompliance = true

      this.$http.delete('content/compliance', {
        params: {
          id: parseInt(this.itemToDelete.id)
        }
      }).then(data => {
          this.complianceDeleteConfirmation = false
          this.isDeletingCompliance = false

          // Remove from store
          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true).then(() => {
            this.updateData(this.compliance)
          })

          // Show message
          this.$toast.success(`${this.itemToDelete.name} deleted successfully.`)

          // Clear deletion
          this.itemToDelete = {}
        }, error => {
          console.error('Something has gone wrong deleting compliance item')
        })
    },
    handleActionComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isActioning = true

      let variables = {
        content_id:         parseInt(this.$route.params.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        pass_or_failed:     parseInt(this.toManage.action),
        actioned_on:        `${this.$moment(this.toManage.actioned_on).format('YYYY-MM-DD')} ${this.$moment().format('HH:mm:ss')}`,
      }

      this.$http.post('content/compliance/action', variables)
        .then(response => {
          this.isActioning = false

          this.$toast.success('Successfully actioned compliance item.')

          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true)
          this.manageComplianceItem(false,null)
        })
        .catch(error => {
          this.isActioning = false

          this.$toast.error('Something went wrong actioning compliance item, please try again.')
        })
    },
    handleUpdateComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isUpdatingCompliance = true

      let variables = {
        content_id:         parseInt(this.$route.params.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        name:               this.toManage.name,
        description:        this.toManage.description,
        warning:            parseInt(this.toManage.warning),
        frequency:          parseInt(this.toManage.frequency),
        tags:               this.toManage.tags
      }

        this.$http.post('content/compliance/update', variables)
        .then(response => {
          
          this.isUpdatingCompliance = false

          this.$toast.success('Successfully updated compliance item.')

          this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS', true)

          this.manageComplianceItem(false,null)
        })
        .catch(error => {
          this.isUpdating = false

          this.$toast.error('Something went wrong updating compliance item, please try again.')
        })
    },
    handleEditAttachmentItem() {
      if (!this.toUpdate) {
        return
      }

      this.isUpdatingAttachment = true

      let variables = {
        id:          parseInt(this.toUpdate.id),
        name:        this.toUpdate.name,
        description: this.toUpdate.description
      }

      this.$http.put('content/attachment', variables)
        .then(response => {
          this.isUpdatingAttachment = false

          if (response.data) {
            let attachment = response.data.data

            this.attachmentsParsed.find( function (item) {
              if (item.id == attachment.id) {
                Object.assign(item, attachment)
                return true
              }

              return false;
            })

            this.$toast.success('Successfully updated attachment.')
          } else {
            this.$toast.error('Something went wrong updating attachment, please try again.')
          }

          this.manageAttachmentItem(false)
        })
        .catch(error => {
          this.isUpdatingAttachment = false

          this.$toast.error('Something went wrong updating attachment, please try again.')
        })
    },
    handleShareContent() {
      this.$http.post('mail/send', {
          reseller_id: 0,
          organisation_id: 0,
          recipients: this.toShare.email,
          template: 'ShareContent',
          template_tags: JSON.stringify({
            url: window.location.origin+'/content/'+encodeURIComponent(parseInt(this.item.id))
          }),
        })
        .then(
          ({ data }) => {
            this.shareContent = false;
            this.isSubmitted = true;

            this.$toast.success('Successfully shared content.')
          },
          ({ error }) => {
            this.$toast.error('Something went wrong sharing content, please try again.')
          }
        );
    },
    resetTotals() {
      this.totals = {
        total: 0,
        pass:  0,
        warn:  0,
        fail:  0
      }
    },
    calculateItemPercentage(v, t) {
      let total = t || this.totals.total
      return v / total * 100
    },
    parseComplianceDate(date) {
      if (date) {
        return `${this.$moment(date).format("Do MMMM, YYYY")} at ${this.$moment(date).format("h:mma")}`
      }
    },
    parseNextDueDate(date,frequency) {
      if(frequency == 0) {
        return 'Not Set'
      }
      if (date) {
        return `${this.$moment(date).add(frequency, 'd').format("Do MMMM, YYYY")}`
      }
    },
    manageComplianceItem(item,method) {
      this.managingComplianceItem = method
      this.toManage.action = false
      this.toManage = item ? {...item} : {}
      this.toManage.tags = this.toManage?.tags?.map(tag => tag.id)
      this.toManage.last_review_date = this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null ? this.toManage.created_at : this.toManage.latest_action.actioned_on; 
      this.toManage.frequency = this.toManage.frequency == null ? 0 : this.toManage.frequency; 

      this.$set(this.toManage, 'next_review_date', this.$moment(this.toManage.last_review_date).add(this.toManage.frequency,'d'));

      if (this.toManage.frequency == 0) {
        this.toManage.frequencyValue = null;
        this.toManage.frequencyUnit = 'None';

        this.$set(this.toManage, 'next_review_date', null);
        this.$set(this.toManage, 'frequency', 0);
      } else {
        //if the frequency is a multiple of 365, then it is a year
        if (this.toManage.frequency % 365 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 365;
          this.toManage.frequencyUnit = 'Years';
        } else if (this.toManage.frequency % 30 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 30;
          this.toManage.frequencyUnit = 'Months';
        } else if (this.toManage.frequency % 7 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 7;
          this.toManage.frequencyUnit = 'Weeks';
        } else {
          this.toManage.frequencyValue = this.toManage.frequency;
          this.toManage.frequencyUnit = 'Days';
        }

        this.formattedNextReviewDate = this.toManage.next_review_date.format('DD/MM/YYYY');
      }

    },

    handleManageComplianceEvent({ item, method }) {
     this.manageComplianceItem(item, method)
    },
    manageAttachmentItem(item) {
      this.managingAttachmentItem = item ? true : false
      this.toUpdate.update = false
      this.toUpdate = item ? _.cloneDeep(item) : {}
      this.selectedAttachment = item ? _.cloneDeep(item) : {}
    },

    populateChildrenContentItems(children, currentContentItems, hierarchy = null) {
      children.forEach((child) => {
        
              let complianceContent = this.complianceItems.find(item => item.content_id == child.id)

              if (!complianceContent) {
                return
              }
    
              const { contentItems, currentHierarchy } = this.populateWithContentItems(currentContentItems, child.id, hierarchy)

              if (child.children && child.children.length > 0) {
                const updatedContentItems = complianceContent.children = this.populateChildrenContentItems(child.children, contentItems, currentHierarchy)
                
                currentContentItems = updatedContentItems
              } else {
                
                currentContentItems = contentItems
              }

          })

          return currentContentItems

},
   populateWithContentItems(currentContentItems, contentId, currentHierarchy = null) {

    let complianceContent = this.complianceItems.find(item => item.content_id == contentId)

    if (!complianceContent) {
      return { currentContentItems, currentHierarchy }
    }

    complianceContent = JSON.parse(JSON.stringify(complianceContent))
    
    if (!currentHierarchy) {
      currentHierarchy = complianceContent.content_name
    } else {
      currentHierarchy = `${currentHierarchy} > ${complianceContent.content_name}`
    }

    complianceContent.hierarchy = currentHierarchy
    
    currentContentItems.push(complianceContent)
    
    return {contentItems: currentContentItems, currentHierarchy}
   },
    getColor(state) {
      if (state == 2) {
        return '#4caf50'
      }

      if (state == 3) {
        return '#fb8c00'
      }

      if (state == 4) {
        return '#ff5252'
      }
    },
    exportAsPDF() {
      const doc = new jsPDF()

      doc.setFontSize(16)
      doc.text('System', 14, 22)
      doc.text(`Crysp Compliance Report - ${this.$moment().format('DD/MM/YYYY')}`, 14, 30)
      doc.setFontSize(11)
      doc.setTextColor(100)
      doc.text(this.compliance.content_name, 14, 40)

      let bodyItems = []
      this.compliance.items.forEach(item => {
        let daysRemaining = () => {
            if (item.latest_action) {
              let date = item.latest_action ? this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd') : this.$moment().add(item.frequency, 'd')
              let today = this.$moment()
              return date.diff(today, 'days')
            }
        }

        let filtered = {
          name: { content: item.name, styles: { cellWidth: 60 } },
          status: { content: '', styles: { fillColor: this.getColor(item.complianceState.id), minCellWidth: 5 } },
          created_at: this.$moment(item.created_at).format("MMM DD, YYYY"),
          actioned_at:  item.latest_action ? this.$moment(item.latest_action.actioned_on).format("DD/MM/YYYY") : 'Never',
          due_at: this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd').format("MMM DD, YYYY"),
          days_remaining: daysRemaining()
        }

        bodyItems.push(filtered);
      });
      autoTable(doc, {
        columns: [
          { dataKey: 'name', header: 'Name' },
          { dataKey: 'status', header: 'Status' },
          { dataKey: 'created_at', header: 'Date Created On' },
          { dataKey: 'actioned_at', header: 'Last Actioned' },
          { dataKey: 'due_at', header: 'Next Due' },
          { dataKey: 'days_remaining', header: 'Days Remaining' }
        ],
        body: bodyItems,
        startY: 50,
        theme: 'plain',
        headStyles: { lineWidth: 0.25 },
        bodyStyles: { lineWidth: 0.25 }
      })
      doc.save(`crysp-compliance-report-${this.compliance.content_name}.pdf`)
    },
    sortComplianceItems(items, sortBy, sortDesc, locale, customSorters) {
      if (sortBy.includes('latest_action.next_due')) {
        return items.sort((a, b) => {
          for (let i = 0; i < sortBy.length; i++) {
            let sortA = this.$moment(a.latest_action ? a.latest_action.actioned_date : a.created_at).add(a.frequency, 'd');
            let sortB = this.$moment(b.latest_action ? b.latest_action.actioned_date : b.created_at).add(b.frequency, 'd');

            if (sortDesc[i]) {
              [sortA, sortB] = [sortB, sortA];
            }

            if (sortA === null && sortB === null) {
              continue;
            }

            if (!sortA.isSame(sortB)) {
              return sortA.isAfter(sortB);
            }
          }

          return 0;
        });
      } else {
        return sortItems(items, sortBy, sortDesc, locale, customSorters);
      }
    },
    updateNextReviewDate() {
      let value = this.toManage.frequencyValue;
      const unit = this.toManage.frequencyUnit;
      const date = (this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null) ? this.toManage.created_at : this.toManage.latest_action.actioned_on;

      if(unit == 'None'){
        this.toManage.next_review_date = null;
        this.formattedNextReviewDate = null;
        this.toManage.frequencyValue = 0;
        this.toManage.frequency = 0;
        this.toManage.warning = 0;
        return;
      }

      if (value !== 0) {
        switch (unit) {
          case 'Days':
            // console.log('Days');
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'d');
            break;
          case 'Weeks':
            // console.log('Weeks');
            value = value * 7;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'w');
            break;
          case 'Months':
            // console.log('Months');
            value = value * 30;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'M');
            break;
          case 'Years':
            // console.log('Years');
            value = value * 365;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'y');
            break;
        }

        // this.toManage.next_review_date = this.$moment.utc(date).add(value,'d');
        this.toManage.frequency = value;
      } else {
        //set the value to the default value of 1
        this.toManage.frequencyValue = 1;

        this.updateNextReviewDate();
      }

      this.formattedNextReviewDate = this.$moment(this.toManage.next_review_date).format('DD/MM/YYYY');

      //update the warning threshold
      this.toManage.warning = Math.min(this.toManage.warning, this.toManage.frequency);

      //print the values for frequencyValue, unit, frequency, and next review date
      // console.log('frequencyValue: '+ this.toManage.frequencyValue);
      // console.log('unit: '+ this.toManage.frequencyUnit);
      // console.log('frequency: '+ this.toManage.frequency);
      // console.log('last review date: '+ this.$moment(this.toManage.last_review_date).format('DD/MM/YYYY'));
      // console.log('next review date: '+ this.$moment(this.toManage.next_review_date).format('DD/MM/YYYY'));
    }
  },
  watch: {
    contentTree: function(payload) {
      this.updateData(this.compliance)
    this.parseAttachmentData(this.item)
    },
    item: function(payload) {
      this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Content' , path: '/content/all/' },
        { text: ':item' }
      ])

      this.replaceBreadcrumb({
        find: ':item',
        replace: {
          text: this.item?.meta.name
        }
      })
     
      this.parseAttachmentData(payload)
    },
    compliance: function(payload) {
      this.updateData(payload)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$http.put('content/viewed', {id: parseInt(this.item?.id)})
    .then(response => {
      next()
    })
    .catch(error => {
      next(false)
    })
  }
}
</script>

<style lang="scss">

.compliance-items-subtitle {
  background-color: #FAFAFA;
  font-weight: 500;
  font-size: 1.05rem;
}

.children-content-compliances {
  overflow-y: auto ;
}


.children-content-compliances .v-expansion-panel-header {
  font-size: 0.9rem;
}
.children-content-compliances .v-expansion-panel::before {
  box-shadow: none;
}
  .v-content-body {
    h1,h2,h3,h4,h5,h6 {
      margin: 10px 0 20px;
    }
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0;
  }

  .v-btn.close_card {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .v-data-table__wrapper {
    border-bottom: 1px solid #e0e0e0;
  }

  .v-btn:not(.v-btn--round).v-size--default.compliance-report-action-button {
    padding: 0 16px 0 0;
  }

  .scrollable-content {
    max-height: 640px; /* Adjust as needed */
    overflow-y: auto;
  }

  @media only screen and (max-width: 600px) {
    .compliance-report-container {
      display: flex;
      width: 100%;
      margin-top: 8px;
    }

    .upload-attachment .v-btn:not(.v-btn--round).v-size--default {
      width: 100%;
    }

    .v-btn:not(.v-btn--round).v-size--default.compliance-report-action-button {
      margin-right: auto;
    }
  }

</style>
