<template>
  <div>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady">
        <v-list-item link v-for="(item, index) in items" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

  </div>
</template>

<script>
export default {
  name: 'FormsNav',
  data() {
    return {
      isReady: false,
      showPageLinks: false
    }
  },
  computed: {
    items() {
      let i = []

      i.push({ text: "My Details", name: "profile", icon: "mdi-account" })

      if (this.$can('update', 'user')) {
        i.push({ text: "Policies", name: "policies", icon: "mdi-file-document-outline" })
        i.push({ text: "Notifications", name: "notifications", icon: "mdi-bell-outline" })
      }

      return i
    }
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true
    }, 500);

    this.checkPageLinks(this.$router.currentRoute)
  },
  methods: {
    checkPageLinks(r) {
      if (r.meta) {
        this.showPageLinks = (r.meta.formPage === true) ? true : false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.checkPageLinks(to)
    }
  }
}
</script>
