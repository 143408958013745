<template>
  <div>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady">
        <v-list-item link v-for="(item, index) in items" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

    <v-divider></v-divider>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady && showPageLinks"> 
        <v-list-item link v-for="(item, index) in pageItems" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

  </div>
</template>

<script>
export default {
  name: 'FormsNav',
  data() {
    return {
      isReady: false,
      showPageLinks: false
    }
  },
  computed: {
    items() {
      let i = []

      i.push({ text: "All Forms", name: "form/all", icon: "mdi-clipboard-text-multiple-outline" })

      if (this.$can('create', 'form')) {
        i.push({ text: "Add New Form", name: "form/create", icon: "mdi-clipboard-plus-outline" })
      }

      return i
    },
    pageItems() {
      let i = []

      i.push({ text: "View Form", name: "form/view", icon: "mdi-clipboard-text-play-outline" })

      if (this.$can('update', 'form')) {
        i.push({ text: "Edit Form", name: "form/edit", icon: "mdi-playlist-edit" })
        i.push({ text: "Form Responses", name: "form/responses", icon: "mdi-email-multiple" })
      }

      return i
    }
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true
    }, 500);

    this.checkPageLinks(this.$router.currentRoute)
  },
  methods: {
    checkPageLinks(r) {
      if (r.meta) {
        this.showPageLinks = (r.meta.formPage === true) ? true : false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.checkPageLinks(to)
    }
  }
}
</script>
