<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card route>
      <v-card-title
        class="primary primary--text--contrast"
        style="position: relative;"
      >
        Submit Form
      </v-card-title>

      <div v-html="form.meta.introduction" class="px-5 pt-5 pb-0"></div>

      <v-divider class="my-5"></v-divider>

      <ValidationObserver
        v-slot="{ invalid, validated, passes }"
        ref="observer"
      >
        <v-form v-if="!isSubmitting" @submit.prevent="">
          <div class="pa-5">
            <div v-for="(field, i) in fields" :key="i">
              <!-- Text -->
              <ValidationProvider
                :rules="
                  `${field.config.validation.required ? 'required|' : ''}${
                    !field.config.validation.min && field.config.validation.max
                      ? 'max:' + field.config.validation.max
                      : ''
                  }${
                    field.config.validation.min && !field.config.validation.max
                      ? 'minimum:' + field.config.validation.min
                      : ''
                  }${
                    field.config.validation.min && field.config.validation.max
                      ? 'minmax:' +
                        field.config.validation.min +
                        ',' +
                        field.config.validation.max
                      : ''
                  }`
                "
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'text'"
              >
                <v-text-field
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                  :counter="field.config.validation.max"
                  :placeholder="field.config.placeholder"
                  v-model="field.field_value"
                  outlined
                ></v-text-field>
              </ValidationProvider>

              <!-- Checkbox -->

              <!-- No options, have to tick -->
              <ValidationProvider
                :rules="`${field.config.validation.required ? 'required' : ''}`"
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'checkbox' && !field.config.options.length"
              >
                <v-checkbox
                  v-model="field.field_value"
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                ></v-checkbox>
              </ValidationProvider>
              <!-- Multiple options -->
              <ValidationProvider
                :rules="`${field.config.validation.required ? 'required' : ''}`"
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'checkbox' && field.config.options.length"
              >
                <label
                  v-text="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                />
                <v-checkbox
                  v-for="(option, i) in field.config.options"
                  :key="i"
                  v-model="field.field_value"
                  :value="option.value"
                  :label="
                    `${
                      field.config.validation.required
                        ? option.name + '*'
                        : option.name
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                ></v-checkbox>
              </ValidationProvider>

              <!-- Radio -->
              <ValidationProvider
                :rules="`${field.config.validation.required ? 'required' : ''}`"
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'radio'"
              >
                <label
                  v-text="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                />
                <v-radio-group
                  v-model="field.field_value"
                  :error-messages="errors"
                  :success="valid"
                >
                  <v-radio
                    v-for="(option, i) in field.config.options"
                    :key="i"
                    :value="option.value"
                    :label="option.name"
                  ></v-radio>
                </v-radio-group>
              </ValidationProvider>

              <!-- Date -->
              <v-row v-if="field.type.input_type === 'date'">
                <v-dialog
                  :ref="`dialog${i}`"
                  v-model="modals[field.id]"
                  :return-value.sync="field.field_value"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="field.field_value"
                      :label="
                        `${
                          field.config.validation.required
                            ? field.config.label + '*'
                            : field.config.label
                        }`
                      "
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="field.field_value" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modals[field.id] = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="saveDate(i, field.field_value)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-row>

              <!-- File -->
              <v-row
                v-if="field.type.input_type === 'file'"
                :class="i < fields.length - 1 ? 'mb-4' : ''"
              >
                <v-container py-0 fluid>
                  <v-row class="py-0">
                    <v-col class="py-0">
                      <label
                        :label="
                          `${
                            field.config.validation.required
                              ? field.config.label + '*'
                              : field.config.label
                          }`
                        "
                      >
                        {{
                          `${
                            field.config.validation.required
                              ? field.config.label + "*"
                              : field.config.label
                          }`
                        }}
                      </label>
                    </v-col>
                  </v-row>
                </v-container>

                <UploadModal
                  uploadType="user_generic"
                  buttonLabel="Upload File"
                  modalLabel="Upload File Attachment"
                  @onUploaded="handleFileAttachment(field, $event)"
                ></UploadModal>
              </v-row>

              <!-- Select -->
              <ValidationProvider
                :rules="`${field.config.validation.required ? 'required' : ''}`"
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'select'"
              >
                <v-select
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                  :items="field.config.options"
                  item-text="name"
                  item-value="value"
                  v-model="field.field_value"
                  outlined
                ></v-select>
              </ValidationProvider>

              <!-- Textarea -->
              <ValidationProvider
                :rules="`${field.config.validation.required ? 'required' : ''}`"
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'textarea'"
              >
                <v-textarea
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                  :counter="field.config.validation.max"
                  :placeholder="field.config.placeholder"
                  v-model="field.field_value"
                  outlined
                ></v-textarea>
              </ValidationProvider>

              <!-- Number -->
              <ValidationProvider
                :rules="
                  `numeric|${
                    field.config.validation.required ? 'required|' : ''
                  }${
                    !field.config.validation.min && field.config.validation.max
                      ? 'maxVal:' + field.config.validation.max
                      : ''
                  }${
                    field.config.validation.min && !field.config.validation.max
                      ? 'minVal:' + field.config.validation.min
                      : ''
                  }${
                    field.config.validation.min && field.config.validation.max
                      ? 'minmaxVal:' +
                        field.config.validation.min +
                        ',' +
                        field.config.validation.max
                      : ''
                  }`
                "
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'number'"
              >
                <v-text-field
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                  v-model.number="field.field_value"
                  outlined
                ></v-text-field>
              </ValidationProvider>

              <!-- Email -->
              <ValidationProvider
                :rules="
                  `${field.config.validation.required ? 'required|' : ''}email`
                "
                :name="field.config.label"
                v-slot="{ errors, valid }"
                v-if="field.type.input_type === 'email'"
              >
                <v-text-field
                  :label="
                    `${
                      field.config.validation.required
                        ? field.config.label + '*'
                        : field.config.label
                    }`
                  "
                  :error-messages="errors"
                  :success="valid"
                  v-model="field.field_value"
                  outlined
                ></v-text-field>
              </ValidationProvider>
            </div>
          </div>

          <v-divider></v-divider>

          <v-card-actions class="pa-5">
            <v-chip outlined v-if="form.status.id === 1">
              This form is currently disabled, and is closed for submissions.
            </v-chip>

            <v-chip outlined v-if="form.status.id === 3">
              This form is currently archived, and is closed for submissions.
            </v-chip>

            <v-spacer></v-spacer>

            <v-btn
              outlined
              color="primary"
              @click="passes(handleSubmit)"
              :disabled="form.status.id !== 2"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>

      <v-row align="center" justify="center" v-if="isSubmitting">
        <v-loading-spinner></v-loading-spinner>
      </v-row>
    </v-card>

    <v-fade-transition mode="out-in">
        <v-overlay v-if="continuePath != ''">
          <v-card class="mx-auto" max-width="600" width="100vw" light>
            <v-card-text class="text-center pa-12">
              <v-icon color="primary" x-large class="mb-6">mdi-alert-circle-outline</v-icon>
              <h1 class="mb-12">Just before you go…</h1>
              <p>This form <b>has not</b> been submitted.<br> Are you sure you want to leave before submitting?</p>
            </v-card-text>

            <v-card-actions class="flex-column pa-12">
              <v-btn text color="primary" class="mb-3" :to="{path:continuePath, params: {force: true}}">
                LEAVE UN-SUBMITTED
              </v-btn>

              <v-btn color="primary" @click="continuePath = '';isEditing = true">
                GO BACK AND SUBMIT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-overlay>
      </v-fade-transition>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import UploadModal from "@/components/upload/UploadModal";

import BreadcrumbsManager from "@/_util/breadcrumbManager";

export default {
  name: "ViewForm",
  components: {
    UploadModal
  },
  props: ["form"],
  mixins: [BreadcrumbsManager],
  data() {
    return {
      isSubmitting: false,
      modals: {},
      modal: false,
      isEditing: true,
      continuePath: '',
      fields: this.form.fields
    };
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    })
  },
  

  methods: {
    saveDate(i, value) {
      this.$refs[`dialog${i}`][0].save(value)
    },
    async handleSubmit() {
      let fileInvalid = false;

      this.fields.map(field => {
        if (field.type.input_type === 'file') {
          if (field.config.validation.required && !field.field_value) {
            fileInvalid = true;
          }
        }
        return field;
      });

      if (fileInvalid) {
        this.$toast.error("Please ensure you have uploaded required files.");
        return;
      }

      this.isSubmitting = true;
      this.isEditing = false;

      const response = await this.$http.post('form/response', {
          id: parseInt(this.$route.params.form_id),
          organisation_id: this.user.organisation_id,
          reseller_id: this.user.reseller_id,
          response_body: this.fields
        })
        .then(response => {
          this.isSubmitting = false;
        })
        .catch(error => {
          this.isSubmitting = false;
          this.$toast.error("Something went wrong, please try again.");
        })
        .finally(() => {
          this.$toast.success("Form submitted successfully!");
          this.$router.push({ name: "form/all" }, () => {});
        });
    },
    async handleFileAttachment(field, data) {
      if (data) {
        const file = data[0];

        if (file) {
          let type = {
            url: file.meta.path,
            id: file.id,
            type: "file",
            original_name: file.meta.original_name
          };

          field.field_value = type;
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing && this.form.status.id != 1) {
      this.continuePath = to.path;
      this.isEditing = false;
      return;
    }
    next();
  },
  created() {
    if (this.fields) {
      this.fields.forEach(field => this.modals[field.id] = false)
    }

  },
  mounted() {
    requestAnimationFrame(() => {
      this.$refs.observer.reset();
    });
  },
  created() {
    this.setBreadcrumbs([
      { text: "Dashboard", path: "/" },
      { text: "Forms", path: "/form/all/" },
      { text: ":form" },
      { text: "View" }
    ]);

    this.replaceBreadcrumb({
      find: ":form",
      replace: {
        text: this.form.meta.name,
        path: `/form/${this.form.id}`
      }
    });
  }
};
</script>
