<template>
  <v-card height="360" class="mx-auto d-flex flex-column">
    <v-img class="white--text align-end" style="max-height: 160px; " :src="card.image" />

    <v-divider></v-divider>

    <v-card-title>
      Users & Groups
    </v-card-title>

    <v-card-subtitle>
      Manage users and groups.
    </v-card-subtitle>

    <v-spacer></v-spacer>

    <v-card-actions class="mb-2 px-4">

      <v-btn :to="{ name: 'organisation/groups', params: { org_id: user.organisation_id }}">
        Groups
      </v-btn>

      <v-btn :to="{ name: 'organisation/users', params: { org_id: user.organisation_id }}">
        Users
      </v-btn>

      <v-spacer></v-spacer>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      card: {
        image: require("@/images/cards/Crysp_Icons_Groups.png")
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    })
  }
}
</script>
