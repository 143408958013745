<template>
  <v-breadcrumbs :items="breadcrumbs" class="pl-0">
    <v-breadcrumbs-item
      slot="item"
      slot-scope="{ item }"
      exact
      :to="{ path: item.path }"
      @click="updateBreadcrumbs(item)"
    >
      {{ item.text }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'Breadcrumbs',
  mixins: [
    BreadcrumbsManager
  ],
  computed: {
    ...mapState({
      breadcrumbs: state => state.BREADCRUMBS.breadcrumbs
    })
  },
  methods: {
    updateBreadcrumbs(item) {
      let index = 0
      let newBreadcrumbs = []

     this.breadcrumbs.map((breadcrumb, i) => {
       if (breadcrumb == item) {
         index = i
       }
     });

    this.breadcrumbs.map((breadcrumb, i) => {
       if (index >= i) {
         newBreadcrumbs.push(breadcrumb)
       }
     });

    this.setBreadcrumbs(newBreadcrumbs);

    }
  }
}
</script>

<style lang="scss" scoped>
  .v-breadcrumbs {
    a {
      text-decoration: none;    
    }
  }

  .disabled {
    color: #ccc;
    pointer-events: none;
  }
</style>
