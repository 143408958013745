<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card>
      <v-card-title class="primary primary--text--contrast">Manage Useful Links</v-card-title>

      <v-form @submit.prevent="" v-if="!isUpdating && !isLoading">

        <v-row class="mt-4">

          <v-col cols="12" class="px-8 mb-4">
            <TextEditor light v-model="usefulContent.meta.content" :value="usefulContent.meta.content" />
          </v-col>

          <v-col cols="12" class="py-0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="px-8">

            <v-card-actions class="px-0">
              <v-spacer></v-spacer>

              <v-btn
                outlined
                color="primary"
                @click="handleSubmitUsefulContent"
              >
                Save Changes
              </v-btn>
            </v-card-actions>

          </v-col>

        </v-row>
      </v-form>

      <v-row align="center" justify="center" v-if="isUpdating || isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import BreadcrumbsManager from '@/_util/breadcrumbManager'
import TextEditor from '@/components/inputs/TextEditor'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: "ManageContent",
  components: {
    TextEditor
  },
  mixins: [
    BreadcrumbsManager
  ],
  props: [
    'organisation'
  ],
  computed: {
    ...mapState({
      usefulContentItem: state => state.CONTENT_STORE.usefulContentItem,
      isLoading: state => state.CONTENT_STORE.loadingContent
    }),
  },
  data() {
    return {
      isUpdating: false,
      usefulContent: ''
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('CONTENT_STORE/GET_USEFUL_CONTENT',parseInt(this.$route.params.org_id))
    },
    async handleSubmitUsefulContent() {
      this.isUpdating = true

      let method = this.usefulContentItem.meta ? 'put' : 'post'

      let variables = {
        content_type: 3,
        name: 'useful_content',
        content_body: this.usefulContent.meta.content,
        visibility: 0,
        status: 1
      }

      if(this.usefulContentItem.meta) {
        variables.id = this.usefulContentItem.id
      } else {
        variables.organisation_id = parseInt(this.$route.params.org_id)
      }

      const response = await this.$http[method]('content', variables)
        .then((response) => {
          return response.data
        },
        ({ error }) => {
          this.$toast.error('Something went wrong creating useful links, please try again.')
        })

      this.isUpdating = false

      this.$store.commit('CONTENT_STORE/SET_USEFUL_CONTENT', response.data)

      if(method == 'post') {
        this.$toast.success('Successfully created useful links.')
      } else {
        this.$toast.success('Successfully updated useful links.')
      }
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Useful Links' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: {
        text: this.organisation.name,
        path: `/organisation/${this.organisation.id}`
      }
    })
  },
  watch: {
    usefulContentItem (payload) {
      this.usefulContent = _.cloneDeep(payload)

      if(!this.usefulContent.meta ) {
        this.usefulContent.meta = {
          content: ''
        }
      }
    }
  }
}
</script>
