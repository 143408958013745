<template>
  <v-card class="mt-8">
    <v-card-title class="primary primary--text--contrast">Security</v-card-title>

    <ValidationObserver v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="" v-if="!isLoading">

        <v-row class="mt-4">

          <v-col cols="12" class="px-8">

            <v-row>

              <v-col cols="12">
                <ValidationProvider name="currentPassword" rules="required"  v-slot="{ errors, valid }">
                  <v-text-field
                    outlined
                    :error-messages="errors"
                    :success="valid"
                    label="Current Password"
                    :append-icon="userPasswordVisibility.password_current_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="userPasswordVisibility.password_current_show ? 'text' : 'password'"
                    @click:append="userPasswordVisibility.password_current_show = !userPasswordVisibility.password_current_show"
                    v-model="current"
                  ></v-text-field>
                </ValidationProvider>

                <v-divider class="mb-8"></v-divider>

                <ValidationProvider name="password" rules="required|min:8"  v-slot="{ errors, valid }">
                  <v-text-field
                    counter
                    :error-messages="errors"
                    :success="valid"
                    label="New Password"
                    :append-icon="userPasswordVisibility.password_new_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="userPasswordVisibility.password_new_show ? 'text' : 'password'"
                    @click:append="userPasswordVisibility.password_new_show = !userPasswordVisibility.password_new_show"
                    v-model="password"
                    outlined
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider name="confirmation" rules="required|password:@password" v-slot="{ errors, valid }">
                  <v-text-field
                    :error-messages="errors"
                    :success="valid"
                    label="Confirm New Password"
                    :append-icon="userPasswordVisibility.password_new_confirm_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="userPasswordVisibility.password_new_confirm_show ? 'text' : 'password'"
                    @click:append="userPasswordVisibility.password_new_confirm_show = !userPasswordVisibility.password_new_confirm_show"
                    v-model="confirmation"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

            </v-row>

          </v-col>

          <v-col cols="12" class="py-0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="px-8">

            <v-card-actions class="px-0">
              <v-spacer></v-spacer>

              <v-btn
                outlined
                type="submit"
                color="primary"
                @click="passes(handleSubmit)"
              >
                Update Password
              </v-btn>
            </v-card-actions>

          </v-col>

        </v-row>

      </v-form>
    </ValidationObserver>

    <v-row align="center" justify="center" v-if="isLoading">
      <v-loading-spinner></v-loading-spinner>
    </v-row>

  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UpdatePassword',
  data() {
    return {
      userPasswordVisibility: {
        password_current_show: false,
        password_new_show: false,
        password_new_confirm_show: false
      },
      current: null,
      password: null,
      confirmation: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.AUTH_STORE.updatingUser
    })
  },
  methods: {
    async handleSubmit() {
      this.$store.commit('AUTH_STORE/UPDATING_USER')

      this.$http.put('user/password', {
          password_current: this.current,
          password_new: this.password,
          password_new_confirm: this.confirmation
        })
        .then(response => {
          this.$toast.success('Password successfully updated.')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$toast.error(error.response.data.message)
          } else {
            this.$toast.error('Something went wrong updating your password, please try again.')
            // console.error('Something has gone wrong updating password', error)
          }
        }).finally(() => {
          this.$store.commit('AUTH_STORE/UPDATING_USER_STOP')
          this.current = null
          this.password = null
          this.confirmation = null
        })
    }
  }
}
</script>
