
import axios from '../axios'

const initialState = () => {
  return {
    loadingContentItem: false,
    loadingContentItemTree: false,
    loadingContent: false,
    loadingUserComplianceItems: false,
    loadingUserComplianceCalendarItems: false,
    usefulContentItem: {},
    usefulContentItems: [],
    contentItem: {},
    contentItemTree: [],
    flatContentItemTree: [],
    content: [],
    contents: [],
    compliancesStats: {},
    loadingComplianceStats: false,
    userComplianceItems: [],
    userComplianceCalendarItems: [],
    cache: {
      content: {
        lifetime: 30000,
        microtime: 0
      }
    }
  }
}

const state = initialState()

const getters = {}

const mutations = {
  CREATE_CONTENT: (state, item) => {
    state.content.push(item)
  },

  LOADING_CONTENT_ITEM: state => state.loadingContentItem = true,

  LOADING_CONTENT_ITEM_TREE: state => state.loadingContentItemTree = true,

  LOADING_CONTENT: state => state.loadingContent = true,
  
  LOADING_USER_COMPLIANCE_ITEMS: state => state.loadingUserComplianceItems = true,
  LOADING_USER_COMPLIANCE_STATS: state => state.loadingComplianceStats = true,

  LOADING_USER_COMPLIANCE_CALENDAR_ITEMS: state => state.loadingUserComplianceItems = true,

  DELETE_CONTENT: (state, id) => {
    const filtered = state.content.filter(item => item.id !== id)
    state.content = filtered
  },

  DELETE_COMPLIANCE_ITEM: (state, id) => {
    const filtered = state.userComplianceItems.filter(item => item.id !== id)
    state.userComplianceItems = filtered
  },

  SET_CONTENT_ITEM: (state, content) => {
    state.loadingContentItem = false
    state.contentItem = content
  },

  SET_CONTENT_ITEM_TREE: (state, data) => {
    let map = new Map(data.map(item => [item.id, item]));
    let tree = []

    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      item.children = JSON.parse(JSON.stringify([]))

      if (typeof item.parent_id !== 'undefined') {
        let parent = map.get(item.parent_id);

        if (parent) {
          parent.children.push(item);
        }
      } else {
        tree.push(item);
      }
    }

    state.contentItemTree = tree
    state.flatContentItemTree = data
    state.loadingContentItemTree = false
  },

  SET_CONTENT: (state, content) => {
    state.content = content
    state.loadingContent = false
  },

  SET_CONTENTS: (state, contents) => {
    state.contents = contents

  },

  UPDATE_CONTENT: (state, updated) => {
    const index = state.content.findIndex(item => item.id === updated.id)
    state.content[index] = updated
  },
  
  SET_USER_COMPLIANCE_ITEMS: (state, items) => {
    state.userComplianceItems = items
    state.loadingUserComplianceItems = false
  },

  SET_USER_COMPLIANCE_CALENDAR_ITEMS: (state, items) => {
    state.loadingUserComplianceCalendarItems = false
    state.userComplianceCalendarItems = items
  },

  SET_USEFUL_CONTENT: (state,useful) => {
    state.usefulContentItem = useful
    state.loadingContent = false
  },

  SET_COMPLIANCES_STATS: (state, stats) => {
    state.compliancesStats = stats
    state.loadingComplianceStats = false
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  GET_CONTENT({ commit, dispatch, rootState }, options) {
    commit('LOADING_CONTENT')

    let cacheStatus = (Date.now() - state.cache.content.microtime) < state.cache.content.lifetime && state.cache.content.microtime > 0
      ? true
      : false

    let variables = {};

    if (typeof options !== "undefined") {

      let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

      if (sortBy == 'unix') {
        sortBy = 'updated_at';
      }

      if (sortBy == 'profile.organisation_type.name') {
        sortBy = 'type.name';
      }

      if (sortBy == 'profile.industry_type.name') {
        sortBy = 'industry.name';
      }

      variables = {
        offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
        limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
        sortBy: sortBy,
        sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
        search: options.search
      }
    }


    let allContents = true;

    if(typeof options != 'undefined' && options.id) {
      variables['id'] = options.id
      allContents = false;
    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
    }

    axios.get('content', {
      params: variables
    }).then(({ data }) => {
      commit('SET_CONTENT', data.data)

      if (allContents) {
        commit('SET_CONTENTS', data.data)
      }

    }, error => {
      console.error('Something has gone wrong getting content.', error)
    })
  },

  GET_USEFUL_CONTENT({ commit, dispatch, rootState }, org_id) {
    commit('LOADING_CONTENT')

    let cacheStatus = (Date.now() - state.cache.content.microtime) < state.cache.content.lifetime && state.cache.content.microtime > 0
      ? true
      : false

    let variables = {}

    let organisation_id = rootState.AUTH_STORE.authUser.organisation_id

    if(org_id) {
      organisation_id = org_id
    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = organisation_id
      variables['content_type'] = 3
    }

    axios.get('content', {
      params: variables
    }).then(({ data }) => {
      let content = {};

      if(typeof data.data !== 'undefined' && data.data.length) {
        content = data.data[0]
      }

      commit('SET_USEFUL_CONTENT', content)
    }, error => {
      console.error('Something has gone wrong getting content.', error)
    })
  },

  GET_CONTENT_TREE({ commit, dispatch, rootState }, content_id) {
    commit('LOADING_CONTENT_ITEM_TREE')

    let variables = {
      id: parseInt(content_id)
    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
    }

    axios.get('content/tree', {
      params: variables
    }).then(({ data }) => {
      commit('SET_CONTENT_ITEM_TREE', data.data)
    }, error => {
      console.error('Something has gone wrong getting content tree.', error)
    })
  },
  
  GET_USER_COMPLIANCE_ITEMS({ commit, dispatch, rootState }, lazy) {
    if (!lazy) {
      commit('LOADING_USER_COMPLIANCE_ITEMS')
    }

    let variables = {}

    axios.get('content/compliance/userActions', {
      params: variables
    }).then(({ data }) => {
      commit('SET_USER_COMPLIANCE_ITEMS', data.data)
    }, error => {
      console.error('Something has gone wrong getting user compliance items.', error)
    })
  },

  GET_USER_COMPLIANCE_CALENDAR_ITEMS({ commit, dispatch, rootState }, dates) {
    let variables = dates ? {
      start_date: dates[0],
      end_date: dates[1]
    } : {}

    axios.get('content/compliance/calendar', {
      params: variables
    }).then(({ data }) => {
      commit('SET_USER_COMPLIANCE_CALENDAR_ITEMS', data.data)
    }, error => {
      console.error('Something has gone wrong getting user compliance calendar items.', error)
    })
  },
  GET_COMPLIANCES_STATS({ commit, dispatch, rootState }, tags = null) {
    commit('LOADING_USER_COMPLIANCE_STATS')
    let variables = {}
    if (tags) {
      variables = tags
    }
    axios.get('/content/compliance/stats', {params: variables}).then(({ data }) => {
      commit('SET_COMPLIANCES_STATS', data)
    }, error => {
      console.error('Something has gone wrong getting user compliance items statistics.', error)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
