<template>
  <div>

    <div v-if="$vuetify.breakpoint.mdAndUp">
      <h1>{{ value }}</h1>
      <v-spacer></v-spacer>
      <Breadcrumbs />
    </div>

    <div v-if="!$vuetify.breakpoint.mdAndUp">
      <v-card flat class="elevation-2">
        <v-card-title>{{ value }}</v-card-title>
        <v-divider></v-divider>
        <div class="px-4">
          <Breadcrumbs />
        </div>
      </v-card>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs"

export default {
  name: 'PageHeader',
  components: {
    Breadcrumbs
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
