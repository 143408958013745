<template>
  <v-container fluid fill-height style="background-color: #1e1e1e">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="mx-auto px-12 py-6" width="400px" max-width="100%" light shape>
          <div :style="{'background-image': `url(${images.logo})`}" class="card-logo">Crysp</div>
          
          <div>

            <p class="title text-center">
              404 Not Found
            </p>

            <p class="body-2 text-center">
              The page you are looking for couldn't be found.
            </p>

            <v-btn
              class="mt-4"
              color="primary"
              depressed
              width="100%"
              :to="{ name: 'dashboard' }"
            >
              Return to Dashboard
            </v-btn>

          </div>
          
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      images: {
        logo: require("@/images/logo-crysp-dark.png")
      }
    }
  }
}
</script>
