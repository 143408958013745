<template>
  <v-container
    fluid
    v-bind:class="{ 'pa-0': flush }"
  >
    <v-btn
      outlined
      color="primary"
      @click.stop="openModal()"
      :disabled="disabled"
      :class="this.classList"
    >
      <v-icon left v-if="buttonIcon">{{ buttonIcon }}</v-icon>
      {{ this.buttonLabel }}
    </v-btn>

    <v-dialog
      v-model="uploadModal"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary primary--text--contrast text-center py-3">
          {{ this.modalLabel }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.stop="closeModal()"
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="modalDescription" class="text-center pb-0 mt-4">
          {{ this.modalDescription }}
        </v-card-text>

        <v-layout v-if="isUploading" style="overflow: hidden;">
          <v-row align="center" justify="center">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-layout>

        <v-row class="px-4 pt-4">
          <v-col cols="12">
            <v-text-field
            v-model="toUpdate.name"
            label="Attachment Name"
            hide-details
            outlined
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
            v-model="toUpdate.description"
            label="Attachment Description"
            hide-details
            outlined
            >
            </v-text-field>
          </v-col>
        </v-row>

        <div class="pa-4" v-if="!isUploading">
          <div
            :class="['file-input-container', isDragging ? 'is-dragging' : '']"
          >
            <div
              :class="['file-input-overlay', isDragging ? 'is-dragging' : '']"
            >
              Drop Files
            </div>
            <div class="file-input-wrapper" @drop="handleFileDrop">
              <input v-if="uploadModal" type="file" ref="uploadFileInput" name="uploadFileInput" @change="handleFileInput"/>
            </div>
            <div class="file-input-label">Drag & Drop Files or Click to Browse (Maximum 10 MB)</div>
          </div>

          <v-list
            dense
            v-if="uploadFiles.length && !autoUpload"
          >
            <template v-for="(uploadFile, i) in uploadFiles">
              <v-list-item
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="uploadFile.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn
                    icon
                    small
                    @click="handleFileRemove(i)"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider
                v-if="i + 1 < uploadFiles.length"
                :key="i"
              ></v-divider>
            </template>
          </v-list>
        </div>

        <v-divider v-if="!autoUpload"></v-divider>

        <v-card-actions v-if="!autoUpload" class="pa-4">
          <v-btn text color="primary" @click.stop="closeModal()">Cancel</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            outlined
            color="primary"
            :disabled="!uploadFiles.length || isUploading"
            @click="handleFileUpload()"
          >
            Upload
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { parse } from 'querystring'
import * as Sentry from '@sentry/browser';

export default {
  name: 'UploadModal',
  components: {

  },
  props: {
    reseller_id: {
      default: false
    },
    organisation_id: {
      default: false
    },
    uploadType: {
      type: String,
      default: 'user_generic'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    closeOnUpload: {
      type: Boolean,
      default: true
    },
    buttonLabel: {
      type: String,
      default: 'Upload'
    },
    buttonIcon: {
      type: String,
      default: 'mdi-upload'
    },
    modalLabel: {
      type: String,
      default: 'Upload'
    },
    modalDescription: {
      type: String,
      default: ''
    },
    flush: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classList: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      toUpdate: {
        name: '',
        description: ''
      },
      isUploading: false,
      isDragging: false,
      lastTarget: null,
      uploadModal: false,
      uploadFiles: [],
      allowMultiple: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    })
  },
  mounted() {},
  beforeDestroy() {
    this.handleReset()
  },
  watch: {
    uploadModal (v) {
      !v && this.handleReset()
    }
  },
  methods: {
    closeModal () {
      this.handleReset()
      this.uploadModal = false
    },
    openModal () {
      this.createListeners()
      this.uploadModal = true
    },
    createListeners() {
      window.addEventListener('dragenter', this.onDragEnter)
      window.addEventListener('dragleave', this.onDragLeave)
      window.addEventListener('dragover', this.onDragOver)
      window.addEventListener('drop', this.onDrop)
    },
    destroyListeners() {
      window.removeEventListener('dragenter', this.onDragEnter)
      window.removeEventListener('dragleave', this.onDragLeave)
      window.removeEventListener('dragover', this.onDragOver)
      window.removeEventListener('drop', this.onDrop)
    },
    onDragEnter(e) {
      this.lastTarget = e.target
      this.isDragging = true
    },
    onDragLeave(e) {
      if (e.target === this.lastTarget) {
        this.isDragging = false
      }
    },

    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();
      this.isDragging = false;
      this.handleFileDrop(e)
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files;

      if(!droppedFiles) {
        return
      }

      if (!this.allowMultiple) {
        this.uploadFiles = []
      }

      ([...droppedFiles]).forEach(f => {
        this.uploadFiles.push(f)
      })

      if (this.autoUpload) {
        this.handleFileUpload()
      }
    },
    handleFileInput(e) {
      let files = e.target.files

      if(!files) {
        return
      }

      if (!this.allowMultiple) {
        this.uploadFiles = []
      }

      ([...files]).forEach(f => {
        this.uploadFiles.push(f)
      })

      if (this.autoUpload) {
        this.handleFileUpload()
      }
    },
    handleFileRemove(i){
      this.uploadFiles.splice(i, 1)
    },
    getFileToUpload() {
      return this.allowMultiple ? this.uploadFiles : this.uploadFiles[0]
    },
    async uploadTest({ target }) {

    },
    handleFileUpload() {
      const file = this.getFileToUpload()

      if (file) {
        this.isUploading = true

        const owner_id = this.user.id
        const reseller_id = (this.reseller_id !== false) ? this.reseller_id : this.user.reseller_id
        const organisation_id = (this.organisation_id !== false) ? this.organisation_id : this.user.organisation_id

        const data = new FormData();

        data.append('file', file);

        let media_type, entity_type;

        [entity_type, media_type] = this.uploadType.split('_')

        data.append('media_type_id', media_type)
        data.append('entity_type_id', entity_type)
        data.append('reseller_id', parseInt(reseller_id))
        data.append('organisation_id', parseInt(organisation_id))

        // console.log(data);

        try {
          this.$http.post('file', data)
            .then(response => {
              this.isUploading = false

              if (response.data.data) {
                if (this.toUpdate.name == '') {
                  this.toUpdate.name = response.data.data[0].meta.original_name;
                }
                this.handlePassback({...response.data.data, ...this.toUpdate})
              } else {
                this.$toast.error('There was a problem completing the request, please try again.')
                this.handleReset()
              }
            })
        } catch (error) {
          Sentry.setExtra('data', data);
          Sentry.captureException(error);
        }

        
      }
    },
    handlePassback(d) {
      console.log(d);
      this.$emit('onUploaded', d)
      this.handleReset()

      if (this.closeOnUpload) {
        this.closeModal()
      }
    },
    handleReset() {
      this.isUploading = false
      this.isDragging = false
      this.lastTarget = null
      this.uploadFiles = []
      this.toUpdate = {
        name: '',
        description: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-input-container {
  position: relative;

  border: 2px dashed #ddd;
  border-radius: 2px;

  width: 100%;
  height: 100%;

  transition: {
    property: opacity;
    duration: 0.2s;
  }

  &.is-dragging {
    border-color: #31cbb9;
  }
}
.file-input-overlay {
  position: fixed;
  display: flex;

  align-items: center;
  justify-content: center;

  visibility: hidden;

  z-index: 10000;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;

  transition: {
    property: visibility, opacity;
    duration: 0s, 0.2s;
    delay: 0.2s, 0s;
  };

  font: {
    size: 3vw;
    weight: bold;
  }

  color: #fff;

  pointer-events: none;

  &::before,
  &::after {
    position: fixed;
    display: block;
    content: '';
  }

  &::before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;

    background-color: rgba(0,0,0,0.66);
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 12px;

    border: 6px dashed #31cbb9;
  }

  &.is-dragging {
    visibility: visible;

    opacity: 1;

    pointer-events: all;

    transition: {
      delay: 0s, 0s;
    }
  }
}
.file-input-wrapper {
    position: relative;
    overflow: hidden;

    height: 100%;
    min-height: 128px;

    margin: 6px;

    text-align: center;

    overflow: hidden;

    background-color: #CCC;
    border-radius: 2px;

    input {
      position: absolute;

      top: 0;
      right: 0;

      width: 100%;
      height: 100%;

      cursor: pointer;

      opacity: 0;

      font-size: 300px;
    }
}
.file-input-label {
  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  color: #282828;
  font-size: 90%;
}
</style>
