<template>
  <v-avatar
    v-if="profile"
    :color="profile.avatar ? '' : color"
    :size="size"
    class="v-user-avatar text-center"
    left
  >
      <img
        v-if="profile.avatar"
        :src="avatar"
      />
      <span 
        v-if="!profile.avatar"
        :class="['primary--text--contrast', getFontSizeClass()]"
      >{{ `${profile.first_name.charAt(0).toUpperCase()}${profile.last_name.charAt(0).toUpperCase()}` }}</span>
  </v-avatar>
</template>

<script>
import StorageManager from '@/_util/storage'
// import UserUtils from '@/_util/user'
import { isValidJSON } from '@/_util/json'
import axios from '../../_store/axios'

export default {
  name: 'UserAvatar',
  mixins: [
    StorageManager,
    // UserUtils
  ],
  props: {
    profile: {
      type: Object,
      default: null
    },
    size: {
      type: Number,
      default: 48
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      avatar: undefined
    }
  },
  mounted() {
    if(this.profile.avatar){
      this.setAvatar();
    }
  },
  methods: {
    setAvatar(){
      //put whole function inline here
      let token = localStorage.getItem('token');

      if(isValidJSON(this.profile.avatar)) {
        let avatar = JSON.parse(this.profile.avatar);
        let path = this.getStorageFile(avatar.url);

        try {
          axios.get(path, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            responseType: 'blob'
          }).then(response => {
            const imageUrl = URL.createObjectURL(response.data);
            this.avatar = imageUrl;
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    getFontSizeClass() {
      if (this.size >= 128) {
        return 'display-2'
      }
      if (this.size >= 96) {
        return 'display-1'
      }
      if (this.size > 48) {
        return 'headline'
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .v-user-avatar img,
  .v-user-avatar svg {
    object-fit: cover;
  }
</style>