import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.defaults.withCredentials = true

const token = localStorage.getItem(process.env.VUE_APP_LOCAL_AUTH_KEY)
if (token) {
  axios.defaults.headers.common = {
    'Authorization': `Bearer ${token}`
  }
}

export default axios
