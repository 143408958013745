<template>
  <v-content>
    <div
      class="header-compliance-external"
      :style="{ 'background-image': `url(${header})` }"
    >
      <h1><img :src="logo" alt=""></h1>
    </div>

    <v-container fluid
      >s
      <v-row justify="center">
        <v-col cols="12" xs="12" md="9" xl="6" v-if="!isSubmitted || isPreview">
          <v-row justify="space-between" v-if="!isUpdating && !isLoading">
            <v-col cols="12">
              <ValidationObserver v-slot="{ invalid, validated, passes }">
                <v-form @submit.prevent="">
                  <v-tabs
                    id="testerton"
                    color="#363636"
                    v-model="activeTab"
                    centered
                  >
                    <v-tabs-slider-wrapper
                      color="transparent"
                    ></v-tabs-slider-wrapper>
                    <v-tab>
                      <v-icon left>mdi-account</v-icon>
                      1. Personal
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-office-building</v-icon>
                      2. Core business
                    </v-tab>
                    <v-tab>
                      <v-icon left>mdi-clipboard</v-icon>
                      3. Regulatory compliance
                    </v-tab>

                    <!--Personal-->
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title

                              class="bg-blue primary--text--contrast"
                              >Your details
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-text-field
                                    label="Employer or Client name*"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="fields.yourdetails.name"
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  >
                                    ></v-text-field
                                  >
                                </ValidationProvider>

                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-text-field
                                    label="Address line 01*"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="fields.yourdetails.line_1"
                                    outlined
                                    required

                                    :readonly="isPreview"
                                  ></v-text-field>
                                </ValidationProvider>

                                <ValidationProvider

                                  v-slot="{ errors, valid }"
                                >
                                  <v-text-field
                                    label="Address line 02*"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="fields.yourdetails.line_2"
                                    outlined

                                    :readonly="isPreview"
                                  ></v-text-field>
                                </ValidationProvider>


                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors, valid }"
                                    >
                                      <v-text-field
                                        label="City*"
                                        :error-messages="errors"
                                        :success="valid"
                                        v-model="fields.yourdetails.city"
                                        outlined
                                        required
                                        :readonly="isPreview"
                                         class="half-input"
                                      ></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors, valid }"
                                    >
                                      <v-text-field
                                        label="Postcode*"
                                        :error-messages="errors"
                                        :success="valid"
                                        v-model="fields.yourdetails.postcode"
                                        outlined
                                        required
                                        class="half-input"
                                        :readonly="isPreview"
                                      ></v-text-field>
                                    </ValidationProvider>


                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >Questionnaire details
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg" >
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-text-field
                                    label="Person(s) Consulted"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.questionnairedetails
                                        .persons_consulted
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-text-field>
                                </ValidationProvider>

                                <!-- Assessor -->

                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-text-field
                                    label="Assessor"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.questionnairedetails.assessor
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-text-field>
                                </ValidationProvider>

                                <!-- Date of analysis -->

                                    <v-dialog
                                      ref="dialog"
                                      v-model="date"
                                      :return-value.sync="
                                        fields.questionnairedetails
                                          .date_of_analysis
                                      "
                                      persistent
                                      width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="fields.date_of_analysis"
                                          label="Date of Analysis"
                                          append-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          required
                                          v-bind="attrs"
                                          v-on="on"
                                          class="half-input"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="fields.date_of_analysis"
                                        scrollable
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="
                                            fields.date_of_analysis = false
                                          "
                                          >Cancel</v-btn
                                        >
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="
                                            $refs.dialog.save(
                                              fields.date_of_analysis
                                            )
                                          "
                                          >OK</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>

                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- core business -->
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              ><p style="word-break: break-word;">
                                1. Have you appointed a competent person or
                                people to help you meet your health and safety
                                legal duties?
                                <!-- <v-tooltip class="" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >
                                  </template>
                                  <span style="max-width:300px;">
                                    <p>
                                      As an employer, you must appoint a
                                      competent person or people to help you
                                      meet your health and safety legal duties.
                                      What a competent person does - They should
                                      have the skills, knowledge and experience
                                      to be able to recognise hazards in your
                                      business and help you put sensible
                                      controls in place to protect workers and
                                      others from harm. Qualifications and
                                      training - It’s not usually essential for
                                      them to have formal qualifications and
                                      they’re not required by law to have formal
                                      training, although it can help <ul><li> Who you
                                      can appoint - You could appoint (one or a
                                      combination of):</li> <li>  yourself </li><li>  one or
                                      more of your workers </li></ul>  someone from
                                      outside your business Usually, managing
                                      health and safety isn’t complicated and
                                      you can do it yourself with the help of
                                      your workers. You know your workplace best
                                      and the risks associated with it. If
                                      there’s a competent person within your
                                      workforce, use them rather than a
                                      competent person from outside your
                                      business. Using a consultant or adviser -
                                      If your business or organisation doesn’t
                                      have the competence to manage health and
                                      safety in-house, for example, if it’s
                                      large, complex or high risk, you can
                                      get help from a consultant or adviser. But
                                      remember, as the employer, managing health
                                      and safety will still be your legal duty.
                                    </p></span
                                  >
                                </v-tooltip> -->



         <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="dialog = !dialog"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

      <v-dialog v-model="dialog" persistent content-class="dialogControl">
        <v-card>
          <v-card-title class="headline">Guidance</v-card-title>
          <v-card-text><p>
                                      As an employer, you must appoint a
                                      competent person or people to help you
                                      meet your health and safety legal duties.
                                      What a competent person does - They should
                                      have the skills, knowledge and experience
                                      to be able to recognise hazards in your
                                      business and help you put sensible
                                      controls in place to protect workers and
                                      others from harm. Qualifications and
                                      training - It’s not usually essential for
                                      them to have formal qualifications and
                                      they’re not required by law to have formal
                                      training, although it can help <ul><li> Who you
                                      can appoint - You could appoint (one or a
                                      combination of):</li> <li>  yourself </li><li>  one or
                                      more of your workers </li></ul>  someone from
                                      outside your business Usually, managing
                                      health and safety isn’t complicated and
                                      you can do it yourself with the help of
                                      your workers. You know your workplace best
                                      and the risks associated with it. If
                                      there’s a competent person within your
                                      workforce, use them rather than a
                                      competent person from outside your
                                      business. Using a consultant or adviser -
                                      If your business or organisation doesn’t
                                      have the competence to manage health and
                                      safety in-house, for example, if it’s
                                      large, complex or high risk, you can
                                      get help from a consultant or adviser. But
                                      remember, as the employer, managing health
                                      and safety will still be your legal duty.
                                    </p></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question1"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question1comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >2. Do you have five or more employees?
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-7">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question2"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <div
                        v-if="fields.corebusiness.question2 == 0"
                        class="message-show"
                      >
                        <p>
                          You are not legally required to document anything, but
                          it is useful to do so. Please disregard question 3 and
                          proceed to question 4
                        </p>
                      </div>

                      <div
                        v-if="fields.corebusiness.question2 == 1"
                        class="message-show"
                      >
                        <p>
                          You must document your policy and procedures, please
                          answer question 3
                        </p>
                      </div>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >
                              <p style="word-break: break-word;">3. Do you have a policy for managing health and
                              safety and communicated this to all your
                              employees?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span style="max-width:300px;"><p>A health and safety policy sets out your general approach to health and safety. It explains how you, as an employer, will manage health and safety in your business. It should clearly say who does what, when and how.
</p><p>You must share the policy, and any changes to it, with your employees.</p></span>
                              </v-tooltip> -->
                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion3 = !coreQuestion3"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

      <v-dialog v-model="coreQuestion3" persistent content-class="dialogControl">
        <v-card>
          <v-card-title class="headline">Guidance</v-card-title>
          <v-card-text><p>A health and safety policy sets out your general approach to health and safety. It explains how you, as an employer, will manage health and safety in your business. It should clearly say who does what, when and how.
</p><p>You must share the policy, and any changes to it, with your employees.</p></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="coreQuestion3 = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div
                                class="pa-2 pt-8 pb-7 pl-0 neg-marg"
                                v-if="fields.corebusiness.question2 !== 1"
                              >
                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question3"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"

                                    :readonly="isPreview"

                                  ></v-select>
                                </ValidationProvider>
                              </div>

                              <div
                                class="pa-2 pt-8 pb-7 pl-0 neg-marg"
                                v-if="fields.corebusiness.question2 === 1"
                              >
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question3"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                       class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question3comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >4. Have you prepared Workplace Risk Assessments?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>As an employer, you’re required by law to protect your employees, and others, from harm.</p><p>Under the Management of Health and Safety at Work Regulations 1999, the minimum you must do is:<ul><li> identify what could cause injury or illness in your business (hazards) </li> <li>decide how likely it is that someone could be harmed and how seriously (the risk)</li><li>take action to eliminate the hazard, or if this isn’t possible, control the risk</li></ul></p></span>
                              </v-tooltip> -->
                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion4 = !coreQuestion4"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

      <v-dialog v-model="coreQuestion4" persistent content-class="dialogControl">
        <v-card>
          <v-card-title class="headline">Guidance</v-card-title>
          <v-card-text><p>As an employer, you’re required by law to protect your employees, and others, from harm.</p><p>Under the Management of Health and Safety at Work Regulations 1999, the minimum you must do is:<ul><li> identify what could cause injury or illness in your business (hazards) </li> <li>decide how likely it is that someone could be harmed and how seriously (the risk)</li><li>take action to eliminate the hazard, or if this isn’t possible, control the risk</li></ul></p></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="coreQuestion4 = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question4"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                       class="pb-7"
                                    :readonly="isPreview"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question4comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >5. Do you consult with your workforce on Health &
                              Safety?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>You must consult all your employees on health and safety. You can do this by listening and talking to them about: <ul><li>health and safety and the work they do</li><li>how risks are controlled</li><li>the best ways of providing information and training</li></ul></p><p>Consultation is a two-way process, allowing employees to raise concerns and influence decisions on managing health and safety.</p><p>
Your employees are often the best people to understand risks in the workplace. Involving them in making decisions shows that you take their health and safety seriously. In a small business, you might choose to consult your workers directly. Larger businesses may consult through a health and safety representative, chosen by your employees or selected by a trade union. As an employer, you cannot decide who the representative will be.</p></span>
                              </v-tooltip> -->
                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion5 = !coreQuestion5"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion5" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>You must consult all your employees on health and safety. You can do this by listening and talking to them about: <ul><li>health and safety and the work they do</li><li>how risks are controlled</li><li>the best ways of providing information and training</li></ul></p><p>Consultation is a two-way process, allowing employees to raise concerns and influence decisions on managing health and safety.</p><p>
                          Your employees are often the best people to understand risks in the workplace. Involving them in making decisions shows that you take their health and safety seriously. In a small business, you might choose to consult your workers directly. Larger businesses may consult through a health and safety representative, chosen by your employees or selected by a trade union. As an employer, you cannot decide who the representative will be.</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion5 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question5"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                    :readonly="isPreview"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question5comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >6. Do you provide Health & Safety Information and
                              Training?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>Everyone who works for you needs to know how to work safely and without risk to their health. This includes contractors and self-employed people. <br>

You must give your workers clear instructions and information, as well as adequate training. Make sure you include employees with particular training needs, for example new recruits, people changing jobs or taking on extra responsibilities, young employees and health and safety representatives<br>

Examples of the minimum required training include Induction (For new starters), Fire Safety and First Aid (For appointed First Aiders). Your Risk Assessments should identify all the hazards that are present for each location and/or activity. From there, a bespoke health and safety training plan can</p></span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion6 = !coreQuestion6"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion6" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>Everyone who works for you needs to know how to work safely and without risk to their health. This includes contractors and self-employed people. <br>

You must give your workers clear instructions and information, as well as adequate training. Make sure you include employees with particular training needs, for example new recruits, people changing jobs or taking on extra responsibilities, young employees and health and safety representatives<br>

Examples of the minimum required training include Induction (For new starters), Fire Safety and First Aid (For appointed First Aiders). Your Risk Assessments should identify all the hazards that are present for each location and/or activity. From there, a bespoke health and safety training plan can</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion6 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question6"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question6comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >7. Do you have adequate Workplace Property
                              Maintenance & Facilities?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>Employers must provide welfare facilities and a working environment that’s healthy and safe for everyone in the workplace, including those with disabilities.
You must have
<ul>
  <li>welfare facilities – the right number of toilets and washbasins, drinking water and having somewhere to rest and eat meals.</li>
  <li> a healthy working environment </li>
  <li>a clean workplace with a reasonable working temperature, good ventilation, suitable lighting and the right amount of space and seating</li>
  <li>a safe workplace</li>
  <li>well-maintained equipment, with no obstructions in floors and traffic routes, and windows that can be easily opened and cleaned</li>
  </ul></p>
  <p>Welfare facilities
    <ul>
      <li>toilets and hand basins, with soap and towels or a hand-dryer</li>
      <li>drinking water</li>
      <li> a place to store clothing (and somewhere to change if special clothing is worn for work)</li>
      <li>somewhere to rest and eat meals</li>
    </ul>
  </p>

  <p>A healthy working environment - To have a healthy working environment, make sure there is:
    <ul>
      <li>good ventilation – a supply of fresh, clean air drawn from outside or a ventilation system</li>4
      <li>a reasonable working temperature so it’s comfortable to work (usually at least 16°C, or 13°C for strenuous work, unless other laws require lower temperatures)</li>
      <li>lighting suitable for the work being carried out</li>
      <li>enough room space and suitable workstations and seating</li>
      <li>a clean workplace with appropriate waste containers</li>
    </ul>
  </p>

  <p>A safe workplace - To keep your workplace safe, you must:
    <ul>
      <li>maintain your premises and work equipment</li>
      <li>keep floors and traffic routes free of obstructions</li>
      <li>have windows that can be opened and cleaned safely</li>
      <li>make sure that any transparent (eg glass) doors or walls are protected or made of safety material</li>
    </ul>
  </p>
  <p>Property Maintenance<br>Importantly, is the property regularly maintained and serviced, in particular, boiler, electrical installation and water systems to manage any risk of legionella, and are you able to provide service records?</p>
  </span>
                              </v-tooltip> -->
 <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion7 = !coreQuestion7"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion7" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>Employers must provide welfare facilities and a working environment that’s healthy and safe for everyone in the workplace, including those with disabilities.
You must have
<ul>
  <li>welfare facilities – the right number of toilets and washbasins, drinking water and having somewhere to rest and eat meals.</li>
  <li> a healthy working environment </li>
  <li>a clean workplace with a reasonable working temperature, good ventilation, suitable lighting and the right amount of space and seating</li>
  <li>a safe workplace</li>
  <li>well-maintained equipment, with no obstructions in floors and traffic routes, and windows that can be easily opened and cleaned</li>
  </ul></p>
  <p>Welfare facilities
    <ul>
      <li>toilets and hand basins, with soap and towels or a hand-dryer</li>
      <li>drinking water</li>
      <li> a place to store clothing (and somewhere to change if special clothing is worn for work)</li>
      <li>somewhere to rest and eat meals</li>
    </ul>
  </p>

  <p>A healthy working environment - To have a healthy working environment, make sure there is:
    <ul>
      <li>good ventilation – a supply of fresh, clean air drawn from outside or a ventilation system</li>
      <li>a reasonable working temperature so it’s comfortable to work (usually at least 16°C, or 13°C for strenuous work, unless other laws require lower temperatures)</li>
      <li>lighting suitable for the work being carried out</li>
      <li>enough room space and suitable workstations and seating</li>
      <li>a clean workplace with appropriate waste containers</li>
    </ul>
  </p>

  <p>A safe workplace - To keep your workplace safe, you must:
    <ul>
      <li>maintain your premises and work equipment</li>
      <li>keep floors and traffic routes free of obstructions</li>
      <li>have windows that can be opened and cleaned safely</li>
      <li>make sure that any transparent (eg glass) doors or walls are protected or made of safety material</li>
    </ul>
  </p>
  <p>Property Maintenance<br>Importantly, is the property regularly maintained and serviced, in particular, boiler, electrical installation and water systems to manage any risk of legionella, and are you able to provide service records?</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion7 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >

                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question7"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question7comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >8. Do you have adequate First Aid Facilities?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span>
                                  <p>Employers must make sure employees get immediate help if taken ill or injured at work.
The law applies to every workplace and to the self-employed.
You must have: <ul><li>a suitably stocked first aid kit</li><li>an appointed person or people to take charge of first aid arrangements</li><li>information for all employees telling them about first aid arrangements</li></ul></p>
                                </span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion8 = !coreQuestion8"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion8" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>Employers must make sure employees get immediate help if taken ill or injured at work.
The law applies to every workplace and to the self-employed.
You must have: <ul><li>a suitably stocked first aid kit</li><li>an appointed person or people to take charge of first aid arrangements</li><li>information for all employees telling them about first aid arrangements</li></ul></p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion8 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question8"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :readonly="isPreview"
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question8comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >9. Are you displaying a Health & Safety Law
                              Poster?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>If you employ anyone, you must either:
                                  <ul>
                                    <li>display the health and safety law poster where your workers can easily read it</li><li>provide each worker with the equivalent health and safety law leaflet </li></ul></p>
                                    <p>The poster explains health and safety laws and lists what workers and their employers should do.<br>
You can add details of any employee safety representatives or health and safety contacts</p></span>
                              </v-tooltip> -->

                               <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion9 = !coreQuestion9"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion9" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>If you employ anyone, you must either:
                                  <ul>
                                    <li>display the health and safety law poster where your workers can easily read it</li><li>provide each worker with the equivalent health and safety law leaflet </li></ul></p>
                                    <p>The poster explains health and safety laws and lists what workers and their employers should do.<br>
You can add details of any employee safety representatives or health and safety contacts</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion9 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question9"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question9comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >10. Do you have adequate / correct Insurance?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>If your business has employees, you will probably need employers' liability insurance.</p><p>If an employee is injured or becomes ill as a result of the work they do for you, they can claim compensation from you. Employers’ liability insurance will help you to pay any compensation</p></span>
                              </v-tooltip> -->

<v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion10 = !coreQuestion10"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion10" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>If your business has employees, you will probably need employers' liability insurance.</p><p>If an employee is injured or becomes ill as a result of the work they do for you, they can claim compensation from you. Employers’ liability insurance will help you to pay any compensation</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion10 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question10"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question10comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >11. Are you adequately protecting your workforce
                              from getting ill or hurt through work?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>Both criminal and civil law apply to workplace health and safety. They’re not the same.<br>
As an employer, you must protect your workers and others from getting hurt or ill through work. <br>
If you don’t: <ul><li>a regulator such as the Health and Safety Executive (HSE) or local authority may take action against you under criminal law</li><li>the person affected may make a claim for compensation against you under civil law</li></ul></p></span>
                              </v-tooltip> -->

<v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion11 = !coreQuestion11"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion11" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>Both criminal and civil law apply to workplace health and safety. They’re not the same.<br>
As an employer, you must protect your workers and others from getting hurt or ill through work. <br>
If you don’t: <ul><li>a regulator such as the Health and Safety Executive (HSE) or local authority may take action against you under criminal law</li><li>the person affected may make a claim for compensation against you under civil law</li></ul></p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion11 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question11"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question11comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >12. Do you keep adequate Accident Records and
                              comply fully with RIDDOR?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span>
                                  <p>In law, you must report certain workplace injuries, near-misses and cases of work-related disease to HSE. This duty is under the Reporting of Injuries, Diseases and Dangerous Occurrences Regulations, known as RIDDOR.</p>
                                  <p>Keep records - If you employ more than 10 employees, you must keep an accident book under social security law. You can buy one from HSE Books or record the details in your own record system.</p>
                                  <p>
Keeping records of incidents helps you to identify patterns of accidents and injuries, so you can better assess and manage risk in your workplace.
Records can also be helpful when you are dealing with your insurance company.</p>
                                <p>Make sure you protect people’s personal details by storing records confidentially in a secure place.</p>
                               </span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion12 = !coreQuestion12"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion12" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>In law, you must report certain workplace injuries, near-misses and cases of work-related disease to HSE. This duty is under the Reporting of Injuries, Diseases and Dangerous Occurrences Regulations, known as RIDDOR.</p>
                                  <p>Keep records - If you employ more than 10 employees, you must keep an accident book under social security law. You can buy one from HSE Books or record the details in your own record system.</p>
                                  <p>
Keeping records of incidents helps you to identify patterns of accidents and injuries, so you can better assess and manage risk in your workplace.
Records can also be helpful when you are dealing with your insurance company.</p>
                                <p>Make sure you protect people’s personal details by storing records confidentially in a secure place.</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion12 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>


                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question12"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question12comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >
                              <p style="word-break: break-word;">
                              13.Has any enforcement (ie. Improvement, deffered prohibtion or Prohibition) notice been issued on the business within the previous 5 years?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>The range of enforcement options by the HSE include:<ul>
                                  <li>Providing information and advice face-to-face or in writing</li>
                                  <li>Serving notices on dutyholders</li>
                                  <li>Withdrawing approvals</li>
                                  <li>Varying licences, conditions or exemptions</li>
                                  <li>Issuing simple cautions</li>
                                  <li>Prosecution</li>
                                  </ul></p><p>For full details of enforcement notices issued by the Health and Safety Executive please visit https://resources.hse.gov.uk/notices/

Enforcement Notices are retained by the HSE for a period of 5 years. To account for the appeals process and internal quality assurance, enforcement notices are published 5 weeks after they are served.</p></span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="coreQuestion13 = !coreQuestion13"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="coreQuestion13" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>The range of enforcement options by the HSE include:<ul>
                                  <li>Providing information and advice face-to-face or in writing</li>
                                  <li>Serving notices on dutyholders</li>
                                  <li>Withdrawing approvals</li>
                                  <li>Varying licences, conditions or exemptions</li>
                                  <li>Issuing simple cautions</li>
                                  <li>Prosecution</li>
                                  </ul></p><p>For full details of enforcement notices issued by the Health and Safety Executive please visit https://resources.hse.gov.uk/notices/

Enforcement Notices are retained by the HSE for a period of 5 years. To account for the appeals process and internal quality assurance, enforcement notices are published 5 weeks after they are served.</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="coreQuestion13 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="fields.corebusiness.question13"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.corebusiness.question13comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item v-if="readyForSubmissions && !isPreview">
                      <v-row>
                        <v-col cols="12">
                          <v-card class="pa-12">
                            <v-card-text>
                              <v-row>
                                <v-col class="mt-5" cols="12">
                                  <v-flex text-center>
                                    <h1 class="mb-3" color="black">
                                      GET YOUR RESULTS
                                    </h1>
                                    <p class="mb-0">
                                      Please enter your email to recieve a copy
                                      and to unlock your results
                                    </p>
                                  </v-flex>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col align="center" cols="12" md="8" offset-md="2">
                                  <v-text-field
                                    label="Email"
                                    outlined
                                    required
                                    v-model="fields.email"
                                    class="neg-marg"
                                  />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-flex text-center>
                                  <v-btn
                                    @click="sendAndShowResults"
                                    color="primary"
                                    class="ml-auto"
                                  >
                                    Submit &amp; View Results
                                  </v-btn>
                                  <p color="black" class="mt-4">
                                    <a @click="resetForm" style="color:black"
                                      >Restart questionnaire</a
                                    >
                                  </p>
                                </v-flex>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="!readyForSubmissions || isPreview">
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >
                              <p style="word-break: break-word;">
                              1. Are you compliant with the requirements of:
                              The Regulatory Reform (Fire Safety) Order 2005?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>The Order places the responsibility on individuals within an organisation to carry out a fire safety risk assessments to identify, manage and reduce the risk of fire?</p></span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp1 = !regcomp1"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp1" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>The Order places the responsibility on individuals within an organisation to carry out a fire safety risk assessments to identify, manage and reduce the risk of fire?</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp1 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>


                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="
                                      fields.regulatorycompliance.question1
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question1comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              ><p style="word-break: break-word;">2. Are you compliant with the requirements of:
                              The Control of Substances Hazardous to Health
                              Regulations 2002?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>You are required to protect employees and other persons from the hazards of substances used at work by risk assessment, control of exposure, health surveillance and incident planning</p></span>
                              </v-tooltip> -->

                               <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp2 = !regcomp2"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp2" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>You are required to protect employees and other persons from the hazards of substances used at work by risk assessment, control of exposure, health surveillance and incident planning</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp2 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="
                                      fields.regulatorycompliance.question2
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    :readonly="isPreview"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question2comments
                                    "
                                    outlined
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >
                              <p style="word-break: break-word;">3. Are you compliant with the requirements of:
                              The Pressure Systems Safety Regulations 2000?
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>The PSSR cover the safe design and use of pressure systems. The aim of PSSR is to prevent serious injury from the hazard of stored energy (pressure) as a result of the failure of a pressure system or one of its component parts. Do you have any pressure systems under these regulations? These for example include:
                                  <ul>
                                    <li>boilers and steam heating systems; </li>
                                    <li> pressurised process plant and piping; </li>
                                    <li>compressed air systems (fixed and portable); </li>
                                    <li> pressure cookers, autoclaves and retorts; </li>
                                    <li>heat exchangers and refrigeration plant; </li>
                                    <li>valves, steam traps and filters; </li>
                                    <li>pipework and hoses; </li>
                                    <li>pressure gauges and level indicators.</li>
                                  </ul>
                                  </p></span>
                              </v-tooltip> -->
                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp3 = !regcomp3"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp3" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>The PSSR cover the safe design and use of pressure systems. The aim of PSSR is to prevent serious injury from the hazard of stored energy (pressure) as a result of the failure of a pressure system or one of its component parts. Do you have any pressure systems under these regulations? These for example include:
                                  <ul>
                                    <li>boilers and steam heating systems; </li>
                                    <li> pressurised process plant and piping; </li>
                                    <li>compressed air systems (fixed and portable); </li>
                                    <li> pressure cookers, autoclaves and retorts; </li>
                                    <li>heat exchangers and refrigeration plant; </li>
                                    <li>valves, steam traps and filters; </li>
                                    <li>pipework and hoses; </li>
                                    <li>pressure gauges and level indicators.</li>
                                  </ul>
                                  </p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp3 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNoNa"
                                    v-model="
                                      fields.regulatorycompliance.question3
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    :readonly="isPreview"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question3comments
                                    "
                                    outlined
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              ><p style="word-break: break-word;">4. Are you compliant with the requirements of:
                              Lifting Operations and Lifting Equipment
                              Regulations (LOLER)
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>The Order places the responsibility on individuals within an organisation to carry out a fire safety risk assessments to identify, manage and reduce the risk of fire?
A safe working load (SWL) should be marked onto lifting equipment with the relevant SWL being dependent on the configuration of the equipment, accessories for lifting such as eye bolts, lifting magnets and lifting beams should also be marked.
Passenger lifts - The regulations stated that all lifts provided for use with work activities should be thoroughly examined by a 'competent person' at regular intervals. </p> <p>
  LOLER Inspections. These are a legal requirement and should be carried out by a competent person, usually an insurance company will provide a competent person or request a third party independent Inspector. These inspections should be carried out at 6 monthly intervals for all lifting items and at least every 12 months for those that could be covered by PUWER, although a competent person may determine different time scales.
Standards state that as a minimum;
<ul>
  <li> Every six months for lifting equipment used for lifting/lowering persons.</li>
  <li> Every six months for lifting accessories.</li>
  <li>Every 12 months for all other lifting equipment not falling into either of the above categories
</li>
  </ul></p></span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp4 = !regcomp4"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp4" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>
A safe working load (SWL) should be marked onto lifting equipment with the relevant SWL being dependent on the configuration of the equipment, accessories for lifting such as eye bolts, lifting magnets and lifting beams should also be marked.
Passenger lifts - The regulations stated that all lifts provided for use with work activities should be thoroughly examined by a 'competent person' at regular intervals. </p> <p>
  LOLER Inspections. These are a legal requirement and should be carried out by a competent person, usually an insurance company will provide a competent person or request a third party independent Inspector. These inspections should be carried out at 6 monthly intervals for all lifting items and at least every 12 months for those that could be covered by PUWER, although a competent person may determine different time scales.
Standards state that as a minimum;
<ul>
  <li> Every six months for lifting equipment used for lifting/lowering persons.</li>
  <li> Every six months for lifting accessories.</li>
  <li>Every 12 months for all other lifting equipment not falling into either of the above categories
</li>
  </ul></p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp4 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                              </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNoNa"
                                    v-model="
                                      fields.regulatorycompliance.question4
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    :readonly="isPreview"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question4comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >5. Are you compliant with the requirements of:
                              Control of Asbestos at Work Regulations 2012
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span>
                                  <p>Asbestos can be found in any industrial or residential building built or refurbished before the year 2000. If the property was constructed or refurbished prior to 2000 there is a high probability that it contains Asbestos.</p>
                                  <p>Before starting any work that is likely to disturb asbestos, a suitable and sufficient risk assessment must be prepared by the employer.</p>
                                  <p>Whoever carries out the risk assessment must:
                                    <ul>
                                      <li>be competent to do the risk assessment </li>
                                      <li>carry it out before work begins and allow enough time to put appropriate precautions in place</li>
                                      <li>make sure the assessment is job specific and considers all aspects of the work </li>
                                    </ul>
                                  </p>
                                  <p>Risk assessments are about identifying and controlling the risks:
                                    <ul>
                                      <li> establish the potential risk (including general risk such as falls from height) and identify who may be affected</li>
                                      <li> identify the action to be taken to remove the risk, or if that is not possible, to reduce the risk to as low as possible</li>
                                      <li>record the findings of the risk assessment, and the action to be taken, and inform employees</li>
                                      <li>implement the actions to be taken</li>
                                      <li>review and update the risk assessment as required</li>
                                    </ul>
                                  </p>
                                  <p>Competency - Whoever carries out the risk assessment must have a sufficient level of knowledge, training and expertise. This is to make sure that they understand the risks from asbestos (and general risks) to enable them to make informed decisions about the risks and identify the appropriate action required to reduce them.  They will also need to be able to estimate the expected level of exposure to help them decide whether or not the control limit is likely to be exceeded. </p>
                                  <p>Content of the risk assessment - A suitable and sufficient risk assessment should include full details of the work to be undertaken and how long the work is expected to take. It should also include:
                                    <ul>
                                      <li>details of the type and quantity of the asbestos</li>
                                      <li>  details of the expected level of exposure</li>
                                      <li>details of the controls to be used to reduce exposure eg use of local exhaust ventilation, controlled wetting, adequate PPE / RPE use of enclosures</li>
                                      <li> decontamination procedures for tools, equipment and PPE</li>
                                      <li>details on how asbestos waste will be managed</li>
                                      <li> emergency procedures</li>
                                    </ul>
                                  </p>
                                  <p>The findings of the assessment should be communicated to employees, and anybody else who could be affected.  A copy of the risk assessment must be available on site</p>
                                </span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp5 = !regcomp5"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp5" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>Asbestos can be found in any industrial or residential building built or refurbished before the year 2000. If the property was constructed or refurbished prior to 2000 there is a high probability that it contains Asbestos.</p>
                                  <p>Before starting any work that is likely to disturb asbestos, a suitable and sufficient risk assessment must be prepared by the employer.</p>
                                  <p>Whoever carries out the risk assessment must:
                                    <ul>
                                      <li>be competent to do the risk assessment </li>
                                      <li>carry it out before work begins and allow enough time to put appropriate precautions in place</li>
                                      <li>make sure the assessment is job specific and considers all aspects of the work </li>
                                    </ul>
                                  </p>
                                  <p>Risk assessments are about identifying and controlling the risks:
                                    <ul>
                                      <li> establish the potential risk (including general risk such as falls from height) and identify who may be affected</li>
                                      <li> identify the action to be taken to remove the risk, or if that is not possible, to reduce the risk to as low as possible</li>
                                      <li>record the findings of the risk assessment, and the action to be taken, and inform employees</li>
                                      <li>implement the actions to be taken</li>
                                      <li>review and update the risk assessment as required</li>
                                    </ul>
                                  </p>
                                  <p>Competency - Whoever carries out the risk assessment must have a sufficient level of knowledge, training and expertise. This is to make sure that they understand the risks from asbestos (and general risks) to enable them to make informed decisions about the risks and identify the appropriate action required to reduce them.  They will also need to be able to estimate the expected level of exposure to help them decide whether or not the control limit is likely to be exceeded. </p>
                                  <p>Content of the risk assessment - A suitable and sufficient risk assessment should include full details of the work to be undertaken and how long the work is expected to take. It should also include:
                                    <ul>
                                      <li>details of the type and quantity of the asbestos</li>
                                      <li>  details of the expected level of exposure</li>
                                      <li>details of the controls to be used to reduce exposure eg use of local exhaust ventilation, controlled wetting, adequate PPE / RPE use of enclosures</li>
                                      <li> decontamination procedures for tools, equipment and PPE</li>
                                      <li>details on how asbestos waste will be managed</li>
                                      <li> emergency procedures</li>
                                    </ul>
                                  </p>
                                  <p>The findings of the assessment should be communicated to employees, and anybody else who could be affected.  A copy of the risk assessment must be available on site</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp5 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNoNa"
                                    v-model="
                                      fields.regulatorycompliance.question5
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    :readonly="isPreview"
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question5comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              ><p style="word-break: break-word;">6. Are you compliant with the requirements of:
                              The Provision and Use of Work Equipment
                              Regulations 1998 (PUWER)
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span>
                                  <p>These Regulations, often abbreviated to PUWER, place duties on people and companies who own, operate or have control over work equipment. PUWER also places responsibilities on businesses and organisations whose employees use work equipment, whether owned by them or not.</p>
                                  <p>PUWER requires that equipment provided for use at work is:
                                    <ul>
                                      <li>suitable for the intended use</li>
                                      <li>safe for use, maintained in a safe condition and inspected to ensure it is correctly installed and does not subsequently deteriorate </li>
                                      <li>used only by people who have received adequate information, instruction and training
accompanied by suitable health and safety measures, such as protective devices and controls. These will normally include emergency stop devices, adequate means of isolation from sources of energy, clearly visible markings and warning devices </li>
                                      <li>used in accordance with specific requirements, for mobile work equipment and power presses</li>
                                    </ul>
                                  </p>
                                  <p>Work equipment is any machinery, appliance, apparatus, tool or installation for use at work (whether exclusively or not). This includes equipment which employees provide for their own use at work. The scope of work equipment is therefore extremely wide. The use of work equipment is also very widely interpreted and '...means any activity involving work equipment and includes starting, stopping, programming, setting, transporting, repairing, modifying, maintaining, servicing and cleaning'.</p>
                                  <p>What you must do - If your business or organisation uses work equipment or is involved in providing work equipment for others to use (eg for hire), you must manage the risks from that equipment. This means you must:
                                    <ul>
                                      <li>ensure the equipment is constructed or adapted to be suitable for the purpose it is used or provided for</li>
                                      <li> take account of the working conditions and health and safety risks in the workplace when selecting work equipment </li>
                                      <li>ensure work equipment is only used for suitable purposes</li>
                                      <li>ensure work equipment is maintained in an efficient state, in efficient working order and in good repair</li>
                                      <li>where a machine has a maintenance log, keep this up to date</li>
                                      <li> where the safety of work equipment depends on the manner of installation, it must be inspected after installation and before being put into use</li>
                                      <li>where work equipment is exposed to deteriorating conditions liable to result in dangerous situations, it must be inspected to ensure faults are detected in good time so the risk to health and safety is managed</li>
                                      <li>ensure that all people using, supervising or managing the use of work equipment are provided with adequate, clear health and safety information. This will include, where necessary, written instructions on its use and suitable equipment markings and warnings </li>
                                      <li>ensure that all people who use, supervise or manage the use of work equipment have received adequate training, which should include the correct use of the equipment, the risks that may arise from its use and the precautions to take.</li>
                                    </ul>
                                  </p>
                                </span>
                              </v-tooltip>
                              </p> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp6 = !regcomp6"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp6" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>These Regulations, often abbreviated to PUWER, place duties on people and companies who own, operate or have control over work equipment. PUWER also places responsibilities on businesses and organisations whose employees use work equipment, whether owned by them or not.</p>
                                  <p>PUWER requires that equipment provided for use at work is:
                                    <ul>
                                      <li>suitable for the intended use</li>
                                      <li>safe for use, maintained in a safe condition and inspected to ensure it is correctly installed and does not subsequently deteriorate </li>
                                      <li>used only by people who have received adequate information, instruction and training
accompanied by suitable health and safety measures, such as protective devices and controls. These will normally include emergency stop devices, adequate means of isolation from sources of energy, clearly visible markings and warning devices </li>
                                      <li>used in accordance with specific requirements, for mobile work equipment and power presses</li>
                                    </ul>
                                  </p>
                                  <p>Work equipment is any machinery, appliance, apparatus, tool or installation for use at work (whether exclusively or not). This includes equipment which employees provide for their own use at work. The scope of work equipment is therefore extremely wide. The use of work equipment is also very widely interpreted and '...means any activity involving work equipment and includes starting, stopping, programming, setting, transporting, repairing, modifying, maintaining, servicing and cleaning'.</p>
                                  <p>What you must do - If your business or organisation uses work equipment or is involved in providing work equipment for others to use (eg for hire), you must manage the risks from that equipment. This means you must:
                                    <ul>
                                      <li>ensure the equipment is constructed or adapted to be suitable for the purpose it is used or provided for</li>
                                      <li> take account of the working conditions and health and safety risks in the workplace when selecting work equipment </li>
                                      <li>ensure work equipment is only used for suitable purposes</li>
                                      <li>ensure work equipment is maintained in an efficient state, in efficient working order and in good repair</li>
                                      <li>where a machine has a maintenance log, keep this up to date</li>
                                      <li> where the safety of work equipment depends on the manner of installation, it must be inspected after installation and before being put into use</li>
                                      <li>where work equipment is exposed to deteriorating conditions liable to result in dangerous situations, it must be inspected to ensure faults are detected in good time so the risk to health and safety is managed</li>
                                      <li>ensure that all people using, supervising or managing the use of work equipment are provided with adequate, clear health and safety information. This will include, where necessary, written instructions on its use and suitable equipment markings and warnings </li>
                                      <li>ensure that all people who use, supervise or manage the use of work equipment have received adequate training, which should include the correct use of the equipment, the risks that may arise from its use and the precautions to take.</li>
                                    </ul>
                                  </p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp6 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                </p>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="
                                      fields.regulatorycompliance.question6
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    :readonly="isPreview"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question6comments
                                    "
                                    outlined
                                    :readonly="isPreview"
                                    required
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >7. Electricity at Work Regulations 1989
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span>
                                  <p>The aim of the Electricity at Work Regulations 1989 is to prevent death or personal injury to any person from electrical causes in connection with work activities.</p>
                                  <p>Every employer, self-employed person and employee has a duty to comply with these regulations in so far as they relate to matters within their control. Employees must also co-operate with their employer as much as is necessary to enable any duty placed on the employer by the regulations to be complied with.</p>
                                  <p>Electrical equipment under the regulations includes anything used, intended to be used or installed for use, to generate, provide, transmit, transform, rectify, convert, conduct, distribute, control, store, measure or use electrical energy.
</p>
                                <p>General duties
Part II of the Electricity at Work Regulations (regs 4 – 16) places general duties on those individuals who the regulations apply to in relation to the following matters:

<ul>
  <li> general safety of electrical systems;</li>
  <li>strength and capability of electrical equipment;</li>
  <li>adverse or hazardous environments;</li>
  <li>insulation, protection and placing of conductors;</li>
  <li> earthing or suitable precautions;</li>
  <li>integrity of referenced conductors;</li>
  <li>connections;</li>
  <li>  means for protecting from excess current;</li>
  <li>means for cutting off the supply and for isolation;</li>
  <li>precautions for work on equipment made dead;</li>
  <li>work on or near live conductors;</li>
</ul>
</p>
                                </span>
                              </v-tooltip> -->

                              <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp7 = !regcomp7"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp7" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>The aim of the Electricity at Work Regulations 1989 is to prevent death or personal injury to any person from electrical causes in connection with work activities.</p>
                                  <p>Every employer, self-employed person and employee has a duty to comply with these regulations in so far as they relate to matters within their control. Employees must also co-operate with their employer as much as is necessary to enable any duty placed on the employer by the regulations to be complied with.</p>
                                  <p>Electrical equipment under the regulations includes anything used, intended to be used or installed for use, to generate, provide, transmit, transform, rectify, convert, conduct, distribute, control, store, measure or use electrical energy.
</p>
                                <p>General duties
Part II of the Electricity at Work Regulations (regs 4 – 16) places general duties on those individuals who the regulations apply to in relation to the following matters:

<ul>
  <li> general safety of electrical systems;</li>
  <li>strength and capability of electrical equipment;</li>
  <li>adverse or hazardous environments;</li>
  <li>insulation, protection and placing of conductors;</li>
  <li> earthing or suitable precautions;</li>
  <li>integrity of referenced conductors;</li>
  <li>connections;</li>
  <li>  means for protecting from excess current;</li>
  <li>means for cutting off the supply and for isolation;</li>
  <li>precautions for work on equipment made dead;</li>
  <li>work on or near live conductors;</li>
</ul>
</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp7 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="
                                      fields.regulatorycompliance.question7
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    :readonly="isPreview"
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question7comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="bg-blue primary--text--contrast"
                              >8. Are you compliant with the requirements of:
                              IET Wiring Regulations (BS7671:2018)
                              <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    color="white"
                                    v-bind="attrs"
                                    v-on="on"
                                    right
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span><p>
                                  Fixed Wire Testing, also known as an Electrical Installation Condition Report (EICR), checks that your electrical installations or circuits conform to the latest wiring regulations, in order to ensure you are compliant with relevant workplace safety legislation.

                                  </p>
                                  <p>The frequency of testing is specified in the latest version of the IET Wiring Regulations (currently BS7671:2018) and is determined by;
                                    <ul>
                                      <li>The type of installation</li>
                                      <li> How often it is used</li>
                                      <li>The external influences or environment to which the electrical installation is exposed.</li>
                                    </ul>
                                  </p>

                                  <p>For many commercial businesses, an EICR, which used to be called a periodic testing report, will be required at the lowest frequency interval – every 5 Years. However, higher risk environments will require 3 Yearly or even more frequent testing.
</p>

<p>It should be noted that all stated periods are maximum intervals. Deterioration of the installation may indicate a need to reduce the interval between testing.</p>

<p>5 Year Fixed Wire Testing
  <ul>
    <li>Most standard workplace environments need an Electrical Installation Condition Report to be produced at least every 5 years. However, there should be regular routine inspections conducted at least annually</li>
    <li> Typical workplaces which require 5 Yearly Fixed Wire Testing include:</li>
    <li> Commercial spaces such as offices and retail outlets</li>
    <li>Hotels and restaurants (excluding spa hotels)</li>
    <li>Schools, colleges and universities</li>
    <li>Laboratories</li>
    <li> Community centres, churches and public houses</li>
    <li>Care homes and hospitals (excluding medical locations)</li>
    <li>Halls of residence, houses of multiple occupancy</li>
  </ul>
</p>

<p>3 Year or 1 Year Fixed Wire Testing

  <ul>
    <li> Environments which, for example, are exposed to moisture, dust, extreme temperatures or which are open to the general public constitute a higher risk and therefore require more frequent testing. These workplaces require testing every 1 -3 years, with more frequent routine inspections also required.</li>
    <li>Typical workplaces or environments which require 3 or 1 year Fixed Wire Testing include:</li>
    <li> Spa hotels and leisure centres – 3 years</li>
    <li> Industrial Units – 3 years</li>
    <li>Theatres and places of public entertainment – 3 years</li>
    <li>Caravans – 3 years</li>
    <li> Cinemas – 1 year (front of house), 3 years (back of house)</li>
    <li>Agricultural or horticultural establishments – 3 years</li>
    <li>Medical locations in hospitals or clinics – 1 year</li>
    <li>Swimming pools and saunas – 1 year</li>
    <li>Caravan Parks and Marinas - 1 year</li>
    <li> Fish Farms, Laundrettes, Petrol Stations – 1 year</li>
  </ul>
</p>
                                  </span>
                              </v-tooltip> -->

                               <v-icon
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                       @click="regcomp8 = !regcomp8"
                                      right
                                      >mdi-help-circle-outline</v-icon
                                    >

                                <v-dialog v-model="regcomp8" persistent content-class="dialogControl">
                                  <v-card>
                                    <v-card-title class="headline">Guidance</v-card-title>
                                    <v-card-text><p>
                                  Fixed Wire Testing, also known as an Electrical Installation Condition Report (EICR), checks that your electrical installations or circuits conform to the latest wiring regulations, in order to ensure you are compliant with relevant workplace safety legislation.

                                  </p>
                                  <p>The frequency of testing is specified in the latest version of the IET Wiring Regulations (currently BS7671:2018) and is determined by;
                                    <ul>
                                      <li>The type of installation</li>
                                      <li> How often it is used</li>
                                      <li>The external influences or environment to which the electrical installation is exposed.</li>
                                    </ul>
                                  </p>

                                  <p>For many commercial businesses, an EICR, which used to be called a periodic testing report, will be required at the lowest frequency interval – every 5 Years. However, higher risk environments will require 3 Yearly or even more frequent testing.
</p>

<p>It should be noted that all stated periods are maximum intervals. Deterioration of the installation may indicate a need to reduce the interval between testing.</p>

<p>5 Year Fixed Wire Testing
  <ul>
    <li>Most standard workplace environments need an Electrical Installation Condition Report to be produced at least every 5 years. However, there should be regular routine inspections conducted at least annually</li>
    <li> Typical workplaces which require 5 Yearly Fixed Wire Testing include:</li>
    <li> Commercial spaces such as offices and retail outlets</li>
    <li>Hotels and restaurants (excluding spa hotels)</li>
    <li>Schools, colleges and universities</li>
    <li>Laboratories</li>
    <li> Community centres, churches and public houses</li>
    <li>Care homes and hospitals (excluding medical locations)</li>
    <li>Halls of residence, houses of multiple occupancy</li>
  </ul>
</p>

<p>3 Year or 1 Year Fixed Wire Testing

  <ul>
    <li> Environments which, for example, are exposed to moisture, dust, extreme temperatures or which are open to the general public constitute a higher risk and therefore require more frequent testing. These workplaces require testing every 1 -3 years, with more frequent routine inspections also required.</li>
    <li>Typical workplaces or environments which require 3 or 1 year Fixed Wire Testing include:</li>
    <li> Spa hotels and leisure centres – 3 years</li>
    <li> Industrial Units – 3 years</li>
    <li>Theatres and places of public entertainment – 3 years</li>
    <li>Caravans – 3 years</li>
    <li> Cinemas – 1 year (front of house), 3 years (back of house)</li>
    <li>Agricultural or horticultural establishments – 3 years</li>
    <li>Medical locations in hospitals or clinics – 1 year</li>
    <li>Swimming pools and saunas – 1 year</li>
    <li>Caravan Parks and Marinas - 1 year</li>
    <li> Fish Farms, Laundrettes, Petrol Stations – 1 year</li>
  </ul>
</p></v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="green darken-1" text @click="regcomp8 = false">Close</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                            </v-card-title>

                            <v-card-text>
                              <div class="pa-2 pt-8 pb-0 pl-0 neg-marg">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors, valid }"
                                >
                                  <v-select
                                    :items="yesOrNo"
                                    v-model="
                                      fields.regulatorycompliance.question8
                                    "
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select"
                                    outlined
                                    :readonly="isPreview"
                                    hide-details
                                    :error-messages="errors"
                                    :success="valid"
                                    class="pb-7"
                                  ></v-select>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors, valid }">
                                  <v-textarea
                                    label="Comments"
                                    :error-messages="errors"
                                    :success="valid"
                                    v-model="
                                      fields.regulatorycompliance
                                        .question8comments
                                    "
                                    outlined
                                    required
                                    :readonly="isPreview"
                                  ></v-textarea>
                                </ValidationProvider>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>

                  <v-row>
                    <v-col cols="12" align="right" justify="right">
                      <v-btn
                        v-if="activeTab < 2"
                        outlined
                        class="mr-2"
                        color="black"
                        @click="activeTab--"
                        :disabled="activeTab == 0"
                      >
                        Previous
                      </v-btn>

                      <v-btn
                        class="bg-blue"
                        v-if="activeTab < 2"
                        outlined
                        color="white"
                        @click="activeTab++"
                        :disabled="activeTab == 2"
                      >
                        Next
                      </v-btn>

                      <v-btn
                        v-if="activeTab == 2 && !isPreview && !readyForSubmissions"
                        outlined
                        color="white"

                        @click="
                          passes(prepareForViewAndSubmit);
                          handleFailedValidation(invalid);
                        "
                        class="ml-auto bg-blue"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" v-if="isSubmitted && !isPreview">
          <v-row justify="space-between" v-if="!isUpdating && !isLoading">
            <v-col cols="12" align="center">
              <v-card background-color="white" justify="center" class="pa-3">
                <h2>YOUR RESULTS</h2>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" align="center" justify="center">
              <v-card background-color="primary" class="pa-8 pa-md-5 primary">
                <v-card-text>
                  <h3 style="color:#fff;" class="mb-5">OVERALL COMPLIANCE</h3>
                  <v-progress-circular
                    :rotate="-90"
                    :size="96"
                    :width="16"
                    :value="overall"
                    color="white"
                  >
                    <h3>{{ overall }}%</h3>
                  </v-progress-circular>

                  <h2 v-if="overall >= 99.5" style="color:#fff;" class="mt-3">
                    Compliant
                  </h2>

                  <h2 v-if="overall < 99.5" style="color:#fff;" class="mt-3">
                    Non Compliant
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" align="center" justify="center">
              <v-card class="pa-8  pa-md-5">
                <v-card-text>
                  <h3 class="mb-5">CORE BUSINESS</h3>
                  <v-progress-circular
                    :rotate="-90"
                    :size="96"
                    :width="16"
                    :value="core"
                    color="primary"
                  >
                    <h3>{{ core }}%</h3>
                  </v-progress-circular>



                  <h2 v-if="core >= 99"  class="mt-3">
                    Compliant
                  </h2>

                  <h2 v-if="core < 99"  class="mt-3">
                    Non Compliant
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" align="center" justify="center">
              <v-card class="pa-8 pa-md-5">
                <v-card-text>
                  <h3 class="mb-5">REGULATORY COMPLIANCE</h3>
                  <v-progress-circular
                    :rotate="-90"
                    :size="96"
                    :width="16"
                    :value="regulatory"
                    color="primary"
                  >
                    <h3>{{ regulatory }}%</h3>
                  </v-progress-circular>




                  <h2 v-if="regulatory > 79" class="mt-3">
                    Compliant
                  </h2>

                  <h2 v-if="regulatory < 79"  class="mt-3">
                    Non Compliant
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="12" md="6" align="right" justify="right"></v-col>
            <v-col cols="12" md="6" align="right" justify="right">
              <v-btn outlined
                        class="mr-2"
                        color="black"  @click="resetForm" >
                Restart Questionnaire
              </v-btn>

              <v-btn
                color="white"
                @click="showPreview"
                outlined
                class="bg-blue primary--text--contrast"
              >
                View Details
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: "HenryComplianceForm",
  data() {
    return {
      header: require("@/images/hs-bg.png"),
      fields: {
        yourdetails: {},
        questionnairedetails: {},
        corebusiness: {},
        regulatorycompliance: {}
      },
      isPreview: false,
      date: false,
      activeTab: 0,
      core: 0,
      overall: 0,
      regulatory: 0,
      yesOrNo: [
        { id: 1, name: "Yes" },
        { id: 0, name: "No" }
      ],
      yesOrNoNa: [
        { id: 1, name: "Yes" },
        { id: 0, name: "No" },
        { id: 2, name: "N/A" }
      ],
      readyForSubmissions: false,
      isSubmitted: false,
      logo: require("@/images/henry-schein-logo-global.png"),
      dialog:false,
      coreQuestion2:false,
      coreQuestion3:false,
      coreQuestion4:false,
      coreQuestion5:false,
      coreQuestion6:false,
      coreQuestion7:false,
      coreQuestion8:false,
      coreQuestion9:false,
      coreQuestion10:false,
      coreQuestion11:false,
      coreQuestion12:false,
      coreQuestion13:false,

      regcomp1:false,
      regcomp2:false,
      regcomp3:false,
      regcomp4:false,
      regcomp5:false,
      regcomp6:false,
      regcomp7:false,
      regcomp8:false,
    };
  },
  methods: {
    handleFailedValidation(v) {
      if (v) {
        this.$toast.error(
          "Please fill out all required fields across all tabs."
        );
      }
    },

    prepareForViewAndSubmit() {
      this.readyForSubmissions = true;
    },
    showPreview() {
      this.isPreview = true;
    },
    sendAndShowResults() {
      this.submitForm();
    },
    resetForm() {
Object.assign(this.$data, this.$options.data.call(this));
    },

    async submitForm() {
      this.isSubmitted = true;

      await this.$http.post('form/sendExternalResponse', {
          toEmail: "service@ascensor.co.uk",
          fields: this.fields
        })
        .then(
          ({ data }) => {
            this.isLoading = false;
            this.isSubmitted = true;

            this.core = data.data.core;
            this.regulatory = data.data.regulatory;
            this.overall = data.data.overall;
          },
          ({ error }) => {
            console.log(error);
          }
        );
    }
  }
};
</script>

<style>
.message-show {
  background-color: #aa2828;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin: 15px 0;
}

.neg-marg {
  margin-bottom: -9px !important;
}

.message-show p {
  margin-bottom: 0;
  font-size: 17px;
}

.v-tooltip__content {
  max-width: 50%;
}

#testerton > .v-tabs-bar {
  background-color: #fff !important;
  border-radius: 5px;
  box-shadow: 3px 3px 10px #717171;
  margin-bottom: 16px;
}

#testerton > .v-tabs-items {
  background-color: transparent;
}

.bg-blue {
  background-color: #0277C2;
}

.header-compliance-external {
  padding-bottom: 20%;
  min-height: 280px;
  max-height: 380px;
  position: relative;
  margin-bottom: -150px;
  background-size: cover;
  background-position: 50% 50%;
}

.header-compliance-external h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: 75px;
  transform: translate(-50%, -50%);
  font-size: 3em;
  letter-spacing: 27px;
  color: #fff;
}

.dialogControl {
  width:50% !important;
}

.half-input {
  width: 45%;
    display: inline-block;

}

.half-input:first-of-type {

    margin-right: 3% !important;
}

@media only screen and (max-width:600px) {
  .dialogControl {
  width:80% !important;
}

.half-input {
  width: 100%;
    display: inline-block;

}

.half-input:first-of-type {

    margin-right: 0 !important;
}
}
</style>
