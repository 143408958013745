<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="10" xl="8">
          <v-page-header :value="isLoading || !$can('create','form') ? 'Manage Form' : form.meta.name"></v-page-header>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-row v-if="$vuetify.breakpoint.mdAndUp && !isLoading">
          <v-col cols="12">
            <v-row align="center" justify="center">

              <v-avatar
                color="primary"
                size="96"
                class="text-center"
                left
              >
                <v-icon dark x-large>mdi-clipboard-text-outline</v-icon>
              </v-avatar>

            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12" md="10" xl="8" class="text-center">

                <div class="d-flex align-center justify-center mt-2">
                  <v-chip
                    outlined
                    class="px-12"
                    large
                  >
                    <span class="font-weight-medium">{{ form.meta.name }}</span>
                  </v-chip>
                </div>

                <Can I="manage" a="organisations">
                  <v-chip outlined class="mt-2 px-6">
                    <v-icon small left>mdi-domain</v-icon>
                    {{ form.ownership.organisation_id|getOrganisationName }}
                  </v-chip>
                </Can>

              </v-col>
            </v-row>

            <Can I="create" a="form">
              <v-row align="center" justify="center">
                <v-col cols="12" md="10" xl="8">

                  <v-toolbar class="elevation-2">
                    <v-spacer></v-spacer>

                    <v-toolbar-items>

                      <v-tooltip top v-for="card in cards" :key="card.title">
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            text
                            :to="{ name: card.name, params: { form_id: form.id }}"
                          >
                            <v-icon v-text="card.icon" class="mr-2"></v-icon>
                            {{ card.title }}
                          </v-btn>

                        </template>
                        <span>{{ card.subtitle }}</span>
                      </v-tooltip>

                      <v-btn
                        class="mx-1"
                        text
                        @click="formDuplication = !formDuplication"
                      >
                        <v-icon class="mr-2">mdi-playlist-plus</v-icon>
                        Duplicate

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              dark
                              dense
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2 text--secondary"
                            >
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                          <span>This will allow you to duplicate this form and make any changes<br> you would like, without effecting your original form created</span>
                        </v-tooltip>
                      </v-btn>


                    </v-toolbar-items>

                    <v-spacer></v-spacer>
                  </v-toolbar>
                </v-col>
              </v-row>
            </Can>

          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-row  v-if="!$vuetify.breakpoint.mdAndUp && !isLoading">
          <v-col cols="12">

            <v-card class="py-2">
              <v-toolbar class="elevation-0">

                <v-avatar
                  color="primary"
                  size="42"
                  class="mr-7"
                  left
                >
                  <v-icon dark>mdi-clipboard-text-outline</v-icon>
                </v-avatar>

                <v-toolbar-title>
                  <span class="subtitle-1 font-weight-medium">{{ form.meta.name }}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <Can I="create" a="form">
                  <v-menu
                    bottom
                    offset-y
                    close-on-click
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list rounded two-line>
                      <v-list-item
                        v-for="card in cards"
                        :key="card.title"
                        router
                        :to="{ name: card.name, params: { form_id: form.id }}"
                      >
                        <v-list-item-action>
                          <v-icon v-text="card.icon" class="mr-2"></v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ card.title }}</v-list-item-title>
                          <v-list-item-subtitle>{{ card.subtitle }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        @click="formDuplication = !formDuplication"
                      >
                        <v-list-item-action>
                          <v-icon class="mr-2">mdi-playlist-plus</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Duplicate</v-list-item-title>
                          <v-list-item-subtitle>Duplicate this form.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </Can>

              </v-toolbar>
            </v-card>

          </v-col>

          <v-expand-transition>
            <v-col cols="12" v-if="!isInnerPage">
              <div class="elevation-2">
                <v-list rounded two-line>
                  <v-list-item
                    v-for="card in cards"
                    :key="card.title"
                    router
                    :to="{ name: card.name, params: { form_id: form.id }}"
                  >
                    <v-list-item-action>
                      <v-icon v-text="card.icon" class="mr-2"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ card.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ card.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="formDuplication = !formDuplication"
                  >
                    <v-list-item-action>
                      <v-icon class="mr-2">mdi-playlist-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Duplicate</v-list-item-title>
                      <v-list-item-subtitle>Duplicate this form.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </div>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-fade-transition>

      <v-row align="center" justify="center" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

      <v-row align="center" justify="center" v-if="!isLoading">
        <v-col cols="12" md="10" xl="8">

          <v-slide-y-transition mode="out-in">
            <router-view
              :form="form"
            ></router-view>
          </v-slide-y-transition>

        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="formDuplication">
          <v-card class="mx-auto" max-width="480" light v-if="!isDuplicating">

            <v-card-title class="primary primary--text--contrast mb-4">
              Duplicate Form
            </v-card-title>

            <v-card-text>
              Please select an organisation to duplicate form <strong>{{ form.meta.name }}</strong> into.
            </v-card-text>

            <ValidationObserver v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="">

                <v-autocomplete outlined dense flat return-object class="mb-5 mx-4"
                  height="35" label="Select Organisation" hide-details="auto" item-text="name" item-value="id"
                  v-model="toDuplicateTo"
                  :items="organisations"
                ></v-autocomplete>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text color="primary" @click="formDuplication = !formDuplication">Cancel</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn @click="passes(duplicateForm)" color="primary">
                    Duplicate
                  </v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>

          <v-row align="center" justify="center" v-if="isDuplicating">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: "FormHome",
  mixins: [
    BreadcrumbsManager
  ],
  components: {},
  data() {
    return {
      isInnerPage: false,
      formDuplication: false,
      toDuplicate: null,
      isDuplicating: false,
      toDuplicateTo: null,
      cards: [
        {
          title: "Submit Answers",
          subtitle: "Preview and submit your form.",
          name: "form/view",
          icon: "mdi-clipboard-text-play-outline"
        },
        {
          title: "Edit Form",
          subtitle: "Edit your form.",
          name: "form/edit",
          icon: "mdi-playlist-edit"
        },
        {
          title: "Form Responses",
          subtitle: "View form responses.",
          name: "form/responses",
          icon: "mdi-email-multiple"
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      form: state => state.FORM_STORE.form,
      organisations: state => state.ORG_STORE.organisations,
      isLoading: (state) => {
        return state.FORM_STORE.loadingForm ||
          state.ORG_STORE.loadingOrganisations
            ? true
            : false
      }
    })
  },
  beforeMount() {
    this.getData()
  },
  mounted() {
    this.checkIsInnerPage(this.$router.currentRoute)
  },
  methods: {
    getData() {
      this.$store.commit('FORM_STORE/SET_RESPONSES', [])
      this.$store.commit('FORM_STORE/LOADING_FORM')
      this.$store.dispatch('ORG_STORE/GET_ORGS')

      const form_id = parseInt(this.$route.params.form_id)
      const reseller_id = parseInt(this.user.reseller_id)
      const organisation_id = parseInt(this.user.organisation_id)

      this.$http.get('form', {
        params: {
          id: `${form_id}`,
          reseller_id: reseller_id,
          organisation_id: organisation_id
        }
      }).then(({ data }) => {
          data.data.forEach(form => {
            if (form.id == form_id) {
              this.$store.commit('FORM_STORE/SET_FORM', form)
            }
          });
        }, error => {
          console.error('Something went wrong retrieving form, please try again.', error)
          this.$toast.error('Something went wrong retrieving form, please try again.')
          this.$router.push({ name: 'form/all' }, () => {})
        })
    },
    duplicateForm() {
      this.isDuplicating = true

      const variables = {
        id: this.form.id,
        org_id: this.toDuplicateTo.id
      }

      this.$http.post('form/duplicate', variables)
        .then(response => {
          this.isDuplicating = false
          this.toDuplicate = response.data.data.id
          this.$toast.success('Successfully duplicated the form')
        })
        .catch(error => {
          this.isDuplicating = false
          console.error('Something has gone wrong duplicating the form', error)
          this.$toast.error('Something went wrong duplicating the form.')
        })
        .finally(() => {
          this.formDuplication = false
          this.$router.replace({ name: 'form', params: { form_id: this.toDuplicate }})
          this.getData();
        })
    },
    checkIsInnerPage(d) {
      if (d.meta) {
        this.isInnerPage = d.meta.formHome ? false : true
      }
    }
  },
  watch: {
      $route (to, from) {
      this.checkIsInnerPage(to)
    },
    form: function() {
      this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Forms' , path: '/form/all/' },
        { text: ':form' }
      ])

      this.replaceBreadcrumb({
        find: ':form',
        replace: {
          text: this.form.meta.name
        }
      })
    }
  }
}
</script>
