<template>
  <div>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady">
        <v-list-item link v-for="(item, index) in items" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

    <v-divider></v-divider>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady && showPageLinks"> 
        <v-list-item link v-for="(item, index) in pageItems" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OrganisationsNav',
  data() {
    return {
      isReady: false,
      showPageLinks: false
    }
  },
  computed: {
      ...mapState({
      organisation: state => state.ORG_STORE.organisation,
      isLoading: (state) => {
        return state.ORG_STORE.loadingOrganisation
      }
    }),
    items() {
      let i = []

      if (this.$can('read','organisation')) {
        i.push({ text: "All Organisations", name: "organisation/all", icon: "mdi-domain" })
      }

      if (this.$can('create', 'organisation')) {
        i.push({ text: "Add New Organisation", name: "organisation/create", icon: "mdi-domain-plus" })
      }

      return i
    },
    pageItems() {
      let i = []

      if (this.$can('update','organisation')) {
        i.push({ text: "Edit Organisation", name: "organisation/edit", icon: "mdi-playlist-edit" })
        i.push({ text: "Edit Theme", name: "organisation/theme", icon: "mdi-palette" })
        i.push({ text: "Manage Users", name: "organisation/users", icon: "mdi-account-multiple" })
        i.push({ text: "Manage Groups", name: "organisation/groups", icon: "mdi-account-group" })
        i.push({ text: "Manage Useful Links", name: "organisation/content", icon: "mdi-text-box-outline" })
        i.push({ text: "Manage Policies", name: "organisation/policies", icon: "mdi-file-document-outline" })
      }

      return i
    }
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true
    }, 500);

    this.checkPageLinks(this.$router.currentRoute)
  },
  methods: {
    checkPageLinks(r) {
      if (r.meta) {
        this.showPageLinks = (r.meta.organisationPage === true) ? true : false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.checkPageLinks(to)
    }
  }
}
</script>
