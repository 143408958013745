<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route>
      <v-card-title class="primary primary--text--contrast">Edit Group</v-card-title>

      <v-row align="center" :class="{'px-6': $vuetify.breakpoint.xs, 'px-8': $vuetify.breakpoint.smAndUp}">
        <v-row justify="space-between" v-if="!isUpdating && !isLoading">

          <v-col>
            <ValidationObserver v-slot="{ invalid, validated, passes }">
              <v-row v-if="group" class="mt-5">
                <!-- <v-col cols="10" v-if="showMicrosoftIntegrationSettings">
                  <span>Link this group with a Microsoft group:</span>
                  <v-select
                    v-model="selectedMicrosoftGroup"
                    :items="microsoftGroups"
                    item-text="displayName"
                    return-object
                    hide-details
                    label="Microsoft Group"
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="2" v-if="showMicrosoftIntegrationSettings">
                  <v-btn
                    class="ma-3"
                    color="primary"
                    :disabled="!selectedMicrosoftGroup || isSyncing"
                    @click="syncMicrosoftGroup"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                  <v-progress-circular v-if="isSyncing" color="primary" indeterminate></v-progress-circular>
                </v-col> -->
                <v-col cols="12">
                  <VTextFieldWithValidation rules="required" v-model="group.name" label="Group Name"/>
                </v-col>
              </v-row>

              <div class="pt-4 elevation-1">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search Users"
                  outlined
                  dense
                  class="mx-4"
                ></v-text-field>

                <v-data-table
                  :headers="headers"
                  :items="userList"
                  :items-per-page="10"
                  :search="search"
                  class="v-data-table--selectable mx-4"
                  mobile-breakpoint="960"
                >
                  <template v-slot:item.profile.avatar="{ item }">
                    <v-row justify="center">
                      <v-col cols="12" class="text-center">
                        <UserAvatar
                            :profile="item"
                            :size="32"
                            color="primary"
                          ></UserAvatar>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:item.profile.full_name="{ item }">
                    {{item.full_name}}
                  </template>

                  <template v-slot:item.status.name="{ item }">
                    <!-- <v-chip small color="primary" v-if="item.status.name === 'Active'"></v-chip>
                    <v-chip small color="warning" v-if="item.status.name != 'Active'"></v-chip> -->
                    <v-chip small :color="item.status.status_id == '1' ? 'cryspGreen' : 'grey'"></v-chip>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <div style="word-break: break-word">
                    {{item.email}}
                    </div>
                  </template>

                  <template v-slot:item.role="{ item }">
                    <v-chip small outlined v-if="item.role && item.status.status_id == 0">
                      Disabled {{ item.role | titleCase }}
                    </v-chip>
                    <v-chip small outlined v-else-if="item.role">
                      {{ item.role | titleCase }}
                    </v-chip>
                  </template>

                  <template v-slot:header.actions="{ }">
                    <v-checkbox
                      v-model="selectAll"
                      @change="toggleSelectAll"
                      v-if="!selectedMicrosoftGroup"
                    ></v-checkbox>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex justify-end" v-if="$vuetify.breakpoint.mdAndUp">
                      <v-btn color="primary" small icon class="ml-auto" @click="add(item)" 
                        v-if="!item.selected" :disabled="selectedMicrosoftGroup || item.status.status_id == '0'">
                        <v-icon>mdi-checkbox-blank-outline</v-icon>
                      </v-btn>
                      <v-btn color="primary" small icon class="ml-auto" @click="remove(item)" 
                        v-if="item.selected" :disabled="selectedMicrosoftGroup || item.status.status_id == '0'">
                        <v-icon>mdi-checkbox-marked</v-icon>
                      </v-btn>
                    </div>

                    <v-row dense v-if="!$vuetify.breakpoint.mdAndUp" class="mb-2">
                      <v-col cols="12">
                        <v-btn
                          v-if="!item.selected"
                          color="primary"
                          block
                          class="ml-auto"
                           :disabled="selectedMicrosoftGroup || item.status.status_id == '0'"
                          @click="add(item)">
                          <v-icon dark left>mdi-account-plus</v-icon>
                          Add to Group
                        </v-btn>

                        <v-btn
                        v-if="item.selected"
                        color="primary"
                        block
                        class="ml-auto"
                         :disabled="selectedMicrosoftGroup || item.status.status_id == '0'"
                        @click="remove(item)">
                          <v-icon dark left>mdi-account-minus</v-icon>
                          Remove from Group
                        </v-btn>
                      </v-col>
                    </v-row>

                  </template>
                </v-data-table>
              </div>

              <v-row class="px-3 py-6">
                <v-chip
                  outlined
                >
                  {{ selectedUsers.length ? selectedUsers.length : 'No' }} user{{ selectedUsers.length > 1 || !selectedUsers.length ? 's' : ''}} assigned to this group.
                </v-chip>

                <v-spacer></v-spacer>

                <v-btn text route :to="{ name: 'organisation/groups', params: { org_id: organisation.id }}" class="mr-2">Back</v-btn>
                <v-btn outlined @click="passes(handleUpdateGroup(true))" color="primary" v-if="!isUpdating && !selectedMicrosoftGroup">Save changes</v-btn>

                <v-row align="end" justify="end" v-if="isUpdating">
                  <v-loading-spinner></v-loading-spinner>
                </v-row>
              </v-row>

            </ValidationObserver>

          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="pa-8" v-if="isLoading || isUpdating">
          <v-loading-spinner></v-loading-spinner>
        </v-row>

      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../_store/axios'

import router from '@/_router'
import * as Sentry from '@sentry/browser';

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import UserAvatar from '@/components/user/Avatar'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'EditGroup',
  props: ['organisation', 'users'],
  mixins: [BreadcrumbsManager],
  components: {
    VTextFieldWithValidation,
    UserAvatar
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      search: '',
      headers: [
        { text: "", value: "profile.avatar", sortable: false },
        { text: "Name", value: "profile.full_name" },
        { text: "Status", value: "status.name" },
        { text: "Role", value: "role" },
        { text: "Job Title", value: "profile.job_title" },
        { text: "Email", value: "email" },
        { text: "", value: "actions", sortable: false }
      ],
      group: null,
      userList: [],
      selectedUsers: [],
      selectAll: false,
      microsoftGroups: [],
      selectedMicrosoftGroup: null,
      isSyncing: false,
      showMicrosoftIntegrationSettings: false,
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted() {},
  methods: {
    getData() {
      this.isLoading = true

      // console.log('isMicrosoftAccount:', this.isMicrosoftAccount());

      if(this.isMicrosoftAccount()) {
        // console.log('getting microsoft groups');
        this.getMicrosoftGroups();
      }

      this.$http.get('group', {
          params: {
            organisation_id: parseInt(this.$route.params.org_id),
            id: parseInt(this.$route.params.group_id)
          }
        })
        .then(({ data }) => {
          this.isLoading = false

          let users = []

          this.users.forEach((user, i) => {
            users.push({
              id: user.id,
              avatar: user.profile.avatar,
              full_name: user.profile.full_name,
              first_name: user.profile.first_name,
              last_name: user.profile.last_name,
              status: user.status,
              role: user.role,
              job_title: user.profile.job_title,
              email: user.email,
              selected: false
            })
          })

          //find the group in data.data where id = the group id
          let idx = 0;
          for (let i = 0; i < data.data.length; i++) {
            if (data.data[i].id === parseInt(this.$route.params.group_id)) {
              idx = i
              break
            }
          }

          this.selectAll = true;

          data.data[idx].users.forEach((prePopulated, i) => users.forEach(user => {
            if (prePopulated.user_id === user.id) {
              user.selected = true
              this.selectedUsers.push(user)
            } else {
              this.selectAll = false;
            }
          }))

          this.userList = users
          this.group = data.data[idx]

          //check to see if 'microsoft_group_id' is not null
          if(this.group.microsoft_group_id !== null) {
            this.selectedMicrosoftGroup = this.microsoftGroups.find(x => x.id === this.group.microsoft_group_id);

            this.getGroupUsers(this.selectedMicrosoftGroup).then(() => {
              // console.log('group synced, calculating users');
              this.calculateNewUsers();
            });
          }
        },
        ({ error }) => {
          console.error('Something went wrong getting group.')
        })
    },
    async handleUpdateGroup(redirectToManageAllGroups = false) {
      this.isUpdating = true

      const user_ids = this.selectedUsers.reduce((arr, elem) => arr.concat(elem.id), []).join()

      const variables = {
        name: this.group.name,
        group_id: parseInt(this.group.id),
        user_ids
      }

      //check if microsoft group is selected, if it isn't then save the null string to the database
      try {
        if(!this.selectedMicrosoftGroup) {
          console.log('no microsoft group selected');
          this.setMicrosoftGroup();
        }
      } catch (error) {
        console.log(error);
      }

      const group = await this.$http.put('group', variables)
        .then((response) => {
          return response.data.data
        },
        ({ error }) => {
          this.$toast.error('Something went wrong updating group, please try again.')

          //alert sentry
          Sentry.captureException(error);
        })

      if (group) {
        this.isUpdating = false
        this.$store.dispatch('GROUP_STORE/UPDATE_GROUP', group)

        this.$toast.success('Successfully updated group.')

        if (redirectToManageAllGroups)
        {
          this.$router.push({ name: 'organisation/groups', params: { org_id: this.organisation.id }})
        }
      }

      this.isSyncing = false;
    },
    add: function(item) {
      item.selected = true
      this.selectedUsers.push({...item})
    },
    remove: function(item) {
      item.selected = false

      const filtered = this.selectedUsers.filter(x => {
        return x.id != item.id
      })

      this.selectedUsers = filtered
    },
    toggleSelectAll() {
      this.userList.forEach(user => {
        user.selected = this.selectAll
      })

      if (this.selectAll) {
        this.userList.forEach(user => {
          if(!this.selectedUsers.find(x => x.id === user.id))
            this.selectedUsers.push({...user})
        })
      } else {
        this.selectedUsers = []
      }
    },
    // Microsoft Groups methods
    isMicrosoftAccount() {
      if(localStorage.getItem('microsoftToken') !== null) {
        this.showMicrosoftIntegrationSettings = true;
        return true;
      }
      return false;
    },
    async getMicrosoftGroups() {
      // console.log('getting microsoft groups');
        // Code to get groups
        let token = localStorage.getItem('token');
        let microsoftToken = localStorage.getItem('microsoftToken');

        // call the /microsoftGroups endpoint
        await axios.post('/microsoftGroups',{
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data:{
                    microsoftToken: microsoftToken
                }
            })
            .then(response => {
                // handle the response
                let arr = Object.values(response.data.data);
                this.microsoftGroups = arr.filter(item => typeof item === 'object' && item !== null);
                // console.log(this.microsoftGroups);
            })
            .catch(error => {
                // handle the error
                console.log(error);
            });
    },
    async syncMicrosoftGroup() {
      this.isSyncing = true;
      this.setMicrosoftGroup().then(() => {
        //force refresh the page
        const currentRoute = this.$route.fullPath;
        this.$router.push('/dummy-route').then(() => {
          this.$nextTick(() => {
            this.$router.push(currentRoute);
          });
        });
      })
    },
    async setMicrosoftGroup() {
      try{
        let token = localStorage.getItem('token');

        await axios.post('/group/linkMicrosoftGroup',{
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: { 
              microsoft_group_id: this.selectedMicrosoftGroup !== null ? this.selectedMicrosoftGroup.id : null,
              group_id: this.group.id
            }
        }).then(response => {
            // handle the response
            // console.log(response);
        }).catch(error => {
            // handle the error
            console.log(error);
        });
      } catch (error) {
        console.log(error);
      }

      this.getGroupUsers(this.selectedMicrosoftGroup).then(() => {
        // console.log('group synced, calculating users');
        this.calculateNewUsers();
      });
    },
    async getGroupUsers(msGroup) {
        // Code to get groups
        let token = localStorage.getItem('token');
        let microsoftToken = localStorage.getItem('microsoftToken');

        // call the /microsoftGroups endpoint
        await axios.post('/microsoftGroupUsers',{
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data:{
                    microsoftToken: microsoftToken,
                    groupId: msGroup.id,
                }
            })
            .then(response => {
              // console.log('group users:', response.data.data);
                // handle the response, but get rid of the non-object values and turn it into an array first
                let arr = Object.values(response.data.data);
                msGroup.members = arr.filter(item => typeof item === 'object' && item !== null);

                return response.data.data;
            })
            .catch(error => {
                // handle the error
            });
    },
    calculateNewUsers(){
      // console.log('current users:', this.users);
      // console.log('selected users:', this.selectedUsers);
      // console.log('microsoft group:', this.selectedMicrosoftGroup.members);

      this.users.forEach(usr => {
        // console.log('checking:', usr.email);
        // if the user is not in the microsoft group ensure they're not in the crysp group
        
        let isInMicrosoft =this.selectedMicrosoftGroup.members.some(u => u.mail === usr.email);
        let isInSelected = this.selectedUsers.some(u => u.email === usr.email);

        // console.log('is in microsoft group:', isInMicrosoft);

        if(isInMicrosoft) {
          
          // console.log('is in selected users:', isInSelected);

          if(!isInSelected){
            // console.log(`adding: ${usr.id}, ${usr.email}`);
            this.add(usr);
          }
        } else {
          // if the user was part of the group but is not anymore, remove them from the selected users calling the "remove" function
          if(isInSelected){
            // console.log(`removing: ${usr.id}, ${usr.email}`);
            this.remove(usr);
          }
        }
      });

      // console.log('refreshed selected users:', this.selectedUsers);

      this.handleUpdateGroup(false);

      this.isSyncing = false;
    }
  },
  watch: {
    group: function() {
       this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Organisations' , path: '/organisation/all/' },
        { text: ':organisation' },
        { text: ':groups' },
        { text: ':group' },
        { text: 'Edit' }
      ])

      this.replaceBreadcrumb({
        find: ':organisation',
        replace: { text: this.organisation.name, path: `/organisation/${this.organisation.id}` }
      })

      this.replaceBreadcrumb({
        find: ':groups',
        replace: { text: 'Groups', path: `/organisation/${this.organisation.id}/groups` }
      })

      this.replaceBreadcrumb({
        find: ':group',
        replace: { text: this.group.name, path: `/organisation/${this.organisation.id}/groups/${this.group.id}/edit` }
      })
    }
  }
}
</script>
