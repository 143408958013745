import axios from '../axios'

const initialState = () => {
  return {
    loadingForm: false,
    loadingForms: false,
    loadingResponse: false,
    loadingResponses: false,
    form: {},
    forms: [],
    response: {},
    responses: [],
    cache: {
      forms: {
        lifetime: 30000,
        microtime: 0
      },
      responses: {
        lifetime: 30000,
        microtime: 0
      }
    }
  }
}

const state = initialState()

const getters = {
  getForm: state => state.forms
}

const mutations = {
  CREATE_FORM: (state, form) => {
    state.forms.push(form)
  },

  LOADING_FORM: state => state.loadingForm = true,

  LOADING_FORMS: state => state.loadingForms = true,

  LOADING_RESPONSE: state => state.loadingResponse = true,

  LOADING_RESPONSES: state => state.loadingResponses = true,

  ARCHIVE_FORM: (state, id) => {
    const index = state.forms.findIndex(form => form.id == id)
    state.forms[index].status.id = 3
    state.forms[index].status.name = "Deleted"
  },

  SET_FORMS: (state, forms) => {
    state.loadingForms = false
    state.forms = forms
  },

  SET_FORM: (state, form) => {
    state.loadingForm = false
    state.form = form
  },

  SET_RESPONSE: (state, response) => {
    state.loadingResponse = false
    state.response = response
  },

  SET_RESPONSES: (state, responses) => {
    state.loadingResponses = false
    state.responses = responses.reverse()
  },

  CLEAR_RESPONSE: (state) => {
    state.response = null
  },

  UPDATE_FORM: (state, updated) => {
    const index = state.forms.findIndex(form => form.id === updated.id)
    state.forms[index] = updated
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

   /**
   * This method returns an array of all responses by form id
   */
  GET_RESPONSES({ commit }, form_id) {
    commit('LOADING_RESPONSES')

    let cacheStatus = (Date.now() - state.cache.responses.microtime) < state.cache.responses.lifetime && state.cache.responses.microtime > 0
      ? true
      : false

     return new Promise((resolve, reject) => {
       axios.get('form/response', {
         params: {
           id: form_id
         }
       }).then(({ data }) => {
         commit('SET_RESPONSES', data.data)
       }, error => {
         console.error('Something has gone wrong getting form responses.', error)
         reject(error)
       })
     })

  },

  GET_FORMS({commit, dispatch, rootState}, options) {
      commit('LOADING_FORMS')

      let cacheStatus = (Date.now() - state.cache.forms.microtime) < state.cache.forms.lifetime && state.cache.forms.microtime > 0
        ? true
        : false

      let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

      if (sortBy == 'unix') {
        sortBy = 'updated_at';
      }

      let variables = {
        offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
        limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
        sortBy: sortBy,
        sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
        search: options.search
      }

      if (rootState.AUTH_STORE.authUser) {
        variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
        variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
      }

      axios.get('form', {
        params: variables
      }).then(({ data }) => {
        commit('SET_FORMS', data.data)
      }, error => {
        console.error('Something has gone wrong getting forms.', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
