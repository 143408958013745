<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route>
      <v-card-title class="primary primary--text--contrast">Create New User</v-card-title>

      <v-row align="center" :class="{'px-6': $vuetify.breakpoint.xs, 'pa-8': $vuetify.breakpoint.smAndUp}">
        <v-row justify="space-between" v-if="!isCreating">

          <v-col cols="12" md="4">

            <v-row align="center" justify="center" class="mb-4">
              <v-avatar color="primary" size="128">
                <v-icon size="48" dark v-if="!userAvatarName()">mdi-account</v-icon>
                <span
                  v-if="userAvatarName()"
                  class="primary--text--contrast display-2"
                >{{ userAvatarName() }}</span>
              </v-avatar>
            </v-row>
          </v-col>

          <v-col>

            <ValidationObserver v-slot="{ invalid, validated, passes, errors  }" ref="provider">

              <v-form @submit.prevent="passes(handleSubmit)">

                <v-row >
                  <v-col class="py-0">
                   <VTextFieldWithValidation vid="username" rules="required" v-model="newUser['username']" label="Username" />
                  </v-col>
                </v-row>
                <v-row >
                  <v-col class="py-0">
                    <VTextFieldWithValidation vid="email" rules="required|email" v-model="newUser['email']" label="Email address" />
                  </v-col>
                </v-row>

                <v-row >
                  <v-col class="py-0">
                    <VTextFieldWithValidation rules="required" v-model="newUser['first_name']" label="First name" />
                  </v-col>
                  <v-col class="py-0">
                    <VTextFieldWithValidation rules="required" v-model="newUser['last_name']" label="Last name" />
                  </v-col>
                </v-row>

                <v-row >
                  <v-col class="py-0 mt-4">
                    <ValidationProvider name="role" rules="required" v-slot="{ errors, valid }">
                      <v-select
                        :error-messages="errors"
                        :success="valid"
                        :items="roles"
                        item-text="name" item-value="value"
                        label="Role"
                        outlined
                        v-model="newUser['role']"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row >
                  <v-col class="py-0">
                    <v-switch
                      v-model="newUser['send_activation']"
                      label="Send Activation Email"
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-row  v-if="!newUser['send_activation']">
                  <v-col class="py-0">
                    <ValidationProvider name="password" :rules="!newUser['send_activation'] ? 'required|min:8' : ''"  v-slot="{ errors, valid }">
                      <v-text-field
                        outlined
                        counter
                        :error-messages="errors"
                        :success="valid"
                        label="Password"
                        :append-icon="userPasswordVisibility.password_current_show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="userPasswordVisibility.password_current_show ? 'text' : 'password'"
                        @click:append="userPasswordVisibility.password_current_show = !userPasswordVisibility.password_current_show"
                        v-model="newUser['password']"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col class="py-0">
                    <ValidationProvider name="confirmation" :rules="!newUser['send_activation'] ? 'required|password:@password' : ''" v-slot="{ errors, valid }">
                      <v-text-field
                        outlined
                        counter
                        :error-messages="errors"
                        :success="valid"
                        label="Password Confirmation"
                        :append-icon="userPasswordVisibility.password_new_show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="userPasswordVisibility.password_new_show ? 'text' : 'password'"
                        @click:append="userPasswordVisibility.password_new_show = !userPasswordVisibility.password_new_show"
                        v-model="newUser['password_confirmation']"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row  class="mt-4">
                  <v-col class="py-0">
                    <v-btn text color="primary" :to="{ name: 'organisation/users', params: { org_id: organisation.id }}">Cancel</v-btn>
                  </v-col>
                  <v-col class="py-0 text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn outlined color="primary" v-on="on" @click="passes(handleSubmit)" :disabled="invalid || !Object.values(errors).every(x => !x.length)">Add New User</v-btn>
                      </template>
                      <span>Create new user and add them to <strong>{{organisation.name}}</strong>.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

              </v-form>
            </ValidationObserver>

          </v-col>

        </v-row>

        <v-row align="center" justify="center" v-if="isCreating">
          <v-loading-spinner></v-loading-spinner>
        </v-row>

      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import axios from '../../_store/axios'

import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  name: 'CreateUser',
  mixins: [BreadcrumbsManager],
  props: [
    'organisation'
  ],
  components: {
    ValidationObserver,
    ValidationProvider,
    VTextFieldWithValidation
  },
  data() {
    return {
      userPasswordVisibility: {
        password_current_show: false,
        password_new_show: false,
        password_new_confirm_show: false
      },
      newUser: {
        send_activation: 1
      },
      roles: [
        { name: 'Admin', value: 'organisation_admin' },
        { name: 'User', value: 'user' }
      ],
      isCreating: false
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.AUTH_STORE.authUser
    }),
  },
  methods: {
    userAvatarName() {
      let name = '';

      if (this.newUser['first_name']) {
        name += `${this.newUser['first_name'].charAt(0).toUpperCase()}`
      }

      if (this.newUser['last_name']) {
        name += `${this.newUser['last_name'].charAt(0).toUpperCase()}`
      }

      if (name !== '') {
        return name
      }

      return false
    },
    async handleSubmit() {

      this.isCreating = true

      this.newUser['reseller_id'] = this.authUser.reseller_id
      this.newUser['organisation_id'] = parseInt(this.$route.params.org_id)
      this.newUser['send_activation'] = this.newUser['send_activation'] ? 1 : 0

      // axios post request with auth token added from localstorage
      let userResponse = undefined;
      const authToken = localStorage.getItem('token');

      try {
        userResponse = await axios.post(`${process.env.VUE_APP_URL}api/v1/user`, this.newUser, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }).then((response) => {
          this.isCreating = false

          this.$store.commit('USER_STORE/CREATE_USER', response.data.data)
          this.$toast.success('Successfully created new user.')
          this.$router.push({ name: "organisation/users", params: { org_id: this.$route.params.org_id }}, () => {})
        });
      } catch (error) {
        this.isCreating = false
        this.$toast.error(error.response.data.message ?? error.message)
        return error.response.data.message ?? error.message
      }
      
      // const userResponse = await this.$http.post('user', this.newUser)
      //   .then(response => {
      //     // this.isCreating = false

      //     // this.$store.commit('USER_STORE/CREATE_USER', response.data.data)
      //     // this.$toast.success('Successfully created new user.')
      //     // this.$router.push({ name: "organisation/users", params: { org_id: this.$route.params.org_id }}, () => {})
      //   }).catch((error) => {
      //     this.isCreating = false
      //     this.$toast.error(error.response.data.message ?? error.message)
      //     return error.response.data.message ?? error.message
      //   })

      this.$refs.provider.setErrors(userResponse);
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Users', path: `/organisation/${this.organisation.org_id}/users/` },
      { text: 'Create' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: { text: this.organisation.name, path: `/organisation/${this.organisation.org_id}` }
    })
  }
}
</script>
