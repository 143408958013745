import { required, numeric, length, min, max, email } from "vee-validate/dist/rules"
import { extend } from "vee-validate"

extend("required", {
  ...required,
  message: "This field is required"
})

extend("numeric", numeric)

extend("length", length)

extend("min", {
  ...min,
  message: "Password must be at least 8 characters long"
})

extend("max", {
  ...max,
  message: 'This field cannot be more than {length} characters long'
})

extend("email", {
  ...email,
  message: "This field must be a valid email address, without any leading or trailing spaces"
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max'],
  message: 'This field must be between {min} and {max} characters long'
});

extend('minimum', {
  validate(value, { min }) {
    return value.length >= min;
  },
  params: ['min'],
  message: 'This field must be at least {min} characters long'
});

extend('minmaxVal', {
  validate(value, { min, max }) {
    return value >= min && value <= max;
  },
  params: ['min', 'max'],
  message: 'This value must be between {min} and {max}'
});

extend('maxVal', {
  validate(value, { max }) {
    return value <= max;
  },
  params: ['max'],
  message: 'This value cannot be larger than {max}'
});

extend('minVal', {
  validate(value, { min }) {
    return value >= min;
  },
  params: ['min'],
  message: 'This value must be at least {min}'
});