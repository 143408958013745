<template>
  <div>
    <TableFilterToolBar
      :tags="complianceTags"
      :hide="['search', 'statuses']"
      @updateStatuses="(newValue) => updateFiltersMap('statuses', newValue)"
      @updateTags="(newValue) => updateFiltersMap('tags', newValue)"
      @updateSearch="(newValue) => updateFiltersMap('searchTerm', newValue)"
    />

    <template v-if="!loadingItems">
      <ViewCompliancyItems
        :items="parsedContents"
        :isLoading="loadingItems"
        :filtersMap="filtersMap"
        :itemTotals="contentTotals"
        @manageComplianceItem="handleManageComplianceEvent"
        @exportAsPDF="exportContentAsPDF"
      />
    </template>
    <template v-else>
      <div class="mt-2">
        <v-progress-linear
          value="15"
          indeterminate
          color="primary"
        ></v-progress-linear>
      </div>
    </template>
    <Pagination
      v-show="!loadingItems"
      :length="lastPage"
      @updatePage="(newPage) => (currentPage = newPage)"
    />
  </div>
</template>

<script>
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { mapState } from "vuex";
import TableFilterToolBar from "../../components/compliance/TableFilterToolBar.vue";
import ViewCompliancyItems from "./ViewCompliancyItems.vue";
import Pagination from "../../components/display/Pagination.vue";

export default {
  name: "ViewComplianceTable",
  components: {
    TableFilterToolBar,
    ViewCompliancyItems,
    Pagination,
  },
  data() {
    return {
      filtersMap: {
        statuses: [],
        tags: [],
        searchTerm: "",
      },
      paginatedContents: {},
      loadingPage: {},
      currentPage: 1,
      lastPage: 0,
      pageBuffer: 1,
      loadingItems: false,
      totals: {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0,
      },
      contents: [],
    };
  },
  computed: {
    ...mapState({
      complianceTags: (state) => state.ORG_STORE.orgComplianceTags || [],
    }),
    parsedContents() {
      return this.parseContentCompliances(this.contents);
    },
    contentTotals() {
      if (!this.parsedContents) {
        return [];
      }

      let allTotals = [];

      this.parsedContents.forEach((content) => {
        let totals = {
          total: 0,
          pass: 0,
          warn: 0,
          fail: 0,
        };

        totals.total = content.items.length;
        totals.pass = content.items.filter(
          (i) => i.complianceState.id === 2
        ).length;
        totals.warn = content.items.filter(
          (i) => i.complianceState.id === 3
        ).length;
        totals.fail = content.items.filter(
          (i) => i.complianceState.id === 4
        ).length;
        allTotals.push(totals);
      });
      return allTotals;
    },
  },
  watch: {
    currentPage() {
      if (!this.paginatedContents[this.currentPage]) {
        this.loadingItems = true;
        this.getPageContents(this.currentPage);
      } else {
        this.contents = Object.values(this.paginatedContents[this.currentPage]);
        this.getBufferItems();
      }
    },
    filtersMap: {
      handler() {
        this.currentPage = 1;

        this.getPageContents(this.currentPage, true, this.filtersMap);
        this.updateTotalComplianceStatus();
      },
      deep: true,
    },
  },
  methods: {
    updateFiltersMap(prop, newValue) {
      this.filtersMap[prop] = newValue;
    },
    getPageContents(page, bufferItems = true, filters = null) {
      if (!filters && page in this.loadingPage && this.loadingPage[page]) return;

      this.loadingPage[page] = true;
      this.$http
        .get("content/compliance/filter", {
          params: {
            page,
            ...filters,
          },
        })
        .then(({ data }) => {
          let responseData = data.data;

          this.setData(page, responseData);

          this.loadingItems = false;
          this.loadingPage[page] = false;

          this.contents = Object.values(
            this.paginatedContents[this.currentPage]
          );

          if (bufferItems) {
            this.getBufferItems();
          }
        });
    },
    setData(page, responseData) {
      this.lastPage = responseData.last_page;

      delete responseData.current_page;
      delete responseData.last_page;
      delete responseData.total;
      delete responseData.per_page;

      this.paginatedContents[page] = responseData;
    },
    getBufferItems() {
      const endPage = Math.min(
        this.currentPage + this.pageBuffer,
        this.lastPage
      );
      const prevStartPage =
        this.currentPage == 1
          ? 1
          : Math.max(this.currentPage - this.pageBuffer, 1);

      for (let page = prevStartPage; page <= endPage; page++) {
        if (!this.paginatedContents[page] && page != this.currentPage) {
          this.getPageContents(page, false);
        }
      }

      this.clearPagesOutsideBuffer(prevStartPage, endPage);
    },
    clearPagesOutsideBuffer(prevStartPage, endPage) {
      for (let page in this.paginatedContents) {
        if (page < prevStartPage || page > endPage) {
          delete this.paginatedContents[page];
        }
      }
    },
    parseContentCompliances(contents) {
      if (this.contents) {
        let parsedContents = [];

        contents.forEach((content, key) => {
          let clonedContent = JSON.parse(JSON.stringify(content));

          if (clonedContent.items) {
            const arrayItems = Object.values(clonedContent.items);
            clonedContent.totals = {
              total: 0,
              pass: 0,
              warn: 0,
              fail: 0,
            };

            clonedContent.totals.total = arrayItems.length;

            arrayItems.forEach((i, key) => {
              arrayItems[key].content_id = clonedContent.content_id;

              let state = i.complianceState.id;

              if (state == 2) {
                clonedContent.totals.pass++;
              }

              if (state == 3) {
                clonedContent.totals.warn++;
              }

              if (state == 4) {
                clonedContent.totals.fail++;
              }
            });

            parsedContents.push(clonedContent);
          }
        });

        return parsedContents;
      }
    },
    getColor(state) {
      if (state == 2) {
        return "#4caf50";
      }

      if (state == 3) {
        return "#fb8c00";
      }

      if (state == 4) {
        return "#ff5252";
      }
    },
    exportContentAsPDF(contentIndex) {
      const doc = new jsPDF();

      const content = this.parsedContents[contentIndex];

      doc.setFontSize(16);
      doc.text("System", 14, 22);
      doc.text(
        `Crysp Compliance Report - ${this.$moment().format("DD/MM/YYYY")}`,
        14,
        30
      );
      doc.setFontSize(11);
      doc.setTextColor(100);
      doc.text(content.content_name, 14, 40);

      let bodyItems = [];

      content.items.forEach((item) => {
        let daysRemaining = () => {
          if (item.latest_action) {
            let date = item.latest_action
              ? this.$moment(item.latest_action.actioned_date).add(
                  item.frequency,
                  "d"
                )
              : this.$moment().add(item.frequency, "d");
            let today = this.$moment();
            return date.diff(today, "days");
          }
        };

        let filtered = {
          name: { content: item.name, styles: { cellWidth: 60 } },
          status: {
            content: "",
            styles: {
              fillColor: this.getColor(item.complianceState.id),
              minCellWidth: 5,
            },
          },
          tags: item.tags.map((tag) => tag.name).join(", "),
          created_at: this.$moment(item.created_at).format("MMM DD, YYYY"),
          actioned_at: item.latest_action
            ? this.$moment(item.latest_action.actioned_on).format("DD/MM/YYYY")
            : "Never",
          due_at: item.latest_action
            ? this.$moment(item.latest_action.actioned_date)
                .add(item.frequency, "d")
                .format("MMM DD, YYYY")
            : this.$moment().format("MMM DD, YYYY"),
          days_remaining: daysRemaining(),
        };

        bodyItems.push(filtered);
      });
      autoTable(doc, {
        columns: [
          { dataKey: "name", header: "Name" },
          { dataKey: "status", header: "Status" },
          { dataKey: "tags", header: "Tags" },
          { dataKey: "created_at", header: "Date Created On" },
          { dataKey: "actioned_at", header: "Last Actioned" },
          { dataKey: "due_at", header: "Next Due" },
          { dataKey: "days_remaining", header: "Days Remaining" },
        ],
        body: bodyItems,
        startY: 50,
        theme: "plain",
        headStyles: { lineWidth: 0.25 },
        bodyStyles: { lineWidth: 0.25 },
      });
      doc.save(`crysp-compliance-report-${content.content_name}.pdf`);
    },
    handleManageComplianceEvent(data) {
      this.$emit("manageComplianceItem", data);
    },
    refreshPage() {
      this.getPageContents(this.currentPage, false);
    },
    updateTotalComplianceStatus() {
      this.$store.dispatch('CONTENT_STORE/GET_COMPLIANCES_STATS', {tags:this.filtersMap.tags});
    }
  },

  beforeMount() {
    this.loadingItems = true;
    this.getPageContents(this.currentPage);
  },

  mounted() {
    this.parsedContents?.forEach((content) => {
      content.items.forEach((item) => {
        let frequency = item.frequency;

        if (frequency == 0) {
          item.frequencyValue = null;
          item.frequencyUnit = "None";
        } else {
          //if the frequency is a multiple of 365, then it is a year
          if (frequency % 365 == 0) {
            item.frequencyValue = frequency / 365;
            item.frequencyUnit = "Years";
          } else if (frequency % 30 == 0) {
            item.frequencyValue = frequency / 30;
            item.frequencyUnit = "Months";
          } else if (frequency % 7 == 0) {
            item.frequencyValue = frequency / 7;
            item.frequencyUnit = "Weeks";
          } else {
            item.frequencyValue = frequency;
            item.frequencyUnit = "Days";
          }
        }

        // calculate the next review date
        this.$emit("updateNextReviewDate", item);
      });
    });
  },
};
</script>

<style></style>
