<template>
  <v-container fluid fill-height class="pa-0">
    <v-layout justify-center>
      <v-flex xs12>
        <HenryComplianceForm />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import HenryComplianceForm from "./HenryComplianceForm";

export default {
  name: "HenryExternalComplianceForm",
  components: {
    HenryComplianceForm
  },

  data() {
    return {
      lostPassword: false,
      assets: {
        images: {
          logo: require("@/images/logo-crysp-dark.png")
        }
      }
    };
  },

  methods: {}
};
</script>
