<template>
  <div>
    <v-list>
      <v-list-item>
        <div class="mr-4 my-2">
          <UserAvatar
          :profile="user.profile"
          color="primary"
          ></UserAvatar>
        </div>

        <v-list-item-content>
          <v-list-item-title>{{ user.profile.first_name }}</v-list-item-title>
          <v-list-item-subtitle v-if="user.profile.job_title">{{ user.profile.job_title }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="!user.profile.job_title">Crysp User</v-list-item-subtitle>

          <div v-if="user.role === 'system_admin'">
          <v-chip
              small
              class="mt-2"
          >
              <span>System Admin</span>
          </v-chip>
          </div>

          <div v-if="user.role === 'reseller_admin'">
          <v-chip
              small
              class="mt-2"
          >
              <span>Reseller Admin</span>
          </v-chip>
          </div>

          <div v-if="user.role === 'organisation_admin'">
          <v-chip
              small
              class="mt-2"
          >
              <span>Organisation Admin</span>
          </v-chip>
          </div>

        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list rounded>

      <Can not I="create" a="organisation">
        <Can I="update" a="organisation">

          <v-list-item route :to="{ name: 'organisation', params: { org_id: user.organisation_id }}">
          <v-list-item-action>
              <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title>Manage Organisation</v-list-item-title>
          </v-list-item-content>
          </v-list-item>

        </Can>
      </Can>

      <v-list-item route :to="{ name: 'profile' }">
        <v-list-item-action>
            <v-icon>mdi-account</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Manage Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item route :to="{ name: 'policies' }">
        <v-list-item-action>
            <v-icon>mdi-file-document-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Policies</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item route :to="{ name: 'notifications' }">
        <v-list-item-action>
            <v-icon>mdi-bell-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    
    <v-list rounded>
      
      <v-list-item v-if="showLogout" @click="handleSubmit">
        <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import UserAvatar from '@/components/user/Avatar'

export default {
  components: {
    UserAvatar
  },
  props: {
    user: Object,
    showLogout: Boolean
  },
  data() {
    return {
      mobileMenu: false,
      images: {
        logo: require('@/images/logo-crysp-light.png')
      }
    }
  },
  computed: {
    ...mapState({
      userOrganisation: state => state.ORG_STORE.myorganisation
    })
  },
  methods: {
    handleSubmit: function() {
      this.$router.push({ name: 'logout' }, () => {})
    }
  },
}
</script>
