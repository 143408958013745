<template>
    <v-card>
        <v-card-title>Test Buttons</v-card-title>
        <v-card-text>
            <v-text-field label="Recipient" v-model="recipient"></v-text-field>
            
            <v-btn @click="sendTestEmail">Send Test Email</v-btn>
            <!-- <v-btn @click="sendTestPolicyMill">Send Test Policy Mill</v-btn> -->
            <!-- <v-btn @click="sendAlertEmail">Send Alert Email</v-btn> -->
            <v-btn @click="getCurrentUsers">Get Current Users</v-btn>
            <v-btn @click="getGroups">Get Groups</v-btn>
            <v-btn @click="getUserGroups">Get User's Groups</v-btn>
            <v-btn @click="getGroupUsers">Get Group User's</v-btn>

            <p>{{ result }}</p>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../_store/axios'

export default {
    data() {
        return {
            result: '',
            recipient: ''
        }
    },
    onMounted() {
        console.log('Test Functions component mounted')
    },
    methods: {
        sendTestEmail() {
            // Code to send a test email goes here
            let token = localStorage.getItem('token');

            axios.post('/mail/send',
                {
                    reseller_id: '0',
                    organisation_id: '0',
                    recipients: [this.recipient],
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }).then(response => {
                    this.result = response.data;
                }).catch(error => {
                    this.result = error;
                });
        },
        sendTestPolicyMill() {
            // Code to send test policy mill
        },
        sendAlertEmail() {
            // Code to send alert email
        },
        getCurrentUsers() {
            let token = localStorage.getItem('token');
            let microsoftToken = localStorage.getItem('microsoftToken');

            // call the /microsoftUsers endpoint
            axios.post('/microsoftUsers',{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data:{
                        microsoftToken: microsoftToken
                    }
                })
                .then(response => {
                    // handle the response
                    response = JSON.stringify(response.data);
                    return response;
                })
                .catch(error => {
                    // handle the error
                });
        },
        getGroups() {
            // Code to get groups
            let token = localStorage.getItem('token');
            let microsoftToken = localStorage.getItem('microsoftToken');

            // call the /microsoftGroups endpoint
            axios.post('/microsoftGroups',{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data:{
                        microsoftToken: microsoftToken
                    }
                })
                .then(response => {
                    // handle the response
                    response = JSON.stringify(response.data);
                    return response;
                })
                .catch(error => {
                    // handle the error
                });
        },
        getGroupUsers() {
            // Code to get groups
            let token = localStorage.getItem('token');
            let microsoftToken = localStorage.getItem('microsoftToken');

            // call the /microsoftGroups endpoint
            axios.post('/microsoftGroupUsers',{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data:{
                        microsoftToken: microsoftToken,
                        groupId: '9b067706-7c23-410d-8d43-076b08cfe5ce',
                    }
                })
                .then(response => {
                    // handle the response
                    response = JSON.stringify(response.data);
                })
                .catch(error => {
                    // handle the error
                });
        },
        getUserGroups() {
            // Code to get groups
            let token = localStorage.getItem('token');
            let microsoftToken = localStorage.getItem('microsoftToken');

            // call the /microsoftGroups endpoint
            axios.post('/microsoftGroupUsers',{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data:{
                        microsoftToken: microsoftToken,
                        userId: '"bb3ae89f-1956-4420-b576-f0c56cdcdf04',
                    }
                })
                .then(response => {
                    // handle the response
                    response = JSON.stringify(response.data);
                })
                .catch(error => {
                    // handle the error
                });
        }
    }
}
</script>