<template>
  <div class="table-toolbar w-100">
    <v-row class="ma-1 align-center table-toolbar__filter-row  justify-end">
      <v-col
        class="ma-0 pa-1 table-toolbar__filter-title"
        cols="2"
        :class="filterOn ? 'font-weight-medium' : null"
      >
        <div class="pa-1 filter-icon-parent">
          <span v-if="filterOn" class="filter-on-display" />
          <v-icon :color="filterOn ? '#fd7e14' : null">mdi-filter</v-icon>
        </div>
        Filter By:
      </v-col>
      <v-col
        class="ma-0 pa-1 table-toolbar__status-toggle"
        cols="2"
        v-if="hide.length == 0 || !hide.includes('statuses')"
      >
        <v-btn-toggle v-model="selectedStatuses" multiple class="px-5">
          <v-btn icon value="2" color="success" small :disabled="isDisabled">
            <v-icon medium>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn icon value="4" color="error" small :disabled="isDisabled">
            <v-icon medium>mdi-alert</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col class="ma-0 pa-1 table-toolbar__select-tags" cols="8"  v-if="tags?.length > 0">

        <VMultipleSelect
          ref="tagsSelect"
          :options="tags"
          :dense="true"
          :singleLine="true"
          :disabled="isDisabled"
          chipCloseIcon="mdi-minus"
          label="Tags"
          @updateSelected="(newValue) => (selectedTags = newValue)"
        />
      </v-col> 
      <v-col :cols="tags?.length > 0 ? 12 : 8" no-gutters class="d-flex justify-end align-center">
        <v-btn
          class="elevation-0 pa-2 clear-filters-btn"
          @click="clearFilters"
          v-if="filterOn"
        >
          Clear All Filters
        </v-btn>
  
        <div
          class="mx-1 table-toolbar__search"
          v-if="hide.length == 0 || !hide.includes('search')"
        >
          <v-text-field
            outlined
            v-model="searchItem"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            clearable
            :disabled="isDisabled"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VMultipleSelect from "@/components/display/VMultipleSelect.vue";

export default {
  name: "TableFilterToolBar",
  components: {
    VMultipleSelect,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    initialSelectedStatuses: {
      type: Array,
      default: () => [],
    },
    initialSelectedTags: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedStatuses: [],
      selectedTags: [],
      searchItem: "",
    };
  },

  created() {
    this.selectedStatuses = this.initialSelectedStatuses;
    this.selectedTags = this.initialSelectedTags;
  },

  computed: {
    filterOn() {
      return (
        this.selectedStatuses.length > 0 ||
        this.selectedTags.length > 0 ||
        this.searchItem?.length > 0
      );
    },
  },

  watch: {
    selectedStatuses(newValue) {
      this.$emit("updateStatuses", newValue);
    },

    selectedTags(newValue) {
      this.$emit("updateTags", newValue);
    },

    searchItem(newValue) {
      let word = newValue;
      if (!word) {
        word = "";
      }
      this.$emit("updateSearch", word);
    },
  },

  methods: {
    clearFilters() {
      this.selectedStatuses = [];
      this.selectedTags = [];
      this.searchItem = "";
      this.$refs.tagsSelect.clearSelected();
    },
  },
};
</script>

<style scoped>
.table-toolbar__filter-title {
  font-size: 1.1rem;
  position:absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 705px) {
  .table-toolbar__filter-title {
    font-size: 0.9rem;
  }
}
.filter-icon-parent {
  display: inline-block;
  position: relative;
}
.filter-on-display {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #fd7e14;
}
.table-toolbar {
  width: 100%;
  padding: 2px;
  border: 1px solid #f0f0f0;
}
.table-toolbar__filter-row {
  position: relative;
}

.table-toolbar__search {
  width: 45%;
}

.table-toolbar__status-toggle .v-btn i {
  color: #575656 !important;
}

.table-toolbar__status-toggle .v-btn i:hover,
.table-toolbar__status-toggle .v-btn--active i {
  color: #333333 !important;
}

.clear-filters-btn {
  font-size: 0.6rem;
  background-color: transparent !important;
}
</style>

<style>
.table-toolbar__select-tags .v-select__selections {
  display: inline-block;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  margin: 3px;
}

.table-toolbar__select-tags .v-select__selections input {
  display: none;
}
</style>
