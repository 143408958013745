<template>
  <v-content>
    <v-container fluid>
      <v-overlay :value="processingExport" style="display:flex; justify-content:center; align-items:center">

        <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
        Processing Data for Export

      </v-overlay>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="6">
          <v-page-header value="Manage Compliance"></v-page-header>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-row align="center" justify="center" v-if="totals.total == 0">
          <v-col cols="12" md="8" xl="6">
            <v-card class="pa-10 mb-8">
              <div class="text-center mb-6">You are not managing any compliance items.</div>
              <div class="text-center">
                <v-btn
                  color="primary"
                  :to="{ name: 'dashboard' }"
                >
                  Return to Dashboard
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-row align="center" justify="center">
          <v-col cols="12" md="10" xl="6">

            <ViewCompliancesTotalStatus :statusData="compliancesStats" :isLoading="isLoading" />

            <div class="alert" v-if="totals.warn > 0 || totals.fail > 0 ">
              <v-icon medium class="mr-2">mdi-information</v-icon>
              <span>You have compliance items that require reviewing. <router-link :to="{ name: 'compliance/calendar' }">Review compliance <v-icon medium color="#FB8C00">mdi-chevron-right</v-icon></router-link></span>
            </div>


            <v-card outlined class="mb-3">
              <v-card-title>
                <v-row no-gutters>
                  <v-col cols="12" md="6" class=" mb-2 mb-md-0">
                      All Compliance
                  </v-col>
                  <v-col cols="12" md="3" class="text-center mb-2 mb-md-0">
                    <v-btn
                        :to="{ name: 'compliance/calendar' }"
                        text
                        :disabled="isLoading"
                        class="ml-2 flex-grow-1"
                      >
                      <v-icon medium class="mr-2">mdi-calendar</v-icon> Calendar
                    </v-btn>
                  </v-col>
                  <v-divider dark></v-divider>
                  <v-col cols="12" md="3" class="d-flex">
                    <v-menu right bottom>
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-btn
                          text
                          :disabled="isLoading"
                          class="ml-2 flex-grow-1"
                          v-bind="attrs"
                          v-on="{ ...menu }"
                          
                        >
                          <v-icon medium class="mr-2">$vuetify.icons.values.download</v-icon> Compliance Report
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click.stop="processExport()">
                          <v-list-item-icon class="mr-3">
                            <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>

            <ViewComplianceTableVue ref="complianceTable"  @updateNextReviewDate="updateNextReviewDate"  @manageComplianceItem="handleManageComplianceEvent" />
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="managingComplianceItem == 'action' && !isLoading">
          <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!Updating">
            <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
              Update Compliance Status
              <v-btn icon @click.stop="manageComplianceItem(false,null)">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-state-machine</v-icon>
                Name
              </span><br />
              <v-divider class="my-2"></v-divider>
              {{ toManage.name }}
            </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-checkbox-blank-circle</v-icon>
                Status
              </span><br />
              <v-divider class="my-2"></v-divider>
              <div v-html="toManage.complianceState.name"></div>
            </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-text-box-outline</v-icon>
                Description
              </span><br />
              <v-divider class="my-2"></v-divider>
              <div v-html="toManage.description"></div>
            </v-card-text>

            <v-divider></v-divider>
            <div class="px-2 pt-4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto" 
                >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Actioned on"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    
                  ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toManage.actioned_on"
                    :show-adjacent-months="true"
                    @input="menu2 = false"
                    :max="$moment().format('YYYY-MM-DD')"
                  >
                  </v-date-picker>
                </v-menu>
            </div>

            <div class="px-2 py-4">
              <v-select
                :items="complianceActions"
                v-model="toManage.action"
                item-text="name"
                item-value="id"
                label="Select Action"
                outlined
                hide-details
              ></v-select>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click.stop="manageComplianceItem(false,null)">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn color="primary" @click.stop="handleActionComplianceItem">
                Update
              </v-btn>

            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="Updating">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="managingComplianceItem == 'update' && !isLoading">
          <v-card class="mx-auto scrollable-content" max-width="480" min-width="420" light v-if="!isUpdating">
            <v-card-title class="primary primary--text--contrast mb-4 justify-space-between">
              Manage Compliance Item
              <v-btn icon @click.stop="manageComplianceItem(false,null)">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-state-machine</v-icon>
                Name
              </span><br />
              <v-divider class="my-2"></v-divider>

              <v-row dense class="pt-2">
                <!-- Compliance item name -->
                <v-col cols="12">
                  <VTextFieldWithValidation rules="required" v-model="toManage.name" label="Name" hideDetails />
                </v-col>
                <!-- Compliance item frequency -->
                <v-col  cols="12" >
                  <div class="d-flex align-center">
                    <v-col cols="4">
                      <span class="mr-2">Repeat every</span>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-if="toManage.frequencyUnit !== 'None'"
                        v-model="toManage.frequencyValue"
                        label="Frequency"
                        @input="updateNextReviewDate()"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="toManage.frequencyUnit"
                        :items="reviewFrequencies"
                        item-text="name"
                        item-value="value"
                        label="Review Frequency"
                        hide-details
                        outlined
                        @change="updateNextReviewDate()"
                      ></v-select>
                    </v-col>
                  </div>
                </v-col>

                <!-- Compliance item warning -->
                <v-col cols="12" sm="6" offset-sm="6" v-if="toManage.next_review_date !== null">
                  <VNumberFieldWithValidation
                    rules="numeric"
                    v-model="toManage.warning"
                    label="Warning Threshold"
                    hideDetails
                    suffix="day(s)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="pt-0">
                  <small>For one-time events that do not repeat, leave these values blank or set to 0.</small>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-checkbox-blank-circle</v-icon>
                Status
              </span><br />
              <v-divider class="my-2"></v-divider>
              <div v-html="toManage.complianceState.name"></div>
            </v-card-text>

            <v-card-text class="pt-1">
                  <span class="overline">
                    <v-icon small>mdi-select-group</v-icon>
                    Tags
                  </span><br />
                  <v-divider class="my-2"></v-divider>
                  <div   v-if="complianceTags.length > 0">
                    <VMultipleSelect 
                  
                    :initialSelected="toManage.tags" 
                    label="Select Tags for the Item" 
                    :options="complianceTags"

                    @updateSelected="(newValue) => toManage.tags = newValue"
                    />
                  </div>
                </v-card-text>

            <v-card-text class="pt-1">
              <span class="overline">
                <v-icon small>mdi-text-box-outline</v-icon>
                Description
              </span><br />
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <TextEditor v-model="toManage.description" light :label="`Description`" />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click.stop="manageComplianceItem(false,null)">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn color="primary" @click.stop="handleUpdateComplianceItem">
                Update
              </v-btn>

            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="Updating">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-row align="center" justify="center" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VNumberFieldWithValidation from '@/components/inputs/VNumberFieldWithValidation'
import TextEditor from '@/components/inputs/TextEditor'

import UploadModal from '@/components/upload/UploadModal'

import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import TableFilterToolBar from '@/components/compliance/TableFilterToolBar.vue'
import VMultipleSelect from '../../components/display/VMultipleSelect.vue'
import ViewCompliancesTotalStatus from './ViewCompliancesTotalStatus.vue'
import ViewComplianceTableVue from './ViewComplianceTable.vue'
import { watch } from 'vue'

export default {
  name: 'ViewCompliancy',
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  components: {
    VTextFieldWithValidation,
    VNumberFieldWithValidation,
    TextEditor,
    UploadModal,
    TableFilterToolBar,
    VMultipleSelect,
    ViewCompliancesTotalStatus,
    ViewComplianceTableVue
  },
  data() {
    return {
      menu2: false,
      search: [],
      filtersMap: {
        statuses: [],
        tags: [],
        searchTerm: ""
      },
      managingComplianceItem: null,
      uploadingAttachment: false,
      toManage: {},
      Updating: false,
      isUpdating: false,
      complianceActions: [
        { id: 1, name: "Mark as Compliant" },
        { id: 2, name: "Mark as Warning" },
        { id: 0, name: "Mark as Outstanding" }
      ],
      items: [],
      totals: {
        total: 3,
        pass: 0,
        warn: 1,
        fail: 0
      },
      reviewFrequencies: [
        'None',
        'Days',
        'Weeks',
        'Months',
        'Years'
      ],
      formattedNextReviewDate: null,
      processingExport: false
    }
  },
  computed: {
    ...mapState({
      compliancesStats: state => state.CONTENT_STORE.compliancesStats,
      complianceItems: state => state.CONTENT_STORE.paginatedUserComplianceItems,
      isLoading: state => state.CONTENT_STORE.loadingComplianceStats,
      loadingAllComplianceItems: state => state.CONTENT_STORE.loadingUserComplianceItems,
      complianceTags: state => state.ORG_STORE.orgComplianceTags || [],
      allComplianceItems: state => state.CONTENT_STORE.userComplianceItems
    }),
    filteredItems() {
  // Pre-calculate conditions that don't change per item
  const hasStatusFilter = this.filtersMap.statuses.length > 0;
  const statusesSet = new Set(this.filtersMap.statuses.map(String));
  const hasTagFilter = this.filtersMap.tags.length > 0;
  const tagsSet = new Set(this.filtersMap.tags);
  const searchTerm = this.filtersMap.searchTerm.toLowerCase();
  const hasSearchTerm = searchTerm.length > 0;

  return this.items?.map(item => {
    // Clone the item shallowly if possible or as needed
    const clonedItem = { ...item };
    
    clonedItem.items = Object.values(item.items).filter(i => {
      const matchedStatus = !hasStatusFilter || statusesSet.has(i.complianceState.id.toString());
      const matchedTags = !hasTagFilter || i.tags.some(t => tagsSet.has(t.id));
      const matchedSearch = !hasSearchTerm || i.name.toLowerCase().includes(searchTerm);

      return matchedStatus && matchedTags && matchedSearch;
    });

    return clonedItem;
  });
},
    itemTotals() {
      if (!this.filteredItems) {
        return []
      }

      let allTotals = [];

      this.filteredItems.forEach(item => {
       let totals = {
          total: 0,
          pass: 0,
          warn: 0,
          fail: 0
        }
        
        totals.total = item.items.length
        totals.pass = item.items.filter(i => i.complianceState.id === 2).length
        totals.warn = item.items.filter(i => i.complianceState.id === 3).length
        totals.fail = item.items.filter(i => i.complianceState.id === 4).length
        allTotals.push(totals)
      })
      return allTotals
    },
    itemsTotalSum() {
      const sumTotals = this.itemTotals?.reduce((acc, item) => {
        acc.total += item.total
        acc.pass += item.pass
        acc.warn += item.warn
        acc.fail += item.fail
        return acc
      }, {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0
      }

)
      return sumTotals
    },
    formattedDate() {
      //return nice date
      return this.$moment(this.toManage.actioned_on).format('DD/MM/YYYY');
    },

  },
  methods: {
    handleActionComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isActioning = true

      let variables = {
        content_id:         parseInt(this.toManage.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        pass_or_failed:     parseInt(this.toManage.action),
        actioned_on:        `${this.$moment(this.toManage.actioned_on).format('YYYY-MM-DD')} ${this.$moment().format('HH:mm:ss')}`
      }

      this.$http.post('content/compliance/action', variables)
        .then(response => {
          this.isActioning = false

          this.$toast.success('Successfully actioned compliance item.')

          this.$refs.complianceTable.refreshPage()
          this.manageComplianceItem(false,null)
        })
        .catch(error => {
          this.isActioning = false

          this.$toast.error('Something went wrong actioning compliance item, please try again.')
        })
    },
    handleUpdateComplianceItem() {
      if (!this.toManage) {
        return
      }

      this.isUpdating = true

      let variables = {
        content_id:         parseInt(this.toManage.content_id),
        compliance_item_id: parseInt(this.toManage.id),
        name:               this.toManage.name,
        description:        this.toManage.description,
        warning:            parseInt(this.toManage.warning),
        frequency:          parseInt(this.toManage.frequency),
        tags:               this.toManage.tags
      }

      this.$http.post('content/compliance/update', variables)
        .then(response => {
          this.isUpdating = false

          this.$toast.success('Successfully updated compliance item.')

          this.$refs.complianceTable.refreshPage()
          this.manageComplianceItem(false,null)
        })
        .catch(error => {
          this.isUpdating = false

          this.$toast.error('Something went wrong updating compliance item, please try again.')
        })
    },
    handleUploadedAttachment(data,content) {
      if (!data) {
        return
      }

      this.isUploadingAttachment = true

      let variables = {
        content_id: parseInt(content.content_id),
        file: data[0].meta.path,
        name: data.name,
        description: data.description
      }

      this.$http.post('content/attachment', variables)
        .then(response => {
          if (response.data) {
            this.isUploadingAttachment = false

            this.$toast.success('Successfully uploaded attachment.')
          } else {
            this.$toast.error('Something went wrong uploading attachment, please try again.')
          }
        })
        .catch(error => {
          this.isUploadingAttachment = false

          this.$toast.error('Something went wrong uploading attachment, please try again.')
        })
    },
    handleManageComplianceEvent({ item, method }) {
     this.manageComplianceItem(item, method)
    },
    manageComplianceItem(item,method) {
      this.managingComplianceItem = method
      this.toManage.action = false
      this.toManage = item ? {...item} : {}
      this.toManage.tags = this.toManage?.tags?.map(tag => tag.id)
      
      this.toManage.last_review_date = this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null ? this.toManage.created_at : this.toManage.latest_action.actioned_on; 
      this.toManage.frequency = this.toManage.frequency == null ? 0 : this.toManage.frequency; 

      this.$set(this.toManage,'next_review_date',this.$moment(this.toManage.last_review_date).add(this.toManage.frequency,'d'));
  
      if (this.toManage.frequency == 0) {
        this.toManage.frequencyValue = null;
        this.toManage.frequencyUnit = 'None';
      } else {
        //if the frequency is a multiple of 365, then it is a year
        if (this.toManage.frequency % 365 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 365;
          this.toManage.frequencyUnit = 'Years';
        } else if (this.toManage.frequency % 30 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 30;
          this.toManage.frequencyUnit = 'Months';
        } else if (this.toManage.frequency % 7 == 0) {
          this.toManage.frequencyValue = this.toManage.frequency / 7;
          this.toManage.frequencyUnit = 'Weeks';
        } else {
          this.toManage.frequencyValue = this.toManage.frequency;
          this.toManage.frequencyUnit = 'Days';
        }

        this.formattedNextReviewDate = this.toManage.next_review_date.format('DD/MM/YYYY');
      }
    },
    uploadAttachment() {
      this.uploadingAttachment = !this.uploadingAttachment
    },
    getColor(state) {
      if (state == 2) {
        return '#4caf50'
      }

      if (state == 3) {
        return '#fb8c00'
      }

      if (state == 4) {
        return '#ff5252'
      }
    },
    processExport() {
      this.processingExport = true;

      if (this.allComplianceItems.length == 0) {
        this.$store.dispatch('CONTENT_STORE/GET_USER_COMPLIANCE_ITEMS')
        return
      }

      this.exportAllAsPDF();
    },
    exportAllAsPDF() {
      const doc = new jsPDF()

      doc.setFontSize(16)
      doc.text('System', 14, 22)
      doc.text(`Crysp Compliance Report - ${this.$moment().format('DD/MM/YYYY')}`, 14, 30)
      doc.setFontSize(11)
      doc.setTextColor(100)
      doc.text('All Compliance', 14, 40)

      this.allComplianceItems.forEach((group,index) => {
        let bodyItems = []
        let posY = index == 0 ? 55 : doc.lastAutoTable.finalY + 10
        doc.text(group.content_name, 14, posY)
        group.items.forEach(item => {
          let daysRemaining = () => {
              if (item.latest_action) {
                let date = item.latest_action ? this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd') : this.$moment().add(item.frequency, 'd')
                let today = this.$moment()
                return date.diff(today, 'days')
              }
          }

          let filtered = {
            name: { content: item.name, styles: { cellWidth: 60 } },
            status: { content: '', styles: { fillColor: this.getColor(item.complianceState.id), minCellWidth: 5 } },
            tags: item.tags.map(tag => tag.name).join(', '),
            created_at: this.$moment(item.created_at).format("MMM DD, YYYY"),
            actioned_at: item.latest_action ? this.$moment(item.latest_action.actioned_date).format("MMM DD, YYYY") : 'Never',
            due_at: item.latest_action ? this.$moment(item.latest_action.actioned_date).add(item.frequency, 'd').format("MMM DD, YYYY") : this.$moment().format("MMM DD, YYYY"),
            days_remaining: daysRemaining()
          }

          bodyItems.push(filtered);
        });
        autoTable(doc, {
          columns: [
            { dataKey: 'name', header: 'Name' },
            { dataKey: 'status', header: 'Status' },
            { dataKey: 'tags', header: 'Tags' },
            { dataKey: 'created_at', header: 'Date Created On' },
            { dataKey: 'actioned_at', header: 'Last Actioned' },
            { dataKey: 'due_at', header: 'Next Due' },
            { dataKey: 'days_remaining', header: 'Days Remaining' }
          ],
          body: bodyItems,
          startY: index == 0 ? 60 : doc.lastAutoTable.finalY + 15,
          theme: 'plain',
          headStyles: { lineWidth: 0.25 },
          bodyStyles: { lineWidth: 0.25 }
        })
      });
      doc.save(`crysp-compliance-report.pdf`)
      this.processingExport = false;
    },
    updateNextReviewDate() {
      let value = this.toManage.frequencyValue;
      const unit = this.toManage.frequencyUnit;
      const date = (this.toManage.latest_action == null || this.toManage.latest_action.actioned_on == null) ? this.toManage.created_at : this.toManage.latest_action.actioned_on;

      if(unit == 'None'){
        this.toManage.next_review_date = null;
        this.formattedNextReviewDate = null;
        this.toManage.frequencyValue = 0;
        this.toManage.frequency = 0;
        this.toManage.warning = 0;
        return;
      }

      if (value !== 0) {
        switch (unit) {
          case 'Days':
            // console.log('Days');
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'d');
            break;
          case 'Weeks':
            // console.log('Weeks');
            value = value * 7;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'w');
            break;
          case 'Months':
            // console.log('Months');
            value = value * 30;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'M');
            break;
          case 'Years':
            // console.log('Years');
            value = value * 365;
            this.toManage.next_review_date = this.$moment.utc(date).add(value,'y');
            break;
        }

        // this.toManage.next_review_date = this.$moment.utc(date).add(value,'d');
        this.toManage.frequency = value;
      } else {
        //set the value to the default value of 1
        this.toManage.frequencyValue = 1;

        this.updateNextReviewDate();
      }

      this.formattedNextReviewDate = this.$moment(this.toManage.next_review_date).format('DD/MM/YYYY');

      this.toManage.warning = Math.min(this.toManage.warning, value);
    },
  },
  created() {
    this.$store.dispatch('CONTENT_STORE/GET_COMPLIANCES_STATS');
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Manage Compliance' }
    ])

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'CONTENT_STORE/SET_USER_COMPLIANCE_ITEMS') {

        // Do whatever makes sense now
        if (state.CONTENT_STORE?.userComplianceItems?.length > 0 && this.processingExport) {
          this.exportAllAsPDF();
        }
      }
    });
  },
  beforeDestroy() {
    this.processingExport = false;
    this.unsubscribe();
  }
}
</script>

<style scoped>

  .card-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .bg-light {
    background: #fff;
  }

  .alert {
    background:#fff6ec;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    font-size: 14px;
    color: #000;
  }

  .alert span, .alert i  { color: #000; }

  .alert a { color: rgb(251, 140, 0);  }

  .scrollable-content {
    max-height: 640px; /* Adjust as needed */
    overflow-y: auto;
  }
</style>