<template>
  <div>

    <h4 class="mb-4" v-if="label">{{ label }}</h4>

    <p v-if="prefix">{{ prefix }}</p>

    <v-card outlined>
      <trumbowyg 
        v-model="content"
        :placeholder="placeholder"
        :config="getConfig()"
      ></trumbowyg>
    </v-card>

  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [Boolean, String],
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    content: ``,
    configDefault: {
      urlProtocol: true,
      btnsDef: {
          formattingCustom: {
              title: 'Formatting',
              dropdown: ['p','h2','h3','h4','h5','h6'],
              ico: 'p',
              hasIcon: true
          }
      },
      btns: [
        ['viewHTML'],
        ['undo', 'redo'],
        ['formattingCustom'],
        ['strong', 'em', 'del'],
        ['link'],
        ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['removeformat'],
        ['fullscreen']
      ]
    },
    configLight: {
      urlProtocol: true,
      btns: [
        ['p'],
        ['undo', 'redo'],
        ['formattingCustom'],
        ['strong', 'em', 'del'],
        ['unorderedList', 'orderedList'],
        ['link']
      ]
    }
  }),
  watch: {
    content(payload) {
      this.$emit('input', payload)
    }
  },
  mounted() {
    if (this.value)
    {
      this.content = this.value;
      console.log(this.content)
    }
  },
  methods: {
    getConfig() {
      return this.light ? this.configLight : this.configDefault
    }
  }
}
</script>

<style>
  .trumbowyg {
    border: none !important;
    margin: 0 !important;
  }

  .trumbowyg-box,
  .trumbowyg-editor { 
    min-height: 96px !important;
  } 

  .trumbowyg-editor {
    height: auto !important;
  }

  .trumbowyg-fullscreen {
    padding: 32px 5vw !important;
    background-color: rgba(0,0,0,0.5) !important;
    animation: fade 0.1s linear !important;
  }

  @keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  .trumbowyg.trumbowyg-fullscreen .trumbowyg-editor,
  .trumbowyg.trumbowyg-fullscreen .trumbowyg-textarea {
    height: calc(100% - 49px) !important;
    background-color: #FFF !important;
  }

  .trumbowyg-fullscreen .trumbowyg-button-pane {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .trumbowyg-fullscreen .trumbowyg-editor {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .trumbowyg-button-pane {
    padding: 0 !important;
    border-bottom: 0 !important;
    z-index: 1 !important;
  }

  .trumbowyg-button-pane::after {
    display: none !important;
  }

  .trumbowyg-button-pane button {
    width: 48px !important;
    height: 48px !important;
    border-radius: 4px !important;
  }

  .trumbowyg-button-pane .trumbowyg-button-group::after {
    display: none !important;
  }

  .trumbowyg-box svg,
  .trumbowyg-modal svg {
    width: 20px !important;
  }

  .trumbowyg,
  .trumbowyg-modal {
    font-family: "Roboto", sans-serif !important;
    line-height: 1.5 !important;
  }

  .trumbowyg-modal-button {
    border-radius: 4px !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    letter-spacing: 0.0892857143em !important;
    text-indent: 0.0892857143em !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
  }
</style>
