import axios from '../axios'

const initialState = () => {
  return {
    loadingPolicies: false,
    loadingObservations: false,
    policies: [],
    live_policies: [],
    archived_policies: [],
    compliance_policies: [],
    observations: [],
    cache: {
      policies: {
        lifetime: 30000,
        microtime: 0
      },
      live_policies: {
        lifetime: 30000,
        microtime: 0
      },
      archived_policies: {
        lifetime: 30000,
        microtime: 0
      },
      compliance_policies: {
        lifetime: 30000,
        microtime: 0
      },
      observations: {
        lifetime: 30000,
        microtime: 0
      },
    }
  }
}

const state = initialState()

const getters = {
  getPolicies: state => state.policies,
  getLivePolicies: state => state.live_policies,
  getArchivedPolicies: state => state.archived_policies,
  getCompliancePolicies: state => state.compliance_policies,
  getObservations: state => state.observations
}

const mutations = {
  CREATE_POLICY: (state, item) => {
    state.policies.push(item)
  },

  LOADING_POLICIES: state => state.loadingPolicies = true,

  LOADING_OBSERVATIONS: state => state.loadingObservations = true,

  ARCHIVE_POLICY: (state, id) => {
    state.live_policies.find(group => {
      let index = group.policies.findIndex(policy => policy.id === id);
      group.policies.splice(index, 1);
    });
  },

  UPDATE_OBSERVATION: (state, id) => {
    let index = state.observations.findIndex(observation => observation.id === id)
    state.observations[index].status = 2
  },

  SET_POLICIES: (state, policies) => {
    state.loadingPolicies = false
    state.policies = policies
  },

  SET_LIVE_POLICIES: (state, policies) => {
    state.loadingPolicies = false
    state.live_policies = policies
  },

  SET_ARCHIVED_POLICIES: (state, policies) => {
    state.loadingPolicies = false
    state.archived_policies = policies
  },

  SET_COMPLIANCE_POLICIES: (state, policies) => {
    state.loadingPolicies = false
    state.compliance_policies = policies
  },

  SET_OBSERVATIONS: (state, observations) => {
    state.loadingObservations = false
    state.observations = observations
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  GET_POLICIES({commit, dispatch, rootState}, options) {
      commit('LOADING_POLICIES')

      let cacheStatus = (Date.now() - state.cache.policies.microtime) < state.cache.policies.lifetime && state.cache.policies.microtime > 0
        ? true
        : false

      let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

      if (sortBy == 'unix') {
        sortBy = 'updated_at';
      }

      let variables = {
        offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
        limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
        sortBy: sortBy,
        sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
        search: options.search
      }

      if (rootState.AUTH_STORE.authUser) {
        variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
        variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
      }

      if (options.organisation) {
        variables['organisation_id'] = parseInt(options.organisation.id)
      }

      commit('SET_POLICIES', [])

      axios.get('policy', {
        params: variables
      }).then(({ data }) => {
        commit('SET_POLICIES', data.data)
      }, error => {
        console.error('Something has gone wrong getting policies.', error)
      })
  },

  GET_LIVE_POLICIES({commit, dispatch, rootState}, options) {
    commit('LOADING_POLICIES')

    let cacheStatus = (Date.now() - state.cache.policies.microtime) < state.cache.policies.lifetime && state.cache.policies.microtime > 0
      ? true
      : false

    let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

    if (sortBy == 'unix') {
      sortBy = 'updated_at';
    }

    let variables = {
      offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
      limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
      sortBy: sortBy,
      sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
      search: options.search
    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
    }

    if (options.organisation) {
      variables['organisation_id'] = parseInt(options.organisation.id)
    }

    commit('SET_LIVE_POLICIES', [])

    axios.get('policy/live', {
      params: variables
    }).then(({ data }) => {
      commit('SET_LIVE_POLICIES', data)
    }, error => {
      console.error('Something has gone wrong getting policies.', error)
    })
},

GET_ARCHIVED_POLICIES({commit, dispatch, rootState}, options) {
  commit('LOADING_POLICIES')

  let cacheStatus = (Date.now() - state.cache.policies.microtime) < state.cache.policies.lifetime && state.cache.policies.microtime > 0
    ? true
    : false

  let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

  if (sortBy == 'unix') {
    sortBy = 'updated_at';
  }

  let variables = {
    offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
    limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
    sortBy: sortBy,
    sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
    search: options.search,
    archived: 1
  }

  if (rootState.AUTH_STORE.authUser) {
    variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
    variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
  }

  if (options.organisation) {
    variables['organisation_id'] = parseInt(options.organisation.id)
  }

  commit('SET_ARCHIVED_POLICIES', [])

  // TODO test this
  // axios.get('policy/live', {
  axios.get('policy/archived', {
    params: variables
  }).then(({ data }) => {
    commit('SET_ARCHIVED_POLICIES', data)
  }, error => {
    console.error('Something has gone wrong getting policies.', error)
  })
},

GET_COMPLIANCE_POLICIES({commit, dispatch, rootState}, options) {
  commit('LOADING_POLICIES')

  let cacheStatus = (Date.now() - state.cache.policies.microtime) < state.cache.policies.lifetime && state.cache.policies.microtime > 0
    ? true
    : false

  let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

  if (sortBy == 'unix') {
    sortBy = 'updated_at';
  }

  let variables = {
    offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
    limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
    sortBy: sortBy,
    sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
    search: options.search
  }

  if (rootState.AUTH_STORE.authUser) {
    variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
    variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
  }

  if (options.organisation) {
    variables['organisation_id'] = parseInt(options.organisation.id)
  }

  commit('SET_COMPLIANCE_POLICIES', [])

  axios.get('policy/compliance', {
    params: variables
  }).then(({ data }) => {
    commit('SET_COMPLIANCE_POLICIES', data)
  }, error => {
    console.error('Something has gone wrong getting policies.', error)
  })
},

  GET_OBSERVATIONS({commit, dispatch, rootState}, options) {
    commit('LOADING_OBSERVATIONS')

    let cacheStatus = (Date.now() - state.cache.observations.microtime) < state.cache.observations.lifetime && state.cache.observations.microtime > 0
      ? true
      : false

    let sortBy = options.sortBy !== undefined && options.sortBy.length > 0 ? options.sortBy[0] : null;

    if (sortBy == 'unix') {
      sortBy = 'updated_at';
    }

    let variables = {
      offset: (options.page * options.itemsPerPage) - options.itemsPerPage,
      limit: options.itemsPerPage !== -1 ? options.itemsPerPage : null,
      sortBy: sortBy,
      sortOrder: options.sortDesc == 'true' ? 'desc' : 'asc',
      search: options.search
    }

    if (rootState.AUTH_STORE.authUser) {
      variables['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
      variables['organisation_id'] = rootState.AUTH_STORE.authUser.organisation_id
    }

    commit('SET_OBSERVATIONS', [])

    axios.get('policy/observation', {
      params: variables
    }).then(({ data }) => {
      commit('SET_OBSERVATIONS', data.data)
    }, error => {
      console.error('Something has gone wrong getting observations.', error)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
