<template>
  <div>
    <v-col v-if="showFilters" cols="12"  class="py-1 d-flex justify-end">
      Filter by status:
      <v-btn-toggle v-model="filterStatus.statuses" multiple class="px-5">
        <v-btn icon value="2" color="success" small>
          <v-icon medium>mdi-check-circle</v-icon>
        </v-btn>
        <v-btn icon value="4" color="warning" small>
          <v-icon medium>mdi-alert</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-text-field
        outlined
        v-model="filterStatus.searchTerm"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
        clearable
      ></v-text-field>
    </v-col>
    <v-data-table
      class="v-data-table--selectable my-1"
      :headers="complianceHeaders"
      :items="filteredComplianceItems"
      :items-per-page="10"
      :custom-sort="sortComplianceItems"
      :hide-default-footer="hideFooter"
    >
      <template v-slot:item="{ item }">
        <tr
          @click.stop="
            $router.push({
              name: 'compliance',
              params: { content_id: contentId, compliance_id: item.id },
            })
          "
          class="clickable-row"
        >
          <!-- Name here -->
          <td>
            <span>{{ item.name }}</span>
          </td>
          <!-- Status chip -->
          <td>
            <v-chip
              small
              color="success"
              v-if="item.complianceState.id === 2"
            ></v-chip>
            <v-chip
              small
              color="warning"
              v-if="item.complianceState.id === 3"
            ></v-chip>
            <v-chip
              small
              color="error"
              v-if="item.complianceState.id === 4"
            ></v-chip>
          </td>

          <!-- Tags -->
          <td>
            <VChips :items="item.tags" itemName="name" />
          </td>
          <!-- Created date -->
          <td>
            <div class="d-flex align-center">
              <span>{{ parseComplianceDate(item.created_at) }}</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-center">
              <span v-if="item.latest_action && item.latest_action.actioned_on">
                {{ parseComplianceDate(item.latest_action.actioned_on) }}
                <small
                  v-if="item.actioned_by && item.actioned_by.updated_by"
                  class="d-block"
                  >By {{ item.actioned_by.updated_by }}</small
                >
              </span>
              <span v-else>Never</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-center">
              <span v-if="item.latest_action">{{
                parseNextDueDate(
                  item.latest_action.actioned_date,
                  item.frequency
                )
              }}</span>
              <span v-else>{{
                parseNextDueDate(item.created_at, item.frequency)
              }}</span>
            </div>
          </td>
          <td>
            <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    title="View compliance item"
                    :to="{
                      name: 'compliance',
                      params: { content_id: contentId, compliance_id: item.id },
                    }"
                  >
                    <v-icon medium>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    title="Update compliance status"
                    @click.stop="handleItemAction(item, 'action')"
                  >
                    <v-icon medium>mdi-update</v-icon>
                  </v-btn>
                </template>
                <span>Action</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    title="Edit compliance item"
                    @click.stop="handleItemAction(item, 'update')"
                  >
                    <v-icon medium>{{ $vuetify.icons.values.edit }}</v-icon>
                  </v-btn>
                </template>
                <span>Update</span>
              </v-tooltip>

              <v-tooltip bottom v-if="enableDelete">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :disabled="isLoading"
                    @click.stop="$emit('delete-item', item)"
                  >
                    <v-icon medium>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>

            <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">
              <v-col cols="12">
                <v-btn
                  @click.stop="handleItemAction(item, 'action')"
                  block
                  color="primary"
                  outlined
                  ><v-icon dark left>{{ $vuetify.icons.values.edit }}</v-icon>
                  Action</v-btn
                >
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <v-container
          v-if="!isChildContent"
          fluid
          class="pa-md-5 d-flex justify-end"
        >
          <v-btn
            outlined
            color="primary"
            :disabled="isLoading"
            :to="{ name: 'content/edit', params: { compliance: true } }"
          >
            <v-icon left>mdi-state-machine</v-icon>
            Add Compliance Item
          </v-btn>
        </v-container>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sortItems } from "vuetify/lib/util/helpers";
import VChips from "@/components/display/VChips";

export default {
  name: "ViewContentItemTable",
  components: {
    VChips,
  },
  props: {
    contentId: {
      type: Number,
      required: true,
    },
    complianceSearch: {
      type: String,
    },
    complianceHeaders: {
      type: Array,
      required: true,
    },
    complianceItems: {
      type: Array,
      required: true,
    },
    filtersMap: {
      type: Object,
      default: () => ({
        statuses: [],
        tags: [],
        searchTerm: "",
      }),
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isChildContent: {
      type: Boolean,
      default: false,
    },
    enableDelete: {
      type: Boolean,
      default: true,
    },
    filterItems: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterStatus: {
        statuses: [],
        tags: [],
        searchTerm: "",
      },
    };
  },
  watch: {
    complianceItems: {
      handler() {
        this.filterStatus = {
        statuses: [],
        tags: [],
        searchTerm: "",
      }
      },
      deep: true,
    },
  },
  computed: {
    filter() {
      return this.showFilters ? this.filterStatus : this.filtersMap;
    },
    filteredComplianceItems() {
      if (!this.filterItems) {
        return this.complianceItems;
      }

      return this.complianceItems.filter((i) => {
        const matchedStatus =
          this.filter.statuses.length == 0 ||
          this.filter.statuses.includes(i.complianceState.id.toString());

        const matchedTags =
          this.filter.tags.length == 0 ||
          this.filter.tags.some((tag) => i.tags.map((t) => t.id).includes(tag));

        const matchedSearch =
          !this.filter.searchTerm ||
          this.filter.searchTerm.length == 0 ||
          i.name.toLowerCase().includes(this.filter.searchTerm.toLowerCase());

        return matchedStatus && matchedTags && matchedSearch;
      });
    },
  },

  methods: {
    sortComplianceItems(items, sortBy, sortDesc, locale, customSorters) {
      if (sortBy.includes("latest_action.next_due")) {
        return items.sort((a, b) => {
          for (let i = 0; i < sortBy.length; i++) {
            let sortA = this.$moment(
              a.latest_action ? a.latest_action.actioned_date : a.created_at
            ).add(a.frequency, "d");
            let sortB = this.$moment(
              b.latest_action ? b.latest_action.actioned_date : b.created_at
            ).add(b.frequency, "d");

            if (sortDesc[i]) {
              [sortA, sortB] = [sortB, sortA];
            }

            if (sortA === null && sortB === null) {
              continue;
            }

            if (!sortA.isSame(sortB)) {
              return sortA.isAfter(sortB);
            }
          }

          return 0;
        });
      } else {
        return sortItems(items, sortBy, sortDesc, locale, customSorters);
      }
    },
    parseComplianceDate(date) {
      if (date) {
        return `${this.$moment(date).format("Do MMMM, YYYY")} at ${this.$moment(
          date
        ).format("h:mma")}`;
      }
    },
    parseNextDueDate(date, frequency) {
      if (frequency == 0) {
        return "Not Set";
      }
      if (date) {
        return `${this.$moment(date)
          .add(frequency, "d")
          .format("Do MMMM, YYYY")}`;
      }
    },
    handleItemAction(item, method) {
      this.$emit("manageComplianceItem", { item, method });
    },
  },
};
</script>

<style></style>
