<template>
  <div>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady && content">
        <v-list-item link v-for="(item, index) in items" :key="index" route :to="{ name: item.name, params: { content_id: content.id }}">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

    <v-divider></v-divider>

    <v-slide-y-transition mode="out-in">
      <v-list v-if="isReady && showPageLinks">
        <v-list-item link v-for="(item, index) in pageItems" :key="index" route :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-slide-y-transition>

    <v-slide-y-transition mode="out-in">
      <div v-if="isReady && showPageTree">
        <v-divider></v-divider>

        <v-subheader>
          Content Navigation

          <v-icon
            small
            class="ml-1 v-tree-loading-icon"
            v-if="isLoadingContentTree"
          >
            mdi-cached
          </v-icon>
        </v-subheader>
      </div>
    </v-slide-y-transition>

    <v-slide-y-transition mode="out-in">
      <v-treeview
        :items="contentTree"
        v-if="isReady && showPageTree && !isLoadingContentTree"
        open-all
        dense
        hoverable
      >
        <template slot="label" slot-scope="props">
          <router-link
            class="caption router-link"
            :to="{ name: 'content', params: { content_id: props.item.id }}"
            v-if="props.item.private !== true"
            :title="props.item.meta.name"
          >
            {{ props.item.meta.name }}
          </router-link>
          <span
            class="caption"
            v-else
          >
            {{ props.item.meta.name }}
            <v-icon small>mdi-lock</v-icon>
          </span>
        </template>
      </v-treeview>
    </v-slide-y-transition>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isReady: false,
      showPageLinks: false,
      showPageTree: false
    }
  },
  computed: {
    ...mapState({
      content: state => state.CONTENT_STORE.content,
      contentTree: state => state.CONTENT_STORE.contentItemTree,
      isLoadingContentTree: state => state.CONTENT_STORE.loadingContentItemTree
    }),
    items() {
      let i = []

      i.push({ text: "All Content", name: "content/all", icon: "mdi-text-box-multiple-outline" })

      if (this.$can('create','document')) {
        i.push({ text: "Add New Content", name: "content/create", icon: "mdi-text-box-plus-outline" })
      }

      return i
    },
    pageItems() {
      let i = []

      i.push({ text: "View Content", name: "content", icon: "mdi-text-box-outline" })

      if (this.$can('update', 'document')) {
        i.push({ text: "Edit Content", name: "content/edit", icon: "mdi-playlist-edit" })
      }

      return i
    }
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true
    }, 500);

    this.checkPageLinks(this.$router.currentRoute)
    this.checkPageTree(this.$router.currentRoute)
  },
  methods: {
    checkPageLinks(r) {
      if (r.meta) {
        this.showPageLinks = (r.meta.contentPage === true) ? true : false
      }
    },
    checkPageTree(r) {
      if (r.meta) {
        this.showPageTree = (r.meta.contentItemPage === true) ? true : false

        if (!this.showPageTree) {
          this.$store.dispatch('CONTENT_STORE/RESET_STORE', 'contentItemTree')
        }
      }
    }
  },
  watch: {
    $route (to, from) {
      this.showPageLinks = false
      this.showPageTree = false

      this.checkPageLinks(to)
      this.checkPageTree(to)
      this.$store.dispatch('CONTENT_STORE/GET_CONTENT', {id: to.params.content_id})
    }
  }
}
</script>

<style scoped>
  .v-tree-loading-icon {
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    100% { transform:rotate(-360deg); }
  }
</style>

<style>
  .v-treeview-node__root {
    position: relative;
    z-index: 0;
  }

  .v-treeview-node__content .router-link::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.04);
    opacity: 0;
    transition: opacity 0.25s linear;
  }

  .v-treeview-node__content .router-link-exact-active::before {
    opacity: 1;
  }
</style>
