import axios from '../axios'

const initialState = () => {
  return {
    groups: [],
    loadingGroups: false,
    cache: {
      groups: {
        lifetime: 30000,
        microtime: 0
      }
    }
  }
}

const state = initialState()

const getters = {}

const mutations = {
  CREATE_GROUP: (state, group) => {
    state.groups.push(group)
  },

  LOADING_GROUPS: state => state.loadingGroups = true,

  SET_GROUPS: (state, groups) => {
    state.loadingGroups = false
    state.groups = groups

    state.cache.groups.microtime = Date.now()
  },

  DELETE_GROUP: (state, id) => {
    const filtered = state.groups.filter(group => group.id !== id)
    state.groups = filtered
  },

  UPDATE_GROUP: (state, payload) => {
    state.groups[payload.index] = payload.group
  },

  RESET_STORE: (state, index) => {
    if (state[index]) {
      state[index] = JSON.parse(JSON.stringify(initialState()[index]))
    } else {
      Object.assign(state, initialState())
    }
  }
}

const actions = {
  RESET_STORE({ commit }, index) {
    commit('RESET_STORE', index)
  },

  /**
   * This method returns an array of all groups by org id
   */
  GET_GROUPS({ commit, rootState }, organisation_id) {
    commit('LOADING_GROUPS')

    let cacheStatus = (Date.now() - state.cache.groups.microtime) < state.cache.groups.lifetime && state.cache.groups.microtime > 0
      ? true
      : false

    let vars = {
      organisation_id: parseInt(organisation_id)
    }

    if (rootState.AUTH_STORE.authUser) {
      vars['reseller_id'] = rootState.AUTH_STORE.authUser.reseller_id
    }

    axios.get('group', {
      params: vars
    }).then(({ data }) => {
      commit('SET_GROUPS', data.data)
    }, error => {
      console.error('Something has gone wrong getting groups.', error)
    })
  },

  /**
   * Update a group, update store groups
   */
  UPDATE_GROUP({ commit }, updated) {
    const groups = state.groups

    for(var i = 0; i < groups.length; i++) {
      if (updated.id === groups[i].id) {
        commit('UPDATE_GROUP', {
          index: i,
          group: updated
        })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
