<template>
  <v-content>
    <v-container fluid>

      <v-row justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Create New Content"></v-page-header>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-card>
            <v-card-title class="primary primary--text--contrast" style="position: relative;">
              Content Builder

              <span style="position: absolute; left: 0; bottom: 0; width: 100%; height: 1px; background-color: #000; opacity: 0.05;"></span>
            </v-card-title>

            <v-row justify="space-between" v-if="!isCreating && !isLoading">
              <v-col class="py-0">

                <ValidationObserver v-slot="{ invalid, validated, passes }">
                  <v-form @submit.prevent="">

                    <v-tabs dark background-color="primary" v-model="activeTab">
                      <v-tab>
                        <v-icon left>mdi-file-document</v-icon>
                        Content
                      </v-tab>
                      <v-tab>
                        <v-icon left>mdi-state-machine</v-icon>
                        Compliance Items
                      </v-tab>
                      <v-tab>
                        <v-icon left>mdi-cog</v-icon>
                        Settings
                      </v-tab>

                      <!-- Details -->
                      <v-tab-item>
                        <v-row class="px-5 py-6">
                          <!-- Content name -->
                          <v-col cols="12">
                            <VTextFieldWithValidation rules="required" v-model="contentData.name" label="Title of content" />
                            Please add a summary or key detail about your content, e.g. address of property
                          </v-col>

                          <!-- Content body -->
                          <v-col cols="12">
                            <TextEditor v-model="contentData.content_body" />
                          </v-col>
                        </v-row>
                      </v-tab-item>


                      <!-- Compliance items -->
                      <v-tab-item>
                        <v-row justify="center" class="px-5 py-2" 
                        v-if="!contentData.officers.length && !contentData.officers_group_ids.length">
                          <v-col cols="12">

                            <v-banner elevation="2">
                              <v-avatar
                                slot="icon"
                                color="grey lighten-4"
                                size="42"
                              >
                                <v-icon
                                  icon="mdi-alert"
                                  color="warning"
                                >
                                  mdi-alert
                                </v-icon>
                              </v-avatar>

                              Compliance items are only visible to officers. Please ensure you assign officers to your content, which can be done in the settings tab.

                            </v-banner>

                          </v-col>

                        </v-row>

                        <!-- EXPANDED: Compliance item expansion panel -->
                        <v-row justify="center" class="px-8 py-6">
                          <v-expansion-panels multiple focusable v-if="contentData.compliance_items.length" v-model="panel">
                            <v-expansion-panel v-for="(item, i) in contentData.compliance_items" :key="i">
                              <v-expansion-panel-header>
                                <template v-slot:default="{ open }">
                                  <v-row no-gutters>
                                    <v-col cols="12" class="text--secondary">
                                      <v-fade-transition leave-absolute>
                                        <span v-if="open">New Compliance Item</span>
                                        <v-row v-else no-gutters style="width: 100%" class="align-center">
                                          <v-col cols="4">Name: {{ item.name || 'Not Set' }}</v-col>
                                          <v-col cols="4" v-if="$vuetify.breakpoint.lgAndUp">Frequency: {{ item.frequency > 0 ? item.frequencyValue + ' ' + item.frequencyUnit : 'Not Set' }}</v-col>
                                          <v-col cols="4" v-if="$vuetify.breakpoint.lgAndUp">Warning Threshold: {{ item.warning || 'Not Set' }}</v-col>
                                        </v-row>
                                      </v-fade-transition>
                                    </v-col>
                                  </v-row>
                                </template>

                                <template v-slot:actions>
                                  <v-icon>mdi-menu-down</v-icon>

                                  <v-divider vertical class="mx-4"></v-divider>

                                  <v-btn @click="remove(contentData.compliance_items, i)" fab small text class="keep-icon-upright">
                                    <v-icon small>mdi-close</v-icon>
                                  </v-btn>
                                </template>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content>
                                <v-row class="pt-2">
                                  <!-- Compliance item name -->
                                  <v-col cols="12">
                                    <VTextFieldWithValidation rules="required" v-model="contentData.compliance_items[i].name" label="Name" hideDetails />
                                  </v-col>
                                  <!-- Compliance item frequency -->
                                  <v-col cols="12" sm="12">
                                    <div class="d-flex align-center">
                                      <v-col cols="4">
                                        <span class="mr-2">Repeat every</span>
                                      </v-col>
                                      <v-col cols="3">
                                        <v-text-field v-if="contentData.compliance_items[i].frequencyUnit !== 'None'"
                                          v-model="contentData.compliance_items[i].frequencyValue"
                                          label="Frequency"
                                          hide-details
                                          @input="updateReviewValue(i)"
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="5">
                                        <v-select
                                          v-model="contentData.compliance_items[i].frequencyUnit"
                                          :items="reviewFrequencies"
                                          item-text="name"
                                          item-value="value"
                                          label="Review Frequency"
                                          hide-details
                                          outlined
                                          @change="updateNextReviewDate(contentData.compliance_items[i].frequencyValue, contentData.compliance_items[i].latest_action, i)"
                                        ></v-select>
                                      </v-col>
                                    </div>
                                  </v-col>

                                  <!-- Compliance item warning -->
                                  <v-col cols="12" sm="6" offset-sm="6" 
                                    v-if="contentData.compliance_items[i].frequencyUnit !== 'None'">
                                    <VNumberFieldWithValidation
                                      rules="numeric"
                                      v-model="contentData.compliance_items[i].warning"
                                      label="Warning Threshold"
                                      hideDetails
                                      suffix="day(s)"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <span v-if="contentData.compliance_items[i].latest_action != null && contentData.compliance_items[i].latest_action.actioned_on != null">  
                                        <!-- test: {{contentData.compliance_items[i].latest_action.actioned_on }} -->
                                    </span>
                                    <v-menu
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">


                                        <v-text-field
                                        :value="contentData.compliance_items[i].latest_action != null && contentData.compliance_items[i].latest_action.actioned_on != null ? contentData.compliance_items[i].latest_action.actioned_on : defaultDate | moment('DD/MM/YYYY')"
                                        label="Last review date"
                                        readonly
                                        hide-details
                                        outlined
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="contentData.compliance_items[i].last_review_date"
                                        @input="updateNextReviewDate(contentData.compliance_items[i].frequencyValue, contentData.compliance_items[i].latest_action, i)"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>

                                  

                                  <v-col v-if="contentData.compliance_items[i].frequencyUnit !== 'None'" cols="12" sm="6">
                                    <v-text-field
                                      :value="contentData.compliance_items[i].next_review_date | moment('DD/MM/YYYY')"
                                      label="Next review date"
                                      readonly
                                      outlined
                                      hide-details
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pt-0">
                                    <small>For one-time events that do not repeat, leave these values blank or set to 0.</small>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12">
                                    <TextEditor v-model="contentData.compliance_items[i].description" light :label="`Description`" />
                                  </v-col>
                                </v-row>
                                <v-row class="my-3">
                                <VMultipleSelect 
                                    v-if="complianceTags.length > 0"
                                  label="Select Tags for the Item" 
                                  :options="complianceTags"
                                  @updateSelected="(newValue) => contentData.compliance_items[i].tags = newValue"
                                  />

                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <div v-if="!contentData.compliance_items.length">
                            <v-chip
                              outlined
                              class="px-12"
                            >
                              No compliance items added to this content.
                            </v-chip>
                          </div>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row class="px-8 py-6">
                          <v-btn outlined color="primary" class="ml-auto" @click="addComplianceItem">
                            <v-icon dark left>mdi-state-machine</v-icon>Add Compliance Item
                          </v-btn>
                        </v-row>
                      </v-tab-item>

                      <!-- Settings -->
                      <v-tab-item :eager="true">
                        <Can I="manage" a="organisations">
                          <div class="pa-6 grey lighten-5">
                            <v-row>
                              <v-col cols="6" md="6">

                                <ValidationProvider name="organisation" rules="required" v-slot="{ errors, valid }">
                                  <v-autocomplete
                                    flat return-object
                                    label="Organisation"
                                    item-text="name"
                                    item-value="id"
                                    hide-details
                                    :loading="isLoadingOrganisations"
                                    :error-messages="errors"
                                    :success="valid"
                                    :items="organisations"
                                    v-model="organisation"
                                    @change="getData($event)"
                                    outlined
                                    prepend-inner-icon="mdi-domain"
                                  >
                                    <template v-slot:prepend-inner>
                                      <v-icon class="mr-1">mdi-domain</v-icon>
                                    </template>
                                  </v-autocomplete>
                                </ValidationProvider>

                              </v-col>
                            </v-row>
                          </div>

                          <v-divider></v-divider>
                        </Can>

                        <!-- <v-tabs :vertical="$vuetify.breakpoint.smAndUp"> -->
                        <v-tabs background-color="grey lighten-4">
                          <v-tab>
                            <v-icon left>mdi-checkbox-blank-circle</v-icon>
                            Status
                          </v-tab>
                          <v-tab>
                            <v-icon left>mdi-account-tie</v-icon>
                            Officers
                          </v-tab>
                          <v-tab>
                            <v-icon left>mdi-account-multiple</v-icon>
                            Viewers
                          </v-tab>
                          <v-tab>
                            <v-icon left>mdi-file-tree</v-icon>
                            Hierarchy
                          </v-tab>

                          <v-tab-item>
                            <v-row class="px-6 py-6">
                              <v-col cols="12" md="6" class="py-0">

                                <v-select
                                  v-model="contentData.status"
                                  :items="contentStatuses|filterStatus"
                                  item-text="name"
                                  item-value="id"
                                  menu-props="auto"
                                  label="Status"
                                  outlined
                                  hide-details
                                ></v-select>

                              </v-col>
                            </v-row>
                          </v-tab-item>

                          <v-tab-item>
                            <v-row class="px-6 py-6">
                              <v-col cols="12" md="6" class="py-0">
                                <v-autocomplete
                                  flat multiple return-object
                                  label="Select Users" item-text="profile.full_name" item-value="id" hide-details="auto"
                                  class="mb-5"
                                  v-model="contentData.officers"
                                  :disabled="!users.length"
                                  :loading="isLoadingUsers"
                                  :items="users"
                                  outlined
                                >
                                  <template v-slot:selection="data">
                                    <v-chip v-bind="data.attrs" close @click:close="remove(contentData.officers, data.item)">
                                      <UserAvatar
                                        :profile="data.item.profile"
                                        :size="32"
                                        color="primary"
                                      ></UserAvatar>
                                      {{ data.item.profile.full_name }}
                                    </v-chip>
                                  </template>
                                </v-autocomplete>
                              </v-col>

                              <v-col cols="6" class="py-0">
                              </v-col>

                              <v-col cols="12" md="6" class="py-0">
                                <v-autocomplete
                                  flat multiple return-object
                                  label="Select Groups" item-text="name" item-value="id" hide-details="auto"

                                  v-model="contentData.officers_group_ids"
                                  :disabled="!groups.length"
                                  :loading="isLoadingGroups"
                                  :items="groups"
                                  outlined
                                >
                                  <template v-slot:selection="data">
                                    <v-chip v-bind="data.attrs" close @click:close="remove(contentData.officers_group_ids, data.item)">
                                      {{ data.item.name }}
                                    </v-chip>
                                  </template>
                                </v-autocomplete>
                              </v-col>

                              <v-col cols="6" class="py-0">
                              </v-col>
                            </v-row>

                          </v-tab-item>

                          <v-tab-item>
                            <v-row class="px-6 py-6">
                              <v-col cols="6" class="pt-0 d-flex align-center">
                                <v-switch inset v-model="contentData.visibility" hide-details class="mx-1 mt-0 pt-0 d-inline-flex">
                                  <template v-slot:label>
                                    <v-chip class="v-chip--visibility" outlined>
                                      <v-icon class="mr-2">{{ contentData.visibility ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon> Visibility: <strong class="ml-1">{{ contentData.visibility ? 'Public' : 'Private' }}</strong>
                                    </v-chip>
                                  </template>
                                </v-switch>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      small
                                      text
                                      outlined
                                      v-on="on"
                                      class="mx-1"
                                    >
                                      <v-icon small>mdi-help</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    <strong>Public</strong> – Your whole organisation can view this form <br>
                                    <strong>Private</strong> – Only selected users within your organisation can view this form
                                    </span>
                                </v-tooltip>
                              </v-col>

                              <template v-if="!contentData.visibility">
                                <v-col tag="p" cols="12">
                                  Please select the users within your organisation that you wish to view this content
                                </v-col>

                                <v-col cols="12" md="6" class="py-0">
                                  <v-autocomplete
                                    flat multiple return-object
                                    label="Select Users" item-text="profile.full_name" item-value="id" hide-details="auto"
                                    class="mb-5"
                                    v-model="contentData.viewers"
                                    :disabled="!users.length"
                                    :loading="isLoadingUsers"
                                    :items="users"
                                    outlined
                                  >
                                    <template v-slot:selection="data">
                                      <v-chip v-bind="data.attrs" close @click:close="remove(contentData.viewers, data.item)">
                                        <UserAvatar
                                          :profile="data.item.profile"
                                          :size="16"
                                          color="primary"
                                        ></UserAvatar>

                                        {{ data.item.profile.full_name }}
                                      </v-chip>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </template>

                              <v-col cols="6" class="py-0">
                              </v-col>

                              <v-col cols="12" md="6" class="py-0" v-if="!contentData.visibility">
                                <v-autocomplete
                                  flat multiple return-object
                                  label="Select Groups" item-text="name" item-value="id" hide-details="auto"
                                  v-model="contentData.viewer_group_ids"
                                  :disabled="!groups.length"
                                  :loading="isLoadingGroups"
                                  :items="groups"
                                  outlined
                                >
                                  <template v-slot:selection="data">
                                    <v-chip v-bind="data.attrs" close @click:close="remove(contentData.viewer_group_ids, data.item)">
                                      {{ data.item.name }}
                                    </v-chip>
                                  </template>
                                </v-autocomplete>
                              </v-col>

                              <v-col cols="6" class="py-0">
                              </v-col>
                            </v-row>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-row class="px-6 py-6">
                                <v-col cols="12" md="6" class="py-0">
                                  <v-autocomplete
                                    flat return-object
                                    label="Select Parent Content"
                                    item-text="meta.name"
                                    item-value="id"
                                    hide-details
                                    :loading="isLoadingContent"
                                    :items="parentContent"
                                    v-model="contentParent"
                                    :disabled="!content.length"
                                    outlined
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>

                              <v-row class="px-6 py-6">
                                <v-col cols="6" class="pt-0 d-flex align-center">
                                  <v-switch
                                    inset
                                    v-model="contentData.cascade_permissions"
                                    label="Cascade Permissions"
                                    hide-details
                                    class="mx-1 mt-0 pt-0 d-inline-flex"
                                  ></v-switch>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        small
                                        text
                                        outlined
                                        v-on="on"
                                        class="mx-1"
                                      >
                                        <v-icon small>mdi-help</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Force all descendants of this items to have the same permissions as this item.<br>
                                      <strong>Any permissions they have currently set will be lost.</strong>
                                    </span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-tab-item>

                        </v-tabs>
                      </v-tab-item>
                    </v-tabs>

                    <v-divider></v-divider>

                    <v-card-actions class="pa-5">
                      <v-btn outlined color="primary" @click="activeTab--" :disabled="activeTab == 0">
                        Previous
                      </v-btn>

                      <v-btn outlined color="primary" @click="activeTab++" :disabled="activeTab == 2">
                        Next
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn color="primary" @click="passes(handleCreateContent); handleFailedValidation(invalid)" class="ml-auto">
                        Save &amp; Create
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </ValidationObserver>

              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="pa-8" v-if="isLoading || isCreating">
              <v-loading-spinner></v-loading-spinner>
            </v-row>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import UserAvatar from '@/components/user/Avatar'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VNumberFieldWithValidation from '@/components/inputs/VNumberFieldWithValidation'
import TextEditor from '@/components/inputs/TextEditor'
import VMultipleSelect from '../../components/display/VMultipleSelect.vue'

export default {
  name: 'CreateContent',
  mixins: [BreadcrumbsManager],
  components: {
    UserAvatar,
    VTextFieldWithValidation,
    VNumberFieldWithValidation,
    TextEditor,
    VMultipleSelect
  },
  data() {
    return {
      // dataMissing: false,
      activeTab: 0,
      isLoading: false,
      isLoadingContent: false,
      isLoadingUsers: false,
      isLoadingGroups: false,
      isCreating: false,
      users: [],
      groups: [],
      organisation: '',
      content: [],
      contentData: {
        organisation_id: '',
        name: '',
        content_type: 1,
        content_body: null,
        compliance_items: [],
        officers: [],
        officers_group_ids: [],
        viewers: [],
        viewer_group_ids: [],
        visibility: true,
        status: 2,
        cascade_permissions: false
      },
      contentStatuses: [
        { id: 1, name: "Inactive"},
        { id: 2, name: "Active"}
      ],
      contentParent: {},
      panel: [],
      toUpdate: {},
      reviewFrequencies: [
        'None',
        'Days',
        'Weeks',
        'Months',
        'Years'
      ], 
      defaultDate: new Date()
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      organisations: state => state.ORG_STORE.organisations,
      isLoadingOrganisations: state => state.ORG_STORE.loadingOrganisations,
      complianceTags: state => state.ORG_STORE.orgComplianceTags || [],
    }),
    parentContent: function() {
      return this.content
    }
  },
  beforeMount() {
    if(!this.$can('manage', 'organisations')) {
      this.getData({ id: `${this.user.organisation_id}` })
    }
  },
  mounted() {},
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Content', path: '/content/all/' },
      { text: 'Create' }
    ])
  },
  methods: {
    getData(org) {
      this.isLoadingContent = true
      this.isLoadingUsers = true
      this.isLoadingGroups = true

      this.users = []

      this.contentData.organisation_id = parseInt(org.id)
      this.contentData.officers = []
      this.contentData.officers_group_ids = []
      this.contentData.viewers = []
      this.contentData.viewer_group_ids = []

      const organisation_id = parseInt(org.id)

      const getContent = async () => {
        const { data } = await this.$http.get('content', {
          params: {
            organisation_id: organisation_id,
            reseller_id: this.user.reseller_id
          }
        })

        return data
      }

      const getUsers = async () => {
        const { data } = await this.$http.get('users', {
          params: {
            organisation_id: organisation_id,
            reseller_id: this.user.reseller_id
          }
        })

        return data
      }

      const getGroups = async () => {
        const { data } = await this.$http.get('group', {
          params: {
            organisation_id: organisation_id,
            reseller_id: this.user.reseller_id
          }
        })

        return data
      }

      Promise.all([
        getContent(),
        getUsers(),
        getGroups()
      ]).then(([content, users, groups]) => {
        this.content = content.data
        this.users = users.data
        this.groups = groups.data

        this.isLoadingContent = false
        this.isLoadingUsers = false
        this.isLoadingGroups = false
      })
    },
    handleFailedValidation(v) {
      if (v) {
        this.$toast.error('Please fill out all required fields across all tabs.')
      }
    },
    handleCreateContent() {
      this.isCreating = true

      let clone = JSON.parse(JSON.stringify(this.contentData))

      let complianceItemStrings = []

      clone.compliance_items.forEach((item) => {
        if (typeof item.tags[0] == 'object') {
          item.tags = item.tags.map(tag => tag.id)
        }
        const complianceItemString = JSON.stringify(item)
        complianceItemStrings.push(complianceItemString)
      })

      clone.compliance_items = complianceItemStrings

      if (this.contentParent) {
        clone.parent_id = this.contentParent.id ? parseInt(this.contentParent.id) : null
      }

      let variables = this.formatData(clone)

      this.$http.post('content', variables)
        .then(response => {
          const content = response.data.data
          this.isCreating = false
          this.$store.commit('CONTENT_STORE/CREATE_CONTENT', content)

          this.$toast.success('Successfully created content')
          this.$router.push({ name: 'content/all' }, () => {})
        })
        .catch(error => {
          this.isCreating = false
          console.error('Something has gone wrong creating content', error)
          this.$toast.error('Something went wrong creating content.')
        })
    },
    // TODO ABSTRACT AS USED HERE AND IN CREATEFORM
    formatData(data) {
      // We need to return an array of objects as a comma seperated string of those object id's
      const propsToStringify = ['officers', 'officers_group_ids', 'viewers', 'viewer_group_ids']

      propsToStringify.forEach(function(prop) {
        if(data[prop].length) {
          const ids = data[prop].map(function(item) {
            return item.id
          })

          data[prop] = ids
        }

        data[prop] = data[prop].toString()
      })

      // We need to format boolean output of v-switch to int (casting it to itself to return numbers value)
      data.visibility = +data.visibility

      return data
    },
    /**
     * This method removes items from a collection, works in both removing fields and options within fields
     */
    remove(arr, item) {
      let index = item

      if (typeof item === 'object') {
        index = arr.map(function(e) { return e.id }).indexOf(item.id)
      }

      if (index >= 0) arr.splice(parseInt(index), 1)
    },
    /**
     * Add a blank compliance item to the content
     */
    addComplianceItem() {
      this.contentData.compliance_items.push({...{
        name: '',
        frequencyValue: 1,
        frequencyUnit: 'Weeks',
        warning: 2,
        description: '',
        next_review_date: this.$moment().add(7,'d'), 
        frequency: 7
      }});

      this.panel.push(this.contentData.compliance_items.length - 1)
    },
    updateNextReviewDate(reviewFrequency, lastReviewDate, index) {
      let value = reviewFrequencyValue;
      const unit = this.contentData.compliance_items[index].frequencyUnit;
      const date = lastReviewDate != null && lastReviewDate.actioned_on != null ? lastReviewDate.actioned_on : new Date();

      if(unit == 'None'){
        this.contentData.compliance_items[index].next_review_date = null;
        this.contentData.compliance_items[index].frequency = 0;
        this.contentData.compliance_items[index].warning = null;
        this.contentData.compliance_items[index].frequencyValue = null;
        return;
      }

      if (value !== 0) {
        // this.contentData.compliance_items[index].next_review_date = this.$moment(date).add(reviewFrequency,'d');

        let nextReviewDate = null;

        switch (unit) {
          case 'Days':
            // console.log('Days');
            nextReviewDate = this.$moment.utc(date).add(value,'d');
            break;
          case 'Weeks':
            // console.log('Weeks');
            value = value * 7;
            nextReviewDate = this.$moment.utc(date).add(value,'w');
            break;
          case 'Months':
            // console.log('Months');
            value = value * 30;
            nextReviewDate = this.$moment.utc(date).add(value,'M');
            break;
          case 'Years':
            // console.log('Years');
            value = value * 365;
            nextReviewDate = this.$moment.utc(date).add(value,'y');
            break;
        }

        this.$set(this.contentData.compliance_items[index],'frequency',value);
        this.$set(this.contentData.compliance_items[index], 'next_review_date', nextReviewDate)
        let warning = this.contentData.compliance_items[index].warning;

        this.contentData.compliance_items[index].warning = Math.min(warning,value);
      } else {
        //call this same method with a value of 1 to set the next review date to the next day
        this.updateNextReviewDate(1, lastReviewDate, index);
      }
    }
  }
}
</script>
