<template>
  <v-card height="100%">
    <ContentNav v-if="canViewContentNav" />
    <!-- <CompliancyNav v-if="canViewCompliancyNav" /> -->
    <FormsNav v-if="canViewFormsNav" />
    <OrganisationsNav v-if="canViewOrganisationNav" />
    <ProfileNav v-if="canViewProfileNav" />
  </v-card>
</template>

<script>
import ContentNav from '@/components/navs/ContentNav'
// import CompliancyNav from '@/components/navs/CompliancyNav'
import FormsNav from '@/components/navs/FormsNav'
import OrganisationsNav from '@/components/navs/OrganisationsNav'
import ProfileNav from '@/components/navs/ProfileNav'

export default {
  name: 'NavDrawer',
  components: { ContentNav, OrganisationsNav, FormsNav, ProfileNav },
  computed: {
    routeName: function() {
      return this.$route.name
    },
    canViewContentNav: function() {
      if (
        this.routeName === "content/all" ||
        this.routeName === "content" ||
        this.routeName === "content/create" ||
        this.routeName === "content/edit"
      ) {
        return true
      }
      return false
    },
    // canViewCompliancyNav: function() {
    //   if (
    //     this.routeName === "compliance"
    //   ) {
    //     return true
    //   }
    //   return false
    // },
    canViewFormsNav: function() {
      if (
        this.routeName === "form/all" ||
        this.routeName === "form/create" ||
        this.routeName === "form" ||
        this.routeName === 'form/view' ||
        this.routeName === 'form/edit' ||
        this.routeName === 'form/responses' ||
        this.routeName === 'form/response'
      ) {
        return true
      }
      return false
    },
    canViewOrganisationNav: function() {
      if (
        this.routeName === "organisation" ||
        this.routeName === "organisation/all" ||
        this.routeName === "organisation/create"||
        this.routeName === "organisation/manage" ||
        this.routeName === "organisation/users" ||
        this.routeName === "organisation/users/create" ||
        this.routeName === "organisation/edit" ||
        this.routeName === "organisation/theme" ||
        this.routeName === "organisation/content" ||
        this.routeName === "organisation/groups" ||
        this.routeName === "organisation/groups/edit"||
        this.routeName === "organisation/groups/create"||
        this.routeName === "organisation/policies"
      ) {
        return true
      }
      return false
    },
    canViewProfileNav: function() {
      if (
        this.routeName === "profile" ||
        this.routeName === "policies" ||
        this.routeName === "notifications"
      ) {
        return true
      }
      return false
    },
  }
};
</script>
