<template>

  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Forms"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card route>

            <v-row dense class="px-4 py-3" justify="end">

              <v-col cols="12" md="4" lg="4" class="d-flex align-center">
                <Can I="create" a="form">
                  <v-btn
                    :block="!$vuetify.breakpoint.mdAndUp"
                    outlined
                    color="primary"
                    :to="{ name: 'form/create' }"
                  >
                    <v-icon dark left>mdi-clipboard-plus-outline</v-icon>Create New
                  </v-btn>

                  <v-spacer></v-spacer>
                </Can>
              </v-col>

              <v-col>
                <v-select
                  :items="headers.slice(0,-1)"
                  outlined
                  dense
                  hide-details
                  v-model="sort"
                  prepend-inner-icon="mdi-filter-outline"
                ></v-select>
              </v-col>

              <v-col cols="auto">
                <v-btn outlined block class="px-5 v-btn--icon" @click="descending = !descending" min-height="40">
                  <v-icon dark v-text="descending ? 'mdi-sort-descending' : 'mdi-sort-ascending'"></v-icon>
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                  :disabled="isLoading"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-tabs>
              <v-tab text large plain @click="filter = null"><v-icon dark left>mdi-clipboard-text-multiple-outline</v-icon>All</v-tab>
              <v-tab text large plain @click="filter = 2"><v-icon dark left>mdi-clipboard-check-outline</v-icon>Live</v-tab>
              <v-tab text large plain @click="filter = 1"><v-icon dark left>mdi-clipboard-remove-outline</v-icon>Not Live</v-tab>
              <Can I="manage" a="form">
                <v-tab text large plain @click="filter = 3"><v-icon dark left>mdi-package-down</v-icon>Archived</v-tab>
              </Can>
            </v-tabs>

            <v-divider></v-divider>

            <v-data-table
              class="v-data-table--selectable pt-md-12"
              :headers="headers"
              :items="getFilteredForms()"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              :search="search"
              :loading="isLoading"
              loading-text="Loading forms... please wait."
            >
              <template v-slot:no-data>
                No forms available.
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.meta.name }}</td>
                  <Can I="manage" a="organisations">
                    <td>
                      {{ item.ownership.organisation_id|getOrganisationName }}
                    </td>
                  </Can>
                  <td>
                    <v-chip v-if="item.status.id !== 3" small :color="item.status.id === 2 ? 'primary' : 'warning'"></v-chip>
                    <v-chip v-else small color="error"></v-chip>
                  </td>
                  <td>{{ +item.unix | moment("DD/MM/YYYY") }}</td>
                  <Can I="read" a="form-response">
                    <td>
                      <v-chip
                        v-if="user.role != 'user' || item.is_officer"
                        v-on="on"
                        :to="{ name: 'form/responses', params: { form_id: item.id }}"
                        outlined>
                        <v-avatar left>
                          <v-icon small>mdi-email-multiple</v-icon>
                        </v-avatar>
                        {{ item.formResponseCount }} Responses
                      </v-chip>
                    </td>
                  </Can>
                  <td>
                    
                <div class="d-flex justify-end" v-if="$vuetify.breakpoint.smAndUp">
                  <Can I="update" a="form">

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :to="{ name: 'form', params: { form_id: item.id }}"
                          :disabled="isLoading"
                        >
                          <v-icon medium>mdi-file-document-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Manage Form</span>
                    </v-tooltip>
                  </Can>

                  <Can not I="update" a="form">

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :to="{ name: 'form/view', params: { form_id: item.id }}"
                          :disabled="isLoading || item.status.id !== 2"
                        >
                          <v-icon medium>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Form</span>
                    </v-tooltip>
                  </Can>

                  <Can I="update" a="form">

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          :to="{ name: 'form/edit', params: { form_id: item.id }}"
                          :disabled="isLoading"
                        >
                          <v-icon medium>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Form</span>
                    </v-tooltip>

                  </Can>

                  <Can I="delete" a="form">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="formArchiveConfirmation = true, toArchive['id'] = item.id, toArchive['name'] = item.meta.name"
                          icon
                          v-on="on"
                          :disabled="isLoading || item.status.id === 3"
                        >
                          <v-icon medium>mdi-package-down</v-icon>
                        </v-btn>
                      </template>
                      <span>Archive Form</span>
                    </v-tooltip>

                  </Can>
                </div>

                <v-row dense v-if="!$vuetify.breakpoint.smAndUp" class="mb-2">

                  <v-col cols="12">
                    <Can I="update" a="form">
                      <v-btn
                        block
                        color="primary"
                        outlined
                        :to="{ name: 'form', params: { form_id: item.id }}"
                        :disabled="isLoading"
                      ><v-icon dark left>mdi-eye</v-icon>
                      Manage Form</v-btn>
                    </Can>

                    <Can not I="update" a="form">
                      <v-btn
                        block
                        color="primary"
                        outlined
                        :to="{ name: 'form/view', params: { form_id: item.id }}"
                        :disabled="isLoading || item.status.id !== 2"
                      ><v-icon dark left>mdi-eye</v-icon>
                      View Form</v-btn>
                    </Can>
                  </v-col>

                  <Can I="update" a="form">
                    <v-col cols="12">
                      <v-btn
                        block
                        color="primary"
                        outlined
                        :to="{ name: 'form/edit', params: { form_id: item.id }}"
                        :disabled="isLoading"
                      >
                        <v-icon dark left>mdi-file-document-edit-outline</v-icon>
                        Edit Form
                      </v-btn>
                    </v-col>
                  </Can>

                  <Can I="delete" a="form">
                    <v-col cols="12">
                      <v-btn
                      @click="formArchiveConfirmation = true, toArchive['id'] = item.id, toArchive['name'] = item.meta.name"
                        block
                        color="primary"
                        outlined
                        :disabled="isLoading || item.status.id === 3"
                      >
                        <v-icon dark left>mdi-package-down</v-icon>
                        Archive Form
                      </v-btn>
                    </v-col>
                  </Can>

                </v-row>
                  </td>
                </tr>
              </template>

              <!-- <template v-slot:item.meta.name="{ item }">
                
              </template>

              <template v-slot:item.meta.organisation="{ item }">
                
                  
                </Can>
              </template>

              <template v-slot:item.status.name="{ item }">
                
              </template>

              <template v-slot:item.unix="{ item }">
                
              </template>

              <template v-slot:item.formResponseCount="{ item, on }">
                
              </template> -->

              <template v-slot:header.actions="{ header }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      text
                      outlined
                      v-on="on"
                      class="mx-1"
                    >
                      <v-icon small>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span><v-icon medium class="mr-2">mdi-package-down</v-icon><strong>Archiving a form</strong> will stop allowing form submissions, all previous responses will still be saved.</span>
                </v-tooltip>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-overlay v-if="formArchiveConfirmation && !isLoading">
          <v-card class="mx-auto" max-width="480" light v-if="!isArchiving">
            <v-card-title class="primary primary--text--contrast mb-4">
              Confirm Archive Form
            </v-card-title>

            <v-card-text>
              Are you sure you want to archive form <strong>{{ toArchive.name }}</strong>? Due to data protection this content becomes archived.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text color="primary" @click="formArchiveConfirmation = !formArchiveConfirmation, toArchive = {}">Cancel</v-btn>

              <v-spacer></v-spacer>

              <v-btn @click="handleArchiveForm" color="warning">
                Archive
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row align="center" justify="center" v-if="isArchiving">
            <v-loading-spinner></v-loading-spinner>
          </v-row>
        </v-overlay>
      </v-fade-transition>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ViewForms',
  mixins: [
    BreadcrumbsManager
  ],
  components: {
  },
  data() {
    return {
      search: '',
      toArchive: {},
      formArchiveConfirmation: false,
      isArchiving: false,
      options: {},
      timer: null,
      filter: null,
      sort: 'meta.name',
      descending: true,
      updateOptionsBasedOnManualSortControls: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      forms: state => state.FORM_STORE.forms,
      serverItemsLength: state => {
        return state.FORM_STORE.forms[0]?.total || 0
      },
      isLoading: (state) => {
        return state.FORM_STORE.loadingForms ||
          state.ORG_STORE.loadingOrganisations
            ? true
            : false
      }
    }),
    headers() {
      let headers = []

      headers.push({ text: "Title of the form", value: "meta.name" })

      if (this.$can('manage','organisations')) {
        headers.push({ text: "Organisation", value: "meta.organisation", sortable: false })
      }

      headers.push({ text: "Status", value: "status.name" })
      headers.push({ text: "Last Updated", value: "unix" })

      if (this.$can('read','form-response')) {
        headers.push({ text: "Responses", value: "formResponseCount" })
      }

      headers.push({ text: "", value: "actions", sortable: false, align: "end" })

      return headers
    }
  },
  beforeMount() {

  },
  mounted() {},
  watch: {
    options: {
      handler () {
        this.updateOptionsBasedOnManualSortControls = false;
        this.sort = this.options.sortBy[0];
        this.descending = this.options.sortDesc[0];
        this.updateOptionsBasedOnManualSortControls = true;
        this.getData()
      },
      deep: true,
    },
    search() {
      this.getDataDebounced();
    },
    sort() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortBy = [this.sort];
      }
    },
    descending() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortDesc = [this.descending];
      }
    }
  },
  methods: {
    getDataDebounced() {
      // cancel pending call
      clearTimeout(this.timer)

      // delay new call 500ms
      this.timer = setTimeout(() => {
        // ensure we request the first page (reset pagination)
        this.options.page = 1;
        this.getData()
      }, 500)
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const search = this.search;
      this.$store.dispatch('FORM_STORE/GET_FORMS', {sortBy, sortDesc, page, itemsPerPage, search})
      this.$store.dispatch('ORG_STORE/GET_ORGS')
    },
    getFilteredForms() {
      let filteredForms = []

      filteredForms = this.forms.filter(item => {
        if (typeof item === 'undefined') {
          return false
        }
        if (!this.filter) {
          if (item.status.id === 3) {
            if (this.$can('manage','forms')) {
              return item
            }
          } else {
            return item
          }
        } else {
          if (item.status.id === this.filter) {
            return item
          }
        }
      })

      return filteredForms
    },
    async handleArchiveForm() {
      this.isArchiving = true

      await this.$http.delete('form', {
        params: {
          id: parseInt(this.toArchive.id)
        }
      }).then(data => {
          this.$store.commit('FORM_STORE/ARCHIVE_FORM', this.toArchive.id)
          this.$toast.success(`${this.toArchive.name} archived successfully.`)
        }, error => {
          console.error('Something has gone wrong deleting form')
        })

      this.formArchiveConfirmation = false
      this.isArchiving = false
      this.toArchive = {}
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Forms' }
    ])
  }
}
</script>
