<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route v-if="users">
      <v-card-title class="primary primary--text--contrast">Manage Users</v-card-title>

      <Can I="create" a="form">
        <!-- <v-row class="px-4 pt-3" v-if="showMicrosoftIntegrationSettings">
          <v-col cols="4" md="4" lg="4" class="py-1 d-flex align-center" >
              <span >
                Sync users from Microsoft
              </span>
              <v-spacer></v-spacer>
          </v-col>
          <v-col cols="8" md="8" lg="8" class="py-1 d-flex align-center" >
              <v-select
                v-model="selectedMicrosoftGroup"
                :items="microsoftGroups"
                item-text="displayName"
                return-object
                color="white"
                label="Choose root Microsoft user group to sync from"
                hide-details
              ></v-select>
              <v-btn 
                class="ma-3"
                color="primary"
                :disabled="!selectedMicrosoftGroup || isSyncing"
                @click="confirmMicrosoftSync = true"
              >
                <v-icon>mdi-sync</v-icon>
              </v-btn>
              <v-progress-circular v-if="isSyncing" color="primary" indeterminate></v-progress-circular>
              <span v-else-if="micrsoftSynced && micrsoftSyncedDate" style="font-style: italic; font-size: 0.6em;" class="ma-3">
                Last synced {{ selectedMicrosoftGroup.displayName }} at {{ micrsoftSyncedDate }} <br />
                Next sync in {{ daysUntilNextSync }} days
              </span>
              <span v-else-if="micrsoftSynced" style="font-style: italic; font-size: 0.6em;" class="ma-3">
                Never synced, will sync on next login
              </span>
          </v-col>
        </v-row> -->
      </Can>

      <v-row class="px-4 py-3" justify="end">

        <v-col cols="12" md="4" lg="6" class="py-1 d-flex align-center">
          <Can I="create" a="form">
            <v-btn
              :block="!$vuetify.breakpoint.mdAndUp"
              outlined
              color="primary"
             :to="{ name: 'organisation/users/create', params: { org_id: this.$route.params.org_id }}"
            >
              <v-icon dark left>mdi-account-plus-outline</v-icon>Create New
            </v-btn>

            <v-spacer></v-spacer>
          </Can>
        </v-col>

        <v-col cols="12" md="8" lg="6" class="py-1 d-flex justify-end">
          <v-text-field
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            clearable
            :disabled="isLoading"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-divider></v-divider>

      <v-data-table
        class="v-data-table--selectable"
        :headers="!showMicrosoftIntegrationSettings ? headers : headers.filter(header => header.value !== 'actions')"
        :items="users"
        :items-per-page="10"
        :search="search"
        :loading="isLoading"
        loading-text="Loading users... please wait."
        mobile-breakpoint="960"
      >

        <template v-slot:no-data>
          No users available.
        </template>

        <template v-slot:item.profile.avatar="{ item }">
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <UserAvatar
                v-if="$vuetify.breakpoint.mdAndUp"
                :profile="item.profile"
                :size="32"
                color="primary"
              ></UserAvatar>
              <UserAvatar
                v-else
                :profile="item.profile"
                :size="60"
                color="primary"
              ></UserAvatar>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.profile.first_name="{ item }">
          <div style="word-break: break-word" :class="item.status.status_id == 0 ? 'text-disabled' : ''" >
            {{item.profile.first_name}}
          </div>
        </template>

        <template v-slot:item.profile.last_name="{ item }">
          <div style="word-break: break-word" :class="item.status.status_id == 0 ? 'text-disabled' : ''"  >
            {{item.profile.last_name}}
          </div>
        </template>

        <template v-slot:item.email="{ item }">
          <div style="word-break: break-word" :class="item.status.status_id == 0 ? 'text-disabled' : ''"  >
            {{item.email}}
          </div>
        </template>

        <template v-slot:item.status.name="{ item }">
          <v-chip small :color="item.status.status_id == '1' ? 'cryspGreen' : 'grey'"></v-chip>
        </template>

        <template v-slot:item.role="{ item }">
          <v-chip
            small
            outlined
            v-if="item.status.status_id == 0 ">
            Disabled {{ item.role | titleCase }}
          </v-chip>
          <v-chip
            small
            outlined
            v-else-if="item.role">
            {{ item.role | titleCase }}
          </v-chip>
          <v-chip v-else>Not set</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end" v-if="$vuetify.breakpoint.mdAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="handleToggleUserStatus(item)"
                  icon
                  :disabled="item.role == 'system_admin' || isLoading"
                  v-on="on"
                >
                  <v-icon v-if="item.status.status_id == '1'" medium>mdi-account-cancel</v-icon>
                  <v-icon v-else-if="item.status.status_id == '0'" medium>mdi-account-check</v-icon>
                </v-btn>
              </template>
              <span>{{ item.status.status_id == '1' ? 'Disable' : 'Enable' }} User</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="userDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.profile.full_name"
                  icon
                  :disabled="item.role == 'system_admin' || isLoading"
                  v-on="on"
                >
                  <v-icon medium>mdi-delete</v-icon>
                </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </div>

            <v-row dense v-if="!$vuetify.breakpoint.mdAndUp" class="mb-2">
              <v-col cols="12">
                <v-btn
                  @click="handleToggleUserStatus(item)"
                    block
                  color="primary"
                  outlined
                  :disabled="item.role == 'system_admin' || isLoading"
                >
                  <v-icon v-if="item.status.status_id == '1'" dark left>mdi-account-cancel</v-icon>
                  <v-icon v-else-if="item.status.status_id == '0'" dark left>mdi-account-check</v-icon>
                  <span>{{ item.status.status_id == '1' ? 'Disable' : 'Enable' }} User</span>
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  @click="userDeleteConfirmation = true, toDelete['id'] = item.id, toDelete['name'] = item.profile.full_name"
                  block
                  color="primary"
                  outlined
                  :disabled="item.role == 'system_admin' || isLoading"
                >
                <v-icon dark left>mdi-delete</v-icon>
                <span>Delete User</span>
              </v-btn>
              </v-col>
            </v-row>
          </template>

        <v-alert slot="no-results" :value="true" type="error" border="top" color="red lighten-2" class="mt-3">
          Your search for "{{ search }}" found no results.
        </v-alert>

      </v-data-table>
    </v-card>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="userDeleteConfirmation && !isLoading">
        <v-card class="mx-auto" max-width="480" light v-if="!isDeleting">
          <v-card-title class="primary primary--text--contrast mb-4">
            Confirm Delete User
          </v-card-title>

          <v-card-text>
            Are you sure you want to delete user <strong>{{ toDelete.name }}</strong>? This action cannot be undone!
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text color="primary" @click="userDeleteConfirmation = !userDeleteConfirmation, toDelete = {}">Cancel</v-btn>

            <v-spacer></v-spacer>

            <v-btn @click="handleDeleteUser" color="error">
              Delete
            </v-btn>

          </v-card-actions>
        </v-card>

        <v-row align="center" justify="center" v-if="isDeleting">
          <v-loading-spinner></v-loading-spinner>
        </v-row>
      </v-overlay>
    </v-fade-transition>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="isDisabling">

        <!-- @TODO: Insert modal for changing a users status? -->

        <v-row align="center" justify="center">
          <v-loading-spinner></v-loading-spinner>
        </v-row>
      </v-overlay>
    </v-fade-transition>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="confirmMicrosoftSync">
        <v-card class="mx-auto" max-width="480" light>
          <v-card-title class="primary primary--text--contrast mb-4">
            Confirm you want to sync users from Microsoft
          </v-card-title>

          <v-card-text>
            <p>
              Are you sure you want to sync users from Microsoft group <strong>{{ selectedMicrosoftGroup.displayName }}</strong>?
              <br/>
              Your users will now be managed by Microsoft, you will be able to change the root group.
            </p>
            <p>
              Please allow up to 5 mintues for the sync to complete and the changes to appear in your account.
            </p>
            <p>Please visit this page to refresh the user list from Microsoft. You can refresh the synchronisation at any time.</p>
            <p>
              If you wish to no longer synchronise from Microsoft please submit a support request.
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text color="primary" @click="confirmMicrosoftSync = !confirmMicrosoftSync, selectedMicrosoftGroup = null">Cancel</v-btn>

            <v-spacer></v-spacer>

            <v-btn @click="syncMicrosoftGroup" color="error">
              Sync
            </v-btn>

          </v-card-actions>
        </v-card>

        <v-row align="center" justify="center" v-if="isSyncing">
          <v-loading-spinner></v-loading-spinner>
        </v-row>
      </v-overlay>
    </v-fade-transition>

  </v-container>
</template>

<script>
import UserAvatar from '@/components/user/Avatar'

import { mapState } from 'vuex'
import axios from '../../_store/axios'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ManageUsers',
  components: {
    UserAvatar
  },
  props: [
    'organisation',
    'groups',
    'users'
  ],
  mixins: [BreadcrumbsManager],
  data() {
    return {
      search: '',
      groupSelection: [],
      headers: [
        { text: "", value: "profile.avatar", sortable: false },
        { text: "First Name", value: "profile.first_name" },
        { text: "Last Name", value: "profile.last_name" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status.name" },
        { text: "Role", value: "role" },
        // { text: "Groups", value: "groups" },
        { text: "", value: "actions", sortable: false }
      ],
      toDelete: {},
      userDeleteConfirmation: false,
      userDisableConfirmation: false,
      isDeleting: false,
      isDisabling: false,
      microsoftGroups: [],
      selectedMicrosoftGroup: null,
      micrsoftSynced: false,
      micrsoftSyncedDate: null,
      daysUntilNextSync: 0,
      isSyncing: false,
      confirmMicrosoftSync: false,
      showMicrosoftIntegrationSettings: false,
    }
  },
  computed: {
    ...mapState({
        isLoading: state => state.USER_STORE.loadingUsers,
        authUser: state => state.AUTH_STORE.authUser
    })
  },
  //watch for changes in the selected group
  watch: {
    selectedMicrosoftGroup: function(newVal, oldVal) {
      if(newVal) {
        // console.log('selected group changed:', newVal);
        // this.getGroupUsers(newVal).then(() => {
        //   console.log('group synced');
        //   this.calculateNewUsers();
        // });
      }
    }
  },

  methods: {
    getData() {
      this.$store.dispatch('USER_STORE/GET_USERS', this.$route.params.org_id)

      // console.log('isMicrosoftAccount:', this.isMicrosoftAccount());
      if(this.isMicrosoftAccount()) {
        this.getMicrosoftGroups()
        this.getSyncStats()
      }
    },
    handleDeleteUser() {
      this.isDeleting = true

      this.$http.delete('user', {
          params: {
            user_id: parseInt(this.toDelete.id),
            confirm_delete: 1
          }
        })
        .then(data => {
          this.$store.commit('USER_STORE/DELETE_USER', this.toDelete.id)

          this.userDeleteConfirmation = false
          this.isDeleting = false

          this.$toast.success(`User ${this.toDelete.name} deleted successfully.`)

          this.toDelete = {}
        }, error => {
          this.userDeleteConfirmation = false
          this.isDeleting = false

          this.$toast.error('Something went wrong while deleting user, please try again.')

          this.toDelete = {}
        })
    },
    async handleToggleUserStatus(user) {
      this.isDisabling = true

      let status = (user.status.status_id == 1) ? 0 : 1

      this.$store.commit('USER_STORE/UPDATING_USER')

      const userUpdated = await this.$http.put('user', {
          user_id: parseInt(user.id),
          first_name: user.profile.first_name,
          last_name: user.profile.last_name,
          status_id: status
        })
        .then(response => {
          return response.data.data[0]
        }, error => {
          this.$toast.error('Something went wrong updating the user status, please try again.')
        })

      this.isDisabling = false

      if(userUpdated) {
        this.$store.dispatch('USER_STORE/UPDATE_USER', userUpdated)
        this.$store.commit('USER_STORE/UPDATING_USER_STOP')
        this.$toast.success('User status successfully updated.')
      }

    },
    isMicrosoftAccount() {
      // console.log('checking if microsoft account');
      // console.log('microsoftToken:', localStorage.getItem('microsoftToken'));
      let microsoftToken = localStorage.getItem('microsoftToken');

      if(microsoftToken === null || microsoftToken === undefined) {
        return false;
      }

      this.showMicrosoftIntegrationSettings = true;
      // console.log('showMicrosoftIntegrationSettings:', this.showMicrosoftIntegrationSettings);
      return true;
    },
    async getSyncStats(){
      // console.log('getting sync stats');
        try {
          let token = localStorage.getItem('token');

          const response = await axios.get('/microsoftSyncStats', {
            params: {
              organisation_id: this.$route.params.org_id
            },
            headers: {
                      'Authorization': `Bearer ${token}`
                  }
          }).then((response) => {
            // console.log('sync response:', response);

            let lastSyncedAt = response.data.data.last_synced_microsoft;
            let group_id = response.data.data.microsoft_root_group_id;

            this.selectedMicrosoftGroup = this.microsoftGroups.find(group => group.id === group_id);
            
            // console.log('lastSyncedAt:', lastSyncedAt);
            // console.log('selectedMicrosoftGroup:', this.selectedMicrosoftGroup);

            if (lastSyncedAt && !isNaN(new Date(lastSyncedAt).getTime())) {
                let nextSyncDate = new Date(lastSyncedAt);
                // Proceed with operations on nextSyncDate
                nextSyncDate.setDate(nextSyncDate.getDate() + 7);
                //get the number of days until the next sync
                let daysUntilNextSync = Math.floor((nextSyncDate - new Date()) / (1000 * 60 * 60 * 24));

                // console.log('Last Synced At:', lastSyncedAt);
                // console.log('Next Sync Date:', nextSyncDate);
                // console.log('Days Until Next Sync:', daysUntilNextSync);
                
                this.micrsoftSyncedDate = lastSyncedAt;
                this.daysUntilNextSync = daysUntilNextSync;
                this.micrsoftSynced = true;

                if(daysUntilNextSync <= 0) {
                  this.isSyncing = true;
                  this.syncMicrosoftGroup();
                }
            }
          });
          
        } catch (error) {
          console.error('Error fetching sync stats:', error);
        }
    },
    async getMicrosoftGroups() {
      // console.log('getting microsoft groups');
        // Code to get groups
        let token = localStorage.getItem('token');
        let microsoftToken = localStorage.getItem('microsoftToken');

        // call the /microsoftGroups endpoint
        await axios.post('/microsoftGroups',{
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data:{
                    microsoftToken: microsoftToken
                }
            })
            .then(response => {
                // handle the response
                let arr = Object.values(response.data.data);
                this.microsoftGroups = arr.filter(item => typeof item === 'object' && item !== null);
                // console.log(this.microsoftGroups);
            })
            .catch(error => {
                // handle the error
                console.log(error);
            });
    },
    async syncMicrosoftGroup() {
      this.isSyncing = true;
      this.confirmMicrosoftSync = false;

      // console.log('syncing group', this.selectedMicrosoftGroup);

      // set the selected group as organisation microsoft sync group
      // api post request to update the organisation
      try{
        let token = localStorage.getItem('token');

        await axios.post(`/registerMicrosoftGroup?organisation_id=${this.$route.params.org_id}`, {
            headers: {
                        'Authorization': `Bearer ${token}`
                    },
          data: { 
            microsoft_sync_group: this.selectedMicrosoftGroup.id,
            all_microsoft_groups: this.microsoftGroups,
          }
        }).then(response => {
          this.$toast.success('Microsoft group set successfully. Now starting sync...');
        }, error => {
          this.$toast.error('Something went wrong while syncing the Microsoft group, please try again.');
        });
      } catch (error) {
        console.error("Error setting organisation's Microsoft group:", error);
      }

      this.getGroupUsers(this.selectedMicrosoftGroup).then(() => {
        // console.log('group synced, calculating users');
        this.calculateNewUsers();
      });
    },
    async getGroupUsers(msGroup) {
        // Code to get groups
        let token = localStorage.getItem('token');
        let microsoftToken = localStorage.getItem('microsoftToken');

        // call the /microsoftGroups endpoint
        await axios.post('/microsoftGroupUsers',{
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data:{
                    microsoftToken: microsoftToken,
                    groupId: msGroup.id,
                }
            })
            .then(response => {
              // console.log('group users:', response.data.data);
                // handle the response, but get rid of the non-object values and turn it into an array first
                let arr = Object.values(response.data.data);
                msGroup.members = arr.filter(item => typeof item === 'object' && item !== null);

                return response.data.data;
            })
            .catch(error => {
                // handle the error
            });
    },
    calculateNewUsers(){
      //get the users from the selected group that are not already in the users array
      let newUsers = this.selectedMicrosoftGroup.members.filter(user => !this.users.some(u => u.email === user.mail));
      // console.log(newUsers);

      //get the users from the users array that are not in the selected group
      // let usersToRemove = this.users.filter(user => !this.selectedMicrosoftGroup.members.some(u => u.mail === user.email && !user.email.endsWith('@crysp.co.uk')));
      this.users.forEach(usr => {
        if(!this.selectedMicrosoftGroup.members.some(u => u.mail === usr.email)) {
          // console.log('user not found:', usr);
          //check if their email address ends with "crysp.co.uk"
          if(!usr.email.endsWith('crysp.co.uk')) {
            //check the user status, if it is not 0, then disable the user
            if(usr.status.status_id != 0) {
              this.handleToggleUserStatus(usr);
            }
          }

        } else {
          // console.log('user found:', usr);
          //if the user is found, check if the user is disabled, if it is, enable the user
          if(usr.status.status_id == 0) {
            this.handleToggleUserStatus(usr);
          }
        }
      });

      // add the new users
      newUsers.forEach(user => {
        this.createNewUser(user);
      });
      
      this.micrsoftSynced = true;
      this.isSyncing = false;
    },
    //duplicate method from CreateUser.vue
    async createNewUser(user) {
      console.log('creating new user:', user);
      let newUser = {
          "username": user.mail,
          "email": user.mail,
          "send_activation": 1,
          "send_welcome": 1,
          "reseller_id": this.authUser.reseller_id,
          "organisation_id": parseInt(this.$route.params.org_id),
          "role": "user",
          "first_name": user.displayName.split(' ')[0],
          "last_name": user.displayName.split(' ').slice(1).join(' '),
      };
      console.log('newUser:', newUser);

      // axios post request with auth token added from localstorage
      let userResponse = undefined;
      const authToken = localStorage.getItem('token');

      //${process.env.VUE_APP_URL}api/v1/user
      try {
        userResponse = await axios.post('/user', newUser, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }).then((response) => {
          this.$store.commit('USER_STORE/CREATE_USER', response.data.data)
          this.$toast.success('Successfully created new users.')
          // this.$router.push({ name: "organisation/users", params: { org_id: this.$route.params.org_id }}, () => {})
        });
      } catch (error) {
        this.isCreating = false
        this.$toast.error(error.response.data.message ?? error.message)
        return error.response.data.message ?? error.message
      }
    },
  },
  beforeMount() {
    this.getData()
  },
  mounted() {},
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Users' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: { text: this.organisation.name, path: `/organisation/${this.organisation.id}` }
    })
  }
}
</script>

<style scoped>
.text-disabled {
  color: #b0b0b0;
}
</style>