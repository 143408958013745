<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card>
      <v-card-title class="primary primary--text--contrast">Edit Details</v-card-title>


      <ValidationObserver v-slot="{ invalid, validated, passes }">
        <v-form @submit.prevent="" v-if="!isUpdating">

          <v-row class="mt-4">

            <v-col cols="12" v-if="updateOrganisation" class="px-8">

              <v-row>

                <v-col cols="12" sm="5" md="4">

                  <v-row align="center" justify="center" class="mb-4">
                    <OrganisationAvatar
                      :organisation="organisation"
                      :size="128"
                      color="primary"
                    ></OrganisationAvatar>
                  </v-row>

                  <v-row align="center" justify="center" class="text-center">
                    <UploadModal
                      :reseller_id="organisation.reseller_id"
                      :organisation_id="organisation.id"
                      uploadType="organisation_avatar"
                      buttonLabel="Upload Avatar"
                      modalLabel="Upload Organisation Avatar"
                      @onUploaded="handleUploadedOrganisationAvatar"
                    ></UploadModal>
                  </v-row>

                  <v-row align="center" justify="center" class="text-center">
                    <v-col>
                      <div class="d-flex align-center justify-center">
                        <v-card flat outlined>
                          <div class="px-6 py-1"><small class="d-block">File Type: PNG, JPG</small></div>
                          <v-divider></v-divider>
                          <div class="px-6 py-1"><small class="d-block">Ideal Size: 512x512</small></div>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>

                <v-col cols="12" sm="7" md="8">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="updateOrganisation.name"
                    label="Organisation Name"
                    :disabled="updateOrganisation.id == 0"
                  />

                  <Can I="delete" a="organisation">
                    <v-radio-group
                      v-model="updateOrganisation.status.status_id"
                      class="my-0 py-0" v-if="updateOrganisation.status.status_id"
                      :disabled="updateOrganisation.id == 0"
                    >
                      <v-radio label="Active" value="1"></v-radio>
                      <v-radio label="Inactive" value="0"></v-radio>
                    </v-radio-group>
                  </Can>

                  <v-autocomplete
                    outlined
                    flat
                    label="Industry Type"
                    placeholder="Industry Type"
                    item-text="name"
                    item-value="type_id"
                    hide-details="auto"
                    class="mb-6"
                    v-model="updateOrganisation.profile.industry_type.type_id"
                    :disabled="updateOrganisation.id == 0"
                    :loading="isLoading"
                    :items="industryTypes"
                  ></v-autocomplete>

                  <v-autocomplete
                    outlined
                    flat
                    label="Organisation Type"
                    placeholder="Organisation Type"
                    item-text="name"
                    item-value="type_id" hide-details="auto"
                    class="mb-6"
                    v-model="updateOrganisation.profile.organisation_type.type_id"
                    :disabled="updateOrganisation.id == 0"
                    :loading="isLoading"
                    :items="organisationTypes"
                  ></v-autocomplete>
                </v-col>

              </v-row>

            </v-col>

            <v-col cols="12" class="py-0">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" class="px-8">

              <v-card-actions class="px-0">
                <v-spacer></v-spacer>

                <v-btn
                  outlined
                  color="primary"
                  @click="passes(handleEditOrganisation)"
                  v-if="!isUpdating"
                  :disabled="updateOrganisation.id == 0"
                >
                  Save Changes
                </v-btn>
              </v-card-actions>

            </v-col>

          </v-row>

        </v-form>
      </ValidationObserver>

      <v-row align="center" justify="center" v-if="isUpdating">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

    </v-card>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { isValidJSON } from '@/_util/json'

import BreadcrumbsManager from '@/_util/breadcrumbManager'
import OrganisationAvatar from '@/components/organisation/Avatar'

import UploadModal from '@/components/upload/UploadModal'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default {
  name: "EditOrganisation",
  mixins: [
    BreadcrumbsManager
  ],
  props: [
    'organisation'
  ],
  components: {
    VTextFieldWithValidation,
    OrganisationAvatar,
    UploadModal
  },
  data() {
    return {
      newAdmins: [],
      admins: [],
      isUpdating: false,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.ORG_STORE.loadingTypes,
      industryTypes: state => state.UTIL_STORE.industryTypes,
      organisationTypes: state => state.UTIL_STORE.organisationTypes
    }),
    // We need a deep clone of the organisation to bind to, otherwise vuex complains as we are running it in strict mode
    updateOrganisation:  function () {
      if (!this.organisation)
        return null

      return JSON.parse(JSON.stringify(this.organisation))
    },
  },
  methods: {
    async handleEditOrganisation(data = {}) {
      this.isUpdating = true

      const variables = {
        id: parseInt(this.organisation.id),
        name: this.updateOrganisation.name,
        status: parseInt(this.updateOrganisation.status.status_id),
        organisation_type:  parseInt(this.updateOrganisation.profile.organisation_type.type_id),
        industry_type: parseInt(this.updateOrganisation.profile.industry_type.type_id),
      }

      Object.keys(data).map((key, index) => {
        variables[key] = data[key]
      })

      const organisation = await this.$http.put('organisation', variables)
        .then((response) => {
          return response.data.data[0]
        },
        ({ error }) => {
          this.$toast.error('Something went wrong updating organisation, please try again.')
        })

      this.isUpdating = false

      if (organisation) {
        this.$store.commit('ORG_STORE/SET_ORG', organisation)
        this.$store.dispatch('ORG_STORE/UPDATE_ORG', organisation)
        this.$toast.success('Successfully updated organisation.')
      } else {
        this.$toast.error('Something went wrong updating organisation, please try again.')
      }
    },
    async handleUploadedOrganisationAvatar(data) {
      if (data) {
        const file = data[0]

        if (file) {
          this.handleEditOrganisation({
            avatar: `${file.id}:${file.meta.path}`
          })
        }
      }
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Edit' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: {
        text: this.organisation.name,
        path: `/organisation/${this.organisation.id}`
      }
    })
  }
}
</script>
