import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

Vue.use(VuetifyToast,{
  x: 'center',
  y: 'top',
  classes: [
    'text-center'
  ],
  timeout: 4000,
  width: 192
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#31cbb9", //Crysp green
        secondary: '#1e1e1e', //off black
        tertiary: '#847CF0', //crysp purple
        warning: '#FF9300', //Crysp orange
        error: '#FB5353', //crysp red
        cryspGreen: "#31cbb9", //Crysp green
        cryspYellow: '#FFEB71', //crysp yellow
      }
    }
  },
  options: {
    customProperties: true,
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      edit: 'mdi-square-edit-outline', // edit icon
      editContent: 'mdi-file-document-edit-outline', // edit icon
      update: 'mdi-update', // update icon
      view: 'mdi-eye', // view icon
      download: 'mdi-progress-download', // download icon
      share: 'mdi-tray-arrow-up', // share icon
      // add more here as per your requirement
    },
  },
})
