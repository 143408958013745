<template>
  <div>
    <div v-if="!isLoading && !isComplete">
      <ValidationObserver v-slot="{ invalid, validated, passes }">
        <p class="body-2 text-center">
          Enter a new password for your account.
        </p>

        <v-form @submit.prevent="">
          <ValidationObserver>
            <ValidationProvider name="password" rules="required|min:8|password:@confirm"  v-slot="{ errors, valid }">
              <v-text-field
                v-model="password"
                :error-messages="errors"
                :success="valid"
                label="New Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                outlined dense required></v-text-field>
            </ValidationProvider>

            <ValidationProvider name="confirm" rules="required"  v-slot="{ errors, valid }">
              <v-text-field
                v-model="password_confirmation"
                :error-messages="errors"
                :success="valid"
                label="Confirm New Password"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                outlined dense required></v-text-field>
            </ValidationProvider>
          </ValidationObserver>

          <v-card-actions class="mx-0 px-0">
            <v-spacer></v-spacer>

            <v-btn color="primary"
              depressed
              width="100%"
              type="submit"
              @click="passes(handlePasswordReset)"
              :disabled="invalid || !validated"
            >
              Reset Password
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </div>

    <div v-if="isComplete">
      <p class="title text-center">
        Password Changed
      </p>

      <p class="body-2 text-center">
        Your password was updated successfully! 🎉<br />
      </p>

      <v-btn
        class="mt-4"
        color="primary"
        depressed
        width="100%"
         :to="{ name: 'login' }"
      >
        Return to Login
      </v-btn>
    </div>

    <v-row align="center" justify="center" v-if="isLoading">
      <v-loading-spinner></v-loading-spinner>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  data() {
    return {
      hash: false,
      email: false,
      password: "",
      password_confirmation: "",
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
      isComplete: false
    }
  },
  mounted() {
    this.hash  = this.$route.params.hash
    this.email = this.$route.query.email
  },
  methods: {
    handlePasswordReset() {
      this.isLoading = true

      this.$http.post('user/password/reset', {
          hash: this.hash,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        .then(({ data }) => {
          this.isLoading = false
          this.isComplete = true
        },
        ({ error }) => {
          this.isLoading = false
          this.$toast.error('There was a problem resetting your password, please try again.')
        })
    }
  }
}
</script>
