<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="10" xl="8">
          <v-page-header value="Manage Organisation"></v-page-header>
        </v-col>
      </v-row>

      <v-fade-transition mode="out-in">
        <v-row v-if="$vuetify.breakpoint.mdAndUp && !isLoading">
          <v-col cols="12">

            <v-row align="center" justify="center">

              <OrganisationAvatar
                :organisation="organisation"
                :size="128"
                color="primary"
              ></OrganisationAvatar>

            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12" md="10" xl="8">

                <div class="d-flex align-center justify-center mt-2">
                  <v-chip
                    outlined
                    class="px-12"
                    large
                  >
                    <span class="font-weight-medium">{{ organisation.name }}</span>
                  </v-chip>
                </div>

              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12" md="10" xl="8">

                <v-toolbar class="elevation-2">
                  <v-spacer></v-spacer>

                  <v-toolbar-items>

                    <v-tooltip top v-for="card in cards" :key="card.title">
                      <template v-slot:activator="{ on, attrs }">

                          <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            text
                            :to="{ name: card.name, params: { org_id: organisation.id }}"
                          >
                            <v-icon v-text="card.icon" class="mr-2"></v-icon>
                            {{ card.title }}
                          </v-btn>

                      </template>
                      <span>{{ card.subtitle }}</span>
                    </v-tooltip>

                  </v-toolbar-items>

                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <v-row  v-if="!$vuetify.breakpoint.mdAndUp && !isLoading">
          <v-col cols="12">

            <v-card class="py-2">
              <v-toolbar class="elevation-0">

                <OrganisationAvatar
                    :organisation="organisation"
                    :size="42"
                    color="primary"
                    class="mr-7"
                ></OrganisationAvatar>

                <v-toolbar-title>
                  <span class="subtitle-1 font-weight-medium">{{ organisation.name }}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-menu
                  bottom
                  offset-y
                  close-on-click
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list rounded two-line>
                    <v-list-item v-for="card in cards" :key="card.title" router :to="{ name: card.name, params: { org_id: organisation.id }}">
                      <v-list-item-action>
                        <v-icon v-text="card.icon" class="mr-2"></v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ card.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{ card.subtitle }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-toolbar>
            </v-card>

          </v-col>

          <v-expand-transition>
            <v-col cols="12" v-if="!isInnerPage">
              <div class="elevation-2">
                <v-list rounded two-line>
                  <v-list-item v-for="card in cards" :key="card.title" router :to="{ name: card.name, params: { org_id: organisation.id }}">
                    <v-list-item-action>
                      <v-icon v-text="card.icon" class="mr-2"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ card.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ card.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-fade-transition>

      <v-row align="center" justify="center" v-if="isLoading">
        <v-loading-spinner></v-loading-spinner>
      </v-row>

      <v-row align="center" justify="center" v-if="!isLoading">
        <v-col cols="12" md="10" xl="8">

          <v-slide-y-transition mode="out-in">
            <router-view
              :organisation="organisation"
              :users="users"
              :groups="groups"
              v-on:microsoftUsersChanged="handleMicrosoftUsersChanged"
            ></router-view>
          </v-slide-y-transition>

        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import OrganisationAvatar from '@/components/organisation/Avatar'

import BreadcrumbsManager from '@/_util/breadcrumbManager'


export default {
  name: "OrganisationHome",
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    OrganisationAvatar
  },
  data() {
    return {
      isInnerPage: false,
      cards: [
        {
          title: "Details",
          subtitle: "Manage organisation details.",
          name: "organisation/edit",
          icon: "mdi-playlist-edit"
        },
        {
          title: "Theme",
          subtitle: "Customise your application.",
          name: "organisation/theme",
          icon: "mdi-palette"
        },
        {
          title: "Users",
          subtitle: "Add or manage users.",
          name: "organisation/users",
          icon: "mdi-account-multiple"
        },
        {
          title: "Groups",
          subtitle: "Create and manage groups.",
          name: "organisation/groups",
          icon: "mdi-account-group"
        },
        {
          title: "Useful Links",
          subtitle: "Manage useful links.",
          name: "organisation/content",
          icon: "mdi-text-box-outline"
        },
        {
          title: "Policies",
          subtitle: "Manage policies.",
          name: "organisation/policies",
          icon: "mdi-file-document-outline"
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
      users: state => state.USER_STORE.users,
      groups: state => state.GROUP_STORE.groups,
      organisation: state => state.ORG_STORE.organisation,
      isLoading: state => state.ORG_STORE.loadingOrganisation
    }),
  },
  beforeMount() {
    this.getData()
  },
  mounted() {
    this.checkIsInnerPage(this.$router.currentRoute)
  },
  methods: {
    getData() {
      this.$store.commit('ORG_STORE/LOADING_ORG')
      this.$store.commit('USER_STORE/LOADING_USERS')
      this.$store.commit('GROUP_STORE/LOADING_GROUPS')

      const reseller_id = parseInt(this.user.reseller_id)
      const organisation_id = parseInt(this.$route.params.org_id)

      const getOrganisation = async () => {
        const { data } = await this.$http.get('organisations', {
          params: {
            id: `${organisation_id}`,
            reseller_id: reseller_id
          }
        })

        return data
      }

      const getUsers = async () => {
        const { data } = await this.$http.get('users', {
          params: {
            reseller_id: reseller_id,
            organisation_id: organisation_id
          }
        })

        return data
      }

      const getGroups = async () => {
        const { data } = await this.$http.get('group', {
          params: {
            reseller_id: reseller_id,
            organisation_id: organisation_id
          }
        })

        return data
      }

      Promise.all([
        getOrganisation(),
        getUsers(),
        getGroups(),
      ]).then(([organisations, users, groups, usefulContent]) => {
        this.$store.commit('ORG_STORE/SET_ORG', organisations.data[0])
        this.$store.commit('USER_STORE/SET_USERS', users.data)
        this.$store.commit('GROUP_STORE/SET_GROUPS', groups.data)
      })
    },
    checkIsInnerPage(d) {
      if (d.meta) {
        this.isInnerPage = d.meta.organisationHome ? false : true
      }
    },
    handleMicrosoftUsersChanged(newUsers, oldUsers) {
      console.log('Microsoft users changed');
      console.log(newUsers);
      console.log(oldUsers);

      // add the new users to the users object
      console.log(this.users);

      //find the oldUsers in this.users and update the status to disabled
      oldUsers.forEach(user => {
        let userIndex = this.users.findIndex(u => u.id === user.id);
        console.log(userIndex);
        if (userIndex !== -1) {
          this.users[userIndex].status = {
            id: 2,
            name: 'Disabled',
            status_id: 0
          }
        }
      })

      newUsers.forEach(user => {
        let newUser = {
          email: user.mail,
          organisation_id: this.organisation.id,
          role: "user",
          profile: {
            full_name: user.displayName,
            first_name: user.givenName,
            last_name: user.surname,
            avatar: null
          },
          created_at: user.createdDateTime,
          updated_at: user.lastModifiedDateTime,
          // microsoft_id: user.id,
          status: {
            id: 1,
            name: 'Active',
            status_id: 1
          },
          username: user.mail
        }
        this.users.push(newUser);
      })
    }
  },
  watch: {
    $route (to, from) {
      this.checkIsInnerPage(to)
    },
    organisation: function() {
      this.setBreadcrumbs([
        { text: 'Dashboard' , path: '/' },
        { text: 'Organisations' , path: '/organisation/all/' },
        { text: ':organisation' }
      ])

      this.replaceBreadcrumb({
        find: ':organisation',
        replace: {
          text: this.organisation.name,
          path: `/organisation/${this.organisation.id}`
        }
      })
    }
  }
}
</script>
