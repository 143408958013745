<template>
  <v-content>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Create New Organisation"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card>
            <v-card-title class="primary primary--text--contrast">Organisation Details</v-card-title>

            <v-row align="center" :class="{'px-6': $vuetify.breakpoint.xs, 'pa-8': $vuetify.breakpoint.smAndUp}">
              <v-row justify="space-between" v-if="!isCreating">
                <v-col cols="12" md="4">
                  <v-row align="center" justify="center" class="mb-4">

                    <v-avatar color="primary" size="128">
                      <v-icon size="48" dark v-if="!organisationAvatarName()">mdi-domain</v-icon>
                      <span
                        v-if="organisationAvatarName()"
                        class="primary--text--contrast display-2"
                      >{{ organisationAvatarName() }}</span>
                    </v-avatar>

                  </v-row>
                </v-col>

                <v-col>
                  <ValidationObserver v-slot="{ invalid, validated, passes }">
                    <v-form @submit.prevent="">
                      <v-row >
                        <v-col class="py-0 mb-6">
                          <VTextFieldWithValidation rules="required" v-model="name" label="Organisation Name" />
                        </v-col>
                      </v-row>

                      <v-row >
                        <v-col class="py-0 mb-8">
                          <ValidationProvider name="industry_type" rules="required"  v-slot="{ errors, valid }">
                            <v-autocomplete
                              outlined
                              flat
                              label="Industry Type" item-text="name" item-value="type_id" hide-details="auto"
                              :loading="isLoading"
                              :error-messages="errors"
                              :success="valid"
                              v-model="industry_type"
                              :items="industryTypes">
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col class="py-0 mb-8">
                          <ValidationProvider name="organisation_type" rules="required" v-slot="{ errors, valid }">
                            <v-autocomplete
                              outlined
                              flat
                              label="Organisation Type" item-text="name" item-value="type_id" hide-details="auto"
                              :loading="isLoading"
                              :error-messages="errors"
                              :success="valid"
                              v-model="organisation_type"
                              :items="organisationTypes">
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="py-0 mb-8">
                          <ValidationProvider name="package" rules="" v-slot="{ errors, valid }">
                            <v-checkbox label="Do not restrict to trial account" name="paidPackage" v-model="paidPackage"></v-checkbox>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row >
                        <v-col class="py-0 mb-8">
                          <ValidationProvider name="package" rules="" v-slot="{ errors, valid }">
                            <v-checkbox label="Do not restrict to trial account" name="paidPackage" v-model="paidPackage"></v-checkbox>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row >
                        <v-col class="py-0 text-right">
                          <v-btn outlined color="primary" @click="passes(handleCreateOrganisation)" v-if="!isCreating">
                            Save &amp; Create
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </ValidationObserver>
                </v-col>
              </v-row>

              <v-row align="center" justify="center" v-if="isCreating">
                <v-loading-spinner></v-loading-spinner>
              </v-row>

            </v-row>
          </v-card>

        </v-col>
      </v-row>

    </v-container>
  </v-content>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default {
  name: 'CreateOrganisation',
  mixins: [
    BreadcrumbsManager
  ],
  components: {
    VTextFieldWithValidation
  },
  data() {
    return {
      name: '',
      organisation_type: '',
      industry_type: '',
      isCreating: false,
      paidPackage: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.UTIL_STORE.loadingTypes,
      industryTypes: state => state.UTIL_STORE.industryTypes,
      organisationTypes: state => state.UTIL_STORE.organisationTypes,
      user: state => state.AUTH_STORE.authUser
    }),
  },
  methods: {
    organisationAvatarName() {
      if (this.name) {
        return this.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase().substring(0, 2);
      }

      return false
    },
    async handleCreateOrganisation() {
      this.isCreating = true

      const organisationPayload = {
        name: this.name,
        reseller_id: this.user.reseller_id,
        organisation_type: this.organisation_type,
        industry_type: this.industry_type,
        package_name: this.paidPackage ? "paid" : "trial"
      }

      const organisation = await this.$http.post('organisation', organisationPayload)
        .then(response => {
          this.isCreating = false

          const org = response.data.data[0]
          this.$store.commit('ORG_STORE/CREATE_ORG', org)

          return org
        })
        .catch(error => {
          this.isCreating = false
          this.$toast.error('Something went wrong creating a new organisation, please try again.')
        })
        .finally(() => {
          this.$toast.success('Successfully created a new organisation.')
          this.$router.push({ name: 'organisation/all' }, () => {})
        })
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations', path: '/organisation/all/' },
      { text: 'Create' }
    ])
  }
}
</script>
