<template>
  <v-card class="pa-4 mb-8">
    <v-row align="center" justify="center">
      <v-col cols="4" md="3" align="center" justify="center">
        <v-progress-circular
          :rotate="-90"
          :size="96"
          :width="16"
          :value="getPercentValue('2')"
          :indeterminate="isLoading"
          color="success"
        >
        <div class="status-total">
          <h3> {{ Math.round(getPercentValue('2'))}}%</h3>
          <span> {{getCountValue('2')}}</span>

        </div>
    
        </v-progress-circular>

        <h5 class="mt-4 text-uppercase">Compliant</h5>
      </v-col>

      <v-col cols="4" md="3" align="center" justify="center">
        <v-progress-circular
          :rotate="-90"
          :size="96"
          :width="16"
          :value="getPercentValue('3')"
          :indeterminate="isLoading"
          color="warning"
        >
        <div class="status-total">
          <h3> {{Math.round(getPercentValue('3'))}}%</h3>
          <span > {{getCountValue('3')}}</span>
        </div>
        </v-progress-circular>

        <h5 class="mt-4 text-uppercase">Warnings</h5>
      </v-col>

      <v-col cols="4" md="3" align="center" justify="center">
        <v-progress-circular
          :rotate="-90"
          :size="96"
          :width="16"
          :value="getPercentValue('4')"
          :indeterminate="isLoading"
          color="error"
        >
        <div class="status-total"> 
          <h3> {{Math.round(getPercentValue('4'))}}%</h3> 
          <span > {{getCountValue('4')}}</span>
        </div>
        </v-progress-circular>

        <h5 class="mt-4 text-uppercase">Outstanding</h5>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ViewCompliancesTotalStatus",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    statusData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    percentageStatusData() {
      return this.statusData?.percent_by_status;
    },

    statusDataCount() {
      return this.statusData?.items_per_status;
    },
  },

  methods: {
    getPercentValue(statusId) {
      if (!this.percentageStatusData) return 0;

      return this.percentageStatusData[statusId];
    },

    getCountValue(statusId) {
      if (!this.statusDataCount) return null;
      return this.statusDataCount[statusId];
    },
    calculateItemPercentage(value, total) {
      return Math.round((value / total) * 100);
    },
  },
};
</script>

<style scoped>
.status-total {
  display: flex;
  flex-direction: column;
}

.status-total h3 {
  font-size: 0.95rem;
}

.status-total span {
  font-size: 0.75rem;
}

</style>
