<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route>
      <v-card-title class="primary primary--text--contrast">Create New Group</v-card-title>

      <v-row align="center" :class="{'px-6': $vuetify.breakpoint.xs, 'px-8': $vuetify.breakpoint.smAndUp}">
        <v-row justify="space-between" v-if="!isCreating">

          <v-col>
            <ValidationObserver v-slot="{ invalid, validated, passes }">
              <v-row>
                <v-col cols="12">
                  <VTextFieldWithValidation rules="required" v-model="newGroup.name" label="Group Name" />
                </v-col>
              </v-row>

              <div class="pt-4 elevation-1">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search Users"
                  outlined
                  dense
                  class="mx-4"
                ></v-text-field>

                <v-data-table
                  v-if="userList.length"
                  class="v-data-table--selectable"
                  :headers="headers"
                  :items="userList"
                  :items-per-page="10"
                  :search="search"
                  mobile-breakpoint="960"
                >

                <template v-slot:item.profile.avatar="{ item }">
                  <v-row justify="center">
                    <v-col cols="12" class="text-center">
                      <UserAvatar
                          :profile="item.profile"
                          :size="32"
                          color="primary"
                        ></UserAvatar>
                     </v-col>
                  </v-row>
                </template>

                <template v-slot:item.status.name="{ item }">
                  <v-chip small color="primary" v-if="item.status.name === 'Active'"></v-chip>
                  <v-chip small color="warning" v-if="item.status.name != 'Active'"></v-chip>
                </template>

                <template v-slot:item.email="{ item }">
                  <div style="word-break: break-word">
                  {{item.email}}
                  </div>
                </template>

                <template v-slot:item.role="{ item }">
                  <v-chip small outlined v-if="item.role">
                    {{ item.role | titleCase }}
                  </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="d-flex justify-end" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn color="primary" small icon class="ml-auto" @click="add(item)" v-if="!item.selected">
                      <v-icon>mdi-checkbox-blank-outline</v-icon>
                    </v-btn>
                    <v-btn color="primary" small icon class="ml-auto" @click="remove(item)" v-if="item.selected">
                      <v-icon>mdi-checkbox-marked</v-icon>
                    </v-btn>
                  </div>

                  <v-row dense v-if="!$vuetify.breakpoint.mdAndUp" class="mb-2">
                    <v-col cols="12">
                      <v-btn
                      v-if="!item.selected"
                      color="primary"
                      block
                      class="ml-auto"
                      @click="add(item)">
                        <v-icon dark left>mdi-account-plus</v-icon>
                        Add to Group
                      </v-btn>

                      <v-btn
                      v-if="item.selected"
                      color="primary"
                      block
                      class="ml-auto"
                      @click="remove(item)">
                        <v-icon dark left>mdi-account-minus</v-icon>
                        Remove from Group
                      </v-btn>
                    </v-col>
                  </v-row>

                </template>

                </v-data-table>
              </div>

              <v-row class="px-3 py-6">
                <v-chip
                  outlined
                >
                  {{ selectedUsers.length ? selectedUsers.length : 'No' }} user{{ selectedUsers.length > 1 || !selectedUsers.length ? 's' : ''}} assigned to this group.
                </v-chip>

                <v-spacer></v-spacer>

                <v-btn outlined @click="passes(handleSubmit)" color="primary" :disabled="invalid || !validated">Create Group</v-btn>
              </v-row>

            </ValidationObserver>

          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="pa-8" v-if="isCreating">
          <v-loading-spinner></v-loading-spinner>
        </v-row>

      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import UserAvatar from '@/components/user/Avatar'
import router from '@/_router'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'CreateGroup',
  props: ['organisation', 'users'],
  mixins: [BreadcrumbsManager],
  components: {
    VTextFieldWithValidation,
    UserAvatar
  },
  data() {
    return {
      isCreating: false,
      search: '',
      headers: [
        { text: "", value: "profile.avatar", sortable: false },
        { text: "First Name", value: "profile.first_name" },
        { text: "Last Name", value: "profile.last_name" },
        { text: "Status", value: "status.name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "", value: "actions", sortable: false }
      ],
      newGroup: {
        name: '',
        organisation_id: parseInt(this.$route.params.org_id),
        reseller_id: parseInt(this.organisation.reseller_id),
      },
      selectedUsers: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser
    }),
    // We need a deep clone of the organisation to bind to, otherwise vuex complains as we are running it in strict mode
    userList:  function () {
      if (!this.users)
        return null

      const cloned = JSON.parse(JSON.stringify(this.users))

      let users = []

      cloned.forEach((user, i) => {
        users.push({
          id: user.id,
          profile: user.profile,
          avatar: user.profile.avatar,
          first_name: user.profile.first_name,
          last_name: user.profile.last_name,
          status: user.status,
          email: user.email,
          role: user.role || "",
          selected: false
        })
      })

      return users
    },
  },
  methods: {
    handleSubmit: function() {
      this.isCreating = true

      const user_ids = this.selectedUsers.reduce((arr, elem) => arr.concat(elem.id), []).join()
      const variables = {...this.newGroup, user_ids}

      this.$http.post('group', variables)
        .then(
          ({ data }) => {
            this.isCreating = false
            this.$store.commit('GROUP_STORE/CREATE_GROUP', data.data)
            this.$store.dispatch('USER_STORE/GET_USERS', parseInt(this.$route.params.org_id))
          }, ({ error }) => {
            this.isCreating = false
            this.$toast.error('Something went wrong creating a new group.')
          }
        ).finally(() => {
          this.$toast.success('Successfully created a new group.')
          this.$router.push({ name: 'organisation/groups', params: { org_id: this.organisation.id }})
        })
    },
    add: function(item) {
      item.selected = !item.selected
      this.selectedUsers.push({...item})
    },
    remove: function(item) {
      item.selected = !item.selected

      const filtered = this.selectedUsers.filter(x => {
        return x.id != item.id
      })

      this.selectedUsers = filtered
    }
  },
  mounted() {
    this.$store.dispatch('USER_STORE/GET_USERS', parseInt(this.$route.params.org_id))
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: ':groups' },
      { text: 'Create' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: { text: this.organisation.name, path: `/organisation/${this.organisation.id}` }
    })

    this.replaceBreadcrumb({
      find: ':groups',
      replace: { text: 'Groups', path: `/organisation/${this.organisation.id}/groups` }
    })
  }
}
</script>
