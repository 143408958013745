<template>
  <v-content>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">
          <v-page-header value="Manage Profile"></v-page-header>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" xl="8">

          <v-card route>
            <v-card-title class="primary primary--text--contrast">Notifications</v-card-title>
            <v-row class="px-4 py-3 font-weight-medium subtitle-2">
              <v-col cols="12" class="d-flex align-baseline pb-0 pr-7">
                All Notifications
                <v-spacer></v-spacer>
                <v-switch
                v-model="all"
                inset
                label="Select all"
                :hide-details="true"
                @click="handleAllNotifications"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-sheet outlined>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                        v-for="(item,i) in notificationsState"
                        :key="item.name"
                        >
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          <v-switch
                          v-model="notifications[i]"
                          :true-value="item.value"
                          false-value=""
                          inset
                          :value="item.value"
                          class="float-end"
                          @change="handleNotifications"
                          ></v-switch>
                        </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbsManager from '@/_util/breadcrumbManager'

export default {
  name: 'ManageNotifications',
  mixins: [
    BreadcrumbsManager
  ],
  components: {
  },
  data() {
    return {
      all: false,
      notificationsState: [
        {
          name: 'Compliance Notifications',
          value: 'compliance'
        },
        {
          name: 'Policy Notifications',
          value: 'policy'
        }
      ],
      notifications: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.AUTH_STORE.authUser,
    }),
  },
  methods: {
    handleAllNotifications() {
      this.notifications = this.all ? this.notificationsState.map(({value}) => value) : [];
      this.handleNotifications();

    },
    async handleNotifications(e) {
      console.log(e);
      await this.$http.put('user/notifications', {notifications:this.notifications})
        .then(data => {
          this.notifications = data.data.data;
          this.all = this.notifications.filter(notification => notification != null).length === this.notificationsState.length;
        }, error => {
          this.$toast.error(`Something has gone wrong updating notifications`)
          console.error('Something has gone wrong updating notifications')
        })

    },
    async getData() {
      await this.$http.get('user/notifications')
        .then(data => {
          this.notifications = data.data.data;
          this.all = this.notifications.filter(notification => notification != null).length === this.notificationsState.length;
        }, error => {
          this.$toast.error(`Something has gone wrong loading notifications`)
          console.error('Something has gone wrong loading notifications')
        })
    },
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Manage Profile', path: '/profile/' },
      { text: 'Notifications', path: '/notifications' }
    ])
  },
  beforeMount() {
    this.getData()
  },
}
</script>

<style lang="scss">
  .v-input--switch .v-input__control .v-input__slot {
    flex-direction: row-reverse;
    .v-input--selection-controls__input {
      margin-left: 12px;
      margin-right: 0;
    }
    .v-label {
      font-size: 0.875rem !important;
    }
  }
</style>