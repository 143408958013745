<template>
  <v-container fluid class="ma-0 pa-0">

    <v-card route>
      <v-card-title>All Policies</v-card-title>

      <v-divider></v-divider>

      <v-tabs>
        <v-tab text large plain @click="filter = 0"><v-icon dark left>mdi-file-document-outline</v-icon>Live Policies</v-tab>
        <v-tab text large plain @click="filter = -1"><v-icon dark left>mdi-file-document-outline</v-icon>Policy Compliance</v-tab>
        <v-tab text large plain @click="filter = 1"><v-icon dark left>mdi-file-document-outline</v-icon>Archived Policies</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-row dense class="px-4 py-3" justify="end">

        <v-col cols="12" md="4" lg="3" class="py-1 d-flex align-center">
          <Can I="create" a="policy">
            <UploadPolicyModal
              :autoUpload="false"
              :organisation_id="organisation.id"
              uploadType="organisation_generic"
              buttonLabel="Upload Policy"
              modalLabel="Upload Policy"
              classList="upload-policy"
              @onUploaded="handleUploadedPolicy"
              flush
            ></UploadPolicyModal>

            <v-spacer></v-spacer>
          </Can>
        </v-col>

        <v-col>
          <!-- <v-select
            :items="groups"
            outlined
            dense
            hide-details
            v-model="sort"
            :menu-props="{closeOnContentClick: true}"
            placeholder="All Groups"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-filter-outline"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="sort = -1"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    All Groups
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select> -->
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-expansion-panels :loading="isLoading" v-if="filter === 0">
        <v-expansion-panel
          v-for="(group,i) in live_policies"
          :key="i"
        >
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon medium>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <span>View policies</span>
              </v-tooltip>
            </template>
            <template v-slot="{ open }">
              <v-row no-gutters>
                <v-col class="d-flex align-center"><strong>{{ group.name }}</strong></v-col>
                <v-col
                  cols="4"
                  class="d-flex align-center justify-end text--secondary px-2"
                  align="right"
                >
                  <v-fade-transition>
                    <span>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                          :rotate="-90"
                          :size="32"
                          :width="5"
                          :value="calculateGroupPolicyCompliance(group,2,true)"
                          color="success"
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          >
                          <strong class="caption">{{ calculateGroupPolicyCompliance(group,2) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Reviewed</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            :rotate="-90"
                            :size="32"
                            :width="5"
                            :value="calculateGroupPolicyCompliance(group,1,true)"
                            color="warning"
                            class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <strong class="caption">{{ calculateGroupPolicyCompliance(group,1) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Due Review</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            :rotate="-90"
                            :size="32"
                            :width="5"
                            :value="calculateGroupPolicyCompliance(group,0,true)"
                            color="error"
                            class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <strong class="caption">{{ calculateGroupPolicyCompliance(group,0) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Review Overdue</span>
                      </v-tooltip>
                    </span>
                  </v-fade-transition>

                  <v-fade-transition>
                    <v-divider vertical class="mx-3"></v-divider>
                  </v-fade-transition>

                  <v-menu right bottom>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon medium>$vuetify.icons.values.download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download</span>
                      </v-tooltip>
                    </template>
                    <v-list dense>
                      <v-list-item @click="exportAllPoliciesPDF(i)">
                        <v-list-item-icon class="mr-3">
                          <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as PDF</v-list-item-title>
                      </v-list-item>
                      <JsonExcel
                        class="btn btn-default d-flex"
                        :data="group.policies"
                        :fields="json_fields_item"
                        worksheet="Crysp Policy Report"
                        :name="`crysp-policy-report-${group.name}.xls`"
                      >
                        <v-list-item link>
                            <v-list-item-icon class="mr-3">
                              <v-icon color="#83CC70">mdi-file-excel-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>

                                Export for Excel
                            </v-list-item-title>
                        </v-list-item>
                      </JsonExcel>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
              <v-data-table
                class="v-data-table--selectable"
                :headers="policyHeaders"
                :items="group.policies"
                :items-per-page="10"
                item-key="index"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:item.last_review_date="{ item }">
                  {{ item.last_review_date || '-' | moment('DD/MM/YYYY') }}
                </template>

                <template v-slot:item.next_review_date="{ item }">
                  {{ item.next_review_date || '-' | moment('DD/MM/YYYY') }}
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip small color="success" v-if="item.status === 2 || item.status === null"></v-chip>
                  <v-chip small color="warning" v-if="item.status === 1 "></v-chip>
                  <v-chip small color="error" v-if="item.status === 0"></v-chip>
                </template>

                <template v-slot:item.groups="{ item }">
                  {{ item.groups.length == groups.length ? 'All' : `${item.groups.length} Group${item.groups.length != 1 ? 's':''}` }}
                </template>

                <template v-slot:item.description="{ item }">
                  {{ item.description && item.description.length > 100 ? item.description.replace(/<[^>]*>?/gm, '').substring(0, 97) + ' ...' : item.description || '-' }}
                </template>

                <template v-slot:item.archived="{ item }">
                  <div class="d-flex align-center justify-end text--secondary px-2" >
                    <v-divider vertical class="mx-3"></v-divider>

                    <Can I="update" a="policy">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <span v-on="{ ...tooltip }">
                            <UploadPolicyModal
                              :autoUpload="false"
                              :organisation_id="organisation.id"
                              uploadType="organisation_generic"
                              modalLabel="Update Policy"
                              classList="upload-policy"
                              buttonIcon="mdi-file-document-edit-outline"
                              :buttonIconOnly="true"
                              :buttonOutlined="false"
                              :policy="item"
                              @onUploaded="handleUpdatePolicy"
                              flush
                            ></UploadPolicyModal>
                          </span>
                        </template>
                        <span>Update Policy</span>
                      </v-tooltip>
                    </Can>

                    <Can I="archive" a="policy">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-on="{ ...tooltip }"
                            @click.stop="archiveConfirmation = true;toUpdate = item"
                          >
                            <v-icon medium>mdi-package-down</v-icon>
                          </v-btn>
                        </template>
                        <span>Archive Policy</span>
                      </v-tooltip>
                    </Can>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <!-- :href="getStorageFile(item.file_path)+'?policy='+item.id" -->
                        <!-- :download="item.name.replace(/ /g,'').toLowerCase()" -->
                        <v-btn
                          icon
                          v-on="{ ...tooltip }"
                          @click="downloadFile(getStorageFile(item.file_path)+'?policy='+item.id, item.name.replace(/ /g,'').toLowerCase())"
                        >
                          <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :loading="isLoading" v-else-if="filter === 1">
        <v-expansion-panel
          v-for="(group,i) in archived_policies"
          :key="i"
        >
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon medium>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <span>View policies</span>
              </v-tooltip>
            </template>
            <template v-slot="{ open }">
              <v-row no-gutters>
                <v-col class="d-flex align-center"><strong>{{ group.name }}</strong></v-col>
                <v-col
                  cols="4"
                  class="d-flex align-center justify-end text--secondary px-2"
                  align="right"
                >
                  <v-fade-transition>
                    <span>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                          :rotate="-90"
                          :size="32"
                          :width="5"
                          :value="calculateGroupPolicyCompliance(group,2,true)"
                          color="success"
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          >
                          <strong class="caption">{{ calculateGroupPolicyCompliance(group,2) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Reviewed</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            :rotate="-90"
                            :size="32"
                            :width="5"
                            :value="calculateGroupPolicyCompliance(group,1,true)"
                            color="warning"
                            class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <strong class="caption">{{ calculateGroupPolicyCompliance(group,1) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Due Review</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            :rotate="-90"
                            :size="32"
                            :width="5"
                            :value="calculateGroupPolicyCompliance(group,0,true)"
                            color="error"
                            class="mx-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <strong class="caption">{{ calculateGroupPolicyCompliance(group,0) }}</strong>
                          </v-progress-circular>
                        </template>
                        <span>Policies Review Overdue</span>
                      </v-tooltip>
                    </span>
                  </v-fade-transition>

                  <v-fade-transition>
                    <v-divider vertical class="mx-3"></v-divider>
                  </v-fade-transition>

                  <v-menu right bottom>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon medium>$vuetify.icons.values.download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download</span>
                      </v-tooltip>
                    </template>
                    <v-list dense>
                      <v-list-item @click="exportAllPoliciesPDF(i)">
                        <v-list-item-icon class="mr-3">
                          <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as PDF</v-list-item-title>
                      </v-list-item>
                      <JsonExcel
                        class="btn btn-default d-flex"
                        :data="group.policies"
                        :fields="json_fields_item"
                        worksheet="Crysp Policy Report"
                        :name="`crysp-policy-report-${group.name}.xls`"
                      >
                        <v-list-item link>
                            <v-list-item-icon class="mr-3">
                              <v-icon color="#83CC70">mdi-file-excel-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>

                                Export for Excel
                            </v-list-item-title>
                        </v-list-item>
                      </JsonExcel>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
              <v-data-table
                class="v-data-table--selectable"
                :headers="archivedPolicyHeaders"
                :items="group.policies"
                :items-per-page="10"
                item-key="index"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:item.last_review_date="{ item }">
                  {{ item.last_review_date || '-' | moment('DD/MM/YYYY') }}
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip small color="success" v-if="item.status === 2 || item.status === null"></v-chip>
                  <v-chip small color="warning" v-if="item.status === 1 "></v-chip>
                  <v-chip small color="error" v-if="item.status === 0"></v-chip>
                </template>

                <template v-slot:item.groups="{ item }">
                  {{ item.groups.length == groups.length ? 'All' : `${item.groups.length} Group${item.groups.length != 1 ? 's':''}` }}
                </template>

                <template v-slot:item.description="{ item }">
                  {{ item.description && item.description.length > 100 ? stripTags(item.description.substring(0, 100)) + '...' : item.description || '-' }}
                </template>

                <template v-slot:item.archived="{ item }">
                  <!-- Removed v-if to allow download to always show -->
                  <div class="d-flex align-center justify-end text--secondary px-2" > 
                    <v-divider vertical class="mx-3"></v-divider>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <!-- :href="getStorageFile(item.file_path)+'?policy='+item.id" -->
                        <!-- :download="item.name.replace(/ /g,'').toLowerCase()" -->
                        <v-btn
                          icon
                          v-on="{ ...tooltip }"
                          @click="downloadFile(getStorageFile(item.file_path)+'?policy='+item.id, item.name.replace(/ /g,'').toLowerCase())"
                        >
                          <v-icon medium>mdi-arrow-down-bold-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :loading="isLoading" v-else>
        <v-expansion-panel
          v-for="(group,i) in compliance_policies"
          :key="i"
        >
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon medium>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <span>View policies</span>
              </v-tooltip>
            </template>
            <template v-slot="{ open }">
              <v-row no-gutters>
                <v-col class="d-flex align-center"><strong>{{ group.name }}</strong></v-col>
                <v-col
                  cols="4"
                  class="d-flex align-center justify-end text--secondary px-2"
                  align="right"
                >
                  <v-fade-transition>
                    <span>

                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                              :rotate="-90"
                              :size="32"
                              :width="5"
                              :value="calculateTotalPolicyObservationCompliance(group,2,true)"
                              color="success"
                              class="mx-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <strong class="caption">{{ calculateTotalPolicyObservationCompliance(group,2) }}</strong>
                            </v-progress-circular>
                          </template>
                        <span>Policies Signed</span>
                      </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-progress-circular
                              :rotate="-90"
                              :size="32"
                              :width="5"
                              :value="calculateTotalPolicyObservationCompliance(group,1,true)"
                              color="warning"
                              class="mx-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <strong class="caption">{{ calculateTotalPolicyObservationCompliance(group,1) }}</strong>
                            </v-progress-circular>
                          </template>
                        <span>Policies Requiring Signing</span>
                      </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-progress-circular
                              :rotate="-90"
                              :size="32"
                              :width="5"
                              :value="calculateTotalPolicyObservationCompliance(group,0,true)"
                              color="error"
                              class="mx-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <strong class="caption">{{ calculateTotalPolicyObservationCompliance(group,0) }}</strong>
                            </v-progress-circular>
                          </template>
                        <span>Policies Signing Overdue</span>
                      </v-tooltip>
                    </span>
                  </v-fade-transition>

                  <v-fade-transition>
                    <v-divider vertical class="mx-3"></v-divider>
                  </v-fade-transition>

                  <v-menu right bottom>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon medium>$vuetify.icons.values.download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download</span>
                      </v-tooltip>
                    </template>
                    <v-list dense>
                      <v-list-item @click="exportAllObservationsPDF(i)">
                        <v-list-item-icon class="mr-3">
                          <v-icon color="#FF7981">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Export as PDF</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(user,i) in group.actually_users"
                :key="i"
              >
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                        >
                          <v-icon medium>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <span>View user policies</span>
                    </v-tooltip>
                  </template>
                  <template v-slot="{ open }">
                    <v-row no-gutters>
                      <v-col class="d-flex align-center"><strong>{{ getUserFullName(user.id) }}</strong></v-col>
                      <v-col
                        cols="4"
                        class="d-flex align-center justify-end text--secondary px-2"
                        align="right"
                      >
                        <v-fade-transition>
                          <span>

                            <v-progress-circular
                                :rotate="-90"
                                :size="32"
                                :width="5"
                                :value="calculatePolicyObservationCompliance(user.id,user.policies,2,true)"
                                color="success"
                                class="mx-1"
                              >
                                <strong class="caption">{{ calculatePolicyObservationCompliance(user.id,user.policies,2) }}</strong>
                              </v-progress-circular>

                              <v-progress-circular
                                :rotate="-90"
                                :size="32"
                                :width="5"
                                :value="calculatePolicyObservationCompliance(user.id,user.policies,1,true)"
                                color="warning"
                                class="mx-1"
                              >
                                <strong class="caption">{{ calculatePolicyObservationCompliance(user.id,user.policies,1) }}</strong>
                              </v-progress-circular>

                              <v-progress-circular
                                :rotate="-90"
                                :size="32"
                                :width="5"
                                :value="calculatePolicyObservationCompliance(user.id,user.policies,0,true)"
                                color="error"
                                class="mx-1"
                              >
                                <strong class="caption">{{ calculatePolicyObservationCompliance(user.id,user.policies,0) }}</strong>
                              </v-progress-circular>
                          </span>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-data-table
                    class="v-data-table--selectable"
                    :headers="complianceHeaders"
                    :items="getUserObservations(user.id,user.policies)"
                    :items-per-page="10"
                    item-key="index"
                  >
                    <template v-slot:item.user_id="{ item }">
                      {{ item.name }}
                    </template>

                    <template v-slot:item.status="{ item }">
                      <v-chip small color="success" v-if="item.agreement_status === 2"></v-chip>
                      <v-chip small color="warning" v-if="item.agreement_status === 1 "></v-chip>
                      <v-chip small color="error" v-if="item.agreement_status === 0"></v-chip>
                    </template>

                    <template v-slot:item.last_review_date="{ item }">
                      {{ item.last_review_date || '-' | moment('DD/MM/YYYY') }}
                    </template>

                    <template v-slot:item.agreement_due_date="{ item }">
                      {{ item.agreement_due_date || '-' | moment('DD/MM/YYYY : HH:mm') }}
                    </template>

                    <template v-slot:item.time="{ item }">
                      {{ getTimeSpent(item.time_clicked_to_download,item.time_clicked_to_confirm) }}
                    </template>

                    <template v-slot:item.time_clicked_to_confirm="{ item }">
                      {{ item.time_clicked_to_confirm || '-' | moment('DD/MM/YYYY : HH:mm') }}
                    </template>

                    <template v-slot:item.time_lapsed="{ item }">
                      <span :style="`color:${statuses[item.agreement_status].color}`">{{ item.agreement_status === 2 ? '-' : getDaysLapsed(item.agreement_due_date) }}</span>
                    </template>

                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-card-text v-if="!getUserObservations(user.policies).length" class="text-center">
                <p class="text--disabled mb-0">No policies available.</p>
              </v-card-text> -->
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-fade-transition mode="out-in">
      <v-overlay v-if="archiveConfirmation && !isLoading">
        <v-card class="mx-auto" max-width="480" light v-if="!isUpdatingPolicy">
          <v-card-title class="primary primary--text--contrast mb-4">
            Confirm Archive Policy
          </v-card-title>

          <v-card-text>
            Are you sure you want to archive policy <strong>{{ toUpdate.name }}</strong>? This action cannot be undone!
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text color="primary" @click="archiveConfirmation = !archiveConfirmation, toUpdate = {}">Cancel</v-btn>

            <v-spacer></v-spacer>

            <v-btn @click="handleArchivePolicy()" color="error">
              Archive
            </v-btn>

          </v-card-actions>
        </v-card>

        <v-row align="center" justify="center" v-if="isUpdatingPolicy">
          <v-loading-spinner></v-loading-spinner>
        </v-row>
      </v-overlay>
    </v-fade-transition>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import StorageManager from '@/_util/storage'
import BreadcrumbsManager from '@/_util/breadcrumbManager'

import UploadPolicyModal from '@/components/upload/UploadPolicyModal'

import JsonExcel from "vue-json-excel";

import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export default {
  name: 'ManagePolicies',
  components: {
    UploadPolicyModal,
    JsonExcel
  },
  props: [
    'organisation',
    'groups',
    'users'
  ],
  mixins: [
    StorageManager,
    BreadcrumbsManager
  ],
  data() {
    return {
      isUploadingPolicy: false,
      search: '',
      members: [],
      policyHeaders: [
        { text: "Name", value: "name" },
        { text: "Issued", value: "last_review_date" },
        { text: "Next Review", value: "next_review_date" },
        { text: "Status", value: "status" },
        { text: "Group(s)", value: "groups" },
        { text: "Description", value: "description" },
        { text: "", value: "archived", sortable: false, align: "end"}
      ],
      archivedPolicyHeaders: [
        { text: "Name", value: "name" },
        { text: "Issued", value: "last_review_date" },
        { text: "Status", value: "status" },
        { text: "Group(s)", value: "groups" },
        { text: "Description", value: "description" },
        { text: "", value: "archived", sortable: false, align: "end"}
      ],
      complianceHeaders: [
        { text: "Policy", value: "name" },
        { text: "Status", value: "status" },
        { text: "Date Received", value: "last_review_date" },
        { text: "Agreement Due Date", value: "agreement_due_date" },
        { text: "Time", value: "time" },
        { text: "Signed", value: "time_clicked_to_confirm" },
        { text: "Days Lapsed", value: "time_lapsed" },
      ],
      toUpdate: {},
      archiveConfirmation: false,
      isUpdatingPolicy: false,
      filter: 0,
      options: {},
      sort: -1,
      descending: true,
      updateOptionsBasedOnManualSortControls: true,
      statuses: [
        { label:'Never', color: '#F44336'},
        { label:'Accessed', color: '#FB8C00'},
        { label:'Signed', color: '#4CAF50'}
      ],
      totals: {
        total: 0,
        pass: 0,
        warn: 0,
        fail: 0
      },
      json_fields_item: {
        Name: {
          field: "name",
        },
        Issued: {
          field: "last_review_date",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("DD/MM/YYYY")
            }
          },
        },
        'Next Review': {
          field: "next_review_date",
          callback: (value) => {
            if (value) {
              return this.$moment(value).format("DD/MM/YYYY")
            }
          },
        },
        Status: {
          field: "status",
          callback: (value) => {
            return this.statuses[value].label
          },
        },
        Groups: {
          callback: (item) => {
            return this.getGroupNames(item.groups)
          },
        }
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => {
        return state.POLICIES_STORE.loadingPolicies ? true : false
      },
      live_policies: state => state.POLICIES_STORE.live_policies,
      archived_policies: state => state.POLICIES_STORE.archived_policies,
      compliance_policies: state => state.POLICIES_STORE.compliance_policies,
    })
  },
  watch: {
    options: {
      handler () {
        this.updateOptionsBasedOnManualSortControls = false;
        this.sort = this.options.sortBy[0];
        this.descending = this.options.sortDesc[0];
        this.updateOptionsBasedOnManualSortControls = true;
        this.getData()
      },
      deep: true,
    },
    search() {
      this.getDataDebounced();
    },
    sort() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortBy = [this.sort];
      }
    },
    descending() {
      if (this.updateOptionsBasedOnManualSortControls) {
        this.options.sortDesc = [this.descending];
      }
    }
  },
  methods: {
    getDataDebounced() {
      // cancel pending call
      clearTimeout(this.timer)

      // delay new call 500ms
      this.timer = setTimeout(() => {
        // ensure we request the first page (reset pagination)
        this.options.page = 1;
        this.getData()
      }, 500)
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search;
      this.$store.dispatch('POLICIES_STORE/GET_LIVE_POLICIES', {sortBy, sortDesc, page, itemsPerPage, search, organisation: this.organisation})
      this.$store.dispatch('POLICIES_STORE/GET_ARCHIVED_POLICIES', {sortBy, sortDesc, page, itemsPerPage, search, organisation: this.organisation})
      this.$store.dispatch('POLICIES_STORE/GET_COMPLIANCE_POLICIES', {sortBy, sortDesc, page, itemsPerPage, search, organisation: this.organisation})
    },
    stripTags(input) {
      return input.replace(/<[^>]*>?/gm, '');
    },
    groupedPolicies(policies) {
      let groupedPolicies = [];
      this.groups.forEach(group => {
        let item = {
          id: group.id,
          name: group.name,
          policies: policies.filter((policy) => policy.groups.filter((policy_group) => policy_group.id === group.id).length > 0)
        }
        if (item.policies.length && (item.id == this.sort || this.sort == -1)) {
          groupedPolicies.push(item);
        }
      });
      return groupedPolicies;
    },
    getUserObservations(id,policies) {
      let data = policies.map(policy => {
        return policy.policy_observation
        .filter(item => item.user_id === id)
        .map(item => {
          let pol = {
            ...item,
            name: policy.name,
            last_review_date: policy.last_review_date,
            agreement_due_date: policy.agreement_due_date
          }
          return pol;
        })
      });

      return data.flat(1);
    },
    getFilteredPolicies(policies) {
      let filteredPolicies = []

      filteredPolicies = policies.filter(item => {
        if (item.archived === this.filter) {
          return item
        }
      })
      return filteredPolicies
    },
    getPolicy(id) {
      return this.policies.find((policy) => policy.id === id);
    },
    countPolicyStatuses(policies,status) {
      return policies.filter((policy) => policy.status === status).length;
    },
    handleUploadedPolicy(data) {
      if (!data) {
        return
      }

      this.isUploadingPolicy = true

      let viewers = [];

      if (data.viewer_groups) {
        data.viewer_groups.forEach(groupID => {
          this.users.forEach(user => {
            let userGroups = user.groups;
            userGroups.forEach(group => {
              if (group.id == groupID && !viewers.includes(user.id)) {
                viewers.push(user.id);
              }
            });
          });
        });
      }

      let variables = {
        name: data.name,
        last_review_date: data.last_review_date,
        next_review_date: data.next_review_date,
        requires_acknowledgement: data.requires_acknowledgement,
        time_threshold: data.time_threshold,
        version: parseInt(data.version),
        reseller_id: parseInt(data.reseller_id),
        organisation_id: parseInt(data.organisation_id),
        owner_id: parseInt(data.owner_id),
        viewer_groups: data.viewer_groups,
        viewers: viewers,
        file: data[0].meta.path,
        file_name: data.name,
        description: data.description, //adding description to the variables after emit from UplaodPolicyModal.vue
      }

      this.$http.post('policy', variables)
        .then(response => {
          if (response.data) {
            const policy = response.data.data

            this.isUploadingPolicy = false
            this.$store.commit('POLICIES_STORE/CREATE_POLICY', policy)
            this.getData();

            this.$toast.success('Successfully uploaded policy.')
          } else {
            this.$toast.error('Something went wrong uploading policy, please try again.')
          }
        })
        .catch(error => {
          this.isUploadingPolicy = false

          this.$toast.error('Something went wrong uploading policy, please try again.')
        })
    },
    handleArchivePolicy() {
      let archive = true;
      this.archiveConfirmation = true;

      this.toUpdate.archive = archive;

      this.$http.post('policy/archive', this.toUpdate)
        .then(response => {
          if (response.data) {
            let policy = response.data.data;

            this.archiveConfirmation = false
            this.isUpdatingPolicy = false

            // Remove from store
            this.$store.commit('POLICIES_STORE/ARCHIVE_POLICY', policy.id)
            this.getData();

            // Show message
            this.$toast.success(`${policy.name} archived successfully.`)

            // Clear deletion
            this.toUpdate = {}
          } else {
            this.$toast.error('Something went wrong updating policy, please try again. :)')
          }
        })
        .catch(error => {
          this.isUpdatingPolicy = false

          this.$toast.error('Something went wrong updating policy, please try again.')
        })
    },
    handleUpdatePolicy(data) {
      if (!data) {
        return
      }

      let viewers = [];
      let groups = [];

      if (data.viewer_groups) {
        data.viewer_groups.forEach(groupID => {
          let handleID = groupID.id ?? groupID;
          groups.push(handleID);
          this.users.forEach(user => {
            let userGroups = user.groups;
            userGroups.forEach(group => {
              if (group.id == handleID && !viewers.includes(user.id)) {
                viewers.push(user.id);
              }
            });
          });
        });
      }

      let variables = {
        viewer_groups: groups,
        viewers: viewers,
        owner_id: parseInt(data.owner_id),
        file: data[0] ? data[0].meta.path : data.file_path,
        file_name: data.name,
        description: data.description, //adding description to the variables after emit from UplaodPolicyModal.vue
      }

      data = {...data, ...variables};

      this.$http.put('policy', data)
        .then(response => {
          if (response.data) {
            const policy = response.data.data

            this.$store.commit('POLICIES_STORE/ARCHIVE_POLICY', policy.parent_id)
            this.$store.commit('POLICIES_STORE/CREATE_POLICY', policy)
            this.getData();

            this.$toast.success('Successfully updated policy.')
          } else {
            this.$toast.error('Something went wrong updating policy, please try again.')
          }
        })
        .catch(error => {
          this.$toast.error('Something went wrong updating policy, please try again.')
        })
    },
    getUserFullName(id) {
      let user = this.users.find(user => parseInt(user.id) === id);
      return user.profile.full_name;
    },
    calculateItemPercentage(v, t) {
      let total = t
      return v / total * 100
    },
    calculateGroupPolicyCompliance(group,state,percentage = false, active = true) {
      let policies = [];
      if(active) { //default behaviour is just the active policies
        policies = group.policies.filter((policy) => policy.archived === 0);
      } else { //get just the archived policies
        policies = group.policies.filter((policy) => policy.archived === 1);
      }
      let total = policies.length;
      let count = policies.filter((item) => item.status === state);
      if (percentage) {
        return this.calculateItemPercentage(count.length,total);
      }
      return count.length;
    },
    calculatePolicyObservationCompliance(id,policies,state,percentage = false) {
      let observations = this.getUserObservations(id,policies);
      let total = observations.length;
      let count = observations.filter((item) => item.agreement_status === state);
      if (percentage) {
        return this.calculateItemPercentage(count.length,total);
      }
      return count.length;
    },
    calculateTotalPolicyObservationCompliance(group,state,percentage = false) {
      let observations = [];
      group.actually_users.find((user) => {
        let userObservations = this.getUserObservations(user.id,user.policies);
        userObservations.forEach((o) => {
          observations.push(o);
        });
      })
      let total = observations.length;
      let count = observations.filter((item) => item.agreement_status === state);
      if (percentage) {
        return this.calculateItemPercentage(count.length,total);
      }
      return count.length;
    },
    getTimeSpent(from, to) {
      if (from && to) {
        let diff = this.$moment.duration(this.$moment(to).diff(this.$moment(from)));
        let duration = this.$moment.duration({
          days: diff.days(),
          hours: diff.hours(),
          minutes: diff.minutes(),
          seconds: diff.seconds()
        });
        return this.$moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
      } else {
        return '-';
      }
    },
    getDaysLapsed(from) {
      let diff = this.$moment.duration(this.$moment.utc().endOf('day').diff(this.$moment.utc(from)));
      let duration = this.$moment.duration({
        years: diff.years(),
        months: diff.months(),
        days: diff.days(),
      });
      return duration.as('days');
    },
    getGroupNames(data) {
      return data.map(group => {
        return this.groups.map(item => {
          if (item.id === group.id) {
            return item.name;
          }
        })
      }).join(", ");
    },
    exportAllPoliciesPDF(i) {
      const doc = new jsPDF()

      doc.setFontSize(16)
      doc.text(this.organisation.name, 14, 22)
      doc.text(`Crysp Policy Report - ${this.$moment().format('DD/MM/YYYY')}`, 14, 30)
      doc.setFontSize(11)
      doc.setTextColor(100)

      const group = this.live_policies[i];

      let policies = group.policies.map((policy) => {
        return {
          name: { content: policy.name, styles: { cellWidth: 60 } },
          last_review_date: policy.last_review_date ? this.$moment(policy.last_review_date).format("DD/MM/YYYY") : '-',
          next_review_date: policy.next_review_date ? this.$moment(policy.next_review_date).format("DD/MM/YYYY") : '-',
          status: { content: '', styles: { fillColor: this.statuses[policy.status].color, cellWidth: 16 } },
          groups: { content: this.getGroupNames(policy.groups), styles: { cellWidth: 50 } },
        }
      })

      autoTable(doc, {
        columns: [
          { header: "Name", dataKey: "name" },
          { header: "Issued", dataKey: "last_review_date" },
          { header: "Next Review", dataKey: "next_review_date" },
          { header: "Status", dataKey: "status" },
          { header: "Group(s)", dataKey: "groups" }
        ],
        body: policies,
        startY: 60,
        theme: 'plain',
        headStyles: { lineWidth: 0.25 },
        bodyStyles: { lineWidth: 0.25 }
      })

      doc.save(`crysp-policy-report-${group.name}.pdf`)
    },
    exportAllObservationsPDF(index) {
      const doc = new jsPDF()

      doc.setFontSize(16)
      doc.text(this.organisation.name, 14, 22)
      doc.text(`Crysp User Policy Report - ${this.$moment().format('DD/MM/YYYY')}`, 14, 30)
      doc.setFontSize(11)
      doc.setTextColor(100)
      doc.text(this.compliance_policies[index].name, 14, 40)

      const group = this.compliance_policies[index];

      group.actually_users.forEach((user,index) => {
        let bodyItems = []
        let posY = index == 0 ? 55 : doc.lastAutoTable.finalY + 10
        doc.text(this.getUserFullName(user.id), 14, posY)
        this.getUserObservations(user.id,user.policies).forEach(item => {
          let filtered = {
            name: { content: item.name, styles: { cellWidth: 60 } },
            status: { content: '', styles: { fillColor: item.agreement_status ? this.statuses[item.agreement_status].color : '', minCellWidth: 5 } },
            last_review_date: item.last_review_date ? this.$moment(item.last_review_date).format("DD/MM/YYYY") : '-',
            agreement_due_date: item.agreement_due_date ? this.$moment(item.agreement_due_date).format("DD/MM/YYYY : HH:mm") : '-',
            time: this.getDaysLapsed(item.agreement_due_date)
          }

          bodyItems.push(filtered);
        });

        autoTable(doc, {
          columns: [
            { dataKey: 'name', header: 'Policy' },
            { dataKey: 'status', header: 'Status' },
            { dataKey: 'last_review_date', header: 'Date Received' },
            { dataKey: 'agreement_due_date', header: 'Agreement Due Date' },
            { dataKey: 'time', header: 'Days Lapsed' }
          ],
          body: bodyItems,
          startY: index == 0 ? 60 : doc.lastAutoTable.finalY + 15,
          theme: 'plain',
          headStyles: { lineWidth: 0.25 },
          bodyStyles: { lineWidth: 0.25 }
        })
      });

      doc.save(`crysp-user-policy-report-${group.name}.pdf`)
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted() {
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Dashboard' , path: '/' },
      { text: 'Organisations' , path: '/organisation/all/' },
      { text: ':organisation' },
      { text: 'Policies' }
    ])

    this.replaceBreadcrumb({
      find: ':organisation',
      replace: { text: this.organisation.name, path: `/organisation/${this.organisation.id}` }
    })
  }
}
</script>
